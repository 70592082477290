import * as durationFormatter from './duration';
import * as secondsFormatter from './seconds';

export const DURATION = 'duration';
export const SECONDS = 'seconds';

const getFormatter = format => {
  switch (format) {
    case DURATION:
      return durationFormatter;
    case SECONDS:
      return secondsFormatter;
    default:
      return durationFormatter;
  }
};

export default getFormatter;
