import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DIV, IMG, VIDEO, TEXT, getTextStyle } from '../models';
import DivObject from './DivObject';
import ImgObject from './ImgObject';
import VideoObject from './VideoObject';
import TextObject from './TextObject';

const PieceObject = ({ object, imageSrcAttr, ...props }) => {
  const getTextStyleMemoized = useCallback(textObj => getTextStyle(textObj), [
    object,
  ]);
  switch (object.type) {
    case DIV:
      return (
        <DivObject className={object.classContent} object={object} {...props}>
          {object.childrens.map(obj => (
            <PieceObject
              key={obj.id}
              object={obj}
              imageSrcAttr={imageSrcAttr}
            />
          ))}
        </DivObject>
      );
    case IMG:
      return (
        <ImgObject
          className={object.classContent}
          object={object}
          imageSrcAttr={imageSrcAttr}
        />
      );
    case VIDEO:
      return (
        <VideoObject
          className={object.classContent}
          object={object}
          srcAttr={imageSrcAttr}
        />
      );
    case TEXT:
      return (
        <TextObject
          object={object}
          textContent={object.textContent}
          textStyleProperties={getTextStyleMemoized(object)}
          classNameSpan={object.classContent}
        />
      );
    default:
      return null;
  }
};

PieceObject.propTypes = {
  object: PropTypes.object.isRequired,
  imageSrcAttr: PropTypes.string.isRequired,
};

export default PieceObject;
