import React from 'react';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import { PopoverItem } from '../ui';
import { getSelectedKeyframes } from '../editor';
import { setKeyframesEasing } from '../project';

const ButtonSetKeyframesEasing = ({ label, onClick }) => (
  <PopoverItem label={label} onClick={onClick} />
);

ButtonSetKeyframesEasing.propTypes = {
  onClick: func.isRequired,
  label: string.isRequired,
};

const container = connect(
  state => ({
    keyframes: getSelectedKeyframes(state),
  }),
  {
    setKeyframesEasing,
  },
  ({ keyframes }, actions, { easing }) => ({
    label: easing.name,
    onClick: () => actions.setKeyframesEasing(keyframes, easing.timingFunction),
  }),
);

export default container(ButtonSetKeyframesEasing);
