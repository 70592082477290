import React from 'react';
import { node } from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';

const useStyles = createUseStyles({
  main: {
    background: colors.black2,
    display: 'flex',
    height: '100%',
    minHeight: 0,
    width: '100%',
    boxShadow: 'none',
    '& div': {
      boxShadow: 'none',
    },
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: 1,
    minHeight: 0,
    minWidth: 0,
  },
  bottom: {
    display: 'flex',
    flexGrow: 1,
    marginTop: 1,
    minHeight: 0,
  },
});

const Layout = ({ sidebar, topbar, stage, panels }) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      {sidebar}
      <div className={classes.content}>
        {topbar}
        <div className={classes.bottom}>
          {stage}
          {panels}
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  sidebar: node.isRequired,
  topbar: node.isRequired,
  stage: node.isRequired,
  panels: node.isRequired,
};

export default Layout;
