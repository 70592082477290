import React from 'react';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import { createHideableComponent } from '../ui/hideable';
import {
  PopoverItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ButtonPrimary,
  ButtonTertiary,
} from '../ui';
import { requestRemoveCollection, getCollectionByPath } from '../project';

const ModalContext = createHideableComponent();

const getID = path => `modal-remove-collection-${path}`;

export const ButtonOpenModalRemoveCollection = ({ collectionPath }) => (
  <ModalContext.Trigger id={getID(collectionPath)}>
    {({ show }) => <PopoverItem label="Remove Collection" onClick={show} />}
  </ModalContext.Trigger>
);

ButtonOpenModalRemoveCollection.propTypes = {
  collectionPath: string.isRequired,
};

const ModalRemoveCollection = ({
  collectionPath,
  collectionName,
  onRemove,
  ...props
}) => (
  <ModalContext.Target id={getID(collectionPath)} {...props}>
    {({ isVisible, hide }) =>
      isVisible && (
        <Modal isVisible={isVisible}>
          <ModalHeader>Confirm your action</ModalHeader>
          <ModalContent>
            Are you sure you want to remove <em>{collectionName}</em>
            collection? All assets within this collection will be also removed
            and this action is irreversible.
          </ModalContent>
          <ModalFooter>
            <ButtonTertiary onClick={hide} label="No, take me back" />
            <ButtonPrimary
              onClick={() => {
                hide();
                onRemove();
              }}
              label="Yes, remove it"
            />
          </ModalFooter>
        </Modal>
      )
    }
  </ModalContext.Target>
);

ModalRemoveCollection.propTypes = {
  collectionPath: string.isRequired,
  collectionName: string.isRequired,
  onRemove: func.isRequired,
};

const container = connect(
  (state, { collectionPath }) => ({
    collectionName: getCollectionByPath(state, collectionPath).name,
  }),
  (dispatch, { collectionPath }) => ({
    onRemove: () => dispatch(requestRemoveCollection(collectionPath)),
  }),
);

export default container(ModalRemoveCollection);
