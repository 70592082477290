import React from 'react';
import { CheckboxWithLabel } from '../ui';
import EditorContext, { TRANSFORMATION_BOX } from '../EditorContext';

const ToggleTransformationBox = () => (
  <EditorContext.Consumer>
    {({ isVisibleTool, toggleToolVisibility }) => (
      <CheckboxWithLabel
        small
        label="Transformation Box"
        checked={isVisibleTool(TRANSFORMATION_BOX)}
        onChange={() => toggleToolVisibility(TRANSFORMATION_BOX)}
      />
    )}
  </EditorContext.Consumer>
);

export default ToggleTransformationBox;
