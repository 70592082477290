import React from 'react';
import { object, string } from 'prop-types';
import { createUseStyles } from 'react-jss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { url } from '../App';
import { ButtonDefault } from '../ui';
import { getCurrentProjectID } from '../project';

const useStyles = createUseStyles({
  selectBox: {
    zIndex: 3,
    marginLeft: 24,
  },
});

const ButtonValidator = ({ projectID, history }) => {
  const classes = useStyles();
  const { location } = history;
  let label = 'Validator';
  let toURL = url.issueList({ projectID });

  if (location.pathname.match(/.*issues.*/)) {
    label = 'Back to RM';
    toURL = url.pieceList({ projectID });
  }

  return (
    <div className={classes.selectBox}>
      <ButtonDefault label={label} onClick={() => history.push(toURL)} />
    </div>
  );
};
ButtonValidator.propTypes = {
  history: object.isRequired,
  projectID: string.isRequired,
};

const container = connect(state => ({
  projectID: getCurrentProjectID(state),
}));

export default container(withRouter(ButtonValidator));
