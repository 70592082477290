import { useDrop } from 'react-dnd';
import { Types } from '../dnd';
import { requestMoveAssetsToCollection } from './actions';

export const dropTargetActions = {
  requestMoveAssetsToCollection,
};

const useDropAssets = props =>
  useDrop({
    accept: Types.ASSETS,
    drop({ assets }) {
      props.requestMoveAssetsToCollection(assets, props.path);
    },
    collect(monitor) {
      const item = monitor.getItem();

      return {
        isOver: monitor.isOver(),
        isDragging: !!item,
      };
    },
  });

export default useDropAssets;
