import React from 'react';
import { CheckboxWithLabel } from '../ui';
import EditorContext, { GUIDES } from '../EditorContext';

const ToggleGuides = () => (
  <EditorContext.Consumer>
    {({ isVisibleTool, toggleToolVisibility }) => (
      <CheckboxWithLabel
        small
        label="Show Guides"
        checked={isVisibleTool(GUIDES)}
        onChange={() => toggleToolVisibility(GUIDES)}
      />
    )}
  </EditorContext.Consumer>
);

export default ToggleGuides;
