import React from 'react';
import {
  Dimensions,
  FullSizeOption,
  Opacity,
  Origin,
  Position,
  Rotation,
  Scale,
  Translate,
  TextContent,
  FontSize,
  Color,
  FontFamily,
  ClassContent,
} from './toolsContainers';
import Section from './Section';
import SectionLabelBox from './SectionLabelBox';
import SectionLabel from './SectionLabel';
import Spacer from './Spacer';
import FormGroup from './FormGroup';

const TextTools = () => (
  <>
    <Section>
      <FormGroup>
        <SectionLabelBox>
          <SectionLabel title="Position:" />
        </SectionLabelBox>
        <Position />
      </FormGroup>
      <FormGroup>
        <SectionLabelBox>
          <SectionLabel title="Translate:" />
        </SectionLabelBox>
        <Translate />
      </FormGroup>
    </Section>
    <Section>
      <FormGroup>
        <SectionLabelBox>
          <SectionLabel title="Size:" />
        </SectionLabelBox>
        <Dimensions />
      </FormGroup>
      <FormGroup>
        <SectionLabelBox>
          <SectionLabel title="Scale:" />
        </SectionLabelBox>
        <Scale />
      </FormGroup>
    </Section>
    <Section>
      <FormGroup width={112}>
        <SectionLabelBox>
          <SectionLabel title="Opacity:" />
        </SectionLabelBox>
        <Opacity />
      </FormGroup>
      <Spacer width={16} />
      <FormGroup width={112}>
        <SectionLabelBox>
          <SectionLabel title="Rotation:" />
        </SectionLabelBox>
        <Rotation />
      </FormGroup>
      <FormGroup>
        <SectionLabelBox>
          <SectionLabel title="Origin:" />
        </SectionLabelBox>
        <Origin />
      </FormGroup>
    </Section>
    <Section>
      <FormGroup>
        <SectionLabelBox>
          <SectionLabel title="Font:" />
        </SectionLabelBox>
        <FontFamily />
      </FormGroup>
    </Section>
    <Section>
      <FormGroup>
        <SectionLabelBox>
          <SectionLabel title="Text Field:" />
        </SectionLabelBox>
        <TextContent />
      </FormGroup>
    </Section>
    <Section>
      <FormGroup>
        <SectionLabelBox>
          <SectionLabel title="Class Field:" />
        </SectionLabelBox>
        <ClassContent />
      </FormGroup>
    </Section>
    <Section>
      <FormGroup width={112}>
        <SectionLabelBox>
          <SectionLabel title="Size:" />
        </SectionLabelBox>
        <FontSize />
      </FormGroup>
      <Spacer width={16} />
    </Section>
    <Section>
      <FormGroup>
        <SectionLabelBox>
          <SectionLabel title="Fill:" />
        </SectionLabelBox>
        <Color />
      </FormGroup>
    </Section>
    <Section>
      <FormGroup>
        <FullSizeOption />
      </FormGroup>
    </Section>
  </>
);

export default TextTools;
