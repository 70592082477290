import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';
import { getClockDuration } from '../playback';
import { getObjectIsSelected } from '../editor';
import { time2px } from './utils';
import KeyframesTimeline from './KeyframesTimeline';

const useStyles = createUseStyles({
  timeline: ({ duration, isSelected }) => ({
    borderBottom: `1px solid ${colors.black2}`,
    borderRight: `1px solid ${colors.black2}`,
    height: 32,
    position: 'relative',
    width: time2px(duration),
    background: isSelected ? colors.highlightTimeLine : '',
  }),
});

const TimelineObject = ({ objectID, ...props }) => {
  const classes = useStyles(props);
  return (
    <>
      <div className={classes.timeline}>
        <KeyframesTimeline key={objectID} objectID={objectID} />
      </div>
    </>
  );
};

TimelineObject.propTypes = {
  objectID: PropTypes.string.isRequired,
};

const container = connect((state, { objectID }) => ({
  duration: getClockDuration(state),
  isSelected: getObjectIsSelected(objectID, state),
}));

export default container(TimelineObject);
