import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles(theme => ({
  content: {
    color: theme.fg,
    overflowWrap: 'break-word',
    width: '100%',
  },
}));

const ModalContent = ({ children, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ ...props, theme });
  return <div className={classes.content}>{children}</div>;
};

ModalContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalContent;
