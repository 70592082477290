const RESET = 'RESET';

export const reset = () => ({
  type: RESET,
});

export default reducer => (state, action) => {
  const newState = action.type === RESET ? undefined : state;
  return reducer(newState, action);
};
