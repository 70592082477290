export const OPPOSITE_AXIS = {
  left: 'top',
  top: 'left',
};

export const invertSignal = value => value - value * 2;

class GuidedMove {
  constructor() {
    this.resetValues();

    this.resetValues = this.resetValues.bind(this);
    this.sumAbsoluteValue = this.sumAbsoluteValue.bind(this);
    this.sumDelta = this.sumDelta.bind(this);
    this.getBiggerMoveAxis = this.getBiggerMoveAxis.bind(this);
    this.calculateGuidedPositionTo = this.calculateGuidedPositionTo.bind(this);
    this.calculatePosition = this.calculatePosition.bind(this);
  }

  hasMove() {
    const { top, left } = this.total;
    return left > 0 || top > 0;
  }

  resetValues() {
    this.total = { left: 0, top: 0 };
    this.delta = { left: 0, top: 0 };
    this.applied = { left: 0, top: 0 };
  }

  sumAbsoluteValue({ left, top }) {
    this.total.left += Math.abs(left);
    this.total.top += Math.abs(top);
  }

  sumDelta({ left, top }) {
    this.delta.left += left;
    this.delta.top += top;
  }

  getBiggerMoveAxis() {
    return this.total.left > this.total.top ? 'left' : 'top';
  }

  calculateGuidedPositionTo(axis) {
    const newOppositeValue = invertSignal(this.applied[OPPOSITE_AXIS[axis]]);
    const newValue = this.delta[axis] - this.applied[axis];
    this.applied = {
      [OPPOSITE_AXIS[axis]]: 0,
      [axis]: this.applied[axis] + newValue,
    };

    return {
      [OPPOSITE_AXIS[axis]]: newOppositeValue,
      [axis]: newValue,
    };
  }

  calculatePosition(movePosition) {
    this.sumAbsoluteValue(movePosition);
    this.sumDelta(movePosition);

    return this.calculateGuidedPositionTo(this.getBiggerMoveAxis());
  }
}

export default GuidedMove;
