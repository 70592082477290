import React from 'react';
import { func } from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors, ButtonPrimary } from '../ui';
import Content from './Content';

const useStyles = createUseStyles({
  box: {
    marginTop: 32,
    width: '100%',
  },
  buttonBox: {
    margin: 12,
    display: 'flex',
    justifyContent: 'right',
  },
  notification: {
    color: colors.black4,
  },
});

const ErrorPage = ({ refetch }) => {
  const classes = useStyles();
  return (
    <Content>
      <div className={classes.box}>
        <span className={classes.notification}>
          The list of items could not be loaded due to a connection failure.
        </span>
        <div className={classes.buttonBox}>
          <ButtonPrimary label="Retry" onClick={refetch} />
        </div>
      </div>
    </Content>
  );
};

ErrorPage.propTypes = {
  refetch: func.isRequired,
};

export default ErrorPage;
