import React from 'react';
import { createUseStyles } from 'react-jss';
import { colors } from '../../ui';

const useStyles = createUseStyles({
  spacer: {
    width: '100%',
    height: 2,
    backgroundColor: colors.black2,
    margin: [16, 0],
  },
});

const Spacer = () => {
  const classes = useStyles();
  return <div className={classes.spacer} />;
};

export default Spacer;
