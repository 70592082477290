import request from '../http';
import {
  nullDecoder,
  urlFactory,
  identityDecoder,
  encodeFilename,
} from './utils';

export const url = urlFactory('assets');

export const errors = {
  unknownError: 'Unknow error. Try again later.',
  createCollection: {
    alreadyExists: `A collection with this name already exists.`,
  },
};

export const createCollectionErrorDecoder = (response = {}) => {
  if (response.error !== 'AlreadyExistsError') {
    return errors.unknownError;
  }
  return errors.createCollection.alreadyExists;
};

const removePath = path =>
  request(
    {
      url: url(`${path}?op=remove`),
      method: 'DELETE',
    },
    nullDecoder,
  );

const upload = (path, file, filename) => {
  const formData = new FormData();
  formData.append('files', file, encodeFilename(filename));
  return request(
    {
      url: url(`${path}?op=upload`),
      method: 'POST',
      data: formData,
    },
    data => data[0],
  );
};

export default {
  getCollection: path =>
    request(
      {
        url: url(`${path}?op=list`),
        method: 'GET',
      },
      identityDecoder,
    ),

  createCollection: (projectID, name) =>
    request(
      {
        url: url(`/${projectID}/${name}?op=mkcol`),
        method: 'PUT',
      },
      identityDecoder,
      createCollectionErrorDecoder,
    ),

  removeCollection: removePath,

  uploadAsset: upload,

  replaceAssetFile: upload,

  moveAsset: (currentPath, newPath) =>
    request(
      {
        url: url(`${currentPath}?op=move&dest=${encodeURIComponent(newPath)}`),
        method: 'PUT',
      },
      identityDecoder,
    ),

  removeAsset: removePath,
};
