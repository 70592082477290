import React from 'react';
import { object } from 'prop-types';
import { ImageIcon } from '../icons';
import { colors } from '../ui';

export const getIconStyle = currentOffset => {
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }

  const width = 50;
  const height = 50;

  const { x, y } = currentOffset;
  const transform = `translate(${x - width / 2}px, ${y - height / 2}px)`;
  return {
    width,
    height,
    backgroundColor: colors.black1,
    fill: colors.gray1,
    border: `1px solid ${colors.black3}`,
    borderRadius: 2,
    transform,
    WebkitTransform: transform,
  };
};

const DragAsset = ({ currentOffset }) => (
  <ImageIcon style={getIconStyle(currentOffset)} />
);

DragAsset.defaultProps = {
  currentOffset: null,
};

DragAsset.propTypes = {
  currentOffset: object,
};

export default DragAsset;
