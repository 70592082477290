import React from 'react';
import PropTypes from 'prop-types';
import InputMultipleLines from '../../ui/Input/InputMultipleLines';

const textContentProperty = 'textContent';

const TextContent = ({ disabled, modifyTextContent, textContent }) => (
  <>
    <InputMultipleLines
      key={`input-${textContentProperty}`}
      id={`panel-properties-${textContentProperty}`}
      disabled={disabled}
      onChange={event => {
        modifyTextContent(event.target.value);
      }}
      value={!textContent ? '' : textContent}
      expanded
    />
  </>
);

TextContent.propTypes = {
  textContent: PropTypes.string,
  disabled: PropTypes.bool,
  modifyTextContent: PropTypes.func,
};

TextContent.defaultProps = {
  textContent: null,
  disabled: true,
  modifyTextContent: () => {},
};

export default TextContent;
