import React from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';
import { getObjectStyle } from '../models';
import PieceContext from './PieceContext';

class ImgObject extends React.Component {
  componentDidMount() {
    const { registerComponent } = this.context;
    registerComponent();
  }

  render() {
    const { className, object, imageSrcAttr } = this.props;
    const { isAMP, loaded, loadFinished } = this.context;
    const style = loaded ? undefined : { visibility: 'hidden' };

    if (isAMP) {
      return (
        <amp-img
          class={loaded ? className : ''}
          src={object.assetURL}
          alt={object.assetURL}
          width={`${object.width}px`}
          height={`${object.height}px`}
          layout="fixed"
          style={style}
        />
      );
    }

    return (
      <img
        onLoad={loadFinished}
        className={loaded ? className : ''}
        alt={object.assetURL}
        style={style}
        {...{ [imageSrcAttr]: object.assetURL }}
      />
    );
  }
}

ImgObject.contextType = PieceContext;

ImgObject.propTypes = {
  className: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired,
  imageSrcAttr: PropTypes.string.isRequired,
};

export default createComponent(getObjectStyle, ImgObject, [
  'object',
  'imageSrcAttr',
]);
