import React from 'react';
import cuid from 'cuid';
import PropTypes from 'prop-types';
import Context, { Component as ComponentModel } from './Context';

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.id = props.id || cuid();
    this.options = {
      permanent: props.hideableOptions.permanent || false,
      isVisible: props.hideableOptions.isVisible || false,
      showArgs: props.hideableOptions.showArgs || [],
      hideArgs: props.hideableOptions.hideArgs || [],
    };
  }

  componentDidMount() {
    this.context.register(this.id, {
      isVisible: this.options.isVisible,
      showArgs: this.options.showArgs,
      hideArgs: this.options.hideArgs,
    });
  }

  componentWillUnmount() {
    if (this.options.permanent === false) {
      this.context.unregister(this.id);
    }
  }

  render() {
    const { isVisible, showArgs, hideArgs } =
      this.context.components.get(this.id) || ComponentModel(this.options);
    return this.props.children({
      isVisible,
      showArgs,
      hideArgs,
      show: (...args) => this.context.show(this.id, ...args),
      hide: (...args) => this.context.hide(this.id, ...args),
    });
  }
}

Component.defaultProps = {
  id: null,
  hideableOptions: {},
};

Component.propTypes = {
  id: PropTypes.string,
  hideableOptions: PropTypes.object,
  children: PropTypes.func.isRequired,
};

Component.contextType = Context;

export default Component;
