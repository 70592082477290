import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Types } from '../dnd';
import { AssetModel } from '../models';
import { AssetIcon, Filename } from '../Assets';
import { SelectableEntry } from '../ui';
import PopoverAssetEntry, {
  ButtonOpenPopoverAssetEntry,
} from './PopoverAssetEntry';
import { selectAssetsOnClick, selectAssetsOnly } from './actions';
import { getAssetIsSelected, getSelectedAssets } from './reducers';

const AssetEntry = ({ asset, depth, isSelected, onClick, ...props }) => {
  const [, drag, connectDragPreview] = useDrag({
    item: { type: Types.ASSETS },
    begin() {
      let assets = props.selectedAssets;

      if (!assets.size || !assets.has(asset)) {
        props.selectAssetsOnly([asset.path]);
        assets = assets.clear().add(asset);
      }

      return { assets };
    },
  });
  connectDragPreview(getEmptyImage());

  return (
    <div ref={drag}>
      <SelectableEntry depth={depth} isSelected={isSelected} onClick={onClick}>
        <PopoverAssetEntry assetPath={asset.path} assetType={asset.assetType} />
        <AssetIcon assetType={asset.assetType} />
        <Filename filename={asset.filename} />
        <ButtonOpenPopoverAssetEntry assetPath={asset.path} />
      </SelectableEntry>
    </div>
  );
};

AssetEntry.defaultProps = {
  depth: 0,
};

AssetEntry.propTypes = {
  asset: PropTypes.instanceOf(AssetModel).isRequired,
  depth: PropTypes.number,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  selectAssetsOnly: PropTypes.func.isRequired,
  selectedAssets: PropTypes.object.isRequired,
};

const container = connect(
  (state, { asset }) => ({
    selectedAssets: getSelectedAssets(state),
    isSelected: getAssetIsSelected(state, asset.path),
  }),
  {
    selectAssetsOnClick,
    selectAssetsOnly,
  },
  (stateProps, actions, props) => ({
    ...props,
    ...stateProps,
    ...actions,
    onClick(e) {
      actions.selectAssetsOnClick(e, stateProps.isSelected, [props.asset.path]);
    },
  }),
);

export default container(AssetEntry);
