import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import Toggle from 'react-toggled';
import { createUseStyles } from 'react-jss';
import { CheckedIcon } from '../../icons';
import { checkbox as colors } from '../colors';

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    height: ({ small }) => (small ? 16 : 24),
    opacity: props => (props.disabled ? 0.35 : 1),
    pointerEvents: props => (props.disabled ? 'none' : 'auto'),
  },
  icon: {
    fill: colors.icon,
    opacity: props => (props.checked ? 1 : 0),
    height: 16,
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: ({ small }) => (small ? 16 : 24),
    outline: 'none',
    height: ({ small }) => (small ? 16 : 24),
    borderRadius: 4,
    border: `2px solid ${colors.box}`,
    boxSizing: 'border-box',
    background: props => (props.checked ? colors.box : 'transparent'),
  },
});

const Checkbox = ({ small, disabled, checked, onChange }) => {
  const classes = useStyles({ small, disabled, checked });
  const onToggle = useCallback(
    on => {
      if (disabled || checked === on) {
        return;
      }

      onChange(on);
    },
    [checked, disabled, onChange],
  );

  return (
    <Toggle on={checked} onToggle={onToggle}>
      {({ getTogglerProps }) => (
        <div className={classes.wrapper}>
          <button
            {...getTogglerProps()}
            disabled={disabled}
            className={classes.box}
            type="button"
          >
            <CheckedIcon className={classes.icon} />
          </button>
        </div>
      )}
    </Toggle>
  );
};

Checkbox.propTypes = {
  onChange: propTypes.func.isRequired,
  checked: propTypes.bool,
  disabled: propTypes.bool,
  small: propTypes.bool,
};

Checkbox.defaultProps = {
  disabled: false,
  checked: false,
  small: false,
};

export default Checkbox;
