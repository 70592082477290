import React from 'react';
import FormGroup from './FormGroup';
import Section from './Section';
import SectionLabelBox from './SectionLabelBox';
import SectionLabel from './SectionLabel';
import { Dimensions, Position, ClassContent } from './toolsContainers';

const ClipPathTools = () => (
  <>
    <Section>
      <FormGroup>
        <SectionLabelBox>
          <SectionLabel title="Position:" />
        </SectionLabelBox>
        <Position />
      </FormGroup>
    </Section>
    <Section>
      <FormGroup>
        <SectionLabelBox>
          <SectionLabel title="Size:" />
        </SectionLabelBox>
        <Dimensions />
      </FormGroup>
    </Section>
    <Section>
      <FormGroup>
        <SectionLabelBox>
          <SectionLabel title="Class Field:" />
        </SectionLabelBox>
        <ClassContent />
      </FormGroup>
    </Section>
  </>
);

export default ClipPathTools;
