const primary = '#FF33B6';
const primaryLight = '#FF55C4';
const primaryDark = '#DD0090';

const black1 = '#000000';
const black2 = '#1E1E1E';
const black3 = '#232323';
const black4 = '#282828';
const black5 = '#161616';

const gray1 = '#5A5A5A';
const gray2 = '#D9D9D9';
const gray3 = '#F2F2F2';
const gray4 = '#CCCCCC';
const white = '#FFFFFF';
const yellow = '#FFD615';

const successLight = '#5EDFAA';
const success = '#33D794';
const successDark = '#23B479';
const errorLight = '#FF4F4F';
const error = '#FF2B2B';
const errorDark = '#D42424';
const info = '#5AD0EE';
const alert = '#FFB700';

const selected = '#0999DB';
const unselected = '#232323';

const highlightTimeLine = '#373737';

export default {
  primary,
  primaryLight,
  primaryDark,

  black1,
  black2,
  black3,
  black4,
  black5,
  gray1,
  gray2,
  gray3,
  gray4,
  white,
  yellow,

  successLight,
  success,
  successDark,
  errorLight,
  error,
  errorDark,
  info,
  alert,

  selected,
  unselected,

  highlightTimeLine,
};

export const button = {
  bg: {
    normal: black4,
    hover: black4,
    active: black2,
    disabled: black4,
  },
  fg: {
    normal: white,
    hover: white,
    active: white,
    disabled: gray3,
  },
};

export const toolbarButton = {
  border: {
    normal: gray1,
    hover: gray2,
    active: gray2,
    disabled: gray1,
  },
  bg: {
    normal: gray1,
    hover: black4,
    active: black2,
    disabled: gray1,
  },
  fg: {
    normal: white,
    hover: white,
    active: white,
    disabled: gray3,
  },
};

export const input = {
  bg: {
    normal: black2,
    hover: black2,
    active: black2,
    disabled: black2,
  },
  fg: {
    normal: white,
    hover: white,
    active: white,
    disabled: gray1,
  },
  border: {
    normal: black2,
    hover: gray2,
    focus: gray3,
    disabled: black2,
  },
};

export const checkbox = {
  icon: white,
  box: primary,
  label: white,
};
