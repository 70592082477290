import cuid from 'cuid';
import escapeRegExp from 'lodash/escapeRegExp';
import max from 'lodash/max';
import { Set, Map } from 'immutable';
import {
  AssetModel,
  CollectionModel,
  DIV,
  IMG,
  TEXT,
  VIDEO,
  CLIPPATH,
  AMP_PROHIBITED_PROPERTIES,
} from '../models';
import { decodeFilename } from '../utils';
import { extractScaleFromMatrix } from '../geometry';
import { applyScale } from '../math';

export const buildPieceID = piece => `${piece.width}x${piece.height}`;

export const getBiggestObject = objects =>
  objects.maxBy(({ height, width }) => height * width);

export const getKeyframesSorted = object =>
  object
    .get('keyframes')
    .toList()
    .sortBy(({ time }) => time);

export const getKeyframesTimeSorted = object =>
  object
    .get('keyframes')
    .keySeq()
    .sortBy(time => time);

const getNextCount = (name, state) => {
  const numbers = [];
  const regex = RegExp(`^${escapeRegExp(name)} (\\d+)$`);
  state.forEach(object => {
    const match = object.name.match(regex);
    if (match) {
      numbers.push(Number(match[1]));
    }
  });
  return max(numbers) + 1 || 1;
};

export const getObjectNameByType = object => {
  switch (object.type) {
    case CLIPPATH:
      return 'clip';
    case DIV:
      return 'mask';
    case TEXT:
      return 'text';
    case IMG:
    case VIDEO:
      return object.name.split('.')[0];
    default:
      return 'unknown';
  }
};

export const setObjectName = (state, createdObject) => {
  if (createdObject.name.match(/^root/) && createdObject.type === DIV) {
    return createdObject.name;
  }

  const name = getObjectNameByType(createdObject);
  const nameExists = state.find(object => object.name === name);
  if (!nameExists) {
    return name;
  }
  const count = getNextCount(name, state);
  return `${name} ${count}`;
};

const getObjectLeft = object => object.getIn(['left']);
const getObjectTop = object => object.getIn(['top']);

export const getObjectDimensions = (object, parentMatrix) => {
  const parentScale = extractScaleFromMatrix(parentMatrix);
  return {
    width: object.dimensions.width * parentScale.scaleX,
    height: object.dimensions.height * parentScale.scaleY,
  };
};

export const getPlainObject = object => ({
  ...getObjectDimensions(object),
  left: getObjectLeft(object),
  top: getObjectTop(object),
});

export const setObjectProperty = (property, currentTime, value) => object =>
  object.apply({ [property]: value }, currentTime);

export const scaleObjectProperty = (property, currentTime, scale) => object =>
  object.apply(
    { [property]: applyScale(object[property], scale) },
    currentTime,
  );
export const setStaticObjectProperty = (property, value) => object =>
  object.apply({ [property]: value }, 0);

export const roundValue = val => Math.floor(val);

export const increaseObjectProperty = (
  property,
  currentTime,
  value,
) => object =>
  object.apply({ [property]: object[property] + value }, currentTime);

export const mergeObjectProperties = () => oldObject => oldObject;

export const reduceObjects = (ids, updater) => state =>
  ids.reduce(updater, state);

export const hasAnimation = object => !object.get('keyframes').isEmpty();

export const computeEditorOptions = (object, { isAMP = false } = {}) =>
  object
    .setIn(['editorOptions', 'beingAnimated'], hasAnimation(object))
    .setIn(
      ['editorOptions', 'prohibitedProperties'],
      isAMP ? Set(AMP_PROHIBITED_PROPERTIES) : Set([]),
    );

export const pieceToString = ({ height, width }) => `${width}x${height}`;

export const makeReducer = (initialState, handlers) => (
  state = initialState,
  action,
) => {
  const handler = handlers[action.type];
  if (!handler) {
    return state;
  }
  return handler(state, action.payload);
};

export const makeAsset = ({ assetType, path, url, dimensions, checksum }) => {
  const list = path.split('/');
  const filename = decodeFilename(list[list.length - 1]);
  const { height, width } = dimensions;
  return AssetModel({
    assetType,
    path,
    url: `${url}#${checksum}`,
    filename,
    width,
    height,
    checksum,
  });
};

export const makeCollection = ({ path }) => {
  const list = path.split('/');
  const name = decodeFilename(list[list.length - 1]);
  return CollectionModel({ path, name });
};

export const duplicateObject = object => {
  let newObject = object.set('id', cuid()).set('inheritance', null);
  newObject = newObject.update('keyframes', keyframes =>
    keyframes.map(keyframe => keyframe.set('id', cuid())),
  );

  return newObject;
};

export const getAllObjectIDsFromTree = (currentList, node) =>
  currentList.add(node.objectID).concat(node.childrensID);

export const getStartAndEndTimeFromKeyframes = keyframes => {
  if (!keyframes.size) {
    return [0, 0];
  }

  return keyframes
    .map(({ time }) => parseInt(time, 10))
    .reduce(
      ([startTime, endTime], val) => {
        return [Math.min(startTime, val), Math.max(endTime, val)];
      },
      [Infinity, 0],
    );
};

export const removeKeyframesBetween = (startTime, endTime) => keyframes =>
  keyframes.filterNot(
    keyframe => keyframe.time >= startTime && keyframe.time <= endTime,
  );

export const buildMaskByObjectIDFromObjects = objects =>
  objects.reduce((currentMap, obj) => {
    if (obj.type !== CLIPPATH) {
      return currentMap;
    }

    return currentMap.set(obj.targetObjectID, obj.id);
  }, Map({}));
