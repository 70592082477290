import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';
import colors from '../colors';
import * as typography from '../typography';

const useStyles = createUseStyles(theme => ({
  wrapper: {
    display: 'flex',
    marginRight: 8,
  },
  input: {
    ...typography.textRegular,
    backgroundColor: 'transparent',
    marginTop: 8,
    border: `1px solid`,
    borderColor: theme.fg,
    borderRadius: 20,
    color: theme.fg,
    padding: [15, 16, 10, 16],
    height: 103,
    width: 320,
    outline: 'none',
    cols: '40',
    rows: '5',
    fontSize: 14,
    resize: 'none',
    '&:disabled': {
      background: 'none',
      opacity: 0.35,
    },
    '&::placeholder': {
      color: theme.fg,
    },
    '&:focus': {
      boxShadow: 'none',
      '&::placeholder': {
        color: 'transparent',
      },
      borderColor: colors.primary,
    },
  },
  error: {
    ...typography.label,
    color: colors.error,
    display: 'block',
    marginTop: 8,
  },
}));

const TextArea = ({ expanded, error, onChange, required, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ ...props, theme });
  delete props.theme; // eslint-disable-line react/prop-types, no-param-reassign
  return (
    <div>
      <div className={classes.wrapper}>
        <textarea
          className={classes.input}
          onChange={onChange}
          required={required}
          {...props}
        />
      </div>
      <span className={classes.error}>{error}</span>
    </div>
  );
};

TextArea.propTypes = {
  expanded: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

TextArea.defaultProps = {
  expanded: null,
  error: null,
  onChange: () => undefined,
  required: false,
};

export default TextArea;
