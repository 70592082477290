import React, { Component, createRef } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import {
  bool,
  object,
  node,
  number,
  instanceOf,
  func,
  oneOfType,
  arrayOf,
} from 'prop-types';
import { List, Record } from 'immutable';
import Guide from './Guide';
import RulerBox, { RULER_SIZE } from './RulerBox';
import { getZoomScale } from '../../stage';
import {
  getCurrentPiece,
  createGuide,
  deleteGuide,
  moveGuide,
  getGuides,
  getLastCreatedGuide,
} from '../../project';
import { PieceModel, HORIZONTAL, VERTICAL } from '../../models';
import ToggleDisable from './ToggleDisable';

class Ruler extends Component {
  constructor() {
    super();

    this.rulerRef = createRef();
    this.state = { movingGuideID: null, disabled: false };

    this.addGuide = this.addGuide.bind(this);
    this.moveLastCreatedGuide = this.moveLastCreatedGuide.bind(this);
    this.moveGuideById = this.moveGuideById.bind(this);
    this.isInitialGuidePosition = this.isInitialGuidePosition.bind(this);
    this.setAsMoving = this.setAsMoving.bind(this);
    this.onGuideDrop = this.onGuideDrop.bind(this);
    this.onToggleDisable = this.onToggleDisable.bind(this);
  }

  onGuideDrop(guideID, event) {
    if (this.isInitialGuidePosition(guideID, event)) {
      this.props.deleteGuide({ guideID });
    }

    this.setState({ movingGuideID: false });
  }

  onToggleDisable() {
    this.setState(state => ({
      ...state,
      disabled: !state.disabled,
    }));
  }

  getPositionByPiece(cursor) {
    const { top, left, scale } = this.props;
    const { current } = this.rulerRef;

    const { x = 0, y = 0 } = current.getBoundingClientRect();

    const diffY = y + top;
    const diffX = x + left;

    return {
      y: Math.floor((cursor.y - diffY) / scale),
      x: Math.floor((cursor.x - diffX) / scale),
    };
  }

  setAsMoving(guideID) {
    this.setState({
      movingGuideID: guideID,
    });
  }

  isInitialGuidePosition(id = null, { clientY, clientX }) {
    const { guides } = this.props;
    const { movingGuideID } = this.state;
    const { current } = this.rulerRef;
    const { x = 0, y = 0 } = current.getBoundingClientRect();

    const currentGuide = guides.find(
      guide => guide.id === (id || movingGuideID),
    );
    return (
      (currentGuide.orientation === VERTICAL && clientX <= x + RULER_SIZE) ||
      (currentGuide.orientation === HORIZONTAL && clientY <= y + RULER_SIZE)
    );
  }

  addGuide(orientation, position) {
    const { x, y } = this.getPositionByPiece(position);

    this.props.createGuide({
      guide: {
        x,
        y,
        orientation,
        pieceID: this.props.piece.get('id'),
      },
    });
  }

  moveLastCreatedGuide(position) {
    const { lastCreatedGuide } = this.props;
    this.setState({ movingGuideID: lastCreatedGuide.id });
    this.moveGuideById(lastCreatedGuide.id, position);
  }

  moveGuideById(guideID, position) {
    this.props.moveGuide({
      guideID,
      position: this.getPositionByPiece(position),
    });
  }

  render() {
    const { disabled } = this.state;
    const {
      classes,
      children,
      top,
      left,
      scale,
      guides,
      isVisible,
    } = this.props;

    return (
      <div className={classes.container} ref={this.rulerRef}>
        <RulerBox
          orientation={HORIZONTAL}
          onCreate={this.addGuide}
          onMove={this.moveLastCreatedGuide}
          onUp={this.onGuideDrop}
          disabled={disabled}
        />
        <RulerBox
          orientation={VERTICAL}
          onCreate={this.addGuide}
          onMove={this.moveLastCreatedGuide}
          onUp={this.onGuideDrop}
          disabled={disabled}
        />
        <ToggleDisable
          checked={this.state.disabled}
          onToggle={this.onToggleDisable}
        />
        {guides.map(guide => (
          <Guide
            guide={guide}
            key={guide.id}
            top={top}
            left={left}
            scale={scale}
            isMoving={guide.id === this.state.movingGuideID}
            onDown={this.setAsMoving}
            onMove={this.moveGuideById}
            onUp={this.onGuideDrop}
            isVisible={isVisible}
            disabled={disabled}
          />
        ))}

        <div className={classes.wrapper}>{children}</div>
      </div>
    );
  }
}

Ruler.propTypes = {
  classes: object.isRequired,
  children: oneOfType([node, arrayOf(node)]).isRequired,
  guides: instanceOf(List),
  piece: instanceOf(Record).isRequired,
  lastCreatedGuide: instanceOf(Record),
  createGuide: func.isRequired,
  moveGuide: func.isRequired,
  deleteGuide: func.isRequired,
  isVisible: bool,
  left: number,
  top: number,
  scale: number,
};

Ruler.defaultProps = {
  isVisible: true,
  left: 0,
  top: 0,
  scale: 1,
  guides: List(),
  lastCreatedGuide: null,
};

const style = injectSheet({
  container: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  wrapper: {
    height: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
});

const container = connect(
  (state, { top, left }) => {
    const scale = getZoomScale(state);

    return {
      piece: getCurrentPiece(state) || PieceModel(),
      lastCreatedGuide: getLastCreatedGuide(state),
      guides: getGuides(state),
      scale,
      left: left * scale,
      top: top * scale,
    };
  },
  {
    createGuide,
    moveGuide,
    deleteGuide,
  },
);

export default container(style(Ruler));
