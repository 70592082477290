import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';
import { getObjectStyle } from '../models';
import PieceContext from './PieceContext';

const VideoObject = ({ className, object, srcAttr }) => {
  const videoRef = useRef();
  const { loaded, loadFinished, registerComponent } = useContext(PieceContext);
  useEffect(() => {
    registerComponent();
  }, [registerComponent]);
  useEffect(() => {
    const video = videoRef.current;
    if (video && loaded && object.autoPlay) {
      video.play();
    }
  }, [loaded]);
  const style = loaded ? undefined : { visibility: 'hidden' };
  const props = {
    [srcAttr]: object.assetURL,
    'data-autoplay': object.autoPlay,
  };

  return (
    <video
      ref={videoRef}
      onLoadedData={loadFinished}
      className={loaded ? className : ''}
      alt={object.assetURL}
      style={style}
      muted={object.muted}
      {...props}
    />
  );
};

VideoObject.propTypes = {
  className: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired,
  srcAttr: PropTypes.string.isRequired,
};

export default createComponent(getObjectStyle, VideoObject, [
  'object',
  'srcAttr',
]);
