import isFinite from 'lodash/isFinite';

const REGEX = /^(\d*):(\d{1,2}):(\d{1,2})$/;
export const DEFAULT_VALUE = '00:00:00';
export const DEFAULT_SPACER = ':';

export const leftNumFill = num => `${num}`.padStart(2, 0);

export const getValueFromProps = milliseconds => {
  const minutes = leftNumFill(Math.floor(milliseconds / (60 * 1000)));
  const seconds = leftNumFill(
    Math.floor((milliseconds - minutes * 60 * 1000) / 1000),
  );
  const parsedMilliseconds = leftNumFill(
    Math.floor((milliseconds - minutes * 60 * 1000 - seconds * 1000) / 10),
  );

  return `${minutes}:${seconds}:${parsedMilliseconds}`;
};

export const convertToDecimal = numberStr => parseInt(numberStr, 10);

export const getTime = timeStr => {
  const [minutes, seconds, milliseconds] = timeStr.split(':');
  return { minutes, seconds, milliseconds };
};

export const isValidTime = timeStr => {
  const { seconds, milliseconds } = getTime(timeStr);

  return convertToDecimal(seconds) < 60 && convertToDecimal(milliseconds) < 100;
};

export const convertToMS = ({ minutes, seconds, milliseconds }) =>
  minutes * (60 * 1000) + seconds * 1000 + milliseconds * 10;

export const isValidValue = timeStr => timeStr.match(REGEX);

export const isValidNumber = numberStr => isFinite(convertToDecimal(numberStr));

export const oldDigitIsMask = oldDigit => oldDigit === DEFAULT_SPACER;

export const getNewDigitWithMask = newDigit => ({
  newDigit: `${DEFAULT_SPACER}${newDigit}`,
  deltaPosition: 1,
});

export const getDigitFromOldDigit = oldDigit =>
  isValidNumber(oldDigit) ? 0 : DEFAULT_SPACER;

export const isOnlyMask = () => false;
