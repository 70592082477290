import { useState } from 'react';

const createAssetsLoader = () => {
  const [state, setState] = useState({
    loaded: false,
    pendingLoading: 0,
    registerComponent() {
      setState(oldState => ({
        ...oldState,
        pendingLoading: oldState.pendingLoading + 1,
      }));
    },
    loadFinished() {
      setState(oldState => {
        const pendingLoading = oldState.pendingLoading - 1;
        return {
          ...oldState,
          loaded: pendingLoading === 0,
          pendingLoading,
        };
      });
    },
  });

  return state;
};

export default createAssetsLoader;
