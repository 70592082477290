import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';
import colors from '../colors';
import * as typography from '../typography';

const useStyles = createUseStyles(theme => ({
  input: {
    ...props => (props.big ? typography.textRegular : typography.label),
    backgroundColor: 'transparent',
    border: `1px solid`,
    borderColor: ({ invalid }) => (invalid ? colors.error : theme.fg),
    borderRadius: props => (props.rounded ? 20 : 4),
    color: theme.fg,
    paddingBottom: 0,
    paddingLeft: props => (props.big ? 16 : 8),
    paddingRight: props => (props.big ? 16 : 8),
    paddingTop: 0,
    outline: 'none',
    height: props => (props.big ? 40 : 24),
    maxWidth: props => {
      if (props.expanded) {
        return '100%';
      }
      if (props.big) {
        return 350;
      }

      return 72;
    },
    width: props => (props.expanded ? '100%' : 'auto'),
    textAlign: 'left',
    '&:disabled': {
      background: 'none',
      opacity: 0.35,
    },
    '&::placeholder': {
      color: theme.fg,
    },
    '&:focus': {
      boxShadow: 'none',
      '&::placeholder': {
        color: 'transparent',
      },
      borderColor: colors.primary,
    },
  },
}));

const Input = ({ ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ ...props, theme });
  delete props.expanded; // eslint-disable-line no-param-reassign
  delete props.big; // eslint-disable-line no-param-reassign
  delete props.invalid; // eslint-disable-line no-param-reassign
  delete props.rounded; // eslint-disable-line no-param-reassign
  return <input type="text" className={classes.input} {...props} />;
};

Input.propTypes = {
  rounded: PropTypes.bool,
  expanded: PropTypes.bool,
  big: PropTypes.bool,
  invalid: PropTypes.bool,
};

Input.defaultProps = {
  invalid: false,
  expanded: false,
  rounded: false,
  big: false,
};

export default Input;
