import React from 'react';
import PropTypes from 'prop-types';
import { explicitNull, or } from 'airbnb-prop-types';
import { FloatInput } from '../../ui';
import { PROPERTIES_PRECISION } from '../../models';

const rotationProperty = 'rotation';
const rotationPrecision = PROPERTIES_PRECISION[rotationProperty];

const Rotation = ({ rotation, disabled, rotationChange }) => (
  <FloatInput
    id={rotationProperty}
    disabled={disabled}
    onChange={rotationChange}
    value={rotation}
    precision={rotationPrecision}
    expanded
  />
);

Rotation.propTypes = {
  rotation: or([PropTypes.number, explicitNull()]),
  disabled: PropTypes.bool,
  rotationChange: PropTypes.func,
};

Rotation.defaultProps = {
  rotation: null,
  disabled: true,
  rotationChange: () => {},
};

export default Rotation;
