import { useCallback, useState } from 'react';

export const useHideableState = (options = { isVisible: false }) => {
  const [isVisible, setIsVisible] = useState(options.isVisible);
  const toggleVisibility = useCallback(() => {
    setIsVisible(oldState => !oldState);
  }, [setIsVisible]);
  const show = useCallback(() => {
    setIsVisible(true);
  }, [setIsVisible]);
  const hide = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  return { isVisible, toggleVisibility, show, hide };
};
