import React from 'react';
import { createUseStyles } from 'react-jss';
import { Redirect } from 'react-router-dom';
import { WhiteTheme } from '../ui';
import logo from './login-logo.png';
import FormLogin from './FormLogin';
import { url } from '../App';
import { isAuthenticated } from '../auth';

const useStyles = createUseStyles({
  pageWrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',
    minHeight: 0,
    minWidth: 0,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: 350,
    height: 'auto',
  },
  image: {
    display: 'block',
    margin: '0 auto 10px',
  },
});

const ViewLogin = () => {
  const classes = useStyles();
  return isAuthenticated() ? (
    <Redirect to={url.home()} />
  ) : (
    <WhiteTheme>
      <div className={classes.pageWrapper}>
        <div className={classes.container}>
          <img src={logo} className={classes.image} alt="Login" />
          <FormLogin />
        </div>
      </div>
    </WhiteTheme>
  );
};

export default ViewLogin;
