import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PopoverItem } from '../ui';
import { removeObjectsByPieceID, removePieceParent } from '../project';

const ResetPiece = ({ onClick }) => (
  <PopoverItem onClick={onClick} label="Limpar peça" />
);

ResetPiece.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { pieceID }) => ({
  onClick() {
    dispatch(removeObjectsByPieceID(pieceID));
    dispatch(removePieceParent(pieceID));
  },
});

export default connect(null, mapDispatchToProps)(ResetPiece);
