import getObjectAnimationStyle, {
  buildProperties,
} from './object-style-animation';
import { ALL_PROPERTIES } from './properties';
import { VIDEO, DIV, IMG, CLIPPATH, TEXT } from './object';

export const convertObjectToValues = object => {
  // FIXME it is a workaround to not break the getters on clipPathObject
  // list of getters affected left, top, width, height
  if (object.type === CLIPPATH) {
    return {
      clipPath: object.clipPath,
    };
  }

  return ALL_PROPERTIES.reduce(
    (values, prop) => ({
      ...values,
      [prop]: object[prop],
    }),
    {},
  );
};

const getObjectSpecificStyle = object => {
  switch (object.type) {
    case IMG:
    case VIDEO:
      return {
        position: 'absolute',
      };
    case TEXT:
    case DIV:
      return {
        overflow: 'hidden',
        boxSizing: 'border-box',
        backgroundColor: object.backgroundColor,
        position: 'absolute',
      };
    default:
      return {};
  }
};

export const getObjectBaseStyle = object => {
  if (!object) {
    return null;
  }

  return {
    ...buildProperties(convertObjectToValues(object)),
    ...getObjectSpecificStyle(object),
  };
};

export const getObjectStyle = ({ object }) => ({
  ...getObjectBaseStyle(object),
  ...getObjectBaseStyle(object.clipPathObject),
  ...getObjectAnimationStyle(object, object.clipPathObject),
});
