import React from 'react';
import { connect } from 'react-redux';
import { bool, string } from 'prop-types';
import { createUseStyles } from 'react-jss';
import Objects from './Objects';
import { getCurrentPieceID } from '../stage';
import { getRootNodeFromPieceID } from '../project';

const perspective = 1;
const WebkitPerspective = perspective;
const MozPerspective = perspective;

const useStyles = createUseStyles({
  piece: ({ left, top, allowOverflow = true }) => ({
    left,
    top,
    outline: 'none',
    position: 'absolute',
    overflow: allowOverflow ? 'visible' : 'hidden',
  }),
});

const Piece = ({ pieceID, rootObjectID, ...props }) => {
  const classes = useStyles(props);
  if (!pieceID || !rootObjectID) {
    return null;
  }

  return (
    <div className={classes.piece}>
      <Objects
        objectID={rootObjectID}
        pieceID={pieceID}
        innerStyle={{
          position: 'relative',
          overflow: 'visible',
          perspective,
          WebkitPerspective,
          MozPerspective,
        }}
      />
    </div>
  );
};

Piece.defaultProps = {
  pieceID: null,
  rootObjectID: null,
  allowOverflow: true,
};

Piece.propTypes = {
  pieceID: string,
  rootObjectID: string,
  allowOverflow: bool,
};

const container = connect(state => {
  const pieceID = getCurrentPieceID(state);

  return {
    pieceID,
    rootObjectID: getRootNodeFromPieceID(state, pieceID).objectID,
  };
});

export default container(Piece);
