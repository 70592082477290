import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PopoverItem } from '../ui';
import { getSelectedObjects, setObjectToRename } from '../editor';

const ButtonRenameLayer = () => {
  const selectedObjects = useSelector(state => getSelectedObjects(state));
  const dispatch = useDispatch();
  const object = selectedObjects.get(0);
  const disabled = selectedObjects.size !== 1 || !object;
  const handleOnClick = useCallback(() => {
    if (disabled) {
      return;
    }
    if (object.id) {
      dispatch(setObjectToRename(object.id));
    }
  }, [dispatch, object, disabled]);

  return (
    <PopoverItem
      label="Rename Layer"
      onClick={handleOnClick}
      disabled={disabled}
    />
  );
};
export default ButtonRenameLayer;
