import React from 'react';
import { func, object } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../auth';
import { url } from './urls';

const PrivateRoute = ({ component: Component, location, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: url.login(),
            state: { next: location },
          }}
        />
      )
    }
  />
);

PrivateRoute.defaultProps = {
  location: {},
};

PrivateRoute.propTypes = {
  component: func.isRequired,
  location: object,
};

export default PrivateRoute;
