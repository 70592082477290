import React, { useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { createUseStyles } from 'react-jss';
import EntryObject from './EntryObject';
import { colors } from '../ui';
import {
  getIsClipPathID,
  getObjectIDHasClipPath,
  getIndexByObjectID,
} from '../selectors';
import { TIMELINE_OBJECT } from '../dnd';
import { ensureTimelineDragSelection, getIsObjectLocked } from '../editor';
import { moveSelectedObjectsToIndex } from '../project';
import { calculateDropOffset } from './dnd-utils';
import { UP, DOWN } from './dnd-constants';

const useStyles = createUseStyles({
  entryWrapper: {
    position: 'relative',
  },
  hoverPreview: {
    height: 2,
    width: '100%',
    zIndex: 1,
    position: 'absolute',
    backgroundColor: colors.selected,
  },
});

export const positionProperty = {
  [DOWN]: 'bottom',
  [UP]: 'top',
};

export const positionValue = {
  [UP]: 0,
  [DOWN]: -2,
};

export const buildHoverPreviewStyle = (isOver, position) => {
  if (!isOver || !position) {
    return { visibility: 'hidden' };
  }

  return {
    [positionProperty[position]]: positionValue[position],
  };
};

const DraggableEntryObject = ({ objectID }) => {
  const ref = useRef();
  const [hoverPreviewPosition, setHoverPreviewPosition] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const isClipPath = useSelector(state => getIsClipPathID(state, objectID));
  const hasClipPath = useSelector(state =>
    getObjectIDHasClipPath(state, objectID),
  );
  const isLocked = useSelector(state => getIsObjectLocked(state, objectID));
  const index = useSelector(state => getIndexByObjectID(state, objectID));
  const evaluateDropPosition = useCallback(
    (item, monitor) => {
      const clientRect = ref.current.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      const dropOffset = calculateDropOffset(clientRect, clientOffset);

      if (isClipPath && dropOffset === UP) {
        return null;
      }

      if (hasClipPath && dropOffset === DOWN) {
        return null;
      }

      return dropOffset;
    },
    [ref, isClipPath, hasClipPath],
  );
  const [{ isOver }, drop] = useDrop(
    {
      accept: TIMELINE_OBJECT,
      hover: (item, monitor) => {
        if (!ref.current) {
          return;
        }

        const dropOffset = evaluateDropPosition(item, monitor);
        setHoverPreviewPosition(dropOffset);
      },
      collect(monitor) {
        return { isOver: monitor.isOver() };
      },
      drop(item, monitor) {
        if (monitor.getItem().index === index) {
          return;
        }

        const dropOffset = evaluateDropPosition(item, monitor);
        if (!dropOffset) {
          return;
        }

        const newIndex = dropOffset === UP ? index + 1 : index;
        dispatch(moveSelectedObjectsToIndex(newIndex));
      },
    },
    [setHoverPreviewPosition, evaluateDropPosition, index],
  );
  const [, drag, connectDragPreview] = useDrag(
    {
      item: { type: TIMELINE_OBJECT, index },
      canDrag() {
        return !isClipPath && !isLocked;
      },
      begin() {
        dispatch(ensureTimelineDragSelection(objectID));
      },
    },
    [isClipPath, isLocked],
  );
  connectDragPreview(getEmptyImage());
  drag(drop(ref));

  return (
    <div className={classes.entryWrapper} ref={ref}>
      <div
        className={classes.hoverPreview}
        style={buildHoverPreviewStyle(isOver, hoverPreviewPosition)}
      />
      <EntryObject objectID={objectID} />
    </div>
  );
};

DraggableEntryObject.propTypes = {
  objectID: PropTypes.string.isRequired,
};

export default DraggableEntryObject;
