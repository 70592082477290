import React from 'react';
import PropTypes from 'prop-types';
import { IconButtonTertiary } from '../ui';
import { AddSquareIcon } from '../icons';

const IconButtonUpload = ({ onClick }) => (
  <IconButtonTertiary icon={AddSquareIcon} onClick={onClick} />
);

IconButtonUpload.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default IconButtonUpload;
