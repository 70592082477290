import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  label: {
    display: 'inline-block',
    fontSize: 12,
    minWidth: 16,
    marginRight: 8,
  },
});

const Label = ({ label, htmlFor }) => {
  const classes = useStyles();
  return (
    <label htmlFor={htmlFor} className={classes.label}>
      {label}
    </label>
  );
};

Label.propTypes = {
  label: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
};

export default Label;
