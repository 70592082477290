import React from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { colors } from '../../ui';
import Resizer from './Resizer';
import Dots from './Dots';
import { getTransformString, clipPathFilter, CLIPPATH } from '../../models';
import { getObjectDimensions } from '../../project';
import {
  getOppositeOrigin,
  CENTER,
  getBoundingBoxFromObjects,
  buildOriginString,
} from '../../geometry';
import { getCurrentTime } from '../../playback';
import { getZoomScale } from '../../stage';
import { getSelectedObjects } from '../../editor';
import { getParentMatrix, getParentMatrixByObjectID } from '../../selectors';
import { BORDER_SIZE, TRIGGERS_POSITIONS, DIRECTIONS } from './constants';
import { withEditableState } from '../../EditableContext';
import { createScale, ensureScale } from '../../math';

export const calculateRelativeDifference = (relativeValue, scale) =>
  relativeValue * scale - relativeValue;

export const getAbsoluteDifference = scale => Math.abs(1 - Math.abs(scale));

class TransformBox extends React.Component {
  constructor() {
    super();

    this.handleOnDown = this.handleOnDown.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnUp = this.handleOnUp.bind(this);
  }

  handleOnDown() {
    this.props.editableState.begin();
  }

  handleOnChange(directions) {
    const {
      area,
      objects,
      editableState,
      currentTime,
      parentMatrix,
    } = this.props;

    return (move, rawEvent) => {
      const newObjects = objects.map(object => {
        let widthScale = 1;
        let heightScale = 1;

        const origin = rawEvent.altKey
          ? buildOriginString(CENTER, CENTER)
          : getOppositeOrigin(directions);
        const dimensions = getObjectDimensions(object, parentMatrix);
        let x = move.x * (createScale(dimensions.width, area.width) || 1);
        let y = move.y * (createScale(dimensions.height, area.height) || 1);

        if (rawEvent.altKey) {
          x *= 2;
          y *= 2;
        }

        if (directions.includes(DIRECTIONS.LEFT)) {
          widthScale = createScale(dimensions.width - x, dimensions.width);
        }

        if (directions.includes(DIRECTIONS.TOP)) {
          heightScale = createScale(dimensions.height - y, dimensions.height);
        }

        if (directions.includes(DIRECTIONS.RIGHT)) {
          widthScale = createScale(dimensions.width + x, dimensions.width);
        }

        if (directions.includes(DIRECTIONS.BOTTOM)) {
          heightScale = createScale(dimensions.height + y, dimensions.height);
        }

        if (rawEvent.shiftKey) {
          if (
            getAbsoluteDifference(widthScale) >
            getAbsoluteDifference(heightScale)
          ) {
            heightScale = widthScale;
          } else if (heightScale !== 1) {
            widthScale = heightScale;
          }
        }

        return object.scale(
          ensureScale(widthScale),
          ensureScale(heightScale),
          currentTime,
          {
            origin,
            area,
            parentMatrix,
          },
        );
      });

      editableState.updateObjects(newObjects);
    };
  }

  handleOnUp() {
    this.props.editableState.commit();
  }

  render() {
    const {
      disabled,
      disabledControls,
      classes,
      rotation,
      zoomScale,
      width,
      height,
      left,
      top,
    } = this.props;
    if (disabled) {
      return null;
    }

    const transform = getTransformString({
      translateX: left,
      translateY: top,
      rotation,
    });

    return (
      <div
        className={classes.transformBox}
        style={{ width, height, transform }}
      >
        {!disabledControls && <Dots />}
        {TRIGGERS_POSITIONS.map(([style, directions]) => (
          <Resizer
            id={`resizer-${directions.join('-')}`}
            key={directions}
            style={style}
            onDown={this.handleOnDown}
            onChange={this.handleOnChange(directions)}
            onUp={this.handleOnUp}
            angle={rotation}
            scale={zoomScale}
            disabled={disabledControls}
          />
        ))}
      </div>
    );
  }
}

TransformBox.propTypes = {
  area: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  zoomScale: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  disabledControls: PropTypes.bool.isRequired,
  objects: PropTypes.object.isRequired,
  editableState: PropTypes.object.isRequired,
  parentMatrix: PropTypes.object.isRequired,
};

const style = injectSheet({
  transformBox: {
    display: 'inline-block',
    position: 'absolute',
    pointerEvents: 'none',
    border: `${BORDER_SIZE}px solid ${colors.primary}`,
    boxSizing: 'content-box',
    zIndex: 100,
  },
});

const container = connect((state, { editableState }) => {
  const selectedObjects = getSelectedObjects(state);
  const currentTime = getCurrentTime(state);
  let objects = editableState.hasObjects()
    ? editableState.objects
    : selectedObjects;

  const zoomScale = getZoomScale(state);
  let parentMatrix = getParentMatrix(state);
  const firstObject = objects.first();

  if (objects.size === 1 && firstObject && firstObject.type === CLIPPATH) {
    parentMatrix = getParentMatrixByObjectID(state, firstObject.id);
  } else {
    objects = objects.filterNot(clipPathFilter);
  }

  const area = getBoundingBoxFromObjects(objects, parentMatrix);

  return {
    parentMatrix,
    disabled: objects.size === 0,
    disabledControls: !area.sameObjectsAngle,
    rotation: area.angleDeg,
    currentTime,
    area,
    objects,
    zoomScale,
    left: area.left * zoomScale - BORDER_SIZE,
    top: area.top * zoomScale - BORDER_SIZE,
    width: area.width * zoomScale,
    height: area.height * zoomScale,
  };
});

export default withEditableState(container(style(TransformBox)));
