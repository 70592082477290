import React from 'react';

import { object } from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider as Redux } from 'react-redux';
import { ApolloProvider } from '@apollo/client';

import { Auth } from '../auth';
import Fela from '../fela';
import '../ui/style.css';

import Hideable from '../ui/hideable';
import RedirectToProjects from '../RedirectToProjects';
import ViewLogin from '../ViewLogin';
import ViewProject from '../ViewProject';
import ViewProjects from '../ViewProjects';
import ViewSharedProject from '../ViewSharedProject';
import ViewReports from '../ViewReports';

import apolloClient from './client';
import { path } from './urls';
import NotFound from './NotFound';
import PrivateRoute from './PrivateRoute';
import Head from './Head';
import { HttpProvider } from '../http';

const App = ({ store }) => (
  <>
    <Head />
    <Redux store={store}>
      <ApolloProvider client={apolloClient}>
        <HttpProvider>
          <Fela>
            <Hideable.Provider>
              <Router>
                <Auth>
                  <Switch>
                    <PrivateRoute
                      exact
                      path={path.home}
                      component={RedirectToProjects}
                    />
                    <PrivateRoute
                      exact
                      path={path.projectList}
                      component={ViewProjects}
                    />
                    <PrivateRoute
                      exact
                      path={path.reports}
                      component={ViewReports}
                    />
                    <PrivateRoute
                      path={path.projectDetail}
                      component={ViewProject}
                    />
                    <Route
                      path={path.sharedProject}
                      component={ViewSharedProject}
                    />
                    <Route path={path.login} component={ViewLogin} />
                    <Route component={NotFound} />
                  </Switch>
                </Auth>
              </Router>
            </Hideable.Provider>
          </Fela>
        </HttpProvider>
      </ApolloProvider>
    </Redux>
  </>
);

App.propTypes = {
  store: object.isRequired,
};

export default App;
