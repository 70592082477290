import * as types from './types';
import { NotificationModel } from './models';

const reducer = (state = NotificationModel(), action) => {
  switch (action.type) {
    case types.SHOW_NOTIFICATION:
      return state.withMutations(newState =>
        newState
          .set('isVisible', true)
          .set('type', action.payload.type)
          .set('message', action.payload.message),
      );
    case types.CLOSE_NOTIFICATION:
      return NotificationModel();
    default:
      return state;
  }
};

export default reducer;

export const getNotificationStatus = state =>
  state.getIn(['notification', 'isVisible']);

export const getNotificationMessage = state =>
  state.getIn(['notification', 'message']);

export const getNotificationType = state =>
  state.getIn(['notification', 'type']);
