import { Component } from 'react';
import PropTypes from 'prop-types';
import parseInt from 'lodash/parseInt';
import { degreeToRadians } from '../geometry';

const noOp = event => {
  event.stopPropagation();
  event.preventDefault();
};

class MovableComponent extends Component {
  constructor() {
    super();

    this.middlewares = [];
    this.onDown = this.onDown.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onUp = this.onUp.bind(this);
    this.onGotCapture = this.onGotCapture.bind(this);
    this.onLostCapture = this.onLostCapture.bind(this);
    this.getPositionFromEvent = this.getPositionFromEvent.bind(this);
    this.applyDiff = this.applyDiff.bind(this);
    this.calculateAngle = this.calculateAngle.bind(this);
    this.resetValues = this.resetValues.bind(this);

    this.isDragging = false;
    this.resetValues();
  }

  onDown(e) {
    e.stopPropagation();
    e.preventDefault();
    if (this.props.disabled) {
      return;
    }

    e.target.setPointerCapture(e.pointerId);
    this.resetValues();

    this.lastPosition.x = e.clientX;
    this.lastPosition.y = e.clientY;
    this.isDragging = true;
    this.props.onDown(e);
  }

  onMove(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!this.isDragging || this.props.disabled) {
      return null;
    }

    const position = this.middlewares.reduce((curr, middleware) => {
      return middleware(curr);
    }, this.getPositionFromEvent(e));

    this.props.onMove(position, e);
    return position;
  }

  onUp(e) {
    e.stopPropagation();
    e.preventDefault();

    if (this.props.disabled) {
      return;
    }

    e.target.releasePointerCapture(e.pointerId);

    this.isDragging = false;
    this.props.onUp(e);
  }

  onGotCapture() {
    this.isDragging = true;
  }

  onLostCapture() {
    this.isDragging = false;
  }

  getPositionFromEvent(e) {
    const { scale } = this.props;
    const calculatedX = (e.clientX - this.lastPosition.x) / scale;
    const calculatedY = (e.clientY - this.lastPosition.y) / scale;

    this.lastPosition.x = e.clientX;
    this.lastPosition.y = e.clientY;

    const x = parseInt(calculatedX);
    const y = parseInt(calculatedY);

    this.diff.x += calculatedX - x;
    this.diff.y += calculatedY - y;

    return this.applyDiff({ x, y });
  }

  calculateAngle(position) {
    const angle = degreeToRadians(this.props.angle);
    const { x, y } = position;

    return {
      x: y * Math.sin(angle) + x * Math.cos(angle),
      y: y * Math.cos(angle) - x * Math.sin(angle),
    };
  }

  applyDiff(position) {
    const diffX = parseInt(this.diff.x);
    const diffY = parseInt(this.diff.y);

    this.diff.x -= diffX;
    this.diff.y -= diffY;

    const x = position.x + diffX;
    const y = position.y + diffY;

    return this.calculateAngle({ x, y });
  }

  resetValues() {
    const initialValues = { x: 0, y: 0 };
    this.lastPosition = { ...initialValues };
    this.diff = { ...initialValues };
  }

  render() {
    const { children } = this.props;

    return children({
      onClick: noOp,
      onContextMenu: noOp,
      onPointerDown: this.onDown,
      onPointerMove: this.onMove,
      onPointerUp: this.onUp,
      onPointerCancel: this.onUp,
      onGotPointerCapture: this.onGotCapture,
      onLostPointerCapture: this.onLostCapture,
      role: 'button',
      tabIndex: '-1',
    });
  }
}

MovableComponent.defaultProps = {
  scale: 1,
  angle: 0,
  onDown: () => null,
  onUp: () => null,
  disabled: false,
};

MovableComponent.propTypes = {
  angle: PropTypes.number,
  scale: PropTypes.number,
  onDown: PropTypes.func,
  onUp: PropTypes.func,
  disabled: PropTypes.bool,
  onMove: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

export default MovableComponent;
