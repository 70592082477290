import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SnackBar } from '../ui';
import * as actions from './actions';
import { NOTIFICATION_TYPES } from './models';
import {
  getNotificationStatus,
  getNotificationMessage,
  getNotificationType,
} from './reducers';

const TIMEOUT = 5000;

class Notification extends React.Component {
  constructor() {
    super();
    this.autoClose = this.autoClose.bind(this);
    this.timeout = null;
  }

  componentDidMount() {
    this.autoClose();
  }

  componentDidUpdate() {
    this.autoClose();
  }

  autoClose() {
    const { isVisible, timeout, closeNotification } = this.props;
    if (!isVisible) {
      return;
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => closeNotification(), timeout);
  }

  render() {
    const { type, isVisible, message, closeNotification } = this.props;
    const props = {
      isVisible,
      text: message,
      onClose: closeNotification,
    };

    switch (type) {
      case NOTIFICATION_TYPES.ERROR:
        return <SnackBar {...props} error />;
      case NOTIFICATION_TYPES.SUCCESS:
        return <SnackBar {...props} success />;
      case NOTIFICATION_TYPES.INFO:
      default:
        return <SnackBar {...props} />;
    }
  }
}

Notification.defaultProps = {
  timeout: TIMEOUT,
};

Notification.propTypes = {
  timeout: PropTypes.number,
  type: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  closeNotification: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    isVisible: getNotificationStatus(state),
    message: getNotificationMessage(state),
    type: getNotificationType(state),
  }),
  {
    closeNotification: actions.closeNotification,
  },
)(Notification);
