import React from 'react';
import { node } from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  keyframes: {
    top: 0,
    left: 0,
    position: 'absolute',
  },
});

const TimelinePropertyKeyframes = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.keyframes}>{children}</div>;
};

TimelinePropertyKeyframes.propTypes = {
  children: node.isRequired,
};

export default TimelinePropertyKeyframes;
