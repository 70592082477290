import request from '../http';
import createRequestDescription from './createRequestDescription';
import { nullDecoder, urlFactory } from './utils';
import { CURVES } from '../models';

export const url = urlFactory('animations');

export const keyframeDecoder = ({
  time = 0,
  timingFunction = CURVES.linear.timingFunction,
  values = {},
}) => ({ time: parseInt(time, 10), timingFunction, values });

export const animationDecoder = ({
  id = '',
  name = '',
  projectUID = '',
  keyframes = [],
}) => ({
  id,
  name,
  projectUID,
  keyframes: keyframes.map(keyframeDecoder),
});

export const requestAnimationsDecoder = ({
  animations = [],
  page = 1,
  hasNext = false,
  hasPrevious = false,
  totalItems = 0,
  startIndex = 0,
  endIndex = 0,
} = {}) => ({
  animations: animations.map(animationDecoder),
  page,
  hasNext,
  hasPrevious,
  totalItems,
  startIndex,
  endIndex,
});

export default {
  getAnimations: (page = 1, params = {}) =>
    createRequestDescription(
      {
        url: url('/'),
        method: 'GET',
        params: {
          ...params,
          page,
        },
      },
      requestAnimationsDecoder,
    ),
  saveAnimation: ({
    name = '',
    projectUID = '',
    objectType = null,
    keyframes = [],
  } = {}) =>
    request(
      {
        url: url('/save'),
        method: 'POST',
        data: {
          name,
          projectUID,
          objectType,
          keyframes,
        },
      },
      animationDecoder,
    ),
  deleteAnimation: (id = '') =>
    createRequestDescription(
      {
        url: url(`/${id}`),
        method: 'DELETE',
      },
      nullDecoder,
    ),
};
