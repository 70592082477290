import { List } from 'immutable';
import { splitPath } from '../utils';
import * as types from './types';
import { makeUploadEntry } from './reducers';
import { getAssetTypeFromFilename } from './utils';

const baseSetAssetsToUpload = assets => ({
  type: types.SET_ASSETS_TO_UPLOAD,
  payload: { assets: List(assets) },
});

export const setAssetsToUpload = ({ filelist, collectionPath }) => {
  const files = [];
  for (let i = 0; i < filelist.length; i += 1) {
    const file = filelist[i];
    const filename = file.name;
    const localURL = URL.createObjectURL(file);
    const assetType = getAssetTypeFromFilename(filename);
    files.push(
      makeUploadEntry({
        file,
        filename,
        localURL,
        path: collectionPath,
        assetType,
      }),
    );
  }
  return baseSetAssetsToUpload(files);
};

export const setAssetToReplace = (assetPath, file) => {
  const { filename, path } = splitPath(assetPath);
  const localURL = URL.createObjectURL(file);
  const assetType = getAssetTypeFromFilename(filename);
  return baseSetAssetsToUpload([
    makeUploadEntry({ file, filename, localURL, path, assetType }),
  ]);
};

export const removeUploadEntry = localURL => ({
  type: types.REMOVE_ASSET_TO_UPLOAD,
  payload: { localURL },
});

export const deleteAsset = assetPath => ({
  type: types.DELETE_ASSET,
  payload: { assetPath },
});

export const requestMoveAssetsToCollection = (assets, collectionPath) => ({
  type: types.REQUEST_MOVE_ASSETS_TO_COLLECTION,
  payload: { assets, collectionPath },
});

// Selected Assets
export const selectAssetsOnly = assetPaths => ({
  type: types.SELECT_ASSETS_ONLY,
  payload: { assetPaths },
});

export const addAssetsToSelection = assetPaths => ({
  type: types.ADD_ASSETS_TO_SELECTION,
  payload: { assetPaths },
});

export const removeAssetsFromSelection = assetPaths => ({
  type: types.REMOVE_ASSETS_FROM_SELECTION,
  payload: { assetPaths },
});

export const clearAssetsSelection = () => ({
  type: types.CLEAR_ASSETS_SELECTION,
});

export const selectAssetsOnClick = (event, isSelected, assetPaths) => {
  if (event.shiftKey) {
    if (isSelected) {
      return removeAssetsFromSelection(assetPaths);
    }

    return addAssetsToSelection(assetPaths);
  }

  if (isSelected) {
    return clearAssetsSelection();
  }

  return selectAssetsOnly(assetPaths);
};
