import React from 'react';
import { string } from 'prop-types';
import BaseHoverMask from './BaseHoverMask';
import { EditIcon } from '../icons';
import LinkToEditPiece from './LinkToEditPiece';

const HoverMask = props => (
  <LinkToEditPiece pieceID={props.pieceID}>
    <BaseHoverMask icon={EditIcon} {...props} />
  </LinkToEditPiece>
);

HoverMask.propTypes = {
  pieceID: string.isRequired,
};

export default HoverMask;
