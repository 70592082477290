import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { label, colors } from '../ui';

const useStyles = createUseStyles({
  pieceSize: {
    ...label,
    position: 'absolute',
    left: 0,
    bottom: 0,
    margin: [16, 40],
    color: colors.gray1,
    touchAction: 'none',
    pointerEvents: 'none',
  },
});

const PieceSize = ({ width, height }) => {
  const classes = useStyles();
  return (
    <span className={classes.pieceSize}>
      Creative size: {width}x{height}
    </span>
  );
};

PieceSize.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default PieceSize;
