import { createAction } from '../utils';
import { getCurrentTime } from './selectors';

export const createCurrentTimeAction = conf =>
  createAction({ ...conf, flags: { withCurrentTime: true } });

const injectCurrentTime = (action, getState) => {
  if (!action.flags || !action.flags.withCurrentTime) {
    return action;
  }

  return {
    ...action,
    payload: {
      ...action.payload,
      currentTime: getCurrentTime(getState()),
    },
  };
};

export const currentTimeMiddleware = ({ getState }) => next => action =>
  next(injectCurrentTime(action, getState));
