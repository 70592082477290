import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { panelAssets as colors } from '../colors';
import { AssetIcon, Filename } from '../Assets';
import { SelectableEntry } from '../ui';

const useStyles = createUseStyles({
  uploadingAsset: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: [0, 16],
  },
  overlay: {
    alignItems: 'center',
    background: colors.uploadEntryBg,
    display: 'flex',
    height: '100%',
    left: 0,
    opacity: 0.95,
    paddingLeft: 20,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
});

const UploadEntry = ({ asset }) => {
  const classes = useStyles();
  return (
    <SelectableEntry>
      <div className={classes.uploadingAsset}>
        <AssetIcon assetType={asset.assetType} />
        <Filename filename={asset.filename} />
        <div className={classes.overlay}>
          <Filename filename="uploading" />
        </div>
      </div>
    </SelectableEntry>
  );
};

UploadEntry.propTypes = {
  asset: PropTypes.object.isRequired,
};

export default UploadEntry;
