import React from 'react';
import { connect } from 'react-redux';
import { CheckboxWithLabel } from '../ui';
import { toggleRepeatMode, getIsPlaying, getRepeatMode } from '../playback';

const ToggleRepeatMode = props => (
  <CheckboxWithLabel small label="Repeat" {...props} />
);

const container = connect(
  state => ({
    disabled: getIsPlaying(state),
    checked: getRepeatMode(state),
  }),
  {
    onChange: toggleRepeatMode,
  },
);

export default container(ToggleRepeatMode);
