import { connect } from 'react-redux';
import { showErrorNotification } from '../Notification';

export const ERROR_MESSAGE = 'Something is wrong, please try again!';

export default connect(null, dispatch => ({
  showError() {
    dispatch(showErrorNotification(ERROR_MESSAGE));
  },
}));
