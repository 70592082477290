import request from '../http';
import { nullDecoder, urlFactory, identityDecoder } from './utils';

export const url = urlFactory('projects');

export const savePiecesQuery =
  'mutation savePieces($projectID: String!, $pieces: JSONString!, $guides: JSONString!) {\n  updateProject(id: $projectID, pieces: $pieces, guides: $guides) {\n    project {\n      id\n      __typename\n    }\n    __typename\n  }\n}\n';

export default {
  importPSD: bodyData =>
    request(
      {
        url: url('/import_psd'),
        method: 'POST',
        data: bodyData,
      },
      nullDecoder,
    ),
  savePieces: (projectID, pieces, guides) =>
    request(
      {
        url: url('/'),
        method: 'POST',
        data: {
          operationName: 'savePieces',
          query: savePiecesQuery,
          variables: {
            projectID,
            pieces: JSON.stringify(pieces),
            guides: JSON.stringify(guides),
          },
        },
      },
      nullDecoder,
    ),
  monthlyReport: (year, month) =>
    request(
      {
        url: url(`/reports/${year}/${month}`),
        method: 'GET',
        responseType: 'blob',
      },
      identityDecoder,
    ),
  fullReport: () =>
    request(
      {
        url: url(`/reports/all`),
        method: 'GET',
        responseType: 'blob',
      },
      identityDecoder,
    ),
};
