import { Map, List } from 'immutable';
import { createSelector } from 'reselect';
import { compose, identity } from 'transformation-matrix';
import { getCurrentPieceID } from '../stage';
import {
  getCurrentObjectID,
  getSelectedKeyframes as getSelectedKeyframesList,
  getSelectedObjectsIDs,
} from '../editor';
import {
  getPieceNodes,
  getObjectByID,
  getObjectKeyframeByID,
  getChildrensFromObject,
  getMaskByObjectID,
} from '../project';
import { injectMaskIDs } from '../utils';

const extractParentObjectIDS = (pieceNodes, list, node) => {
  if (!node) {
    return list;
  }

  const currentList = list.push(node.objectID);
  const { parentID } = node;

  if (parentID) {
    return extractParentObjectIDS(
      pieceNodes,
      currentList,
      pieceNodes.get(parentID),
    );
  }

  return currentList;
};

export const getParentMatrices = (state, nodeID) => {
  if (!nodeID) {
    return [];
  }

  const parentList = List();
  const pieceNodes = getPieceNodes(state, getCurrentPieceID(state));
  const objectIDS = extractParentObjectIDS(
    pieceNodes,
    parentList,
    pieceNodes.get(nodeID),
  );
  const objects = objectIDS
    .reverse()
    .map(objectID => getObjectByID(state, objectID));
  const matrices = objects.map(obj => obj.matrix);

  return matrices.toJS();
};

export const findNodeWithChildID = (state, objectID) => {
  const pieceNodes = getPieceNodes(state, getCurrentPieceID(state));

  return pieceNodes.find(node => node.childrensID.includes(objectID));
};

export const getParentMatrix = (state, objectID = null) => {
  try {
    if (!objectID) {
      return compose(getParentMatrices(state, getCurrentObjectID(state)));
    }

    const node = findNodeWithChildID(state, objectID);
    return compose(getParentMatrices(state, node.objectID));
  } catch (err) {
    // returns identity matrix if node is not found
    return identity();
  }
};

export const defaultCache = Map();

export const getParentMatrixByObjectID = (
  state,
  objectID,
  { cache = defaultCache } = {},
) => {
  const matrix = getParentMatrix(state);

  const object = cache.get(objectID) || getObjectByID(state, objectID);
  if (object.targetObjectID) {
    const targetObject =
      cache.get(object.targetObjectID) ||
      getObjectByID(state, object.targetObjectID);

    return compose(matrix, targetObject.matrix);
  }

  return matrix;
};

export const getObjectMatrixByID = (state, objectID) => {
  const object = getObjectByID(state, objectID);

  return object.matrix;
};

export const getSelectedKeyframes = state => {
  const selectedKeyframes = getSelectedKeyframesList(state);
  return selectedKeyframes.reduce(
    (currentMap, [objectID], keyframeID) =>
      currentMap.set(
        objectID,
        currentMap
          .get(objectID, List())
          .push(getObjectKeyframeByID(state, objectID, keyframeID)),
      ),
    Map(),
  );
};

export const getChildrensFromCurrentObject = state => {
  return getChildrensFromObject(
    state,
    getCurrentPieceID(state),
    getCurrentObjectID(state),
  );
};

export const getObjectIDByMaskID = createSelector(
  [getMaskByObjectID],
  maskByID =>
    maskByID.reduce(
      (currentMap, value, key) => currentMap.set(value, key),
      Map(),
    ),
);

export const getIndexByObjectID = (state, objectID) => {
  let realObjectID = objectID;

  const foundObjectID = getObjectIDByMaskID(state).get(objectID);
  if (foundObjectID) {
    realObjectID = foundObjectID;
  }

  const childrens = getChildrensFromObject(
    state,
    getCurrentPieceID(state),
    getCurrentObjectID(state),
  );

  return childrens.indexOf(realObjectID);
};

export const getAllCurrentChildrens = createSelector(
  [getChildrensFromCurrentObject, getMaskByObjectID],
  (childrensID, maskMap) => {
    return childrensID.reduce((list, childrenID) => {
      let currentList = list.push(childrenID);

      const clipPathObjectID = maskMap.get(childrenID);
      if (clipPathObjectID) {
        currentList = currentList.push(clipPathObjectID);
      }

      return currentList;
    }, List());
  },
);

export const getReversedCurrentChildrens = createSelector(
  [getChildrensFromCurrentObject, getMaskByObjectID],
  (childrensID, maskMap) =>
    childrensID.reverse().reduce(injectMaskIDs(maskMap), List()),
);

export const getReversedSelectedChildrens = createSelector(
  getChildrensFromCurrentObject,
  getMaskByObjectID,
  getSelectedObjectsIDs,
  (childrensID, maskMap, selectedObjectsIds) =>
    childrensID
      .reverse()
      .filter(id => selectedObjectsIds.includes(id))
      .reduce(injectMaskIDs(maskMap), List()),
);

export const getAllClipPathObjectIDs = createSelector(
  [getMaskByObjectID],
  maskByObjectID => {
    return maskByObjectID.valueSeq();
  },
);

export const getHasClipPathSelected = createSelector(
  [getAllClipPathObjectIDs, getSelectedObjectsIDs],
  (allClipPathIDs, selectedIDs) => {
    return selectedIDs.some(selectedID => allClipPathIDs.includes(selectedID));
  },
);

export const getIsClipPathID = (state, id) =>
  getAllClipPathObjectIDs(state).includes(id);

export const getObjectIDHasClipPath = (state, id) =>
  getMaskByObjectID(state).has(id);
