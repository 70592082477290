import { OrderedSet } from 'immutable';
import { REMOVE_OBJECTS, REQUEST_REMOVE_COLLECTION } from '../project/types';
import * as types from './types';

const removeObjectsFromSelection = ({ ids }) => state =>
  ids.reduce((newState, objectID) => newState.delete(objectID), state);

const selectionReducer = (state = OrderedSet(), action) => {
  switch (action.type) {
    case types.SET_SELECTED_OBJECTS:
      return OrderedSet(action.payload.objects);
    case types.SELECT_OBJECT_ONLY:
      return OrderedSet([action.payload.objectID]);
    case types.ADD_OBJECT_TO_SELECTION:
      return state.add(action.payload.objectID);
    case types.REMOVE_OBJECT_FROM_SELECTION:
    case REMOVE_OBJECTS:
      return removeObjectsFromSelection({ ...action.payload })(state);
    case types.CLEAR_OBJECTS_SELECTION:
    case REQUEST_REMOVE_COLLECTION:
      return OrderedSet();
    default:
      return state;
  }
};

export default selectionReducer;
