import React from 'react';
import { string } from 'prop-types';
import { createUseStyles } from 'react-jss';
import ConnectedPiece from './ConnectedPiece';
import Format from './Format';
import BaseHoverMask from './BaseHoverMask';
import ModalPreview, { PreviewButton } from './ModalPreview';
import { createHideableComponent } from '../ui/hideable';
import { PlayIcon } from '../icons';
import LinkToSharedPlayer from './LinkToSharedPlayer';

const useStyles = createUseStyles({
  sharedPiece: {
    marginBottom: 48,
    marginRight: 48,
    flex: 0,
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 8,
    minWidth: 150,
  },
  headerIcon: {
    paddingLeft: 16,
  },
  piece: {
    display: 'inline-block',
    flex: 0,
    position: 'relative',
  },
});

const SharedPiece = ({ pieceID }) => {
  const classes = useStyles();
  const PreviewContext = createHideableComponent();

  return (
    <div className={classes.sharedPiece}>
      <PreviewContext.Target>
        {({ isVisible, hide }) => (
          <ModalPreview
            pieceID={pieceID}
            isVisible={isVisible}
            onClick={hide}
          />
        )}
      </PreviewContext.Target>
      <div className={classes.header}>
        <Format pieceID={pieceID} />
        <div className={classes.headerIcon}>
          <PreviewContext.Trigger>
            {({ show }) => <PreviewButton onClick={show} />}
          </PreviewContext.Trigger>
        </div>
        <div className={classes.headerIcon}>
          <LinkToSharedPlayer pieceID={pieceID} />
        </div>
      </div>
      <div className={classes.piece}>
        <ConnectedPiece pieceID={pieceID} loaded={false} />
        <PreviewContext.Trigger>
          {({ show }) => (
            <BaseHoverMask icon={PlayIcon} pieceID={pieceID} onClick={show} />
          )}
        </PreviewContext.Trigger>
      </div>
    </div>
  );
};

SharedPiece.propTypes = {
  pieceID: string.isRequired,
};

export default SharedPiece;
