import cuid from 'cuid';
import { Map, Set } from 'immutable';
import {
  EditorOptions,
  InheritanceModel,
  updateObjectProperties,
} from './base';
import ImageObject, { defaultObject as defaultImageObject } from './image';
import VideoObject, { defaultObject as defaultVideoObject } from './video';
import DivObject, { defaultObject as defaultDivObject } from './div';
import TextObject, {
  defaultObject as defaultTextObject,
  getTextStyle,
} from './text';
import ClipPathObject, {
  defaultObject as defaultClipPathObject,
} from './clip-path';

export * from './type';
export * from './utils';

export {
  EditorOptions,
  InheritanceModel,
  ImageObject,
  DivObject,
  VideoObject,
  TextObject,
  getTextStyle,
  updateObjectProperties,
};

export const makeObjectWith = (
  Class,
  {
    pieceID,
    left = 0,
    top = 0,
    height = 0,
    width = 0,
    name = '',
    opacity = 1,
    rotation = 0,
    originX = width / 2,
    originY = height / 2,
    translateX = 0,
    translateY = 0,
    scaleX = 1,
    scaleY = 1,
    fullSize = false,
    forceID = null,
    inheritance = null,
    keyframes = Map({}),
    editorOptions = EditorOptions(),
    ...props
  } = {},
) =>
  new Class({
    id: forceID !== null ? forceID : cuid(),
    name,
    pieceID,
    fullSize,
    left,
    top,
    height,
    width,
    opacity,
    rotation,
    originX,
    originY,
    translateX,
    translateY,
    scaleX,
    scaleY,
    keyframes,
    editorOptions: EditorOptions({
      beingAnimated: editorOptions.beingAnimated || false,
      prohibitedProperties: Set(editorOptions.prohibitedProperties || []),
    }),
    inheritance: inheritance ? InheritanceModel(inheritance) : null,
    ...props,
  });

export const makeObject = ({
  assetPath,
  assetURL = null,
  ...objectProps
} = {}) =>
  makeObjectWith(ImageObject, {
    assetPath,
    assetURL,
    ...objectProps,
  });

export const makeDivObject = ({
  backgroundColor = 'transparent',
  ...objectProps
} = {}) =>
  makeObjectWith(DivObject, {
    backgroundColor,
    ...objectProps,
  });

export const makeClipPathObject = ({
  forceID = null,
  name = '',
  pieceID,
  targetObjectID,
  clipPath = null,
  keyframes = Map({}),
  editorOptions = EditorOptions(),
  inheritance = null,
} = {}) =>
  new ClipPathObject({
    id: forceID !== null ? forceID : cuid(),
    name,
    pieceID,
    targetObjectID,
    clipPath,
    keyframes,
    editorOptions: EditorOptions({
      beingAnimated: editorOptions.beingAnimated || false,
      prohibitedProperties: Set(editorOptions.prohibitedProperties || []),
    }),
    inheritance: inheritance ? InheritanceModel(inheritance) : null,
  });

export const makeRawImgObject = object => ({
  ...defaultImageObject,
  editorOptions: {},
  keyframes: {},
  ...object,
});

export const makeRawDivObject = (object, childrens = []) => ({
  ...defaultDivObject,
  editorOptions: {},
  keyframes: {},
  childrens,
  ...object,
});

export const makeRawClipPathObject = object => ({
  ...defaultClipPathObject,
  editorOptions: {},
  keyframes: {},
  ...object,
});

export const makeVideoObject = ({
  assetPath,
  assetURL = null,
  muted = true,
  autoPlay = true,
  ...objectProps
} = {}) =>
  makeObjectWith(VideoObject, {
    assetPath,
    assetURL,
    muted,
    autoPlay,
    ...objectProps,
  });

export const makeRawVideoObject = object => ({
  ...defaultVideoObject,
  editorOptions: {},
  keyframes: {},
  ...object,
});

export const makeTextObject = ({
  backgroundColor = 'transparent',
  textContent = defaultTextObject.textContent,
  classContent = defaultTextObject.classContent,
  fontWeight = defaultTextObject.fontWeight,
  textAlign = defaultTextObject.textAlign,
  fontFamily = defaultTextObject.fontFamily,
  color = defaultTextObject.color,
  fontSize = defaultTextObject.fontSize,
  ...objectProps
} = {}) =>
  makeObjectWith(TextObject, {
    backgroundColor,
    textContent,
    color,
    fontSize,
    fontFamily,
    fontWeight,
    textAlign,
    classContent,
    ...objectProps,
  });

export const makeRawTextObject = object => ({
  ...defaultTextObject,
  editorOptions: {},
  keyframes: {},
  ...object,
});
