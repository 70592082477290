import React from 'react';
import { createUseStyles } from 'react-jss';
import { AddIcon } from '../icons';
import { ButtonPrimary, Hideable } from '../ui';

const useStyles = createUseStyles({
  addSize: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
    height: '100%',
  },
});

const AddSizesButton = () => {
  const classes = useStyles();
  return (
    <div className={classes.addSize}>
      <Hideable.Trigger id="modalAddSizes">
        {({ show }) => (
          <ButtonPrimary onClick={show} icon={AddIcon} label="Add Sizes" />
        )}
      </Hideable.Trigger>
    </div>
  );
};

export default AddSizesButton;
