import { all } from 'redux-saga/effects';
import { panelAssetsSaga } from './PanelAssets';
import { projectSaga } from './project';
import { viewPiecesSaga } from './ViewPieces';
import { editorSaga } from './editor';

function* rootSaga() {
  yield all([panelAssetsSaga(), projectSaga(), viewPiecesSaga(), editorSaga()]);
}

export default rootSaga;
