import { takeEvery, select, put } from 'redux-saga/effects';
import { clearKeyframesSelectionFromObjects } from './actions';
import { getSelectedObjectsIDs, getSelectedKeyframes } from './selectors';

export function* syncKeyframesSelection() {
  const selectedKeyframes = yield select(getSelectedKeyframes);
  if (!selectedKeyframes.size) {
    return;
  }

  const selectedObjects = yield select(getSelectedObjectsIDs);
  const notSelectedObjects = selectedKeyframes
    .toSet()
    .map(([objectID]) => objectID)
    .filterNot(objectID => selectedObjects.includes(objectID));

  if (!notSelectedObjects.size) {
    return;
  }

  yield put(clearKeyframesSelectionFromObjects(notSelectedObjects));
}

export const objectSelectionMatcher = action =>
  action.type.match(/SELECTION\/OBJECTS\/.*/);

function* editorSaga() {
  yield takeEvery(objectSelectionMatcher, syncKeyframesSelection);
}

export default editorSaga;
