import React from 'react';
import PropTypes from 'prop-types';
import {
  APPROVED,
  RESOLVED,
  ApproveIssue,
  ArchiveIssue,
  ReopenIssue,
} from '../ViewIssues';

const SharedIssueActions = props => {
  switch (props.issue.status) {
    case APPROVED:
      return (
        <>
          <ReopenIssue {...props} />
          <ArchiveIssue {...props} />
        </>
      );
    case RESOLVED:
      return (
        <>
          <ApproveIssue {...props} />
          <ReopenIssue {...props} />
          <ArchiveIssue {...props} />
        </>
      );
    default:
      return <ArchiveIssue {...props} />;
  }
};

SharedIssueActions.propTypes = {
  issue: PropTypes.object.isRequired,
};

export default SharedIssueActions;
