import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PopoverItem } from '../ui';
import { duplicateObjects } from '../project';
import { getSelectedObjects } from '../editor';
import { clipPathFilter } from '../models';

const disabled = objects => objects.filterNot(clipPathFilter).size === 0;

const ButtonDuplicateObjects = () => {
  const dispatch = useDispatch();
  const selectedObjects = useSelector(state => getSelectedObjects(state));
  const onClick = useCallback(
    () => dispatch(duplicateObjects(selectedObjects.filterNot(clipPathFilter))),
    [selectedObjects],
  );

  return (
    <PopoverItem
      label="Duplicate object"
      onClick={onClick}
      disabled={disabled(selectedObjects)}
    />
  );
};

export default ButtonDuplicateObjects;
