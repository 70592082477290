import React from 'react';
import { func, number } from 'prop-types';
import { connect } from 'react-redux';
import { PopoverItem } from '../ui';
import { removeObjects } from '../project';
import { getSelectedObjectsIDs } from '../editor';

const ButtonDeleteObject = ({ onClick, nObjects }) => {
  if (nObjects === 0) {
    return null;
  }
  const label = nObjects === 1 ? 'Delete object' : `Delete ${nObjects} objects`;
  return <PopoverItem label={label} onClick={onClick} />;
};

ButtonDeleteObject.propTypes = {
  onClick: func.isRequired,
  nObjects: number.isRequired,
};

const container = connect(
  state => ({
    ids: getSelectedObjectsIDs(state),
  }),
  {
    removeObjects,
  },
  ({ ids }, actions) => ({
    onClick: () => actions.removeObjects(ids),
    nObjects: ids.count(),
  }),
);

export default container(ButtonDeleteObject);
