import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { clipText } from '../utils';

export const useStyles = createUseStyles({
  filename: {
    fontSize: 14,
    marginRight: 20,
    flexGrow: 1,
    wordBreak: 'break-all',
  },
});

const Filename = ({ filename }) => {
  const classes = useStyles();
  return <p className={classes.filename}>{clipText(filename, 20)}</p>;
};

Filename.propTypes = {
  filename: PropTypes.string.isRequired,
};

export default Filename;
