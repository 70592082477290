import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

class TimelineLeft extends React.Component {
  constructor() {
    super();
    this.handleScroll = this.handleScroll.bind(this);
  }

  // eslint-disable-next-line
  UNSAFE_componentWillUpdate({ scrollTop }) {
    if (this.props.scrollTop !== scrollTop) {
      this.container.scrollTop = scrollTop;
    }
  }

  handleScroll(e) {
    const { onScroll } = this.props;

    // FIXME it will be fixed in v17
    // prevent scroll from children
    // https://github.com/facebook/react/issues/19156
    if (e.target !== e.currentTarget) {
      return;
    }

    onScroll(e);
  }

  render() {
    const { classes, children } = this.props;
    return (
      <div
        role="button"
        tabIndex="-1"
        className={classes.wrapper}
        ref={elm => {
          this.container = elm;
        }}
        onScroll={this.handleScroll}
      >
        {children}
      </div>
    );
  }
}

TimelineLeft.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  scrollTop: PropTypes.number.isRequired,
  onScroll: PropTypes.func.isRequired,
};

const styles = injectSheet({
  wrapper: {
    flexGrow: 1,
    minHeight: 0,
    overflow: 'scroll',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export default styles(TimelineLeft);
