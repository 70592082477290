import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';

const useStyles = createUseStyles({
  projectFailed: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    color: colors.errorDark,
  },
  failed: {
    width: '100%',
    fontSize: 16,
    color: 'inherit',
  },
  errorMessage: {
    width: '100%',
    color: 'inherit',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
  },
});

export const UNKNOWN_ERROR = 'Unknown error, please inform an administrator!';
export const SERVER_ERROR = 'Server error, please inform an administrator!';

const decodeErrorMessage = error => {
  let errorMessage = UNKNOWN_ERROR;

  try {
    const decodedError = JSON.parse(error);
    if (decodedError) {
      errorMessage = decodedError.message;
    }
  } catch (err) {
    errorMessage = SERVER_ERROR;
  }

  return errorMessage;
};

const ProjectFailed = ({ error }) => {
  const classes = useStyles();
  const errorMessage = decodeErrorMessage(error);

  return (
    <div className={classes.projectFailed}>
      <span className={classes.failed}>Project import failed</span>
      {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

ProjectFailed.defaultProps = {
  error: null,
};

ProjectFailed.propTypes = {
  error: PropTypes.string,
};

export default ProjectFailed;
