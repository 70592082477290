import React from 'react';
import { createUseStyles } from 'react-jss';
import { node } from 'prop-types';
import colors from '../colors';

const useStyles = createUseStyles({
  panelsWrapper: {
    background: colors.black3,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    width: 272,
  },
});

const PanelsWrapper = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.panelsWrapper}>{children}</div>;
};

PanelsWrapper.propTypes = {
  children: node.isRequired,
};

export default PanelsWrapper;
