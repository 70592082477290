import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ButtonLeftEntry from './ButtonLeftEntry';
import { EyeIcon } from '../icons';
import { getIsObjectVisible, toggleObjectVisibility } from '../editor';

const ButtonToggleVisibility = ({ isVisible, onClick, isSelected }) => (
  <ButtonLeftEntry onClick={onClick} isSelected={isSelected}>
    {isVisible && <EyeIcon />}
  </ButtonLeftEntry>
);

ButtonToggleVisibility.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

const container = connect(
  (state, { objectID }) => ({
    isVisible: getIsObjectVisible(state, objectID),
  }),
  { toggleObjectVisibility },
  (state, actions, { isSelected, objectID }) => ({
    ...state,
    isSelected,
    onClick(event) {
      event.preventDefault();
      event.stopPropagation();

      actions.toggleObjectVisibility(objectID);
    },
  }),
);

export default container(ButtonToggleVisibility);
