import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OneStepToBottomIcon } from '../icons';
import { ToolbarButton } from '../ui';
import { moveObjectsOneStepToBottom } from '../project';
import { getCurrentPieceID } from '../stage';
import { getSelectedObjectsIDs } from '../editor';

const ButtonMoveOneStepToBottom = ({ disabled, onClick }) => {
  if (disabled) {
    return null;
  }
  return <ToolbarButton icon={<OneStepToBottomIcon />} onClick={onClick} />;
};

ButtonMoveOneStepToBottom.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const reduxConnector = connect(
  state => {
    const pieceID = getCurrentPieceID(state);
    const ids = getSelectedObjectsIDs(state);
    let disabled = true;
    if (ids.size > 0) {
      disabled = false;
    }
    return { ids, disabled, pieceID };
  },
  { moveObjectsOneStepToBottom },
  ({ ids, pieceID, ...state }, actions) => ({
    ...state,
    onClick: () => actions.moveObjectsOneStepToBottom({ pieceID, ids }),
  }),
);

export default reduxConnector(ButtonMoveOneStepToBottom);
