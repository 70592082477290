import React from 'react';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';

/* eslint-disable-next-line  */
export const getVersion = () => BUILD_VERSION;

const useStyles = createUseStyles({
  version: {
    position: 'fixed',
    bottom: 10,
    right: 15,
    fontSize: 12,
    color: colors.gray2,
    userSelect: 'none',
    select: 'none',
  },
});

const BuildVersion = () => {
  const classes = useStyles();
  return <span className={classes.version}>Media Display {getVersion()}</span>;
};

export default BuildVersion;
