import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getIsPlaying,
  getRepeatMode,
  getCurrentTime,
  setCurrentTime,
  pause,
  getClockDuration,
} from '../playback';

const frameLength = 1000 / 30; // 30 FPS

export class ClockComponent extends React.Component {
  constructor() {
    super();
    this.startTime = null;
    this.startProgress = 0;
    this.progress = 0;
    this.animationFrame = null;
    this.shouldStart = this.shouldStart.bind(this);
    this.tick = this.tick.bind(this);
    this.setStartTime = this.setStartTime.bind(this);
    this.setProgress = this.setProgress.bind(this);
    this.shouldFinish = this.shouldFinish.bind(this);
  }

  // eslint-disable-next-line
  UNSAFE_componentWillUpdate(nextProps) {
    if (this.shouldStart(nextProps.isPlaying)) {
      this.animationFrame = requestAnimationFrame(this.tick);
    }
  }

  componentWillUnmount() {
    this.finish();
  }

  setStartTime(timestamp) {
    if (this.startTime === null) {
      this.startTime = timestamp - this.props.currentTime;
      this.startProgress = timestamp - this.startTime;
    }
  }

  setProgress(timestamp) {
    this.progress = Math.round(timestamp - this.startTime);
  }

  shouldStart(isPlaying) {
    return isPlaying && isPlaying !== this.props.isPlaying;
  }

  shouldTick() {
    return this.progress - this.props.currentTime > frameLength;
  }

  shouldFinish() {
    return this.progress >= this.props.length;
  }

  finish() {
    cancelAnimationFrame(this.animationFrame);
    this.animationFrame = null;
    this.startTime = null;
    this.progress = 0;
    this.startProgress = 0;
    this.props.onFinish();
  }

  tick(timestamp) {
    if (!this.props.isPlaying) {
      this.finish();
      return;
    }
    this.setStartTime(timestamp);
    this.setProgress(timestamp);
    if (this.shouldFinish()) {
      this.props.onTick(this.startProgress);
      if (this.props.isRepeating) {
        this.startTime = null;
      } else {
        this.finish();
        return;
      }
    } else if (this.shouldTick()) {
      this.props.onTick(this.progress);
    }
    this.animationFrame = requestAnimationFrame(this.tick);
  }

  render() {
    return null;
  }
}

ClockComponent.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  isRepeating: PropTypes.bool.isRequired,
  currentTime: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  onFinish: PropTypes.func.isRequired,
  onTick: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    isPlaying: getIsPlaying(state),
    isRepeating: getRepeatMode(state),
    currentTime: getCurrentTime(state),
    length: getClockDuration(state),
  }),
  {
    onFinish: pause,
    onTick: setCurrentTime,
  },
)(ClockComponent);
