import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { LinkedIcon, UnlinkedIcon } from '../../icons';
import { toggleAspectRatio } from '../../colors';

const useStyles = createUseStyles({
  box: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderRadius: 2,
    height: 18,
    justifyContent: 'center',
    marginLeft: 4,
    marginRight: 4,
    outline: 'none',
    width: 18,
    padding: [0, 3],

    '&:hover': {
      cursor: 'pointer',
    },

    '& svg': {
      fill: toggleAspectRatio.color,
    },
  },
});

const ToggleAspectRatio = ({ keepAspectRatio, onClick }) => {
  const classes = useStyles();
  return (
    <button type="button" className={classes.box} onClick={onClick}>
      {keepAspectRatio ? <LinkedIcon /> : <UnlinkedIcon />}
    </button>
  );
};

ToggleAspectRatio.propTypes = {
  keepAspectRatio: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ToggleAspectRatio;
