import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { explicitNull } from 'airbnb-prop-types';
import { EmptyIcon } from '../../icons';
import colors from '../colors';

const sizeCard = (big, small) => {
  if (small) {
    return 26;
  }
  return big ? 42 : 34;
};
const BORDER_SIZE = 1;

const useStyles = createUseStyles({
  cardColor: ({ isSelected, big, small, color }) => ({
    boxSizing: 'border-box',
    width: sizeCard(big, small),
    height: sizeCard(big, small),
    backgroundColor: color ? `${color}` : colors.white,
    borderRadius: isSelected ? 5 : 4,
    border: isSelected
      ? `${BORDER_SIZE}px solid ${colors.primary}`
      : `${BORDER_SIZE}px solid transparent`,
    outline: 'none',
    margin: 0,
  }),
});

const CardColor = ({
  color,
  isSelected,
  small,
  onClick,
  big,
  onChangeColor,
}) => {
  const classes = useStyles({ big, small, color, isSelected });
  const handleOnClick = useCallback(
    e => {
      onChangeColor(color);
      onClick(e);
    },
    [color, onChangeColor, onClick],
  );

  return (
    <button type="button" className={classes.cardColor} onClick={handleOnClick}>
      {color ? null : <EmptyIcon />}
    </button>
  );
};
CardColor.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, explicitNull]),
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  big: PropTypes.bool,
  small: PropTypes.bool,
  onChangeColor: PropTypes.func,
};

CardColor.defaultProps = {
  color: null,
  isSelected: false,
  onClick: () => {},
  big: false,
  small: false,
  onChangeColor: () => {},
};

export default CardColor;
