import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { flowRight as compose } from 'lodash';
import { object, func } from 'prop-types';
import { Formik, Form } from 'formik';
import { useMutation, gql } from '@apollo/client';
import {
  ButtonPrimary,
  ButtonTertiary,
  TextField,
  CheckboxWithLabel,
  Modal,
  ModalHeader,
  ModalContent,
  ModalButtons,
  ModalSpacer,
  createHideableComponent,
} from '../../ui';
import { url } from '../../App';
import { showErrorNotification } from '../../Notification';
import { projects } from '../../api';
import SingleFileField from './SingleFileField';

export const ERROR_MESSAGE =
  'It was not possible to create project. Try again later.';

export const CREATE_PROJECT = gql`
  mutation CreateProject($name: String!, $amp: Boolean!) {
    createProject(name: $name, amp: $amp) {
      project {
        id
      }
    }
  }
`;
const ModalContext = createHideableComponent('createProject');

const ModalCreateProject = ({ history, sendErrorNotification }) => {
  const inputFileRef = React.createRef();
  const [createProject] = useMutation(CREATE_PROJECT);
  return (
    <ModalContext.Target>
      {({ isVisible, hide }) => (
        <Modal small isVisible={isVisible}>
          <ModalHeader>Create Project</ModalHeader>
          <ModalContent>
            <Formik
              initialValues={{ name: '', amp: false, hasPSD: false }}
              validate={values => {
                const errors = {};
                if (!values.name) {
                  errors.name = 'Required';
                }

                return errors;
              }}
              onSubmit={({ name, amp, hasPSD }, { setSubmitting }) => {
                setSubmitting(true);

                if (hasPSD) {
                  const fileInput = inputFileRef.current;
                  if (!fileInput) {
                    setSubmitting(false);
                    return;
                  }

                  const psdFile = fileInput.files[0];
                  if (!psdFile) {
                    setSubmitting(false);
                    return;
                  }

                  const formData = new FormData();
                  formData.append('name', name);
                  formData.append('amp', amp);
                  formData.append('psd_file', psdFile);

                  projects
                    .importPSD(formData)
                    .then(({ ok }) => {
                      setSubmitting(false);
                      if (!ok) {
                        sendErrorNotification();
                        return;
                      }

                      history.push(url.home());
                      hide();
                    })
                    .catch(() => {
                      sendErrorNotification();
                      setSubmitting(false);
                    });
                  return;
                }

                createProject({
                  variables: { name, amp },
                })
                  .then(({ data }) => {
                    const { project } = data.createProject;
                    const { id } = project;
                    history.push(url.pieceList({ projectID: id }));
                  })
                  .catch(() => {
                    sendErrorNotification();
                    setSubmitting(false);
                  });
              }}
            >
              {({
                isSubmitting,
                isValid,
                values,
                errors,
                handleChange,
                handleBlur,
                setValues,
                dirty,
              }) => (
                <Form>
                  <TextField
                    type="text"
                    title="Project Title"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={errors.name}
                    expanded
                    required
                  />
                  <ModalSpacer />
                  <CheckboxWithLabel
                    label="AMP"
                    name="amp"
                    checked={values.amp}
                    onChange={amp => setValues({ ...values, amp })}
                  />
                  <ModalSpacer />
                  <CheckboxWithLabel
                    label="Use PSD as template"
                    name="hasPSD"
                    checked={values.hasPSD}
                    onChange={hasPSD => setValues({ ...values, hasPSD })}
                  />
                  <ModalSpacer />
                  {values.hasPSD && (
                    <SingleFileField
                      name="psdFile"
                      accept=".psd"
                      inputRef={inputFileRef}
                    />
                  )}
                  <ModalButtons>
                    <ButtonPrimary
                      extended
                      type="submit"
                      label="Create Project"
                      disabled={isSubmitting || !isValid || !dirty}
                    />
                    <ModalSpacer />
                    <ButtonTertiary
                      type="button"
                      extended
                      label="Cancel"
                      disabled={isSubmitting}
                      onClick={hide}
                    />
                  </ModalButtons>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </ModalContext.Target>
  );
};

ModalCreateProject.propTypes = {
  history: object.isRequired,
  sendErrorNotification: func.isRequired,
};

const withRedux = connect(null, dispatch => ({
  sendErrorNotification() {
    dispatch(showErrorNotification(ERROR_MESSAGE));
  },
}));

export default compose(withRedux, withRouter)(ModalCreateProject);
