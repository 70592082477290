import React from 'react';
import { func, string, bool } from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { colors, CheckboxWithLabel } from '../../../ui';
import DisplayOrLoader from './DisplayOrLoader';
import {
  getIsWaitingForEstimative,
  getIsLoading,
  getSizeOfFile,
} from '../../selectors';

const useStyles = createUseStyles({
  display: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: colors.black3,
    padding: '8px 8px',
  },
});

const DisplayPieceWithSize = ({
  checked,
  onChange,
  label,
  templateName,
  piece,
}) => {
  const isWaitingForEstimation = useSelector(state =>
    getIsWaitingForEstimative(state),
  );
  const sizeOfFile = useSelector(state =>
    getSizeOfFile(state, templateName, piece),
  );
  const isLoading = useSelector(state => getIsLoading(state));

  const classes = useStyles();

  return (
    <div className={classes.display}>
      <CheckboxWithLabel checked={checked} label={label} onChange={onChange} />
      {isWaitingForEstimation && checked ? (
        <DisplayOrLoader loading={isLoading} sizeOfFile={sizeOfFile} />
      ) : null}
    </div>
  );
};

DisplayPieceWithSize.propTypes = {
  checked: bool.isRequired,
  onChange: func.isRequired,
  label: string,
  templateName: string.isRequired,
  piece: string.isRequired,
};

DisplayPieceWithSize.defaultProps = {
  label: '',
};

export default DisplayPieceWithSize;
