import React from 'react';
import { object } from 'prop-types';
import { createUseStyles } from 'react-jss';
import { withRouter } from 'react-router-dom';
import { colors, jumbo, title3, ButtonPrimary } from '../ui';
import { url } from './urls';

const useStyles = createUseStyles({
  view: {
    alignItems: 'center',
    background: colors.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  title: {
    ...jumbo,
    color: colors.black3,
    marginBottom: 40,
  },
  subtitle: {
    ...title3,
    color: colors.gray3,
    marginBottom: 48,
  },
});

const NotFound = ({ history }) => {
  const classes = useStyles();

  return (
    <div className={classes.view}>
      <h2 className={classes.title}>Error 404</h2>
      <p className={classes.subtitle}> Page not found</p>
      <ButtonPrimary
        label="Take me home"
        onClick={() => history.push(url.home())}
      />
    </div>
  );
};

NotFound.propTypes = {
  history: object.isRequired,
};

export default withRouter(NotFound);
