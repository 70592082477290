import React from 'react';
import { string } from 'prop-types';
import { withEditableState } from './Context';

const ObjectConsumer = withEditableState(
  ({ editableState, objectID, children }) =>
    children({
      ...editableState,
      object: editableState.objects.get(objectID),
    }),
);

export const withEditableObjectContext = Component => {
  const WithEditableContextComponent = props => (
    <ObjectConsumer objectID={props.objectID}>
      {editableState => <Component editableState={editableState} {...props} />}
    </ObjectConsumer>
  );

  WithEditableContextComponent.propTypes = {
    objectID: string.isRequired,
  };

  return WithEditableContextComponent;
};

export default ObjectConsumer;
