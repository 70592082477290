import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CheckboxWithLabel } from '../ui';
import {
  toggleAlignToContainer,
  getAlignToContainer,
  getSelectedObjectsIDs,
} from '../editor';

const ToggleAlignToContainer = ({ disabled, checked, onChange }) => {
  if (disabled) {
    return null;
  }

  return (
    <CheckboxWithLabel
      small
      label="Align to container"
      checked={checked}
      onChange={onChange}
    />
  );
};

ToggleAlignToContainer.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const container = connect(
  state => ({
    checked: getAlignToContainer(state),
    disabled: getSelectedObjectsIDs(state).size < 1,
  }),
  { onChange: toggleAlignToContainer },
);

export default container(ToggleAlignToContainer);
