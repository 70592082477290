import React from 'react';
import { createUseStyles } from 'react-jss';
import * as typography from '../typography';
import colors from '../colors';

const useStyles = createUseStyles({
  input: {
    width: '100%',
    height: '100%',
    fontStyle: typography.textRegular,
    backgroundColor: colors.black2,
    borderLeft: `1px solid`,
    borderBottom: `1px solid`,
    color: colors.white,
    textAlign: 'left',
    '&::placeholder': {
      color: colors.white,
    },
    userSelect: 'auto !important',
  },
});

const InputTimeline = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return <input className={classes.input} type="text" {...props} ref={ref} />;
});

export default InputTimeline;
