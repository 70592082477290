import Context from './Context';
import Provider from './Provider';
import Component from './Component';
import Trigger from './Trigger';
import * as events from './events';

const Hideable = { Provider, Consumer: Context.Consumer, Component, Trigger };

export * from './hooks';

export {
  withHideableComponent,
  createHideableComponent,
  getHideableEvents,
  wrapHideable,
} from './utils';

export const hideableEvents = { ...events };

export default Hideable;
