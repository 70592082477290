import React from 'react';
import { createUseStyles } from 'react-jss';
import { ClockIcon } from '../icons';

const useStyles = createUseStyles({
  icon: {
    fill: 'white',
    margin: [3, 12, 0],
    width: 16,
  },
});

const Clock = () => {
  const classes = useStyles();

  return (
    <div className={classes.icon}>
      <ClockIcon />
    </div>
  );
};

export default Clock;
