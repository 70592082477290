import React from 'react';
import PropTypes from 'prop-types';
import { explicitNull } from 'airbnb-prop-types';
import BaseInput from './BaseInput';
import Spacer from '../Spacer';
import { PROPERTIES_PRECISION } from '../../models';

const originXProperty = 'originX';
const originYProperty = 'originY';
const originXPrecision = PROPERTIES_PRECISION[originXProperty];
const originYPrecision = PROPERTIES_PRECISION[originYProperty];

const Origin = ({
  originX,
  originY,
  disabled,
  originXChange,
  originYChange,
}) => (
  <>
    <BaseInput
      id={originXProperty}
      label="X"
      disabled={disabled}
      onChange={originXChange}
      value={originX}
      precision={originXPrecision}
    />
    <Spacer />
    <BaseInput
      id={originYProperty}
      label="Y"
      disabled={disabled}
      onChange={originYChange}
      value={originY}
      precision={originYPrecision}
    />
  </>
);

Origin.propTypes = {
  originX: PropTypes.oneOfType([PropTypes.number, explicitNull()]),
  originY: PropTypes.oneOfType([PropTypes.number, explicitNull()]),
  disabled: PropTypes.bool,
  originXChange: PropTypes.func,
  originYChange: PropTypes.func,
};

Origin.defaultProps = {
  originX: null,
  originY: null,
  disabled: true,
  originXChange: () => {},
  originYChange: () => {},
};

export default Origin;
