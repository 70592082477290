import request from '../http';
import { identityDecoder, urlFactory } from './utils';

export const url = urlFactory('share');

export default {
  sharePieces: ({ id, name, pieces, updatedAt }, isQA = false) =>
    request(
      {
        url: url('/'),
        method: 'POST',
        data: {
          project: {
            uid: id,
            name,
            pieces,
            updatedAt,
          },
          isQA,
        },
      },
      data => data.uid,
    ),

  getSharedPieces: shareID =>
    request(
      {
        url: url(`/${shareID}`),
        method: 'GET',
        headers: {},
      },
      identityDecoder,
    ),
};
