import { call, takeEvery, select, put } from 'redux-saga/effects';
import { getCurrentProject } from '../project';
import { share as shareAPI } from '../api';
import * as types from './types';
import * as actions from './actions';
import { url } from '../App';
import { getOrigin } from './utils';

export const ERROR = 'Unknown error to generating share link for this project';

export function* requestShareLink(action) {
  const { isQA } = action.payload;
  const { id, name, pieces, updatedAt } = yield select(getCurrentProject);
  if (!id) return;

  const { ok, data } = yield call(
    shareAPI.sharePieces,
    {
      id,
      name,
      pieces,
      updatedAt,
    },
    isQA,
  );
  if (!ok) {
    yield put(
      actions.responseShareLink({
        error: ERROR,
      }),
    );
    return;
  }

  yield put(
    actions.responseShareLink({
      shareLink: `${getOrigin()}${url.sharedProject({
        shareID: data,
      })}`,
    }),
  );
}

function* viewPiecesSaga() {
  yield takeEvery(types.REQUEST_SHARE_LINK, requestShareLink);
}

export default viewPiecesSaga;
