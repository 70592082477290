import { ActionTypes } from 'redux-undo';
import {
  removeObjectsFromSelection,
  setCurrentObjectID,
  getSelectedObjectsIDs,
  getCurrentObjectID,
} from '../editor';
import { getCurrentPieceID } from '../stage';
import { getPieceNodes, getPast as getPastProjectObjects } from '../project';

const getByIDFromPast = pastStateArray =>
  pastStateArray[pastStateArray.length - 1].get('byID');

const removeOrphanObjectsFromSelection = (state, dispatch) => {
  const pastStateArray = getPastProjectObjects(state);
  if (!pastStateArray.length) {
    return;
  }

  const selectedIDs = getSelectedObjectsIDs(state);
  const byID = getByIDFromPast(pastStateArray);
  const idsToRemove = selectedIDs.filterNot(id => byID.has(id));

  if (!idsToRemove.size) {
    return;
  }

  dispatch(removeObjectsFromSelection(idsToRemove));
};

const ensureValidParentOnUndo = (state, dispatch) => {
  const pastStateArray = getPastProjectObjects(state);
  if (!pastStateArray.length) {
    return;
  }

  const byID = getByIDFromPast(pastStateArray);
  const currentObjectID = getCurrentObjectID(state);
  if (byID.has(currentObjectID)) {
    return;
  }

  const pieceID = getCurrentPieceID(state);
  const parentObjectID = getPieceNodes(state, pieceID).get(currentObjectID)
    .parentID;
  dispatch(setCurrentObjectID(parentObjectID));
};

export const ensureConsistencyOnUndo = ({
  getState,
  dispatch,
}) => next => action => {
  if (action.type === ActionTypes.UNDO) {
    const state = getState();

    removeOrphanObjectsFromSelection(state, dispatch);
    ensureValidParentOnUndo(state, dispatch);
  }

  return next(action);
};
