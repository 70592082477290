import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors } from '../../ui';
import SelectionIcon from './SelectionIcon';
import { createSentence } from './utils';

const useStyles = createUseStyles({
  box: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 40,
    backgroundColor: colors.black2,
    marginTop: 8,
    marginBottom: 8,
    padding: 8,
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 24,
    fill: colors.white,
  },
  name: {
    flex: 1,
    fontSize: 12,
  },
});

const SelectionInfo = ({ selectionSize, computedType }) => {
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <div className={classes.icon}>
        <SelectionIcon computedType={computedType} />
      </div>
      <div className={classes.name}>
        {createSentence(selectionSize, computedType)}
      </div>
    </div>
  );
};

SelectionInfo.propTypes = {
  selectionSize: PropTypes.number.isRequired,
  computedType: PropTypes.string.isRequired,
};

export default SelectionInfo;
