import { Record } from 'immutable';
import { createReducer } from '../utils';
import * as types from './types';
import { SET_CURRENT_PIECE, UNSET_CURRENT_PIECE } from '../stage';

export const StageMode = {
  select: 'select',
  pan: 'pan',
  div: 'div',
  insetClipPath: 'insetClipPath',
  text: 'Text',
};

export const EditorStageModel = Record({
  mode: StageMode.select,
  alignToContainer: false,
  currentObjectID: null,
  lastPieceID: null,
});

const setStageMode = ({ mode }) => state => state.set('mode', mode);

const toggleAlignToContainer = () => state =>
  state.update('alignToContainer', value => !value);

const setLastPieceID = ({ pieceID }) => state =>
  state.set('lastPieceID', pieceID);

const setCurrentObjectID = ({ objectID }) => state =>
  state.set('currentObjectID', objectID);

const unsetCurrentObjectID = () => state => state.set('currentObjectID', null);

export const stageReducer = createReducer(EditorStageModel(), {
  [types.SET_STAGE_MODE]: setStageMode,
  [types.TOGGLE_ALIGN_TO_CONTAINER]: toggleAlignToContainer,
  [SET_CURRENT_PIECE]: setLastPieceID,
  [types.SET_CURRENT_OBJECT]: setCurrentObjectID,
  [UNSET_CURRENT_PIECE]: unsetCurrentObjectID,
});

export default stageReducer;
