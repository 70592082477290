import { Record } from 'immutable';
import * as types from './types';
import { createReducer } from '../utils';

export const ProjectMetadataModel = Record({
  id: null,
  name: '',
  isAMP: false,
  updatedAt: null,
});

const setCurrentProject = ({ id, name, amp }) => state =>
  state.set('id', id).set('name', name).set('isAMP', amp);

const setUpdatedAt = ({ updatedAt }) => state =>
  state.set('updatedAt', updatedAt);

const resetMetadata = () => () => ProjectMetadataModel();

const metadataReducer = createReducer(ProjectMetadataModel(), {
  [types.SET_PROJECT_UPDATED_AT]: setUpdatedAt,
  [types.INIT_FROM_SERVER]: setUpdatedAt,
  [types.SET_CURRENT_PROJECT]: setCurrentProject,
  [types.UNSET_PROJECT]: resetMetadata,
});

export default metadataReducer;
