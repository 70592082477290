import React, { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { colors, DefaultPallete, InputColor } from '../../ui';
import WrapperColorPicker from './WrapperColorPicker';

const useStyles = createUseStyles({
  borderPicker: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
  },
  color: {
    display: 'flex',
    marginLeft: 16,
  },
  wrapperInputColor: {
    marginLeft: 16,
  },
});

const defaultConfigBorder = value => `1px solid ${value}`;

const BorderPicker = ({ templateName }) => {
  const [clickPosition, setClickPosition] = useState({ left: 0, top: 0 });
  const [colorBorder, setColorBorder] = useState(null);
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);

  const toggleVisibilityColorPicker = useCallback(
    () => setIsColorPickerVisible(!isColorPickerVisible),
    [isColorPickerVisible, setIsColorPickerVisible],
  );

  const handleOnClick = useCallback(
    ({ clientX, clientY }) => {
      setClickPosition({ left: clientX, top: clientY });
    },
    [setClickPosition],
  );

  const classes = useStyles();
  return (
    <div className={classes.borderPicker}>
      <label className={classes.label}>Border</label>
      <Field name={templateName}>
        {({ field, form }) => {
          const onChange = useCallback(
            border => {
              setColorBorder(border);
              form.setFieldValue(templateName, {
                ...field.value,
                border: border ? defaultConfigBorder(border) : 'none',
              });
            },
            [templateName, form, field],
          );
          return (
            <div className={classes.color}>
              <DefaultPallete onChangeColor={onChange} color={colorBorder} />
              {isColorPickerVisible && (
                <WrapperColorPicker
                  position={clickPosition}
                  color={colorBorder || colors.white}
                  setColor={onChange}
                  closeColorPicker={toggleVisibilityColorPicker}
                />
              )}
              <div className={classes.wrapperInputColor}>
                <InputColor
                  hasPreview
                  value={colorBorder || colors.white}
                  onChange={onChange}
                  onClick={e => {
                    toggleVisibilityColorPicker(e);
                    handleOnClick(e);
                  }}
                  rounded
                />
              </div>
            </div>
          );
        }}
      </Field>
    </div>
  );
};

BorderPicker.propTypes = {
  templateName: PropTypes.string.isRequired,
};

export default BorderPicker;
