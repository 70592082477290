import { getCurrentObjectID } from './selectors';

export const createActionWithParentObjectID = conf => ({
  ...conf,
  flags: { ...conf.flags, withParentObjectID: true },
});

export const currentParentObjectIDMiddleware = ({
  getState,
}) => next => action => {
  if (!action.flags || action.flags.withParentObjectID !== true) {
    return next(action);
  }

  const parentObjectID = getCurrentObjectID(getState());

  return next({ ...action, payload: { ...action.payload, parentObjectID } });
};
