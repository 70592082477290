import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';

const useStyles = createUseStyles({
  title: {
    fontSize: 12,
    color: colors.gray2,
    letterSpacing: 0.35,
    flex: [1, 1, '100%'],
  },
});

const SectionLabel = ({ title }) => {
  const classes = useStyles();
  return <span className={classes.title}>{title}</span>;
};

SectionLabel.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SectionLabel;
