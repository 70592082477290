import React from 'react';
import PropTypes from 'prop-types';
import { PopoverItem } from '../ui/Popover';
import { qa } from '../api';

const ApproveIssue = ({ projectID, issue, doAction }) => (
  <PopoverItem
    label="Approve"
    onClick={() => doAction(qa.approveIssue(projectID, issue.id))}
  />
);

ApproveIssue.propTypes = {
  projectID: PropTypes.string.isRequired,
  issue: PropTypes.object.isRequired,
  doAction: PropTypes.func.isRequired,
};

export default ApproveIssue;
