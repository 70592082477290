import { Map } from 'immutable';
import flatten from 'lodash/flatten';
import * as types from './types';
import { createReducer } from '../utils';
import { extractObjectsFromObject } from './reducer-objects-by-id';
import { buildMaskByObjectIDFromObjects } from './utils';

const initFromServer = ({ pieces }) => () => {
  const objects = flatten(
    pieces.map(piece => extractObjectsFromObject(piece.objects)),
  );

  return buildMaskByObjectIDFromObjects(objects);
};

const addReferenceToClipPath = ({ object }) => state => {
  return state.set(object.targetObjectID, object.id);
};

const handlers = {
  [types.INIT_FROM_SERVER]: initFromServer,
  [types.CREATE_CLIPPATH_OBJECT]: addReferenceToClipPath,
};

const maskByObjectID = createReducer(Map(), handlers);

export default maskByObjectID;
