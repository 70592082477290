import { Record } from 'immutable';
import round from 'lodash/round';
import { INSET } from './types';

export const insetStyle = {
  type: INSET,
  values: [0, 0, 0, 0],
};

export class InsetClipPath extends Record(insetStyle) {
  get left() {
    return this.values[0];
  }

  get top() {
    return this.values[1];
  }

  get width() {
    return this.values[2];
  }

  get height() {
    return this.values[3];
  }

  setLeft(left) {
    return this.update('values', values => {
      const newValues = [...values];
      newValues[0] = left;
      return newValues;
    });
  }

  setTop(top) {
    return this.update('values', values => {
      const newValues = [...values];
      newValues[1] = top;
      return newValues;
    });
  }

  setWidth(width) {
    return this.update('values', values => {
      const newValues = [...values];
      newValues[2] = width;
      return newValues;
    });
  }

  setHeight(height) {
    return this.update('values', values => {
      const newValues = [...values];
      newValues[3] = height;
      return newValues;
    });
  }

  round(precision = 6) {
    return this.update('values', values =>
      values.map(val => round(val, precision)),
    );
  }

  animate(next, progress) {
    return this.update('values', values => {
      const [fromLeft, fromTop, fromWidth, fromHeight] = values;
      const [toLeft, toTop, toWidth, toHeight] = next.values;

      return [
        fromLeft + (toLeft - fromLeft) * progress,
        fromTop + (toTop - fromTop) * progress,
        fromWidth + (toWidth - fromWidth) * progress,
        fromHeight + (toHeight - fromHeight) * progress,
      ];
    });
  }
}
