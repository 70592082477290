import React from 'react';
import { useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { Clock, SharedStage } from '../StageComponents';
import { colors } from '../ui';
import Header from './Header';
import { Provider as EditableContextProvider } from '../EditableContext';
import PlaybackControls from './PlaybackControls';
import { usePieceLoader } from '../hooks';
import RedirectToSharedPieces from './RedirectToSharedProject';
import SharedPlayerZoom from './SharedPlayerZoom';

const useStyles = createUseStyles({
  sharedPlayer: {
    background: colors.black2,
    display: 'flex',
    width: '100%',
    height: '100%',
    minHeight: 0,
    overflow: 'hidden',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  stage: {
    flexGrow: 1,
    minHeight: 0,
  },
  bottom: {
    width: '100%',
    padding: [0, 200],
  },
  bottomContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.black4,
    minWidth: 0,
    minHeight: 0,
    position: 'relative',
    zIndex: 1,
  },
});

const ViewSharedPlayer = () => {
  const classes = useStyles();
  const { pieceID } = useParams();
  const [piece, rootNode, currentPiece] = usePieceLoader(pieceID);

  if (!piece || !rootNode) {
    return <RedirectToSharedPieces />;
  }

  if (!currentPiece) {
    return <p>Loading...</p>;
  }

  return (
    <EditableContextProvider>
      <Clock />
      <div className={classes.sharedPlayer}>
        <Header pieceID={pieceID} />
        <div className={classes.content}>
          <div className={classes.stage}>
            <SharedStage />
          </div>
          <div className={classes.bottom}>
            <SharedPlayerZoom />
            <div className={classes.bottomContent}>
              <PlaybackControls />
            </div>
          </div>
        </div>
      </div>
    </EditableContextProvider>
  );
};

export default ViewSharedPlayer;
