import React from 'react';
import { object, node } from 'prop-types';
import { createRenderer } from 'fela';
import { RendererProvider } from 'react-fela';
import webPreset from 'fela-preset-web';

const [extend, embedded, prefixer, , unit] = webPreset;
export const rendererConfig = {
  plugins: [extend, embedded, prefixer, unit],
};
const defaultRenderer = createRenderer(rendererConfig);

const Fela = ({ children, renderer }) => (
  <RendererProvider renderer={renderer}>{children}</RendererProvider>
);

Fela.defaultProps = {
  renderer: defaultRenderer,
};

Fela.propTypes = {
  children: node.isRequired,
  renderer: object,
};

export default Fela;

export const wrapFela = (component, renderer = defaultRenderer) => (
  <Fela renderer={renderer}>{component}</Fela>
);
