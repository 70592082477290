export const AUTO_SAVE_PATTERN = 'PROJECT/PIECE';

// View Project
export const INIT_FROM_SERVER = 'PROJECT/INIT_FROM_SERVER';

export const INIT_ASSETS_FROM_SERVER = 'PROJECT/INIT_ASSETS_FROM_SERVER';

export const REQUEST_ASSETS = 'PROJECT/REQUEST_ASSETS';

// Assets
export const ADD_ASSET = 'PROJECT/ASSETS/ADD';

export const REMOVE_ASSET = 'PROJECT/ASSETS/REMOVE';

export const MOVE_ASSET = 'PROJECT/ASSETS/MOVE';

export const ADD_COLLECTION = 'PROJECT/COLLECTIONS/ADD';

export const REMOVE_COLLECTION = 'PROJECT/COLLECTIONS/REMOVE';

export const REQUEST_REMOVE_COLLECTION = 'PROJECT/COLLECTIONS/REQUEST_REMOVE';

// Project Metadata
export const SET_CURRENT_PROJECT = 'PROJECT/SET_CURRENT_PROJECT';

export const UNSET_PROJECT = 'PROJECT/UNSET_PROJECT';

export const SET_PROJECT_UPDATED_AT = 'PROJECT/SET_UPDATED_AT';

// Project
export const ADD_SIZES = 'PROJECT/PIECE/CREATE_PIECES';

export const REMOVE_PIECE = 'PROJECT/PIECE/REMOVE_PIECE';

// Piece properties
export const CREATE_OBJECT = 'PROJECT/PIECE/OBJECT/CREATE';

export const CREATE_CLIPPATH_OBJECT = 'PROJECT/PIECE/OBJECT/CREATE_CLIPPATH';

export const DUPLICATE_OBJECTS = 'PROJECT/PIECE/OBJECTS/DUPLICATE';

export const REMOVE_OBJECTS = 'PROJECT/PIECE/OBJECTS/REMOVE_MANY';

export const REMOVE_OBJECTS_BY_ASSET_PATH =
  'PROJECT/PIECE/OBJECTS/REMOVE_BY_ASSET_PATH';

export const REMOVE_OBJECTS_BY_PIECE_ID = 'PROJECT/PIECE/OBJECTS/REMOVE_ALL';

export const SET_PIECE_DURATION = 'PROJECT/PIECE/SET_DURATION';

export const SET_PIECE_PARENT_ID = 'PROJECT/PIECE/SET_PARENT';

export const REMOVE_PIECE_PARENT = 'PROJECT/PIECE/UNSET_PARENT';

export const COPY_PIECE_CONTENT = 'PROJECT/PIECE/COPY_FROM_OTHER_PIECE';

export const SET_UPDATED_AT = 'PROJECT/PIECE/SET_UPDATED_AT';

// Objects properties
export const UPDATE_OBJECTS = 'PROJECT/PIECE/OBJECTS/UPDATE';

export const UPDATE_OBJECTS_OPTION =
  'PROJECT/PIECE/OBJECT/UPDATE_OBJECTS_OPTION';

export const PROPERTY_INCREMENT = 'PROJECT/PIECE/OBJECT/INCREMENT_PROPERTY';

export const PROPERTY_SCALE = 'PROJECT/PIECE/OBJECT/SCALE_PROPERTY';

export const PROPERTY_SET = 'PROJECT/PIECE/OBJECT/SET_PROPERTY';

export const PROPERTY_STATIC_SET =
  'PROJECT/PIECE/OBJECT/SET_OBJECT_STATIC_PROPERTY';

export const UPDATE_OBJECTS_PROPERTIES =
  'PROJECT/PIECE/OBJECTS/UPDATE_PROPERTIES';

export const CHANGE_OBJECT_ASSET = 'PROJECT/PIECE/OBJECT/CHANGE_ASSET';

export const UPDATE_PIECE_FROM_PARENT =
  'PROJECT/PIECE/OBJECTS/UPDATE_FROM_PARENT';

export const RENAME_OBJECT = 'PROJECT/PIECE/OBJECTS/RENAME_OBJECT';

export const UPDATE_ASSET_PATH = 'PROJECT/PIECE/OBJECT/UPDATE_ASSET_PATH';

// Objects keyframes
export const CREATE_PROPERTIES_KEYFRAME =
  'PROJECT/PIECE/OBJECT/CREATE_KEYFRAMES';

export const REMOVE_KEYFRAMES = 'PROJECT/PIECE/OBJECT/REMOVE_KEYFRAMES';

export const SET_KEYFRAMES_EASING = 'PROJECT/PIECE/OBJECT/SET_KEYFRAMES_EASING';

export const UPDATE_PROPERTY_KEYFRAME_TIME =
  'PROJECT/PIECE/OBJECT/SET_KEYFRAME_TIME';

export const UPDATE_OBJECTS_KEYFRAMES_TIME =
  'PROJECT/PIECE/OBJECTS/UPDATE_KEYFRAMES_TIME';

export const APPLY_SAVED_ANIMATION =
  'PROJECT/PIECE/OBJECTS/APPLY_SAVED_ANIMATION';

// Objects Z positioning
export const MOVE_OBJECTS_ONE_STEP_TO_BOTTOM =
  'PROJECT/PIECE/OBJECTS/MOVE_ONE_STEP_TO_BOTTOM';

export const MOVE_OBJECTS_ONE_STEP_TO_TOP =
  'PROJECT/PIECE/OBJECTS/MOVE_ONE_STEP_TO_TOP';

export const MOVE_OBJECTS_TO_BOTTOM = 'PROJECT/PIECE/OBJECTS/MOVE_TO_BOTTOM';

export const MOVE_OBJECTS_TO_TOP = 'PROJECT/PIECE/OBJECTS/MOVE_TO_TOP';

export const MOVE_OBJECTS_TO_INDEX = 'PROJECT/PIECE/OBJECTS/MOVE_TO_INDEX';

// Objects alignment
export const ALIGN_OBJECTS_TO_BOTTOM = 'PROJECT/PIECE/OBJECTS/ALIGN_TO_BOTTOM';

export const ALIGN_OBJECTS_TO_CENTER = 'PROJECT/PIECE/OBJECTS/ALIGN_TO_CENTER';

export const ALIGN_OBJECTS_TO_LEFT = 'PROJECT/PIECE/OBJECTS/ALIGN_TO_LEFT';

export const ALIGN_OBJECTS_TO_MIDDLE = 'PROJECT/PIECE/OBJECTS/ALIGN_TO_MIDDLE';

export const ALIGN_OBJECTS_TO_RIGHT = 'PROJECT/PIECE/OBJECTS/ALIGN_TO_RIGHT';

export const ALIGN_OBJECTS_TO_TOP = 'PROJECT/PIECE/OBJECTS/ALIGN_TO_TOP';

// Objects distribution
export const DISTRIBUTE_OBJECTS_CENTER_H =
  'PROJECT/PIECE/OBJECTS/DISTRIBUTE_CENTER_H';

export const DISTRIBUTE_OBJECTS_CENTER_V =
  'PROJECT/PIECE/OBJECTS/DISTRIBUTE_CENTER_V';

export const DISTRIBUTE_OBJECTS_JUSTIFIED_H =
  'PROJECT/PIECE/OBJECTS/DISTRIBUTE_JUSTIFIED_H';

export const DISTRIBUTE_OBJECTS_JUSTIFIED_V =
  'PROJECT/PIECE/OBJECTS/DISTRIBUTE_JUSTIFIED_V';

export const AFTER_UNDO_REDO = 'PROJECT/AFTER_UNDO_REDO';

// Objects editor options
export const TOGGLE_OBJECT_ANIMATION =
  'PROJECT/PIECE/OBJECTS/TOGGLE_OBJECT_ANIMATION';

// Guides
export const CREATE_GUIDE = 'PROJECT/GUIDE/CREATE_GUIDE';

export const DELETE_GUIDE = 'PROJECT/GUIDE/DELETE_GUIDE';

export const MOVE_GUIDE = 'PROJECT/GUIDE/MOVE_GUIDE';

export const undoableTypes = [
  CREATE_OBJECT,
  UPDATE_OBJECTS,
  DUPLICATE_OBJECTS,
  ALIGN_OBJECTS_TO_BOTTOM,
  ALIGN_OBJECTS_TO_CENTER,
  ALIGN_OBJECTS_TO_LEFT,
  ALIGN_OBJECTS_TO_MIDDLE,
  ALIGN_OBJECTS_TO_RIGHT,
  ALIGN_OBJECTS_TO_TOP,
  CREATE_PROPERTIES_KEYFRAME,
  DISTRIBUTE_OBJECTS_CENTER_H,
  DISTRIBUTE_OBJECTS_CENTER_V,
  DISTRIBUTE_OBJECTS_JUSTIFIED_H,
  DISTRIBUTE_OBJECTS_JUSTIFIED_V,
  MOVE_OBJECTS_ONE_STEP_TO_TOP,
  MOVE_OBJECTS_ONE_STEP_TO_BOTTOM,
  MOVE_OBJECTS_TO_BOTTOM,
  MOVE_OBJECTS_TO_TOP,
  REMOVE_OBJECTS,
  REMOVE_OBJECTS_BY_PIECE_ID,
  REMOVE_KEYFRAMES,
  SET_KEYFRAMES_EASING,
  UPDATE_OBJECTS_PROPERTIES,
  UPDATE_PROPERTY_KEYFRAME_TIME,
  UPDATE_OBJECTS_KEYFRAMES_TIME,
  APPLY_SAVED_ANIMATION,
  RENAME_OBJECT,
];
