import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { getReversedCurrentChildrens } from '../selectors';
import DraggableEntryObject from './DraggableEntryObject';
import PopoverTimeline from './PopoverTimeline';

const ListObjects = ({ objectsID }) => (
  <>
    <PopoverTimeline />
    {objectsID.map(objectID => (
      <DraggableEntryObject key={objectID} objectID={objectID} />
    ))}
  </>
);

ListObjects.propTypes = {
  objectsID: PropTypes.instanceOf(List).isRequired,
};

const container = connect(state => ({
  objectsID: getReversedCurrentChildrens(state),
}));

export default container(ListObjects);
