import React from 'react';
import { createUseStyles } from 'react-jss';
import { node } from 'prop-types';

const useStyles = createUseStyles({
  Content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
});

const Content = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.Content}>{children}</div>;
};

Content.propTypes = {
  children: node.isRequired,
};

export default Content;
