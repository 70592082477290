export const SCALAR_PROPERTIES = [
  'left',
  'top',
  'width',
  'height',
  'opacity',
  'rotation',
  'originX',
  'originY',
  'translateX',
  'translateY',
  'scaleX',
  'scaleY',
];

export const PROPERTIES_PRECISION = {
  left: 0,
  top: 0,
  width: 0,
  height: 0,
  opacity: 2,
  rotation: 2,
  originX: 0,
  originY: 0,
  translateX: 2,
  translateY: 2,
  scaleX: 5,
  scaleY: 5,

  // non scalar
  clipPath: 2,
};

export const NON_SCALAR_PROPERTIES = ['clipPath'];

export const ALL_PROPERTIES = [...SCALAR_PROPERTIES, ...NON_SCALAR_PROPERTIES];

export const TRANSFORM_PROPERTIES = [
  ['rotation', 0],
  ['translateX', 0],
  ['translateY', 0],
  ['scaleX', 1],
  ['scaleY', 1],
];

export const AMP_PROHIBITED_PROPERTIES = [
  'left',
  'top',
  'width',
  'height',
  'originX',
  'originY',
];

export const PROPERTIES_LABEL = {
  left: 'Left',
  top: 'Top',
  width: 'Width',
  height: 'Height',
  opacity: 'Opacity',
  rotation: 'Rotation',
  originX: 'Pivot X',
  originY: 'Pivot Y',
  translateX: 'Translate X',
  translateY: 'Translate Y',
  scaleX: 'Scale X',
  scaleY: 'Scale Y',
  clipPath: 'Clip-Path',
};
