import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';
import { title4 } from '../typography';

const useStyles = createUseStyles(theme => ({
  modalHeader: {
    ...title4,
    color: theme.fg,
    marginBottom: 24,
  },
}));

const ModalHeader = ({ children, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ ...props, theme });
  return <div className={classes.modalHeader}>{children}</div>;
};
ModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalHeader;
