import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import Spacer from './Spacer';
import AnimationEntry from './AnimationEntry';
import { getIsClipPathID } from '../../selectors';
import { CLIPPATH, IMG, DIV, VIDEO } from '../../models';
import { animations as api } from '../../api';
import { Pagination } from '../../ui';
import { useRequest } from '../../http';

const useStyles = createUseStyles({
  paginator: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
});

export const getObjectTypes = isClipPath => {
  if (isClipPath) {
    return [CLIPPATH];
  }

  return [IMG, DIV, VIDEO];
};

const ListAnimations = ({
  searchParams,
  selectedAnimation,
  onSelectAnimation,
  onRequest,
  objectID,
}) => {
  const classes = useStyles();
  const isClipPath = useSelector(state => getIsClipPathID(state, objectID));
  const [params, setParams] = useState({
    ...searchParams,
    objectTypes: getObjectTypes(isClipPath),
  });
  const { loading, data, ok, request } = useRequest(
    api.getAnimations(1, params),
    { onRequest },
    [params],
  );
  useEffect(() => {
    setParams(oldState => {
      const newParams = {
        ...searchParams,
        objectTypes: getObjectTypes(isClipPath),
      };

      if (isEqual(oldState, newParams)) {
        return oldState;
      }

      return newParams;
    });
  }, [searchParams, isClipPath]);
  const handlePageChange = useCallback(
    page => request(api.getAnimations(page, params)),
    [request, params],
  );

  if (loading) {
    return <h4>Loading</h4>;
  }

  if (!ok) {
    return <h4>Something is wrong, list cannot be loaded!</h4>;
  }

  return (
    <>
      {data &&
        data.animations.map(animation => (
          <AnimationEntry
            key={animation.id}
            animation={animation}
            isSelected={
              selectedAnimation !== null &&
              selectedAnimation.id === animation.id
            }
            onClick={onSelectAnimation}
            refetch={request}
          />
        ))}
      <Spacer />
      <div className={classes.paginator}>
        <Pagination {...data} fetchMore={handlePageChange} />
      </div>
    </>
  );
};

ListAnimations.defaultProps = {
  selectedAnimation: null,
};

ListAnimations.propTypes = {
  searchParams: PropTypes.object.isRequired,
  selectedAnimation: PropTypes.object,
  onSelectAnimation: PropTypes.func.isRequired,
  onRequest: PropTypes.func.isRequired,
  objectID: PropTypes.string.isRequired,
};

export default ListAnimations;
