/* istanbul ignore file */

const mockGetBoundingClientRect = ({
  left = 10,
  top = 10,
  width = 10,
  height = width,
} = {}) => () => ({
  left,
  top,
  x: left,
  y: top,
  width,
  height,
});

const mockDimensionsRef = dimensions => {
  return {
    current: {
      getBoundingClientRect: mockGetBoundingClientRect(dimensions),
    },
  };
};

const mockEvent = ({
  clientX = 0,
  clientY = 0,
  shiftKey = false,
  button = 0,
} = {}) => ({
  pointerId: 'test',
  stopPropagation: jest.fn(),
  persist: jest.fn(),
  target: {
    setPointerCapture: jest.fn(),
    releasePointerCapture: jest.fn(),
  },
  clientX,
  clientY,
  shiftKey,
  button,
});

const createEventMockFn = eventName => (wrapper, eventArgs) => {
  const event = mockEvent(eventArgs);
  wrapper.instance()[eventName](event);
  return event;
};

export const events = {
  down: createEventMockFn('onPointerDown'),
  move: createEventMockFn('onPointerMove'),
  up: createEventMockFn('onPointerUp'),
};

export const mockSelectionArea = (wrapper, dimensions) => {
  /* eslint-disable no-param-reassign */
  wrapper.instance().areaRef = mockDimensionsRef(dimensions);
  return wrapper;
};

export const mockSelectable = (wrapper, dimensions) => {
  wrapper.instance().selectableRef = mockDimensionsRef(dimensions);
  return wrapper;
};
