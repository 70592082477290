import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { GlobalHotKeys, configure } from 'react-hotkeys';

export const globalShortcutsToProps = (shortcuts, dispatch) =>
  shortcuts.reduce(
    ({ map, handlers }, { name, keys, handler }) => ({
      map: {
        ...map,
        [name]: keys,
      },
      handlers: {
        ...handlers,
        [name]: event => {
          dispatch(handler(event));
        },
      },
    }),
    {
      map: {},
      handlers: {},
    },
  );

const GlobalShortcuts = ({ shortcutsHandlers }) => {
  const dispatch = useDispatch();
  const { map, handlers } = globalShortcutsToProps(shortcutsHandlers, dispatch);
  configure({ simulateMissingKeyPressEvents: false });
  return <GlobalHotKeys keyMap={map} handlers={handlers} allowChanges />;
};

GlobalShortcuts.propTypes = {
  shortcutsHandlers: PropTypes.array,
};

GlobalShortcuts.defaultProps = {
  shortcutsHandlers: [],
};

export default GlobalShortcuts;
