import React from 'react';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';
import Content from './Content';

const useStyles = createUseStyles({
  loading: {
    display: 'flex',
    height: 104,
    marginTop: 16,
    width: '100%',
  },
  picture: {
    backgroundColor: colors.gray3,
    flexGrow: 0,
    flexShrink: 0,
    height: 104,
    marginRight: 28,
    width: 104,
  },
  infos: {
    flexGrow: 1,
    paddingTop: 16,
  },
  info1: {
    background: colors.gray3,
    height: 40,
    marginBottom: 16,
    width: '100%',
  },
  info2: {
    background: colors.gray3,
    height: 16,
    width: '100%',
  },
});

const LoadingPage = () => {
  const classes = useStyles();
  return (
    <Content>
      <div className={classes.loading}>
        <div className={classes.picture} />
        <div className={classes.infos}>
          <div className={classes.info1} />
          <div className={classes.info2} />
        </div>
      </div>
    </Content>
  );
};

export default LoadingPage;
