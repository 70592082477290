import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors } from '../../ui';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    fontSize: 16,
  },
  input: {
    display: 'none',
  },
  displayName: {
    flexGrow: 1,
    color: colors.black4,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    overflowWrap: 'normal',
  },
  chooseButton: {
    width: 110,
    flexShrink: 0,
    backgroundColor: 'transparent',
    color: colors.black4,
    opacity: 0.6,

    '&:hover, &:active': {
      opacity: 1,
    },
  },
});

const SingleFileField = ({ inputRef, ...inputOptions }) => {
  const classes = useStyles();
  const [filename, setFilename] = useState('');
  const handleClick = useCallback(() => {
    const { current } = inputRef;
    if (!current) {
      return;
    }

    current.click();
  }, [inputRef]);

  const handleChange = useCallback(() => {
    const { current } = inputRef;
    if (!current) {
      return;
    }

    setFilename(current.files[0].name);
  });

  return (
    <div className={classes.container}>
      <input
        type="file"
        ref={inputRef}
        className={classes.input}
        onChange={handleChange}
        {...inputOptions}
      />
      <span className={classes.displayName}>{filename}</span>
      <button
        type="button"
        className={classes.chooseButton}
        onClick={handleClick}
      >
        Choose File
      </button>
    </div>
  );
};

SingleFileField.propTypes = {
  inputRef: PropTypes.object.isRequired,
};

export default SingleFileField;
