import React from 'react';
import { string, bool } from 'prop-types';
import { connect } from 'react-redux';
import { getIsSyncedPiece } from '../project';
import SelectParentPiece from './SelectParentPiece';
import UpdatePieceButton from './UpdatePieceButton';

const ParentPiece = ({ pieceID, isSyncedPiece }) => (
  <div>
    {isSyncedPiece ? (
      <SelectParentPiece pieceID={pieceID} />
    ) : (
      <UpdatePieceButton pieceID={pieceID} />
    )}
  </div>
);

ParentPiece.propTypes = {
  pieceID: string.isRequired,
  isSyncedPiece: bool.isRequired,
};

const container = connect((state, { pieceID }) => ({
  isSyncedPiece: getIsSyncedPiece(state, pieceID),
}));

export default container(ParentPiece);
