import createRequestDescription from './createRequestDescription';
import { urlFactory } from './utils';

export const url = urlFactory('tracker');

export const requestTrackerDecoder = ({ data = {} } = {}) => ({
  status: data.status || null,
  counter: data.counter || 0,
});

export const TRACKER_STATUS = {
  STARTED: 'STARTED',
  PAUSED: 'PAUSED',
  STOPPED: 'STOPPED',
};

export const validateStatus = status => status === 200 || status === 404;

export default {
  getTracker: project =>
    createRequestDescription(
      {
        url: url(`/${project}`),
        method: 'GET',
        validateStatus,
      },
      requestTrackerDecoder,
    ),
  startTracker: project =>
    createRequestDescription(
      {
        url: url(`/start`),
        method: 'POST',
        data: {
          project,
        },
      },
      requestTrackerDecoder,
    ),
  pauseTracker: project =>
    createRequestDescription(
      {
        url: url(`/pause`),
        method: 'POST',
        data: {
          project,
        },
      },
      requestTrackerDecoder,
    ),
  stopTracker: project =>
    createRequestDescription(
      {
        url: url(`/stop`),
        method: 'POST',
        data: {
          project,
        },
      },
      requestTrackerDecoder,
    ),
};
