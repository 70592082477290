import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { useField } from 'formik';
import { createUseStyles } from 'react-jss';
import { Select, ItemModel } from '../../ui';

// The actual representation for these values is 1 (playback) + loop times
export const LOOP_OPTIONS = List([
  ItemModel({ label: 'None', value: null }),
  ItemModel({ label: '1 time', value: 2 }),
  ItemModel({ label: '2 times', value: 3 }),
  ItemModel({ label: '3 times', value: 4 }),
  ItemModel({ label: '5 times', value: 6 }),
  ItemModel({ label: '10 times', value: 11 }),
  ItemModel({ label: 'Infinite', value: 'infinite' }),
]);

const useStyles = createUseStyles({
  loopSelector: {
    display: 'flex',
    alignItems: 'center',
    '&> label': {
      paddingRight: 16,
    },
  },
  selectLoop: {
    width: 200,
  },
});

const LoopSelector = ({ templateName }) => {
  const classes = useStyles();
  const [{ value }, , { setValue }] = useField(templateName);
  const onChange = useCallback(
    newValue => {
      setValue({ ...value, looping: newValue });
    },
    [value, setValue],
  );

  return (
    <div className={classes.loopSelector}>
      <label>Loop</label>
      <div className={classes.selectLoop}>
        <Select
          placeholder="Loop Options"
          items={LOOP_OPTIONS}
          value={value.looping}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

LoopSelector.propTypes = {
  templateName: PropTypes.string.isRequired,
};

export default LoopSelector;
