import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import { gql, useMutation } from '@apollo/client';
import { string, func } from 'prop-types';
import {
  ButtonPrimary,
  ButtonTertiary,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  createHideableComponent,
} from '../../ui';
import { showNotification } from '../../Notification';

export const ERROR_MESSAGE =
  'It was not possible to delete project. Try again later.';

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: String!) {
    deleteProject(id: $id) {
      ok
    }
  }
`;

const ModalContext = createHideableComponent('deleteProject');

const ModalDeleteProject = ({ id, refetch, sendErrorNotification }) => {
  const [state, setState] = useState({
    submitting: false,
  });

  const [deleteProject] = useMutation(DELETE_PROJECT);

  const deletingProject = useCallback(
    closeModal => {
      return () => {
        setState({
          submitting: true,
        });
        deleteProject({ variables: { id } })
          .then(({ data }) => {
            const { ok } = data.deleteProject;
            if (ok) {
              refetch();
              closeModal();
              setState({
                submitting: false,
              });

              return;
            }

            throw new Error('Response error');
          })
          .catch(() => {
            setState({
              submitting: false,
            });
            sendErrorNotification();
          });
      };
    },
    [id, setState, sendErrorNotification, refetch],
  );

  return (
    <ModalContext.Target>
      {({ isVisible, hide }) => (
        <Modal isVisible={isVisible}>
          <ModalHeader>Delete Item</ModalHeader>
          <ModalContent>
            You are about to delete an item from your projects list. If pressing
            confirm, you will delete this item permanently, and won &apos;t be
            able to rescue it afterwards.
          </ModalContent>
          <ModalFooter>
            <ButtonTertiary
              label="Cancel"
              disabled={state.submitting}
              onClick={hide}
            />
            <ButtonPrimary
              label="Confirm"
              disabled={state.submitting}
              onClick={deletingProject(hide)}
            />
          </ModalFooter>
        </Modal>
      )}
    </ModalContext.Target>
  );
};

ModalDeleteProject.propTypes = {
  id: string.isRequired,
  refetch: func.isRequired,
  sendErrorNotification: func.isRequired,
};

const withRedux = connect(null, dispatch => ({
  sendErrorNotification() {
    dispatch(showNotification(ERROR_MESSAGE));
  },
}));

export default compose(withRedux)(ModalDeleteProject);
