export const getExportCreatives = state => state.get('exportCreatives');

export const getIsWaitingForEstimative = state =>
  getExportCreatives(state).get('isWaitingForEstimative');

export const getIsLoading = state => getExportCreatives(state).get('loading');

export const getPiecesSelected = state =>
  getExportCreatives(state).get('piecesSelected');

export const getSizeOfFile = (state, templateName, piece) => {
  if (Object.keys(getPiecesSelected(state)).length > 0) {
    try {
      const pieceSize = getPiecesSelected(state)[`${templateName}`][
        `${piece}.zip`
      ].size;
      return pieceSize;
    } catch (e) {
      return null;
    }
  }
  return null;
};
