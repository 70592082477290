import {
  cancel,
  call,
  debounce,
  put,
  takeEvery,
  take,
  fork,
  select,
} from 'redux-saga/effects';
import { projects as projectsAPI, assets as assetsAPI } from '../api';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../Notification';
import * as actions from './actions';
import * as types from './types';
import { getAssetsByCollectionPath } from './selectors';
import { getCurrentProject } from './selector-graphql-serializer';
import { getCurrentPieceID } from '../stage';
import {
  updateLastModification,
  START_BACKGROUND_TASKS,
  STOP_BACKGROUND_TASKS,
} from '../editor';

export const errors = {
  fetchAssets: 'Could not fetch your assets. Try again later.',
  removeCollection: 'Could not remove your collection. Try again later',
  autoSave: 'Could not save this project.',
  autoSaveFatal:
    'FATAL ERROR in auto save module. Please inform the development team!',
};

export const successMessages = {
  removeCollection: 'Collection removed successfully',
};

export function* fetchAssetsSaga(action) {
  const { projectID } = action.payload;
  const rootPath = `/${projectID}`;
  const response = yield call(assetsAPI.getCollection, rootPath);
  if (!response.ok) {
    yield put(showErrorNotification(errors.fetchAssets));
    return;
  }

  const assets = [];
  const collections = [];

  for (let i = 0; i < response.data.length; i += 1) {
    const entry = response.data[i];
    if (entry.type !== 'col') {
      assets.push(entry);
    } else {
      collections.push(entry);
      const childResponse = yield call(assetsAPI.getCollection, entry.path);

      if (!childResponse.ok) {
        yield put(showErrorNotification(errors.fetchAssets));
        return;
      }

      for (let j = 0; j < childResponse.data.length; j += 1) {
        const childEntry = childResponse.data[j];
        if (childEntry.type !== 'col') {
          assets.push(childEntry);
        }
      }
    }
  }

  yield put(actions.initAssetsFromServer({ assets, collections }));
}

export function* removeCollectionSaga(action) {
  const { path } = action.payload;
  const response = yield call(assetsAPI.removeCollection, path);
  if (!response.ok) {
    yield put(showErrorNotification(errors.removeCollection));
    return;
  }
  const assets = (yield select(getAssetsByCollectionPath, path)).toArray();
  for (let i = 0; i < assets.length; i += 1) {
    const asset = assets[i];
    yield put(actions.removeObjectsByAssetPath(asset.path));
    yield put(actions.removeAsset(asset.path));
  }
  yield put(actions.removeCollection(path));
  yield put(showSuccessNotification(successMessages.removeCollection));
}

export function* updatePieceUpdateAt(updatedAt) {
  const pieceID = yield select(getCurrentPieceID);

  if (!pieceID) {
    return;
  }

  yield put(actions.setUpdatedAt(pieceID, updatedAt));
}

export const pieceActionMatcher = ({ type }) =>
  type !== types.SET_UPDATED_AT && type.startsWith('PROJECT/PIECE');

export const updateLastInteractionDelay = 100;

export function* updateLastInteraction() {
  const lastModification = new Date().toISOString();

  yield put(updateLastModification(lastModification));
  yield call(updatePieceUpdateAt, lastModification);
}

export const autoSaveDebounceDelay = 3000;

export function* autoSave() {
  try {
    const { id, pieces, guides } = yield select(getCurrentProject);

    if (!id) return;

    const { ok } = yield call(projectsAPI.savePieces, id, pieces, guides);
    if (!ok) {
      yield put(showErrorNotification(errors.autoSave));
      return;
    }

    yield put(actions.setProjectUpdatedAt(new Date().toISOString()));
  } catch (err) {
    // eslint-disable-next-line
    yield call(console.error, err);
    yield put(showErrorNotification(errors.autoSaveFatal));
  }
}

export const setLastModificationDelay = 250;

export const autoSaveActionMatcher = ({ type }) =>
  type !== types.SET_PROJECT_UPDATED_AT &&
  type.startsWith(types.AUTO_SAVE_PATTERN);

export function* backgroundTasks() {
  yield debounce(
    updateLastInteractionDelay,
    pieceActionMatcher,
    updateLastInteraction,
  );
  yield debounce(autoSaveDebounceDelay, autoSaveActionMatcher, autoSave);
}

export default function* projectSagas() {
  yield takeEvery(types.REQUEST_ASSETS, fetchAssetsSaga);
  yield takeEvery(types.REQUEST_REMOVE_COLLECTION, removeCollectionSaga);

  while (yield take(START_BACKGROUND_TASKS)) {
    const task = yield fork(backgroundTasks);

    yield take(STOP_BACKGROUND_TASKS);
    yield cancel(task);
  }
}
