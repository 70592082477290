import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';
import colors from '../colors';
import * as typography from '../typography';

const iconStyle = {
  position: 'absolute',
  height: 16,
  width: 16,
  top: '50%',
  transform: 'translateY(-50%)',
};

const useStyles = createUseStyles(theme => ({
  wrapper: {
    display: 'inline-flex',
    position: 'relative',
    width: ({ expanded }) => (expanded ? '100%' : 'auto'),
  },
  input: {
    ...typography.textRegular,
    backgroundColor: 'transparent',
    border: `1px solid`,
    borderColor: theme.fg,
    borderRadius: 20,
    color: theme.fg,
    paddingBottom: 0,
    paddingLeft: ({ iconLeft }) => (iconLeft ? 46 : 16),
    paddingRight: ({ iconRight }) => (iconRight ? 46 : 16),
    paddingTop: 0,
    height: 40,
    minWidth: 350,
    outline: 'none',
    width: ({ expanded }) => (expanded ? '100%' : 'auto'),
    '&:disabled': {
      background: 'none',
      opacity: 0.35,
    },
    '&::placeholder': {
      color: theme.fg,
    },
    '&:focus': {
      boxShadow: 'none',
      '&::placeholder': {
        color: 'transparent',
      },
      borderColor: colors.primary,
    },
  },
  title: {
    ...typography.textRegular,
    color: ({ error }) => (error ? colors.error : theme.fg),
    display: 'block',
    marginBottom: 8,
    opacity: ({ disabled }) => (disabled ? 0.35 : 1),
  },
  iconLeft: {
    ...iconStyle,
    fill: theme.fg,
    left: 16,
  },
  iconRight: {
    ...iconStyle,
    fill: theme.fg,
    right: 16,
  },
  error: {
    ...typography.label,
    color: colors.error,
    display: 'block',
    marginTop: 8,
  },
}));

const TextField = ({
  expanded,
  iconLeft,
  iconRight,
  error,
  onChange,
  required,
  title,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles({
    ...props,
    error,
    iconLeft,
    expanded,
    iconRight,
    theme,
  });
  const IconLeft = iconLeft;
  const IconRight = iconRight;
  return (
    <div>
      {title && (
        <label className={classes.title}>
          {title}
          {required && '*'}
        </label>
      )}
      <div className={classes.wrapper}>
        <input
          className={classes.input}
          onChange={onChange}
          required={required}
          {...props}
        />
        {iconLeft && <IconLeft className={classes.iconLeft} />}
        {iconRight && <IconRight className={classes.iconRight} />}
      </div>
      <span className={classes.error}>{error}</span>
    </div>
  );
};

TextField.propTypes = {
  expanded: PropTypes.bool,
  error: PropTypes.string,
  iconLeft: PropTypes.func,
  iconRight: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  title: PropTypes.string,
};

TextField.defaultProps = {
  expanded: null,
  iconLeft: null,
  iconRight: null,
  error: null,
  onChange: () => undefined,
  required: false,
  title: null,
};

export default TextField;
