import React from 'react';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { UpdateIcon } from '../icons';
import { colors } from '../ui';
import {
  copyPieceContent,
  updatePieceFromParent,
  getPieceParentID,
  getPieceByID,
} from '../project';
import { showSuccessNotification } from '../Notification';

export const UPDATE_MESSAGE = 'Piece objects updated!';

const useStyles = createUseStyles({
  box: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    fontSize: 12,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: colors.yellow,
    fill: colors.yellow,
    marginTop: 5,
    marginBottom: 5,
    marginRight: 4,
    '&:hover': {
      opacity: 0.8,
    },
    '&:active': {
      opacity: 1,
    },
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
});

const UpdatePieceButton = ({ parentPieceID, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.box}>
      <button type="button" className={classes.button} onClick={onClick}>
        <UpdateIcon className={classes.icon} />
        update style
      </button>
      <span>(inheriting from {parentPieceID})</span>
    </div>
  );
};

UpdatePieceButton.propTypes = {
  onClick: func.isRequired,
  parentPieceID: string.isRequired,
};

const container = connect(
  (state, { pieceID }) => {
    const parentPieceID = getPieceParentID(state, pieceID);
    return {
      piece: getPieceByID(state, pieceID),
      parentPieceID,
      parentPiece: getPieceByID(state, parentPieceID),
    };
  },
  {
    updatePieceFromParent,
    copyPieceContent,
    showSuccessNotification,
  },
  ({ parentPieceID, parentPiece, piece }, actions, { classes, pieceID }) => ({
    classes,
    parentPieceID,
    pieceID,
    onClick() {
      actions.copyPieceContent(parentPiece, piece);
      actions.updatePieceFromParent(parentPiece, piece);
      actions.showSuccessNotification(UPDATE_MESSAGE);
    },
  }),
);

export default container(UpdatePieceButton);
