import React, { useCallback, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { ColorPicker } from '../../ui';

const useStyles = createUseStyles({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10002,
    overflow: 'hidden',
  },
  wrapperColorPicker: ({ top, left }) => ({
    position: 'absolute',
    top: top - 116,
    left: left - 116,
    width: 232,
    height: 384,
    zIndex: 10003,
    boxShadow: '0px 2px 8px 0px #00000014, 0px 16px 16px 0px #00000029',
  }),
});

const WrapperColorPicker = ({ closeColorPicker, position, ...props }) => {
  const classes = useStyles(position);
  const refColorPicker = useRef(null);
  const handleOnClick = useCallback(
    e => {
      // FIXME, this component is receiving
      // the click from children that is overlay
      // the parent, this is not a desired behavior
      if (refColorPicker.current.contains(e.target)) {
        return;
      }

      closeColorPicker();
    },
    [closeColorPicker],
  );
  return (
    <div className={classes.overlay} onPointerDown={handleOnClick} role="none">
      <div className={classes.wrapperColorPicker} ref={refColorPicker}>
        <ColorPicker onClick={closeColorPicker} {...props} />
      </div>
    </div>
  );
};

WrapperColorPicker.propTypes = {
  closeColorPicker: PropTypes.func.isRequired,
  position: PropTypes.object.isRequired,
};

export default WrapperColorPicker;
