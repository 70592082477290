import * as types from './types';

export const setPieceToBreakParent = piece => ({
  type: types.SET_PIECE_TO_BREAK_PARENT,
  payload: { piece },
});

export const unsetPieceToBreakParent = () => ({
  type: types.UNSET_PIECE_TO_BREAK_PARENT,
});

export const setPiecesOrderBy = orderBy => ({
  type: types.SET_PIECES_ORDER_BY,
  payload: { orderBy },
});

export const requestShareLink = (isQA = false) => ({
  type: types.REQUEST_SHARE_LINK,
  payload: { isQA },
});

export const responseShareLink = payload => ({
  type: types.RESPONSE_SHARE_LINK,
  payload,
});
