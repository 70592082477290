import React from 'react';
import PropTypes from 'prop-types';
import { FloatInput } from '../../ui';
import Label from './Label';

const BaseInput = ({ id, label, ...props }) => (
  <>
    {label && (
      <Label
        key={`label-${id}`}
        label={`${label}:`}
        htmlFor={`panel-properties-${id}`}
      />
    )}
    <FloatInput key={`input-${id}`} id={`panel-properties-${id}`} {...props} />
  </>
);

BaseInput.defaultProps = {
  label: null,
};

BaseInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default BaseInput;
