import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AlignLeftIcon } from '../icons';
import { ToolbarButton } from '../ui';
import { getAlignToContainer, getSelectedObjectsIDs } from '../editor';
import { getCurrentPiece, alignObjectsToLeft } from '../project';

const ButtonAlignLeft = ({ disabled, onClick }) => {
  if (disabled) {
    return null;
  }

  return <ToolbarButton icon={<AlignLeftIcon />} onClick={onClick} />;
};

ButtonAlignLeft.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const reduxConnector = connect(
  state => {
    let container = getCurrentPiece(state);
    if (!getAlignToContainer(state)) {
      container = null;
    }

    const ids = getSelectedObjectsIDs(state);
    let disabled = true;
    if (ids.size >= 1) {
      disabled = false;
    }
    return { ids, disabled, container };
  },
  { alignObjectsToLeft },
  ({ ids, container, ...state }, actions) => ({
    ...state,
    onClick: () => actions.alignObjectsToLeft({ ids, container }),
  }),
);

export default reduxConnector(ButtonAlignLeft);
