import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearAuthStorage } from './storage';
import { reset } from '../reset';
import { url } from '../App/urls';

export const useLogout = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const history = useHistory();
  const logout = useCallback(() => {
    client.clearStore().then(() => {
      clearAuthStorage();
      dispatch(reset());
      history.push(url.login());
    });
  }, [client, dispatch, history]);

  return logout;
};
