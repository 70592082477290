import React from 'react';
import { node } from 'prop-types';
import axios from 'axios';
import Context from './Context';
import { getAccessToken } from '../auth';

export { default as useRequest } from './useRequest';

export const errors = {
  valueError: 'ValueError: you must pass a request decoder',
  requestDecoderError:
    'Could not decode response data after successful response',
  errorDecoderError: 'Could not decode error',
  knownError: 'Known error',
  unknownError: 'Unknown error',
  configError: 'Config error',
  networkError: 'Network error',
};

export const makeFailResult = (reason, data) => ({
  ok: false,
  reason,
  data,
});

export const makeSuccessResult = data => ({
  ok: true,
  data,
});

const request = (config, requestDecoder, errorDecoder) => {
  if (!requestDecoder) {
    throw new Error(errors.valueError);
  }
  return axios({
    headers: { authorization: getAccessToken() },
    ...config,
  })
    .then(response => {
      try {
        const decodedData = requestDecoder(response.data);
        return makeSuccessResult(decodedData);
      } catch (error) {
        return makeFailResult(errors.requestDecoderError);
      }
    })
    .catch(error => {
      if (error.response) {
        try {
          if (errorDecoder) {
            const decodedError = errorDecoder(error.response.data);
            return makeFailResult(errors.knownError, decodedError);
          }
          return makeFailResult(errors.unknownError);
        } catch (anotherError) {
          return makeFailResult(errors.errorDecoderError);
        }
      } else if (error.request) {
        return makeFailResult(errors.networkError);
      } else {
        return makeFailResult(errors.configError);
      }
    });
};

export const HttpProvider = ({ children }) => (
  <Context.Provider value={request}>{children}</Context.Provider>
);

HttpProvider.propTypes = {
  children: node.isRequired,
};

export default request;
