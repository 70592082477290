import React from 'react';
import Container from './Container';
import View from './View';
import { WhiteTheme } from '../ui';
import { Header } from '../HeaderProjectList';
import MonthlyReport from './MonthlyReport';
import FullReport from './FullReport';

const ViewReports = () => (
  <WhiteTheme>
    <View>
      <Header />
      <Container>
        <MonthlyReport />
        <FullReport />
      </Container>
    </View>
  </WhiteTheme>
);

export default ViewReports;
