import cuid from 'cuid';
import { List, Map, Set } from 'immutable';
import { combineReducers } from 'redux-immutable';
import reduceReducers from 'reduce-reducers';
import undoable, { includeAction } from 'redux-undo';
import { createReducer } from '../utils';
import { SET_CURRENT_TIME } from '../playback';
import byID from './reducer-objects-by-id';
import byPieceID from './reducer-objects-by-piece-id';
import maskByObjectID from './reducer-mask-by-object-id';
import animateObjects from './animation';
import * as types from './types';
import {
  buildPieceID,
  getAllObjectIDsFromTree,
  setObjectName,
  roundValue,
  buildMaskByObjectIDFromObjects,
} from './utils';
import {
  InheritanceModel,
  makeDivObject,
  NodeModel,
  DIV,
  IMG,
  CLIPPATH,
} from '../models';
import { makeObjectResponsive } from './responsive';

const combinedReducer = combineReducers({
  byID,
  byPieceID,
  maskByObjectID,
});

export const rebuildMaskByObjectID = oldState =>
  oldState.update(state => {
    const objects = state.get('byID');

    return state.set('maskByObjectID', buildMaskByObjectIDFromObjects(objects));
  });

const copyPieceContent = ({ fromPiece, toPiece }) => state => {
  const fromPieceID = fromPiece.id;
  const toPieceID = toPiece.id;

  const fromRootNode = state
    .getIn(['byPieceID', fromPieceID])
    .find(node => node.parentID === null);
  const toRootNode = state
    .getIn(['byPieceID', toPieceID])
    .find(node => node.parentID === null);
  const inheritedObjectsIDs = state
    .getIn(['byPieceID', toPieceID])
    .reduce(getAllObjectIDsFromTree, Set())
    .map(objectID => state.getIn(['byID', objectID, 'inheritance', 'fromID']))
    .filter(id => !!id);
  let clipPathsToCopy = Map();

  const newObjects = state
    .getIn(['byPieceID', fromPieceID])
    .reduce(getAllObjectIDsFromTree, Set())
    .filterNot(objectID => inheritedObjectsIDs.includes(objectID))
    .reduce((currentMap, objectID) => {
      if (fromRootNode.objectID === objectID) {
        return currentMap.set(
          toRootNode.objectID,
          state.getIn(['byID', toRootNode.objectID]),
        );
      }

      const newObjectID = cuid();
      const parentObject = state.getIn(['byID', objectID]);

      const { clipPathObjectID } = parentObject;
      if (clipPathObjectID) {
        clipPathsToCopy = clipPathsToCopy.set(newObjectID, clipPathObjectID);
      }

      const newObject = parentObject
        .set('id', newObjectID)
        .set('pieceID', toPieceID)
        .set('clipPathObjectID', null)
        .set(
          'inheritance',
          InheritanceModel({
            fromID: objectID,
            lastSyncHash: parentObject.hashCode(),
          }),
        );

      return currentMap.set(
        newObjectID,
        makeObjectResponsive({
          object: newObject,
          parentObject,
          piece: toPiece,
          parentPiece: fromPiece,
        }),
      );
    }, Map());

  const newClipPathObjects = clipPathsToCopy.reduce(
    (currentSet, parentObjectID, targetObjectID) => {
      const newClipPathObjectID = cuid();
      const parentObject = state.getIn(['byID', parentObjectID]);

      const newObject = parentObject
        .set('id', newClipPathObjectID)
        .set('pieceID', toPieceID)
        .set('targetObjectID', targetObjectID)
        .set(
          'inheritance',
          InheritanceModel({
            fromID: parentObjectID,
            lastSyncHash: parentObject.hashCode(),
          }),
        );

      return currentSet.set(
        targetObjectID,
        makeObjectResponsive({
          object: newObject,
          parentObject,
          piece: toPiece,
          parentPiece: fromPiece,
        }),
      );
    },
    Map(),
  );

  const allObjects = newObjects.reduce((currentSet, object) => {
    const clipPath = newClipPathObjects.get(object.id);
    if (clipPath) {
      return currentSet
        .add(clipPath)
        .add(object.set('clipPathObjectID', clipPath.id));
    }

    return currentSet.add(object);
  }, Set());

  let newState = state.mergeIn(
    ['byID'],
    allObjects.reduce((curr, obj) => ({ ...curr, [obj.id]: obj }), {}),
  );

  const fromPieceTree = state.getIn(['byPieceID', fromPieceID]);
  const inheritedObjects = fromPieceTree
    .reduce(getAllObjectIDsFromTree, Set())
    .reduce(
      (currentMap, objectID) => {
        const inheritedObject = newState
          .get('byID')
          .find(
            obj =>
              obj.get('pieceID') === toPieceID &&
              obj.getIn(['inheritance', 'fromID']) === objectID,
          );

        if (!inheritedObject) {
          return currentMap;
        }

        return currentMap.set(objectID, inheritedObject.id);
      },
      Map({
        [fromRootNode.objectID]: toRootNode.objectID,
      }),
    );

  newState = newState.setIn(
    ['byPieceID', toPieceID],
    fromPieceTree.reduce((currentMap, node) => {
      const newNode = node
        .update('objectID', oldObjectID => inheritedObjects.get(oldObjectID))
        .update(
          'parentID',
          oldObjectID => inheritedObjects.get(oldObjectID) || null,
        )
        .update('childrensID', childrens =>
          childrens.map(childID => inheritedObjects.get(childID)),
        );
      return currentMap.set(newNode.objectID, newNode);
    }, Map()),
  );

  return rebuildMaskByObjectID(newState);
};

const removeObjects = ({ ids, removeAll = false }) => oldState => {
  let orphanIDs = List();
  const state = ids.reduce((newState, objectID) => {
    if (!removeAll) {
      const usedTimes = newState
        .get('byPieceID')
        .count(pieces =>
          pieces.find(
            node =>
              node.objectID === objectID || node.childrensID.includes(objectID),
          ),
        );

      if (usedTimes > 1) {
        return newState;
      }
    }

    const clipPathObjectID = newState.getIn([
      'byID',
      objectID,
      'clipPathObjectID',
    ]);

    if (clipPathObjectID) {
      orphanIDs = orphanIDs.push(clipPathObjectID);
    }

    const targetObjectID = newState.getIn(['byID', objectID, 'targetObjectID']);

    return newState
      .deleteIn(['byID', objectID])
      .deleteIn(['maskByObjectID', objectID])
      .deleteIn(['maskByObjectID', targetObjectID])
      .update('byID', byIDstate => {
        if (!targetObjectID || !byIDstate.has(targetObjectID)) {
          return byIDstate;
        }

        return byIDstate.setIn([targetObjectID, 'clipPathObjectID'], null);
      })
      .update('byPieceID', pieces =>
        pieces.map(pieceNodes =>
          pieceNodes.reduce((currentNodes, node) => {
            if (node.objectID === objectID) {
              orphanIDs = orphanIDs.concat(node.childrensID);
              return currentNodes.delete(node.objectID);
            }

            return currentNodes.updateIn(
              [node.objectID, 'childrensID'],
              childrensID => childrensID.filter(id => id !== objectID),
            );
          }, pieceNodes),
        ),
      );
  }, oldState);

  if (orphanIDs.size > 0) {
    return removeObjects({ ids: orphanIDs })(state);
  }

  return state;
};

const removeObjectsByAssetPath = ({ assetPath }) => state => {
  const staleObjects = state
    .get('byID')
    .filter(object => object.assetPath === assetPath)
    .map(object => object.id);
  const removeStaleObjects = removeObjects({
    ids: staleObjects,
    removeAll: true,
  });
  return removeStaleObjects(state);
};

const removeObjectsByPieceID = ({ pieceID }) => state => {
  const rootNode = state
    .getIn(['byPieceID', pieceID], Map())
    .find(node => node.parentID === null);

  if (!rootNode) {
    return state;
  }

  return removeObjects({ ids: rootNode.childrensID })(state);
};

const updatePieceObjectsFromParent = ({ parentPiece, piece }) => state => {
  const objects = state.getIn(['byID']);

  const newObjects = objects.reduce((actualMap, object, objectKey) => {
    if (!object.inheritance || object.pieceID !== piece.id) {
      return actualMap;
    }

    const parentObject = objects.get(object.inheritance.fromID);
    if (!parentObject) {
      return actualMap.delete(objectKey);
    }

    const parentHashCode = parentObject.hashCode();
    if (parentHashCode === object.inheritance.lastSyncHash) {
      return actualMap;
    }

    const newObject = actualMap
      .set(
        objectKey,
        makeObjectResponsive({
          object,
          parentObject,
          piece,
          parentPiece,
        }),
      )
      .setIn([objectKey, 'inheritance', 'lastSyncHash'], parentHashCode);

    if (object.type === IMG) {
      return newObject.setIn(
        [objectKey, 'assetPath'],
        parentObject.getIn(['assetPath']),
      );
    }

    return newObject;
  }, objects);

  return state.set('byID', newObjects);
};

const createAndInsertNode = ({ object, parentObjectID }) => oldState => {
  let state = oldState;

  if (parentObjectID) {
    state = state.updateIn(
      ['byPieceID', object.pieceID, parentObjectID, 'childrensID'],
      childrensID => childrensID.push(object.id),
    );
  }

  if (object.type === DIV) {
    state = state.setIn(
      ['byPieceID', object.pieceID, object.id],
      NodeModel({
        objectID: object.id,
        parentID: parentObjectID,
      }),
    );
  }

  return state;
};

const createObject = ({ object, parentObjectID = null }) => state =>
  state
    .setIn(
      ['byID', object.id],
      object
        .update('name', () => setObjectName(state.get('byID'), object))
        .update('left', roundValue)
        .update('top', roundValue),
    )
    .update(createAndInsertNode({ object, parentObjectID }));

export const createClipPathObject = ({ object }) => state => {
  if (!state.hasIn(['byID', object.targetObjectID])) {
    return state;
  }

  return state
    .setIn(
      ['byID', object.id],
      object.update('name', () => setObjectName(state, object)),
    )
    .setIn(['byID', object.targetObjectID, 'clipPathObjectID'], object.id);
};

const addSizes = ({ pieces }) => oldState =>
  pieces.reduce((state, piece) => {
    const id = buildPieceID(piece);
    if (state.hasIn(['byPieceID', id])) {
      return state;
    }

    const object = makeDivObject({
      name: 'root-object',
      pieceID: id,
      left: 0,
      top: 0,
      width: piece.width,
      height: piece.height,
      backgroundColor: 'white',
    });

    return createObject({ object })(state.setIn(['byPieceID', id], Map()));
  }, oldState);

export const cloneObject = objectToClone => {
  let object = objectToClone
    .set('id', cuid())
    .set('inheritance', null)
    .update('keyframes', keyframes =>
      keyframes.map(keyframe => keyframe.set('id', cuid())),
    );

  if (object.type === CLIPPATH) {
    object = object.set('targetObjectID', null);
  } else {
    object = object.set('clipPathObjectID', null);
  }

  return [object.id, object];
};

export const duplicateObject = (oldState, object, parentObjectID) => {
  if (object.type === CLIPPATH) {
    return oldState;
  }

  const [newID, newObject] = cloneObject(object);
  let resultState = createObject({ object: newObject, parentObjectID })(
    oldState,
  );

  const clipPathObjectID = object.get('clipPathObjectID');
  if (clipPathObjectID) {
    let [, clipPathObject] = cloneObject(
      resultState.getIn(['byID', clipPathObjectID]),
    );
    clipPathObject = clipPathObject.set('targetObjectID', newID);

    resultState = createClipPathObject({ object: clipPathObject })(resultState);
  }

  if (newObject.type === DIV) {
    const childrensID = resultState.getIn(
      ['byPieceID', object.pieceID, object.id, 'childrensID'],
      List(),
    );

    resultState = childrensID.reduce(
      (state, childID) =>
        duplicateObject(state, state.getIn(['byID', childID]), newObject.id),
      resultState,
    );
  }

  return resultState;
};

export const duplicateObjects = ({ objects, parentObjectID }) => oldState =>
  rebuildMaskByObjectID(
    objects.reduce(
      (state, object) => duplicateObject(state, object, parentObjectID),
      oldState,
    ),
  );

const featureReducer = createReducer(Map(), {
  [types.ADD_SIZES]: addSizes,
  [types.CREATE_OBJECT]: createObject,
  [types.CREATE_CLIPPATH_OBJECT]: createClipPathObject,
  [types.DUPLICATE_OBJECTS]: duplicateObjects,
  [types.COPY_PIECE_CONTENT]: copyPieceContent,
  [types.UPDATE_PIECE_FROM_PARENT]: updatePieceObjectsFromParent,
  [types.REMOVE_OBJECTS]: removeObjects,
  [types.REMOVE_OBJECTS_BY_ASSET_PATH]: removeObjectsByAssetPath,
  [types.REMOVE_OBJECTS_BY_PIECE_ID]: removeObjectsByPieceID,
  [SET_CURRENT_TIME]: animateObjects,
});

export default undoable(reduceReducers(combinedReducer, featureReducer), {
  filter: includeAction(types.undoableTypes),
  syncFilter: true,
});
