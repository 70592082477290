import { UPDATE_LAST_MODIFICATION } from './types';
import { createReducer } from '../utils';

const updateLastModificationDate = lastModification => () => lastModification;

export const lastModification = createReducer(null, {
  [UPDATE_LAST_MODIFICATION]: updateLastModificationDate,
});

export default lastModification;
