import { Map } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { PieceModel } from '../models';
import { createReducer } from '../utils';
import { buildPieceID } from './utils';
import { SET_CLOCK_DURATION } from '../playback';
import * as types from './types';

const initFromServer = ({ pieces }) => () =>
  Map(
    pieces.reduce(
      (curr, piece) => ({ ...curr, [piece.id]: PieceModel(piece) }),
      {},
    ),
  );

const addSizes = ({ pieces }) => state => {
  const createdAt = new Date();

  return state.merge(
    pieces.reduce((curr, piece) => {
      const id = buildPieceID(piece);
      if (state.has(id) || curr[id]) return curr;
      return {
        ...curr,
        [id]: PieceModel({ ...piece, id, createdAt, updatedAt: createdAt }),
      };
    }, {}),
  );
};

const setPieceParentID = ({ childID, parentID }) => state =>
  state.setIn([childID, 'parentID'], parentID);

const removePieceParent = ({ pieceID }) => state =>
  state.setIn([pieceID, 'parentID'], null);

const removePiece = ({ pieceID }) => state =>
  state.delete(pieceID).map(piece => {
    if (piece.get('parentID') !== pieceID) {
      return piece;
    }

    return piece.set('parentID', null);
  });

export const setPieceDuration = ({ pieceID, duration }) => state =>
  state.setIn([pieceID, 'duration'], duration);

const setClockDuration = ({ pieceID, clockDuration }) =>
  setPieceDuration({ pieceID, duration: clockDuration });

export const setUpdatedAt = ({ pieceID, updatedAt }) => state =>
  state.setIn([pieceID, 'updatedAt'], updatedAt);

const copyPieceContent = ({ fromPiece, toPiece }) => state =>
  state.setIn([toPiece.id, 'duration'], fromPiece.duration);

const resetByID = () => () => Map();

export const byID = createReducer(Map(), {
  [types.ADD_SIZES]: addSizes,
  [types.COPY_PIECE_CONTENT]: copyPieceContent,
  [types.INIT_FROM_SERVER]: initFromServer,
  [types.SET_PIECE_DURATION]: setPieceDuration,
  [types.SET_PIECE_PARENT_ID]: setPieceParentID,
  [types.REMOVE_PIECE_PARENT]: removePieceParent,
  [types.REMOVE_PIECE]: removePiece,
  [SET_CLOCK_DURATION]: setClockDuration,
  [types.SET_UPDATED_AT]: setUpdatedAt,
  [types.UNSET_PROJECT]: resetByID,
});

export default combineReducers({
  byID,
});
