import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';
import { getObjectStyle } from '../models';
import PieceContext from './PieceContext';

const DivObject = ({ id, className, style, children, rootObject }) => {
  const { loaded } = useContext(PieceContext);
  return (
    <div
      id={id}
      className={rootObject || loaded ? className : ''}
      style={style}
    >
      {children}
    </div>
  );
};

DivObject.defaultProps = {
  style: {},
  id: undefined,
  rootObject: false,
};

DivObject.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  rootObject: PropTypes.bool,
};

export default createComponent(getObjectStyle, DivObject, [
  'object',
  'rootObject',
]);
