import React from 'react';
import PropTypes from 'prop-types';
import { VIDEO_ASSET_TYPE, IMAGE_ASSET_TYPE } from './constants';
import Image from './Image';
import VideoThumb from '../VideoThumb';

const Preview = ({ asset }) => {
  if (!asset) {
    return null;
  }

  switch (asset.assetType) {
    case VIDEO_ASSET_TYPE:
      return <VideoThumb small />;
    case IMAGE_ASSET_TYPE:
      return <Image src={asset.url} alt={asset.filename} />;
    default:
      return null;
  }
};

Preview.defaultProps = {
  asset: null,
};

Preview.propTypes = {
  asset: PropTypes.object,
};

export default Preview;
