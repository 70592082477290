import { colors } from './ui';

const { black3, black4, gray2, primary } = colors;

export const object = {
  selectBorder: primary,
};

export const panelAssets = {
  uploadEntryBg: black4,
  entryBg: black4,
  thumbBg: black3,
};

export const toggleAspectRatio = {
  color: gray2,
};
