import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import colors from '../colors';
import { ArrowDownIcon, PlayIcon as ArrowRightIcon } from '../../icons';
import { textRegular } from '../typography';

const useStyles = createUseStyles({
  header: {
    ...textRegular,
    alignItems: 'center',
    background: colors.black4,
    boxShadow: 'none',
    color: colors.white,
    cursor: 'pointer',
    display: 'flex',
    flexShrink: 0,
    height: 48,
    marginBottom: 1,
    outline: 'none',
    padding: [0, 16],
    textAlign: 'left',
    width: '100%',
    '&:active, &:focus': {
      outline: 'none',
    },
  },
  content: {
    background: colors.black3,
    flexGrow: 1,
    height: '100%',
    marginBottom: 1,
    minHeight: 0,
  },
  icon: {
    fill: 'white',
    flexShrink: 0,
    width: 8,
    marginLeft: 8,
  },
  spacer: {
    flexGrow: 1,
  },
});

const BasePanel = ({ title, isOpened, onClick, children, actions }) => {
  const classes = useStyles();
  const Icon = isOpened ? ArrowDownIcon : ArrowRightIcon;
  return (
    <>
      <div
        className={classes.header}
        onClick={onClick}
        tabIndex="-1"
        role="button"
      >
        {title}
        <Icon className={classes.icon} />
        <div className={classes.spacer} />
        {actions && <div className={classes.actions}>{actions}</div>}
      </div>
      {isOpened && <div className={classes.content}>{children}</div>}
    </>
  );
};

BasePanel.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  actions: PropTypes.node,
};

BasePanel.defaultProps = {
  actions: null,
};

export default BasePanel;
