import React from 'react';
import PropTypes from 'prop-types';
import { createComponent } from 'react-fela';
import { getObjectStyle } from '../models';

const TextObject = ({
  className,
  classNameSpan,
  textContent,
  textStyleProperties,
}) => (
  <div
    className={className}
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <span
      className={classNameSpan}
      style={{
        width: 'calc(100% - 8px)',
        height: 'calc(100% - 8px)',
        wordWrap: 'break-word',
        ...textStyleProperties,
      }}
    >
      {textContent}
    </span>
  </div>
);

TextObject.propTypes = {
  textStyleProperties: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  classNameSpan: PropTypes.string,
};

export default createComponent(getObjectStyle, TextObject, [
  'object',
  'textContent',
  'textStyleProperties',
  'classNameSpan',
]);
