import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PopoverItem } from '../ui';
import { removePiece } from '../project';

const RemovePiece = ({ onClick }) => (
  <PopoverItem onClick={onClick} label="Remover peça" />
);

RemovePiece.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { pieceID }) => ({
  onClick() {
    dispatch(removePiece(pieceID));
  },
});

export default connect(null, mapDispatchToProps)(RemovePiece);
