import React from 'react';
import { SaveIcon } from '../icons';
import { MenuItem } from '../ui';
import { useSavePieces } from '../hooks';

const ButtonSavePieces = () => {
  const savePieces = useSavePieces();

  return <MenuItem icon={SaveIcon} onClick={savePieces} label="Save Project" />;
};

export default ButtonSavePieces;
