const regular = 400;
const semiBold = 600;

export const label = {
  fontSize: 12,
  fontWeight: regular,
  lineHeight: '18px',
};

export const textRegular = {
  fontSize: 16,
  fontWeight: regular,
  lineHeight: '24px',
};

export const textRegularHeaderLabels = {
  fontSize: 12,
  fontWeight: regular,
  lineHeight: '18px',
};

export const textBold = {
  fontSize: 16,
  fontWeight: semiBold,
  lineHeight: '24px',
};

export const title4 = {
  fontSize: 21,
  fontWeight: semiBold,
  lineHeight: '31px',
};

export const title3 = {
  fontSize: 28,
  fontWeight: semiBold,
  lineHeight: '42px',
};

export const title2 = {
  fontSize: 37,
  fontWeight: semiBold,
  lineHeight: '40px',
};

export const title1 = {
  fontSize: 50,
  fontWeight: semiBold,
  lineHeight: '55px',
};

export const jumbo = {
  fontSize: 60,
  fontWeight: semiBold,
  lineHeight: '60px',
};
