export const LEFT = 'left';
export const RIGHT = 'right';
export const CENTER = 'center';
export const TOP = 'top';
export const BOTTOM = 'bottom';

export const X_ORIGIN_KEYWORDS = [LEFT, CENTER, RIGHT];
export const Y_ORIGIN_KEYWORDS = [TOP, CENTER, BOTTOM];

export const OPPOSITE_ORIGIN = {
  [LEFT]: RIGHT,
  [CENTER]: CENTER,
  [RIGHT]: LEFT,
  [TOP]: BOTTOM,
  [BOTTOM]: TOP,
};
