import React from 'react';
import { string } from 'prop-types';
import { Popover, PopoverItem, Hideable } from '../ui';

export const buildPopoverIdentifier = id => `popoverProject-${id}`;

const PopoverProject = ({ id }) => (
  <Popover id={buildPopoverIdentifier(id)}>
    <Hideable.Trigger id="duplicateProject">
      {({ show }) => <PopoverItem label="Duplicate Project" onClick={show} />}
    </Hideable.Trigger>
    <Hideable.Trigger id="renameProject">
      {({ show }) => <PopoverItem label="Rename Project" onClick={show} />}
    </Hideable.Trigger>
    <Hideable.Trigger id="deleteProject">
      {({ show }) => <PopoverItem label="Delete Project" onClick={show} />}
    </Hideable.Trigger>
  </Popover>
);

PopoverProject.propTypes = {
  id: string.isRequired,
};

export default PopoverProject;
