import React from 'react';
import { node } from 'prop-types';

const defaultState = {
  scrollLeft: 0,
  scrollTop: 0,
};

const Context = React.createContext(defaultState);

class ScrollContext extends React.Component {
  constructor(props) {
    super(props);

    this.setScrollLeft = this.setScrollLeft.bind(this);
    this.setScrollTop = this.setScrollTop.bind(this);
    this.state = {
      ...defaultState,
      ...props,
      setScrollLeft: this.setScrollLeft,
      setScrollTop: this.setScrollTop,
    };
  }

  setScrollTop(scrollTop) {
    this.setState(state => ({
      ...state,
      scrollTop,
    }));
  }

  setScrollLeft(scrollLeft) {
    this.setState(state => ({
      ...state,
      scrollLeft,
    }));
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

ScrollContext.propTypes = {
  children: node.isRequired,
};

ScrollContext.Consumer = Context.Consumer;

export default ScrollContext;
