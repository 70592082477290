import { Set } from 'immutable';
import { makeObject } from '../models';
import { getBiggestObject } from '../project';

export const isSelectionEmpty = selection => selection.size === 0;

export const getBaseObject = (objects, disabled) => {
  if (disabled || objects.size < 1) {
    return makeObject({
      opacity: null,
    });
  }

  if (objects.size === 1) {
    return objects.first();
  }
  return getBiggestObject(objects);
};

export const getProperty = (prop, baseObject, objects) => {
  const expected = baseObject[prop];
  const same = objects.every(obj => obj[prop] === expected);

  if (same) {
    return expected;
  }

  return null;
};

export const getFullSizeOption = (baseObject, objects) => {
  const expectedFullSize = baseObject.fullSize;
  const sameFullSize = objects.every(
    object => object.fullSize === expectedFullSize,
  );

  if (sameFullSize) {
    return expectedFullSize;
  }

  return false;
};

export const extractTypes = objects =>
  objects.reduce((currentSet, obj) => currentSet.add(obj.type), Set());
