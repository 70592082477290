import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AlignCenterIcon } from '../icons';
import { ToolbarButton } from '../ui';
import { getAlignToContainer, getSelectedObjectsIDs } from '../editor';
import { getCurrentPiece, alignObjectsToCenter } from '../project';

const ButtonAlignCenter = ({ disabled, onClick }) => {
  if (disabled) {
    return null;
  }

  return <ToolbarButton icon={<AlignCenterIcon />} onClick={onClick} />;
};

ButtonAlignCenter.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const reduxConnector = connect(
  state => {
    let container = getCurrentPiece(state);
    if (!getAlignToContainer(state)) {
      container = null;
    }

    const ids = getSelectedObjectsIDs(state);
    let disabled = true;
    if (ids.size >= 1) {
      disabled = false;
    }
    return { ids, disabled, container };
  },
  { alignObjectsToCenter },
  ({ ids, container, ...state }, actions) => ({
    ...state,
    onClick: () => actions.alignObjectsToCenter({ ids, container }),
  }),
);

export default reduxConnector(ButtonAlignCenter);
