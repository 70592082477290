import * as types from './types';

// Timeline
export const play = () => ({
  type: types.PLAY,
});

export const pause = () => ({
  type: types.PAUSE,
});

export const setCurrentTime = currentTime => ({
  type: types.SET_CURRENT_TIME,
  payload: { currentTime },
});

export const setClockDuration = clockDuration => ({
  type: types.SET_CLOCK_DURATION,
  payload: { clockDuration },
});

export const toggleRepeatMode = () => ({
  type: types.TOGGLE_REPEAT_MODE,
});
