import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { getCurrentPiece } from '../project';
import { usePanArea, usePanInitialization } from './hooks';
import PanArea from './PanArea';
import { Piece, ZoomBox } from '../PieceEditable';

const useStyles = createUseStyles({
  sharedStage: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
});

const SharedStage = () => {
  const classes = useStyles();
  const stageRef = useRef();
  const [
    { pieceLeft, pieceTop },
    updatePanPosition,
    setPanPosition,
  ] = usePanArea();
  const piece = useSelector(getCurrentPiece);
  usePanInitialization(stageRef, piece.width, piece.height, setPanPosition);

  return (
    <div ref={stageRef} className={classes.sharedStage}>
      <ZoomBox>
        <Piece left={pieceLeft} top={pieceTop} allowOverflow={false} />
      </ZoomBox>
      <PanArea update={updatePanPosition} alwaysEnabled />
    </div>
  );
};

export default SharedStage;
