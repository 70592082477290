import { connect } from 'react-redux';
import {
  getObjectIsBeingAnimated,
  toggleObjectAnimation,
  getObjectHasKeyframes,
} from '../project';
import { TimelineToggle } from '../ui';
import { AnimateIcon } from '../icons';

const container = connect(
  (state, { objectID }) => ({
    isActive: getObjectIsBeingAnimated(state, objectID),
    disabled: getObjectHasKeyframes(state, objectID),
  }),
  { toggleObjectAnimation },
  (state, actions, { objectID }) => ({
    ...state,
    icon: AnimateIcon,
    onClick: () => {
      actions.toggleObjectAnimation({ objectID });
    },
  }),
);

export default container(TimelineToggle);
