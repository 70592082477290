import React from 'react';
import { string } from 'prop-types';
import { Ellipsis } from '../icons';
import {
  Popover,
  PopoverTrigger,
  PopoverItem,
  IconButtonTertiary,
} from '../ui';
import InputReplaceAssetFile from './InputReplaceAssetFile';
import ButtonOpenDeleteModal from './ButtonOpenDeleteModal';

const popoverID = 'panel-assets-popover';

class PopoverAssetEntry extends React.Component {
  constructor() {
    super();
    this.inputDOM = null;
  }

  render() {
    const { assetPath, assetType } = this.props;
    return (
      <>
        <InputReplaceAssetFile
          assetPath={assetPath}
          assetType={assetType}
          innerRef={elm => {
            this.inputDOM = elm;
          }}
        />
        <Popover id={`${popoverID}-${assetPath}`}>
          <PopoverItem
            label="Update file"
            onClick={() => this.inputDOM.click()}
          />
          <ButtonOpenDeleteModal assetPath={assetPath} />
        </Popover>
      </>
    );
  }
}

PopoverAssetEntry.propTypes = {
  assetType: string.isRequired,
  assetPath: string.isRequired,
};

export const ButtonOpenPopoverAssetEntry = ({ assetPath }) => (
  <PopoverTrigger id={`${popoverID}-${assetPath}`}>
    {show => <IconButtonTertiary icon={Ellipsis} onClick={show} />}
  </PopoverTrigger>
);

ButtonOpenPopoverAssetEntry.propTypes = {
  assetPath: string.isRequired,
};

export default PopoverAssetEntry;
