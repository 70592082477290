import React from 'react';
import { func } from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';

const useStyles = createUseStyles({
  hoverMask: {
    alignItems: 'center',
    background: 'white',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    '&:hover': {
      opacity: 0.8,
    },
  },
  icon: {
    fill: colors.gray1,
    height: 56,
  },
});

const BaseHoverMask = ({ icon, onClick }) => {
  const classes = useStyles();
  const Icon = icon;
  return (
    <div
      className={classes.hoverMask}
      role="button"
      tabIndex={-1}
      onClick={onClick}
    >
      <Icon className={classes.icon} />
    </div>
  );
};

BaseHoverMask.defaultProps = {
  onClick: undefined,
};

BaseHoverMask.propTypes = {
  onClick: func,
  icon: func.isRequired,
};

export default BaseHoverMask;
