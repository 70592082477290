import React from 'react';
import { string } from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { url } from '../App';
import { IconButtonTertiary } from '../ui';
import { ExpandIcon } from '../icons';

const LinkToSharedPlayer = ({ pieceID }) => {
  const { shareID } = useParams();

  return (
    <Link to={url.sharedPiece({ shareID, pieceID })}>
      <IconButtonTertiary icon={ExpandIcon} />
    </Link>
  );
};

LinkToSharedPlayer.propTypes = {
  pieceID: string.isRequired,
};

export default LinkToSharedPlayer;
