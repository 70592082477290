import { Record } from 'immutable';
import { createReducer } from '../utils';
import * as types from './types';

export const StageModel = Record({
  zoomScale: 1,
  currentPieceID: null,
});

const setZoomScale = ({ scale }) => state => state.set('zoomScale', scale);

const setCurrentPieceID = ({ pieceID }) => state =>
  state.set('currentPieceID', pieceID);

const unsetCurrentPieceID = () => state => state.set('currentPieceID', null);

export const stageReducer = createReducer(StageModel(), {
  [types.SET_ZOOM_SCALE]: setZoomScale,
  [types.SET_CURRENT_PIECE]: setCurrentPieceID,
  [types.UNSET_CURRENT_PIECE]: unsetCurrentPieceID,
});

export default stageReducer;
