import React from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { createUseStyles } from 'react-jss';
import { projects } from '../api';
import { colors, ButtonPrimary } from '../ui';
import Title from './Title';
import connect from './containers';

const useStyles = createUseStyles({
  warning: {
    color: colors.errorLight,
    marginBottom: 15,
  },
});

const FullReport = ({ showError }) => {
  const classes = useStyles();
  const [downloading, setDownloading] = React.useState(false);
  const handleDownload = React.useCallback(() => {
    setDownloading(true);
    projects.fullReport().then(({ ok, data }) => {
      setDownloading(false);
      if (ok) {
        saveAs(data, `full-report.csv`);
      } else {
        showError();
      }
    });
  }, [setDownloading]);

  return (
    <div>
      <Title>Full Report*</Title>
      <p className={classes.warning}>
        * Only use if necessary, this process needs a heavy processing depending
        amount of data
      </p>
      <ButtonPrimary
        label="Download Full Report"
        onClick={handleDownload}
        disabled={downloading}
      />
    </div>
  );
};

FullReport.propTypes = {
  showError: PropTypes.func.isRequired,
};

export default connect(FullReport);
