import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import TimelineObject from './TimelineObject';
import { getReversedCurrentChildrens } from '../selectors';

const ListTimelines = ({ objectsID }) =>
  objectsID.map(objectID => (
    <TimelineObject key={objectID} objectID={objectID} />
  ));

ListTimelines.propTypes = {
  objectsID: PropTypes.instanceOf(List).isRequired,
};

const container = connect(state => ({
  objectsID: getReversedCurrentChildrens(state),
}));

export default container(ListTimelines);
