import { Map, List, Set } from 'immutable';
import { createSelector } from 'reselect';
import {
  AssetModel,
  NodeModel,
  DIV,
  IMG,
  VIDEO,
  roundObjectProperties,
} from '../models';
import { animateObject } from './animation';
import { pieceToString, getAllObjectIDsFromTree } from './utils';
import { getCurrentPieceID } from '../stage';

export const getProject = state => state.get('project');

// Assets
export const getAssetsSlice = state =>
  getProject(state).getIn(['assets', 'assets']);

export const getCollectionsSlice = state =>
  getProject(state).getIn(['assets', 'collections']);

export const getAssets = createSelector(getAssetsSlice, assets =>
  assets.toList().sortBy(asset => asset.filename),
);

export const getCollectionsPaths = createSelector(
  getCollectionsSlice,
  collections =>
    collections
      .toList()
      .sortBy(collection => collection.name)
      .map(collection => collection.path),
);

export const getCollectionByPath = (state, collectionPath) =>
  getCollectionsSlice(state).get(collectionPath);

export const getCollectionPathToFilter = (_, props) => {
  return props.collectionPath;
};

export const makeGetAssetsByCollectionPath = () => {
  return createSelector(
    [getAssetsSlice, getCollectionPathToFilter],
    (assets, collectionPath) => {
      return assets
        .filter(asset => asset.path.startsWith(`${collectionPath}/`))
        .toList()
        .sortBy(asset => asset.filename);
    },
  );
};

export const getAssetsByCollectionPath = (state, collectionPath) =>
  getAssetsSlice(state)
    .filter(asset => asset.path.startsWith(`${collectionPath}/`))
    .toList()
    .sortBy(asset => asset.filename);

export const getAssetsWithoutCollection = createSelector(
  getAssetsSlice,
  assets =>
    assets
      .filter(asset => asset.path.split('/').length < 4)
      .toList()
      .sortBy(asset => asset.filename),
);

export const getAssetByPath = (assetPath, state) =>
  getAssetsSlice(state).get(assetPath, AssetModel());

export const getAssetFilename = (state, assetPath) =>
  getAssetsSlice(state).getIn([assetPath, 'filename']);

export const getAssetURL = (state, assetPath) =>
  getAssetsSlice(state).getIn([assetPath, 'url']);

// Pieces
export const getPiecesSlice = state => getProject(state).get('pieces');

const getByID = state => getPiecesSlice(state).get('byID');

export const getAllPiecesIDs = state => getByID(state).keySeq().toArray();

export const getAllPieces = state => getByID(state).toList();

export const getPieceByID = (state, pieceID) => getByID(state).get(pieceID);

export const getPieceDuration = (state, pieceID) =>
  getPieceByID(state, pieceID).duration;

// FIXME, duplicated from editor/selectors to avoid cycle import
export const getCurrentPiece = state =>
  getPieceByID(state, getCurrentPieceID(state));

export const getPieceParentID = (state, pieceID) => {
  const piece = getPieceByID(state, pieceID);
  if (!piece) {
    return null;
  }

  return piece.parentID;
};

export const getPieceFormatString = (state, pieceID) =>
  pieceToString(getPieceByID(state, pieceID));

// Objects
export const getPresent = state => getProject(state).get('objects').present;

export const getPast = state => getProject(state).get('objects').past;

export const getRawObjectByID = (state, objectID) =>
  getPresent(state).getIn(['byID', objectID]) || null;

export const getObjectByID = (state, objectID) => {
  const object = getPresent(state).getIn(['byID', objectID]);

  if (!object) {
    return null;
  }

  if (object.type === IMG || object.type === VIDEO) {
    return object.update(obj =>
      obj.set('assetURL', getAssetURL(state, object.assetPath) || null),
    );
  }

  return object;
};

export const getByPieceID = state => getPresent(state).get('byPieceID', Map());

export const getPieceNodes = (state, pieceID) =>
  getByPieceID(state).get(pieceID, Map());

export const getRootNodeFromPieceID = (state, pieceID) => {
  const rootNode = getPieceNodes(state, pieceID).find(
    node => node.parentID === null,
  );

  if (!rootNode) {
    return NodeModel();
  }

  return rootNode;
};

export const getChildrensFromObject = (state, pieceID, objectID) => {
  const node = getPieceNodes(state, pieceID).get(objectID);

  if (!node) {
    return List();
  }

  return node.childrensID;
};

export const getPieceObjectsIDs = (state, pieceID) => {
  const tree = getPieceNodes(state, pieceID);
  if (!tree) {
    return Set();
  }

  return tree.reduce(getAllObjectIDsFromTree, Set());
};

export const getPieceNodeByID = (state, pieceID, parentID) =>
  getPieceNodes(state, pieceID).get(parentID, NodeModel());

export const getObjectsByPieceID = (state, pieceID) => {
  const objects = getPieceObjectsIDs(state, pieceID);
  return objects.map(objectID => getObjectByID(state, objectID));
};

export const getPieceObjects = (state, pieceID) =>
  getObjectsByPieceID(state, pieceID).toJS();

export const getOptionValue = (state, objectID, option) =>
  getObjectByID(state, objectID).get(option);

export const getProperty = (state, objectID, property) =>
  getObjectByID(state, objectID).get(property);

export const getObjectIsBeingAnimated = (state, objectID) =>
  getObjectByID(state, objectID).getIn(['editorOptions', 'beingAnimated']);

export const getObjectHasKeyframes = (state, objectID) => {
  const object = getObjectByID(state, objectID);

  return !object.get('keyframes').isEmpty();
};

// Warning: due recent modifications about properties precision on editor, and animation
// the roundObjectProperties here is required to guarantee all serialization
// FIXME: try to keep one serialization method to avoid rounding operations here
export const getNestedObjectsMap = (
  state,
  rawObject,
  objectUpdater = obj => obj,
) => {
  if (!rawObject) {
    return Map();
  }

  let objectMap = rawObject
    .toMap()
    .update(obj => objectUpdater(obj, rawObject))
    .update(obj => roundObjectProperties(obj));
  const id = objectMap.get('id');
  const pieceID = objectMap.get('pieceID');

  if (objectMap.get('type') === DIV) {
    objectMap = objectMap.set(
      'childrens',
      getChildrensFromObject(state, pieceID, id).map(objectID =>
        getNestedObjectsMap(
          state,
          getObjectByID(state, objectID),
          objectUpdater,
        ),
      ),
    );
  }

  const clipPathObjectID = objectMap.get('clipPathObjectID');
  if (clipPathObjectID) {
    objectMap = objectMap.set(
      'clipPathObject',
      getNestedObjectsMap(
        state,
        getObjectByID(state, clipPathObjectID),
        objectUpdater,
      ),
    );
  }

  objectMap = objectMap.delete('clipPathObjectID');
  return objectMap;
};

export const getPiece = (state, pieceID, processObject = null) => {
  const piece = getPieceByID(state, pieceID);
  const objects = getNestedObjectsMap(
    state,
    getObjectByID(state, getRootNodeFromPieceID(state, pieceID).objectID),
    animatedObject => {
      let obj = animatedObject;
      if (processObject) {
        obj = processObject(obj, piece);
      }

      obj = animateObject(0)(obj);
      if (obj.get('type') !== IMG && obj.get('type') !== VIDEO) {
        return obj;
      }

      return obj
        .set('assetFilename', getAssetFilename(state, obj.get('assetPath')))
        .delete('assetPath');
    },
  ).toJS();

  const { width, height, background } = piece;
  return {
    background,
    height,
    objects,
    width,
  };
};

export const getRawObjectsByPieceID = (state, pieceID) => {
  const objects = getPieceObjectsIDs(state, pieceID);
  return objects.map(objectID => getRawObjectByID(state, objectID));
};

export const getIsSyncedPiece = (state, pieceID) => {
  const parentID = getPieceParentID(state, pieceID);
  if (!parentID) {
    return true;
  }

  if (
    getPieceObjects(state, pieceID).length !==
    getPieceObjects(state, parentID).length
  ) {
    return false;
  }

  return (
    getRawObjectsByPieceID(state, pieceID).filter(object => {
      if (!object.inheritance) {
        return false;
      }

      const parentObject = getRawObjectByID(state, object.inheritance.fromID);
      if (!parentObject) {
        return true;
      }

      return parentObject.hashCode() !== object.inheritance.lastSyncHash;
    }).size === 0
  );
};

export const getObjectKeyframes = (state, objectID) =>
  getObjectByID(state, objectID).get('keyframes');

export const getObjectKeyframeByID = (state, objectID, keyframeID) =>
  getObjectKeyframes(state, objectID).find(
    keyframe => keyframe.id === keyframeID,
  );

export const getAllGuides = state => getProject(state).get('guides').toList();

export const getGuides = state => {
  const piece = getCurrentPiece(state);

  return getAllGuides(state).filter(guide => guide.pieceID === piece.id);
};

export const getLastCreatedGuide = state =>
  getGuides(state)
    .sort((a, b) => (Number(a.createdAt) >= Number(b.createdAt) ? 1 : -1))
    .last();

export const getProjectMetadata = state => getProject(state).get('metadata');

export const getCurrentProjectID = state => getProjectMetadata(state).get('id');

export const getCurrentProjectName = state =>
  getProjectMetadata(state).get('name');

export const getCurrentProjectIsAMP = state =>
  getProjectMetadata(state).get('isAMP');

export const getCurrentProjectUpdatedAt = state =>
  getProjectMetadata(state).get('updatedAt');

export const getMaskByObjectID = state => {
  return getPresent(state).get('maskByObjectID');
};

export const getObjectName = (state, objectID) => {
  const object = getObjectByID(state, objectID);
  if (!object) {
    return null;
  }
  const { name } = object;
  return name;
};
