import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { PopoverItem } from '../ui';
import { removeKeyframes } from '../project';
import { getSelectedKeyframes } from '../editor';

const ButtonRemoveKeyframes = ({ onClick }) => (
  <PopoverItem label="Delete keyframe" onClick={onClick} />
);

ButtonRemoveKeyframes.propTypes = {
  onClick: func.isRequired,
};

const container = connect(
  state => ({
    keyframes: getSelectedKeyframes(state),
  }),
  {
    removeKeyframes,
  },
  ({ keyframes }, actions) => ({
    onClick: () => actions.removeKeyframes({ keyframes }),
  }),
);

export default container(ButtonRemoveKeyframes);
