import React, { Component, createRef } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import MovableComponent from '../../MovableComponent';
import colors from '../../ui/colors';
import { VERTICAL, HORIZONTAL } from '../../models';

export const RULER_SIZE = 16;

class RulerBox extends Component {
  constructor() {
    super();

    this.state = {
      lockCreation: false,
    };

    this.rulerRef = createRef();
    this.handleMove = this.handleMove.bind(this);
    this.handleUp = this.handleUp.bind(this);
  }

  handleUp(event) {
    if (!this.state.lockCreation) {
      return;
    }
    this.setState({ lockCreation: false });
    this.props.onUp(null, event);
  }

  handleMove(move, { clientY, clientX }) {
    const { orientation, onCreate, onMove } = this.props;
    const { lockCreation } = this.state;
    const { current } = this.rulerRef;

    const { x = 0, y = 0, height, width } = current.getBoundingClientRect();

    const guideLimitX = width + x;
    const guideLimitY = height + y;

    if (lockCreation) {
      onMove({ x: clientX, y: clientY });

      return;
    }

    if (orientation === VERTICAL && guideLimitX < clientX) {
      onCreate(orientation, { x: clientX, y: 0 });
      this.setState({ lockCreation: true });
    }

    if (orientation === HORIZONTAL && guideLimitY < clientY) {
      onCreate(orientation, { x: 0, y: clientY });
      this.setState({ lockCreation: true });
    }
  }

  render() {
    const { orientation, classes, disabled } = this.props;

    return (
      <MovableComponent
        onMove={this.handleMove}
        onUp={this.handleUp}
        disabled={disabled}
      >
        {movable => (
          <div
            {...movable}
            ref={this.rulerRef}
            className={classes.container}
            style={{
              width: orientation === VERTICAL ? RULER_SIZE : '100%',
              height: orientation === VERTICAL ? '100%' : RULER_SIZE,
            }}
          />
        )}
      </MovableComponent>
    );
  }
}
RulerBox.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onUp: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  orientation: PropTypes.oneOf([VERTICAL, HORIZONTAL]),
  disabled: PropTypes.bool,
};

RulerBox.defaultProps = {
  orientation: VERTICAL,
  disabled: false,
};

const style = injectSheet({
  container: {
    height: RULER_SIZE,
    width: '100%',
    background: colors.black5,
    position: 'absolute',
    top: 0,
    left: 0,
    cursor: 'move',
    zIndex: 120,
  },
});

export default style(RulerBox);
