import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import colors from '../colors';
import * as typography from '../typography';

const useStyles = createUseStyles({
  button: {
    ...typography.textRegular,
    alignItems: 'center',
    background: colors.primary,
    borderRadius: 20,
    cursor: 'pointer',
    color: colors.white,
    display: 'flex',
    minHeight: 40,
    justifyContent: 'center',
    minWidth: 88,
    outline: 'none',
    width: extended => (extended ? '100%' : 'auto'),
    padding: [0, 16],
    '&:hover': {
      background: colors.primaryLight,
    },
    '&:active': {
      background: colors.primaryDark,
    },
    '&:disabled': {
      opacity: 0.35,
      background: colors.white,
      color: colors.black4,
      '&:hover': {
        background: colors.white,
      },
      '&:active': {
        background: colors.white,
      },
    },
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 16,
    fill: colors.white,
  },
});

const ButtonFourth = ({ label, icon, extended, ...props }) => {
  const classes = useStyles(extended);
  const Icon = icon;
  return (
    <button type="button" className={classes.button} {...props}>
      {icon && <Icon className={classes.icon} />}
      {label}
    </button>
  );
};

ButtonFourth.propTypes = {
  extended: PropTypes.bool,
  label: PropTypes.string.isRequired,
  icon: PropTypes.func,
};

ButtonFourth.defaultProps = {
  extended: false,
  icon: null,
};

export default ButtonFourth;
