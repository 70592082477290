import React from 'react';
import PropTypes from 'prop-types';
import PieceObject from './PieceObject';
import PieceContext, { defaultProps } from './PieceContext';
import createAssetsLoader from './createAssetsLoader';

const perspective = 1;
const WebkitPerspective = perspective;
const MozPerspective = perspective;

const Piece = ({
  objects,
  isObjectsVisible,
  border,
  isAMP,
  rootObjectID,
  imageSrcAttr,
  ...props
}) => {
  if (!isObjectsVisible) {
    return false;
  }

  let state = {};
  if (props.loaded === false) {
    state = createAssetsLoader();
  }

  return (
    <PieceContext.Provider value={{ ...defaultProps, ...state, isAMP }}>
      <PieceObject
        object={objects}
        style={{
          border,
          position: 'relative',
          perspective,
          WebkitPerspective,
          MozPerspective,
        }}
        id={rootObjectID}
        imageSrcAttr={imageSrcAttr}
        rootObject
      />
    </PieceContext.Provider>
  );
};

Piece.defaultProps = {
  isObjectsVisible: true,
  border: 'none',
  rootObjectID: undefined,
  isAMP: false,
  loaded: true,
  imageSrcAttr: 'src',
};

Piece.propTypes = {
  objects: PropTypes.object.isRequired,
  isObjectsVisible: PropTypes.bool,
  rootObjectID: PropTypes.string,
  border: PropTypes.string,
  isAMP: PropTypes.bool,
  loaded: PropTypes.bool,
  imageSrcAttr: PropTypes.string,
};

export default Piece;
