import React from 'react';
import { createUseStyles } from 'react-jss';
import SliderZoom from './SliderZoom';

const useStyles = createUseStyles({
  wrapper: {
    position: 'absolute',
    bottom: 0,
    right: 16,
  },
});

const StageZoom = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <SliderZoom />
    </div>
  );
};

export default StageZoom;
