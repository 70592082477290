import { Record, Map } from 'immutable';
import * as types from './types';
import { makeReducer, makeAsset, makeCollection } from './utils';

export const AssetsReducerModel = Record({
  assets: Map(),
  collections: Map(),
});

const initAssetsFromServer = (state, { assets, collections }) =>
  state
    .set(
      'assets',
      assets.reduce(
        (curr, asset) => curr.set(asset.path, makeAsset(asset)),
        Map(),
      ),
    )
    .set(
      'collections',
      collections.reduce(
        (curr, collection) =>
          curr.set(collection.path, makeCollection(collection)),
        Map(),
      ),
    );

const addAsset = (state, asset) =>
  state.setIn(['assets', asset.path], makeAsset(asset));

const removeAsset = (state, { assetPath }) =>
  state.deleteIn(['assets', assetPath]);

const moveAsset = (state, { oldAssetPath, newAssetPath }) => {
  const oldPath = ['assets', oldAssetPath];
  const newPath = ['assets', newAssetPath];
  const asset = state.getIn(oldPath).set('path', newAssetPath);

  return state.deleteIn(oldPath).setIn(newPath, asset);
};

const addCollection = (state, { path }) =>
  state.setIn(['collections', path], makeCollection({ path }));

const removeCollection = (state, { path }) =>
  state.deleteIn(['collections', path]);

const resetAssets = () => AssetsReducerModel();

const assetsReducer = makeReducer(AssetsReducerModel(), {
  [types.INIT_ASSETS_FROM_SERVER]: initAssetsFromServer,
  [types.ADD_ASSET]: addAsset,
  [types.REMOVE_ASSET]: removeAsset,
  [types.MOVE_ASSET]: moveAsset,
  [types.ADD_COLLECTION]: addCollection,
  [types.REMOVE_COLLECTION]: removeCollection,
  [types.UNSET_PROJECT]: resetAssets,
});

export default assetsReducer;
