import React from 'react';
import { bool, node } from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';

const useStyles = createUseStyles({
  content: {
    position: 'relative',
    minHeight: ({ isDragging = false, requiredMinHeight = false }) =>
      isDragging && requiredMinHeight ? 60 : null,
  },
  overlay: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.yellow,
    position: 'absolute',
    opacity: 0.25,
    zIndex: 10,
  },
});

const Content = ({ isOver, children, ...props }) => {
  const classes = useStyles(props);
  return (
    <div className={classes.content}>
      {isOver && <div className={classes.overlay} />}
      {children}
    </div>
  );
};

Content.defaultProps = {
  isOver: false,
};

Content.propTypes = {
  children: node.isRequired,
  isOver: bool,
};

export default Content;
