import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { colors, PopoverTrigger } from '../ui';
import { getObjectIsSelected, selectObjectOnClickInTimeline } from '../editor';
import ButtonToggleAnimation from './ButtonToggleAnimation';
import ButtonToggleVisibility from './ButtonToggleVisibility';
import ButtonToggleLock from './ButtonToggleLock';
import { ClipPathIcon } from '../icons';
import EntryName from './EntryName';
import { getIsClipPathID } from '../selectors';

const useStyles = createUseStyles({
  entry: {
    borderBottom: `1px solid ${colors.black2}`,
    display: 'flex',
    height: 32,
    background: ({ isSelected }) =>
      isSelected ? colors.highlightTimeLine : null,
  },
  leftButtons: {
    marginRight: 16,
    display: 'flex',
  },
  selectButton: {
    background: ({ isSelected }) =>
      isSelected ? colors.selected : colors.unselected,
    borderRight: `1px solid ${colors.black2}`,
    cursor: 'pointer',
    flexShrink: 0,
    height: '100%',
    outline: 'none',
    width: 8,
  },
  toggleButton: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingRight: 8,
    flexShrink: 0,
  },
  entryDetails: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    marginRight: 6,
  },
  iconArea: {
    display: 'flex',
    width: 12,
    flexShrink: 0,
    justifyItems: 'center',
    alignItems: 'center',
    marginRight: 6,
  },
});

const EntryObject = ({ select, isSelected, isClipPath, objectID }) => {
  const classes = useStyles({ isSelected });
  return (
    <>
      <PopoverTrigger id="popover-timeline" onOpen={select}>
        {openPopover => (
          <div
            role="button"
            tabIndex="-1"
            className={classes.entry}
            onClick={select}
            onContextMenu={openPopover}
          >
            <div className={classes.leftButtons}>
              <button type="button" className={classes.selectButton} />
              <ButtonToggleVisibility
                objectID={objectID}
                isSelected={isSelected}
              />
              <ButtonToggleLock objectID={objectID} isSelected={isSelected} />
            </div>
            <div className={classes.entryDetails}>
              {isClipPath && (
                <div className={classes.iconArea}>
                  <ClipPathIcon width="12" height="12" />
                </div>
              )}
              <EntryName objectID={objectID} />
            </div>
            <span className={classes.toggleButton}>
              <ButtonToggleAnimation objectID={objectID} />
            </span>
          </div>
        )}
      </PopoverTrigger>
    </>
  );
};

EntryObject.propTypes = {
  select: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isClipPath: PropTypes.bool.isRequired,
  objectID: PropTypes.string.isRequired,
};

const container = connect(
  (state, { objectID }) => ({
    isSelected: getObjectIsSelected(objectID, state),
    isClipPath: getIsClipPathID(state, objectID),
    objectID,
  }),
  null,
  (state, { dispatch }, { objectID, ...props }) => ({
    ...props,
    ...state,
    select: event => dispatch(selectObjectOnClickInTimeline(event, objectID)),
  }),
);

export default container(EntryObject);
