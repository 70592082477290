import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { LoadingIndicator } from '../LoadingIndicator';

class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: props.loaded,
    };
    this.onLoad = this.onLoad.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src === this.props.src) {
      return;
    }

    /* eslint-disable-next-line */
    this.setState({ loaded: false });
  }

  onLoad() {
    this.setState({
      loaded: true,
    });
  }

  render() {
    const { alt, src, classes } = this.props;
    const { loaded } = this.state;

    return (
      <>
        <img
          alt={alt}
          src={src}
          style={{ opacity: loaded ? 1 : 0 }}
          className={classes.preview}
          onLoad={this.onLoad}
        />
        {!loaded && <LoadingIndicator size={24} />}
      </>
    );
  }
}

Image.defaultProps = {
  alt: '',
  src: '',
  loaded: false,
};

Image.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  loaded: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

const style = injectSheet({
  preview: {
    maxWidth: '100%',
    maxHeight: '100%',
    position: 'absolute',
  },
});

export default style(Image);
