import React from 'react';
import { string } from 'prop-types';
import { Ellipsis } from '../icons';
import { Popover, PopoverTrigger, IconButtonTertiary } from '../ui';
import ButtonUpload from './ButtonUpload';
import Input from './InputUploadAsset';
import { ButtonOpenModalRemoveCollection } from './ModalRemoveCollection';

const popoverID = 'collection-popover';

const getID = path => `${popoverID}-${path}`;

export const ButtonOpenPopoverCollection = ({ collectionPath }) => (
  <PopoverTrigger id={getID(collectionPath)}>
    {show => <IconButtonTertiary icon={Ellipsis} onClick={show} />}
  </PopoverTrigger>
);

ButtonOpenPopoverCollection.propTypes = {
  collectionPath: string.isRequired,
};

class PopoverCollection extends React.Component {
  constructor() {
    super();
    this.inputDOM = null;
  }

  render() {
    const { collectionPath } = this.props;
    return (
      <>
        <Input
          collectionPath={collectionPath}
          innerRef={elm => {
            this.inputDOM = elm;
          }}
        />
        <Popover id={getID(collectionPath)}>
          <ButtonUpload onClick={() => this.inputDOM.click()} />
          <ButtonOpenModalRemoveCollection collectionPath={collectionPath} />
        </Popover>
      </>
    );
  }
}

PopoverCollection.propTypes = {
  collectionPath: string.isRequired,
};

export default PopoverCollection;
