import cuid from 'cuid';
import { Record } from 'immutable';
import { CURVES } from './easing';
import { ALL_PROPERTIES } from './properties';

export const KeyframeValuesModel = Record(
  ALL_PROPERTIES.reduce(
    (currObj, propName) => ({ ...currObj, [propName]: null }),
    {},
  ),
);

const defaultKeyframe = {
  id: '',
  time: 0,
  values: KeyframeValuesModel(),
  timingFunction: CURVES.linear.timingFunction,
};

export const KeyframeModel = Record(defaultKeyframe);

export const makeKeyframe = ({
  forceID = null,
  values = {},
  ...keyframe
} = {}) =>
  KeyframeModel({
    ...defaultKeyframe,
    ...keyframe,
    values: KeyframeValuesModel({ ...values }),
    id: forceID || cuid(),
  });

export const makeBaseKeyframe = keyframe => ({
  ...defaultKeyframe,
  ...keyframe,
});
