import React from 'react';
import { Record, Map } from 'immutable';

export const Component = Record({
  isVisible: false,
  showArgs: [],
  hideArgs: [],
});

const emptyFn = () => null;

export const defaultState = {
  components: Map(),
  register: emptyFn,
  unregister: emptyFn,
  show: emptyFn,
  hide: emptyFn,
};

const Context = React.createContext(defaultState);

export default Context;
