import { alignTo } from './align';
// import { mergeObjectProperties } from './utils';
import { AABB } from '../geometry';

const distributeParams = {
  x: {
    propertySetter: 'setX',
    alignDirection: 'left',
    alignDirectionOposite: 'right',
    dimensionProperty: 'width',
  },
  y: {
    propertySetter: 'setY',
    alignDirection: 'top',
    alignDirectionOposite: 'bottom',
    dimensionProperty: 'height',
  },
};

class BaseDistribute {
  /* eslint-disable class-methods-use-this */

  constructor(axis, ids, state, container, currentTime) {
    this.updateMiddleObjectPosition = this.updateMiddleObjectPosition.bind(
      this,
    );
    this.updateFirstObjectPosition = this.updateFirstObjectPosition.bind(this);
    this.updateLastObjectPosition = this.updateLastObjectPosition.bind(this);

    this.axis = axis;
    this.alignDirection = distributeParams[axis].alignDirection;
    this.alignDirectionOposite = distributeParams[axis].alignDirectionOposite;
    this.dimensionProperty = distributeParams[axis].dimensionProperty;

    this.propertySetter = distributeParams[axis].propertySetter;

    this.currentTime = currentTime;
    this.state = state;
    this.objects = this.setObjects(ids);
    this.area = container
      ? AABB.fromPiece(container)
      : AABB.fromObjects(this.objects.toList());

    this.getCenterPosition = this.setGetCenterPosition();
    this.orderedObjects = this.sortObjectsByCenter();
    this.firstObject = this.setFirstObject();
    this.lastObject = this.setLastObject();
    this.gap = this.setGap();
    this.lastPosition = this.getStartPosition();
  }

  setObjects(ids) {
    return this.state.filter(object => ids.indexOf(object.id) !== -1);
  }

  setGetCenterPosition() {
    return object => object[this.axis] + object[this.dimensionProperty] / 2;
  }

  sortObjectsByCenter() {
    return this.objects.sort((objectA, objectB) => {
      const objectACenter = this.getCenterPosition(objectA);
      const objectBCenter = this.getCenterPosition(objectB);

      if (objectACenter < objectBCenter) {
        return -1;
      }
      if (objectACenter > objectBCenter) {
        return +1;
      }
      return 0;
    });
  }

  updateObjectPosition(updater) {
    return object => updater(object);
  }

  setFirstObject() {
    return this.orderedObjects
      .first()
      .update(this.updateObjectPosition(this.updateFirstObjectPosition));
  }

  updateFirstObjectPosition(object) {
    return object[this.propertySetter](
      alignTo[this.alignDirection](this.area, object),
      this.currentTime,
    );
  }

  setLastObject() {
    return this.orderedObjects
      .last()
      .update(this.updateObjectPosition(this.updateLastObjectPosition));
  }

  updateLastObjectPosition(object) {
    return object[this.propertySetter](
      alignTo[this.alignDirectionOposite](this.area, object),
      this.currentTime,
    );
  }

  setGap() {
    return this.getSpaceToDistribute() / (this.objects.size - 1);
  }

  getSpaceToDistribute() {
    throw new Error('You must implement getSpaceToDistribute method');
  }

  getStartPosition() {
    throw new Error('You must implement getStartPosition method');
  }

  evaluate() {
    this.updateFirstAndLastObjects();
    this.updateMiddleObjects();
    return this.updateState();
  }

  updateFirstAndLastObjects() {
    this.orderedObjects = this.orderedObjects
      .setIn([this.firstObject.id], this.firstObject)
      .setIn([this.lastObject.id], this.lastObject);
  }

  updateMiddleObjects() {
    this.orderedObjects = this.orderedObjects.merge(
      this.orderedObjects
        .rest()
        .butLast()
        .map(this.updateObjectPosition(this.updateMiddleObjectPosition)),
    );
  }

  updateMiddleObjectPosition(object) {
    const currentPosition = this.getCurrentPosition(object);
    this.lastPosition = this.getNextPosition(object);
    return object[this.propertySetter](
      Math.round(currentPosition),
      this.currentTime,
    );
  }

  getCurrentPosition() {
    throw new Error('You must implement getCurrentPosition method');
  }

  getNextPosition() {
    throw new Error('You must implement getNextPosition method');
  }

  updateState() {
    return this.state.merge(this.orderedObjects);
  }
}

export class DistributeObjects extends BaseDistribute {
  getObjectHalfDimension(object) {
    return object[this.dimensionProperty] / 2;
  }

  getSpaceToDistribute() {
    return (
      this.getCenterPosition(this.lastObject) -
      this.getCenterPosition(this.firstObject)
    );
  }

  getStartPosition() {
    // FIXME this.area['x' or 'y'] not ['left' or 'bottom']
    return (
      this.area[this.alignDirection] +
      this.getObjectHalfDimension(this.firstObject)
    );
  }

  getCurrentPosition(object) {
    return this.lastPosition + this.gap - this.getObjectHalfDimension(object);
  }

  getNextPosition() {
    return this.lastPosition + this.gap;
  }
}

export class JustifyObjects extends BaseDistribute {
  getSpaceToDistribute() {
    const objectsLength = this.orderedObjects.reduce(
      (sum, obj) => sum + obj[this.dimensionProperty],
      0,
    );
    return this.area[this.dimensionProperty] - objectsLength;
  }

  getStartPosition() {
    // FIXME this.area['x' or 'y'] not ['left' or 'bottom']
    return (
      this.area[this.alignDirection] + this.firstObject[this.dimensionProperty]
    );
  }

  getCurrentPosition() {
    return this.lastPosition + this.gap;
  }

  getNextPosition(object) {
    return this.lastPosition + this.gap + object[this.dimensionProperty];
  }
}
