import React from 'react';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';
import { url } from '../App';
import { ListIcon } from '../icons';
import { MenuItem } from '../ui';

const ButtonProjectList = ({ history }) => (
  <MenuItem
    label="Project List"
    icon={ListIcon}
    onClick={() => history.push(url.home())}
  />
);

ButtonProjectList.propTypes = {
  history: object.isRequired,
};

export default withRouter(ButtonProjectList);
