import { connect } from 'react-redux';
import { setAssetsToUpload } from './actions';
import BaseInput from './BaseInputUploadAsset';

const container = connect(
  null,
  (dispatch, { collectionPath, innerRef }) => ({
    innerRef,
    multiple: true,
    onChange(e) {
      dispatch(
        setAssetsToUpload({
          collectionPath,
          filelist: e.target.files,
        }),
      );
    },
  }),
  (state, actions) => actions,
);

export default container(BaseInput);
