import { combineReducers } from 'redux-immutable';
import lockedObjects from './reducer-locked-objects';
import hiddenObjects from './reducer-hidden-objects';
import selectedObjects from './reducer-selected-objects';
import selectedKeyframes from './reducer-selected-keyframes';
import objectToRename from './reducer-object-to-rename';
import lastModification from './reducer-last-modification';
import focusedArea from './reducer-focused-area';
import stage from './reducer-stage';

export default combineReducers({
  lockedObjects,
  hiddenObjects,
  selectedKeyframes,
  selectedObjects,
  stage,
  objectToRename,
  focusedArea,
  lastModification,
});
