import React from 'react';
import { createUseStyles } from 'react-jss';
import { getLogoInverseURL } from '../logo';

const useStyles = createUseStyles({
  logo: {
    height: 30,
    marginRight: 48,
  },
});

const Logo = () => {
  const classes = useStyles();
  return (
    <img className={classes.logo} alt="Artisan" src={getLogoInverseURL()} />
  );
};

export default Logo;
