import React from 'react';
import { string, func, bool } from 'prop-types';
import { createUseStyles } from 'react-jss';
import colors from '../colors';

const useStyles = createUseStyles({
  item: {
    width: '100%',
    height: 40,
    background: 'transparent',
    fontSize: 16,
    lineHeight: 1.5,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      background: colors.black3,
    },

    '&:active': {
      background: colors.black2,
    },
  },
  icon: {
    height: 16,
    width: 16,
    marginLeft: 24,
    fill: props => (props.disabled ? colors.gray1 : colors.white),
  },
  label: {
    marginLeft: 34,
    color: props => (props.disabled ? colors.gray1 : colors.white),
  },
});

const MenuItem = ({ label, icon, onClick, disabled }) => {
  const classes = useStyles({ disabled });
  const Icon = icon;

  return (
    <button
      type="button"
      className={classes.item}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon className={classes.icon} />

      <span className={classes.label}>{label}</span>
    </button>
  );
};

MenuItem.propTypes = {
  label: string,
  icon: func,
  onClick: func,
  disabled: bool,
};

MenuItem.defaultProps = {
  label: '',
  icon: null,
  onClick: null,
  disabled: false,
};

export default MenuItem;
