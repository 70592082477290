import React from 'react';
import { createUseStyles } from 'react-jss';
import ButtonAlignLeft from './ButtonAlignLeft';
import ButtonAlignCenter from './ButtonAlignCenter';
import ButtonAlignRight from './ButtonAlignRight';
import ButtonAlignTop from './ButtonAlignTop';
import ButtonAlignMiddle from './ButtonAlignMiddle';
import ButtonAlignBottom from './ButtonAlignBottom';
import ButtonDistributeCenterH from './ButtonDistributeCenterH';
import ButtonDistributeJustifiedH from './ButtonDistributeJustifiedH';
import ButtonDistributeCenterV from './ButtonDistributeCenterV';
import ButtonDistributeJustifiedV from './ButtonDistributeJustifiedV';
import ToggleAlignToContainer from './ToggleAlignToContainer';
import ButtonMoveToTop from './ButtonMoveToTop';
import ButtonMoveToBottom from './ButtonMoveToBottom';
import ButtonMoveOneStepToTop from './ButtonMoveOneStepToTop';
import ButtonMoveOneStepToBottom from './ButtonMoveOneStepToBottom';
import ToggleGuides from './ToggleGuides';
import TogglePivot from './TogglePivot';
import ToggleBorder from './ToggleBorder';
import ToggleTransformationBox from './ToggleTransformationBox';
import Group from './Group';
import Splitter from './Splitter';
import Wrapper from './WrapperButtons';

const useStyles = createUseStyles({
  tools: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    padding: [0, 32],
  },
});

const Tools = () => {
  const classes = useStyles();
  return (
    <div className={classes.tools}>
      <Group minSelectedObjects={1}>
        <Wrapper>
          <ButtonAlignLeft />
          <ButtonAlignCenter />
          <ButtonAlignRight />
          <ButtonAlignTop />
          <ButtonAlignMiddle />
          <ButtonAlignBottom />
        </Wrapper>
        <Splitter />
      </Group>
      <Group minSelectedObjects={2}>
        <Wrapper>
          <ButtonDistributeCenterH />
          <ButtonDistributeJustifiedH />
          <ButtonDistributeCenterV />
          <ButtonDistributeJustifiedV />
        </Wrapper>
        <Splitter />
      </Group>
      <Group minSelectedObjects={1}>
        <ToggleAlignToContainer />
        <Splitter />
      </Group>
      <Group minSelectedObjects={1}>
        <Wrapper>
          <ButtonMoveOneStepToTop />
          <ButtonMoveOneStepToBottom />
          <ButtonMoveToTop />
          <ButtonMoveToBottom />
        </Wrapper>
      </Group>
      <Group minSelectedObjects={1}>
        <Splitter />
      </Group>
      <ToggleTransformationBox />
      <Splitter />
      <ToggleGuides />
      <Splitter />
      <TogglePivot />
      <Splitter />
      <ToggleBorder />
    </div>
  );
};

export default Tools;
