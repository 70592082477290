import React from 'react';
import { node } from 'prop-types';
import { colors, label } from '../ui';

const FormError = ({ children }) => (
  <p
    style={{
      ...label,
      color: colors.error,
    }}
  >
    {children}
  </p>
);

FormError.propTypes = {
  children: node.isRequired,
};

export default FormError;
