import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';

const useStyles = createUseStyles({
  toggleContainer: isSelected => ({
    width: 24,
    height: '100%',
    backgroundColor: isSelected ? 'transparent' : colors.black3,
    borderRight: `1px solid ${colors.black2}`,
    fill: colors.white,
    padding: 6,
  }),
});

const ButtonLeftEntry = ({ children, onClick, isSelected }) => {
  const classes = useStyles(isSelected);
  return (
    <button type="button" className={classes.toggleContainer} onClick={onClick}>
      {children}
    </button>
  );
};

ButtonLeftEntry.defaultProps = {
  isSelected: false,
};

ButtonLeftEntry.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

export default ButtonLeftEntry;
