import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { IMAGE_ASSET_TYPE } from '../../models';
import { VideoThumb } from '../../ui';

const WIDTH = 40;
const HEIGHT = 40;

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: WIDTH,
    height: HEIGHT,
    marginRight: 8,
  },
  icon: {
    maxWidth: WIDTH,
    maxHeight: HEIGHT,
  },
});

const AssetIcon = ({ asset }) => {
  const classes = useStyles();

  let children = null;

  if (asset.assetType === IMAGE_ASSET_TYPE) {
    children = (
      <img src={asset.url} alt="Asset preview" className={classes.icon} />
    );
  } else {
    children = <VideoThumb customSize={24} />;
  }

  return <div className={classes.wrapper}>{children}</div>;
};

AssetIcon.propTypes = {
  asset: PropTypes.object.isRequired,
};

export default AssetIcon;
