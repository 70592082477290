import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { bool, number, func } from 'prop-types';
import { PrevIcon, NextIcon } from '../../icons';
import { textRegular, textBold } from '../typography';
import IconButtonPrimary from '../Buttons/IconButtonPrimary';

const useStyles = createUseStyles(theme => ({
  pagination: {
    alignItems: 'center',
    display: 'flex',
    height: 32,
  },
  label: {
    ...textRegular,
    color: theme.fg,
    margin: [0, 16],
    '& b': {
      ...textBold,
      color: theme.fg,
    },
  },
}));

const Pagination = ({
  page,
  hasNext,
  hasPrevious,
  startIndex,
  endIndex,
  totalItems,
  fetchMore,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles({ ...props, theme });
  return (
    <div className={classes.pagination}>
      {hasPrevious && (
        <IconButtonPrimary
          onClick={() => fetchMore(page - 1)}
          icon={PrevIcon}
        />
      )}
      <span className={classes.label}>
        <b>
          {startIndex}-{endIndex}
        </b>{' '}
        of <b>{totalItems}</b>
      </span>
      {hasNext && (
        <IconButtonPrimary
          onClick={() => fetchMore(page + 1)}
          icon={NextIcon}
        />
      )}
    </div>
  );
};

Pagination.propTypes = {
  hasNext: bool.isRequired,
  hasPrevious: bool.isRequired,
  page: number.isRequired,
  startIndex: number.isRequired,
  endIndex: number.isRequired,
  totalItems: number.isRequired,
  fetchMore: func.isRequired,
};

export default Pagination;
