import { SET_OBJECT_TO_RENAME, RESET_OBJECT_TO_RENAME } from './types';
import { createReducer } from '../utils';

const setObjectToRename = objectID => () => objectID;

const resetObjectToRename = () => () => null;

export const objectToRename = createReducer(null, {
  [SET_OBJECT_TO_RENAME]: setObjectToRename,
  [RESET_OBJECT_TO_RENAME]: resetObjectToRename,
});

export default objectToRename;
