import React from 'react';
import { MenuItem } from '../ui';
import { ExitIcon } from '../icons';
import { useLogout } from '../auth';

const LogoutButton = () => {
  const logout = useLogout();

  return <MenuItem icon={ExitIcon} onClick={logout} label="Logout" />;
};

export default LogoutButton;
