import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { label } from '../ui';
import RenameObject from './RenameObject';
import {
  resetObjectToRename,
  getIsRenamingObject,
  setObjectToRename,
  getIsObjectLocked,
} from '../editor';
import { getObjectName } from '../project';

const useStyles = createUseStyles({
  name: {
    ...label,
    width: '100%',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: '30px',
    cursor: 'pointer',
  },
});

const EntryName = ({ objectID }) => {
  const dispatch = useDispatch();
  const objectName = useSelector(state => getObjectName(state, objectID));
  const isRenaming = useSelector(state => getIsRenamingObject(state, objectID));
  const isObjectLocked = useSelector(state =>
    getIsObjectLocked(state, objectID),
  );
  const classes = useStyles();

  const handleDoubleClick = useCallback(() => {
    if (isObjectLocked) {
      return;
    }
    dispatch(setObjectToRename(objectID));
  });
  const handleClose = useCallback(() => {
    dispatch(resetObjectToRename());
  });

  return (
    <div className={classes.name} onDoubleClick={handleDoubleClick}>
      {isRenaming ? (
        <RenameObject
          isOpen
          onClose={handleClose}
          initialValue={objectName}
          objectID={objectID}
        />
      ) : (
        objectName
      )}
    </div>
  );
};

EntryName.propTypes = {
  objectID: PropTypes.string.isRequired,
};

export default EntryName;
