import React from 'react';
import PropTypes from 'prop-types';

const Spacer = ({ width }) => <div style={{ width, flexShrink: 0 }} />;

Spacer.defaultProps = {
  width: 24,
};

Spacer.propTypes = {
  width: PropTypes.number,
};

export default Spacer;
