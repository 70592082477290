import React from 'react';
import { node, func } from 'prop-types';
import SelectionArea from './SelectionArea';

export const Context = React.createContext({
  components: new Map(),
  registerComponent: () => {},
  removeComponent: () => {},
});

class SelectionContext extends React.Component {
  constructor() {
    super();
    this.registerComponent = this.registerComponent.bind(this);
    this.removeComponent = this.removeComponent.bind(this);
    this.checkCollision = this.checkCollision.bind(this);

    this.state = {
      components: new Map(),
      registerComponent: this.registerComponent,
      removeComponent: this.removeComponent,
    };
  }

  registerComponent(component) {
    this.setState(state => ({
      ...state,
      components: state.components.set(component.id, component),
    }));
  }

  removeComponent(component) {
    this.setState(state => {
      const components = new Map(state.components);
      components.delete(component.id);
      return {
        ...state,
        components,
      };
    });
  }

  checkCollision(event, { minX, maxX, minY, maxY }) {
    const items = [];
    this.state.components.forEach(component => {
      if (component.props.isSelectable === false) {
        return;
      }

      if (component.props.isSelected && event.shiftKey) {
        items.push(component);
      }

      const area = component.area();
      const collisionX = maxX >= area.x && area.x + area.width >= minX;
      const collisionY = maxY >= area.y && area.y + area.height >= minY;
      if (collisionX && collisionY) {
        items.push(component);
        component.setState({ ...component.state, isUnderSelection: true });
      }
    });

    this.props.onSelectedItems(
      event,
      items.map(item => item.data),
    );
  }

  render() {
    const { children } = this.props;

    const selectionAreaProps = { ...this.props };
    delete selectionAreaProps.onSelectedItems;
    delete selectionAreaProps.children;

    return (
      <Context.Provider value={this.state}>
        <SelectionArea
          onSelectedArea={this.checkCollision}
          {...selectionAreaProps}
        >
          {children}
        </SelectionArea>
      </Context.Provider>
    );
  }
}

SelectionContext.defaultProps = {
  onSelectedItems: () => null,
};

SelectionContext.propTypes = {
  children: node.isRequired,
  onSelectedItems: func,
};

export default SelectionContext;
