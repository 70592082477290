import { identityDecoder } from './utils';

const createRequestDescription = (
  config,
  decoder = identityDecoder,
  errorDecoder = identityDecoder,
) => ({
  config,
  decoder,
  errorDecoder,
});

export default createRequestDescription;
