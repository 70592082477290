import React from 'react';
import { connect } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { flowRight as compose } from 'lodash';
import { object, func, bool } from 'prop-types';
import { Form, FieldArray, withFormik } from 'formik';
import { AddIcon } from '../../icons';
import {
  ButtonPrimary,
  ButtonTertiary,
  Modal,
  ModalHeader,
  ModalContent,
  ModalButtons,
  ModalSpacer,
  IconButtonPrimary,
  createHideableComponent,
} from '../../ui';
import { addSizes } from '../../project';
import { CustomScroll } from '../../CustomScroll';
import SizeField from './SizeField';
import ErrorList from './ErrorList';

const INVALID_VALUE = 'must be higher than 0 to be valid.';

const ModalContext = createHideableComponent('modalAddSizes');

export const STANDARD_FORMAT = {
  height: 250,
  width: 300,
};

const useStyles = createUseStyles({
  scrollable: {
    maxHeight: 380,
    '&>div': {
      minHeight: 100,
    },
  },
});

/* eslint-disable react/no-array-index-key */
const ModalAddSizes = ({
  values,
  errors,
  isSubmitting,
  isValid,
  handleBlur,
  isVisible,
  hide,
}) => {
  const classes = useStyles();
  return (
    <Modal small isVisible={isVisible}>
      <ModalHeader>Add Sizes</ModalHeader>
      <ModalContent>
        <Form>
          <FieldArray
            name="sizes"
            render={arrayHelpers => (
              <div className={classes.scrollable}>
                <CustomScroll autoHeight autoHeightMax={380}>
                  {values.sizes.map((size, index) => (
                    <SizeField
                      key={index}
                      size={size}
                      index={index}
                      handleBlur={handleBlur}
                      error={errors[index]}
                      {...arrayHelpers}
                    />
                  ))}
                  <IconButtonPrimary
                    type="button"
                    icon={AddIcon}
                    onClick={() => arrayHelpers.push(STANDARD_FORMAT)}
                  />
                </CustomScroll>
              </div>
            )}
          />
          {errors.sizes !== undefined && (
            <ErrorList error={{ sizes: errors.sizes }} />
          )}
          <ModalButtons>
            <ButtonPrimary
              extended
              type="submit"
              label="Add Size"
              disabled={!isValid || isSubmitting}
            />
            <ModalSpacer />
            <ButtonTertiary
              type="button"
              extended
              label="Cancel"
              disabled={isSubmitting}
              onClick={hide}
            />
          </ModalButtons>
        </Form>
      </ModalContent>
    </Modal>
  );
};

ModalAddSizes.propTypes = {
  isVisible: bool.isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  handleBlur: func.isRequired,
  isSubmitting: bool.isRequired,
  isValid: bool.isRequired,
  hide: func.isRequired,
};

const withRedux = connect(null, { addSizes });

const withForm = withFormik({
  mapPropsToValues: () => ({
    sizes: [STANDARD_FORMAT],
  }),
  validate: values => {
    const errors = {};

    if (values.sizes.length === 0) {
      errors.sizes = 'Please add at least one size';
      return errors;
    }

    const sizeErrors = values.sizes.reduce((curr, value, index) => {
      const error = {};
      if (!value.width || value.width <= 0) {
        error.width = `Width ${INVALID_VALUE}`;
      }

      if (!value.height || value.height <= 0) {
        error.height = `Height ${INVALID_VALUE}`;
      }

      if (Object.keys(error).length > 0) {
        return { ...curr, [index]: error };
      }

      return curr;
    }, {});

    return { ...errors, ...sizeErrors };
  },
  handleSubmit: ({ sizes }, { props, setSubmitting, resetForm }) => {
    setSubmitting(true);
    props.addSizes(sizes);
    setSubmitting(false);
    props.hide();
    resetForm();
  },
});

export default compose(withRedux, ModalContext.hoc, withForm)(ModalAddSizes);
