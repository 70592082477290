import React from 'react';
import { TextIcon } from '../icons';
import SidebarTool from './SidebarTool';
import { StageMode } from '../editor';

const ButtonTextTool = () => (
  <SidebarTool tool={StageMode.text} icon={TextIcon} />
);

export default ButtonTextTool;
