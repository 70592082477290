import React from 'react';
import cuid from 'cuid';
import { Context } from './SelectionContext';

const createSelectable = (WrappedComponent, resolver) => {
  class Selectable extends React.Component {
    constructor(props) {
      super(props);
      this.id = cuid();
      this.selectableRef = React.createRef();
      this.data = resolver(props);

      this.state = {
        isUnderSelection: false,
      };
    }

    componentDidMount() {
      if (!this.selectableRef.current) {
        return;
      }

      this.context.registerComponent(this);
    }

    componentWillUnmount() {
      this.context.removeComponent(this);
    }

    area() {
      return this.selectableRef.current.getBoundingClientRect();
    }

    render() {
      return (
        <WrappedComponent
          selectableRef={this.selectableRef}
          {...this.state}
          {...this.props}
        />
      );
    }
  }

  Selectable.contextType = Context;

  return Selectable;
};

export default createSelectable;
