import React from 'react';
import { node } from 'prop-types';
import { ThemeProvider } from 'react-jss';
import colors from './colors';

const black = {
  buttons: {
    default: {
      background: colors.white,
      color: colors.black2,
      fill: colors.black2,
      '&:hover': {
        background: colors.gray2,
      },
      '&:active': {
        background: colors.gray4,
      },
      '&:disabled': {
        opacity: 0.35,
        '&:hover': {
          background: colors.white,
        },
        '&:active': {
          background: colors.white,
        },
      },
    },
    secondary: {
      color: colors.white,
      fill: colors.white,
      background: 'transparent',
      border: '1px solid',
      borderColor: colors.white,
      borderRadius: 20,
      '&:hover, &:active': {
        color: colors.black2,
        fill: colors.black2,
      },
      '&:hover': {
        background: colors.white,
      },
      '&:active': {
        borderColor: colors.gray4,
        background: colors.gray4,
      },
      '&:disabled': {
        opacity: 0.35,
        '&:hover, &:active': {
          color: colors.white,
          fill: colors.white,
          background: 'transparent',
          borderColor: colors.white,
        },
      },
    },
    tertiary: {
      color: colors.white,
      fill: colors.white,
      background: 'transparent',
      border: '1px solid',
      borderColor: 'transparent',
      borderRadius: 20,
      '&:hover': {
        borderColor: colors.white,
      },
      '&:active': {
        background: colors.gray4,
        borderColor: colors.gray4,
        color: colors.black2,
        fill: colors.black2,
      },
      '&:disabled': {
        opacity: 0.35,
        '&:hover, &:active': {
          color: colors.white,
          fill: colors.white,
          background: 'transparent',
          borderColor: 'transparent',
        },
      },
    },
  },
  fg: colors.white,
  bg: colors.black4,
};

const white = {
  buttons: {
    default: {
      background: colors.black2,
      color: colors.white,
      fill: colors.white,
      '&:hover': {
        background: colors.black4,
      },
      '&:active': {
        background: colors.black1,
      },
      '&:disabled': {
        opacity: 0.35,
        '&:hover': {
          background: colors.black2,
        },
        '&:active': {
          background: colors.black2,
        },
      },
    },
    secondary: {
      color: colors.black2,
      fill: colors.black2,
      background: 'transparent',
      border: '1px solid',
      borderColor: colors.black2,
      borderRadius: 20,
      '&:hover, &:active': {
        color: colors.white,
        fill: colors.white,
      },
      '&:hover': {
        borderColor: colors.black4,
        background: colors.black4,
      },
      '&:active': {
        borderColor: colors.black1,
        background: colors.black1,
      },
      '&:disabled': {
        opacity: 0.35,
        '&:hover, &:active': {
          color: colors.black2,
          fill: colors.black2,
          background: 'transparent',
          borderColor: colors.black2,
        },
      },
    },
    tertiary: {
      color: colors.black2,
      fill: colors.black2,
      background: 'transparent',
      border: '1px solid',
      borderColor: 'transparent',
      borderRadius: 20,
      '&:hover, &:active': {
        borderColor: colors.black2,
      },
      '&:hover': {
        background: colors.white,
      },
      '&:active': {
        background: colors.black1,
        borderColor: colors.black1,
        color: colors.white,
        fill: colors.white,
      },
      '&:disabled': {
        opacity: 0.35,
        '&:hover, &:active': {
          color: colors.black2,
          fill: colors.black2,
          background: 'transparent',
          borderColor: 'transparent',
        },
      },
    },
  },
  fg: colors.black4,
  bg: colors.white,
};

const propTypes = { children: node.isRequired };

export const BlackTheme = ({ children }) => (
  <ThemeProvider theme={black}>{children}</ThemeProvider>
);

BlackTheme.propTypes = propTypes;

export const WhiteTheme = ({ children }) => (
  <ThemeProvider theme={white}>{children}</ThemeProvider>
);

WhiteTheme.propTypes = propTypes;
