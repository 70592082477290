import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { round } from 'lodash';
import { Slider } from '../ui';
import { setZoomScale, getZoomScale } from '../stage';

const SliderZoom = () => {
  const dispatch = useDispatch();
  const onChange = useCallback(value => dispatch(setZoomScale(value / 100)), [
    dispatch,
  ]);
  const value = useSelector(state => getZoomScale(state));

  return (
    <Slider
      value={round(value * 100)}
      min={10}
      max={400}
      onChange={onChange}
      step={10}
      stepButton={50}
    />
  );
};

export default SliderZoom;
