/* eslint-disable class-methods-use-this */
import { CENTER } from '../constants';
import { round, buildCoordinates, buildOriginString, ORIGINS } from '../utils';

const NOT_IMPLEMENTED_ERROR = new Error('Not implemented on base class');
const BB_PROPERTIES = [
  'angle',
  'angleDeg',
  'sameObjectsAngle',
  'coordinates',
  'width',
  'height',
  'left',
  'right',
  'top',
  'bottom',
];

class BaseBB {
  constructor({ left = 0, top = 0, width = 0, height = 0, coordinates = [] }) {
    this.angle = 0;
    this.angleDeg = 0;
    this.width = round(width);
    this.height = round(height);
    this.left = round(left);
    this.right = round(this.left + this.width);
    this.top = round(top);
    this.bottom = round(this.top + this.height);
    this.sameObjectsAngle = true;

    if (!coordinates.length) {
      this.coordinates = buildCoordinates(this);
    } else {
      this.coordinates = coordinates;
    }

    this.getCoordinatesFromOriginString = this.getCoordinatesFromOriginString.bind(
      this,
    );
    this.getCoordinates = this.getCoordinates.bind(this);
  }

  static fromPiece() {
    throw NOT_IMPLEMENTED_ERROR;
  }

  static fromObjects() {
    throw NOT_IMPLEMENTED_ERROR;
  }

  getCoordinatesFromOriginString(originString) {
    return this.coordinates[ORIGINS[originString]];
  }

  getCoordinates({ x = CENTER, y = CENTER } = {}) {
    return this.getCoordinatesFromOriginString(buildOriginString(x, y));
  }

  toJS() {
    const self = this;
    return BB_PROPERTIES.reduce(
      (curr, prop) => ({ ...curr, [prop]: self[prop] }),
      {},
    );
  }
}

export default BaseBB;
