import {
  AABB,
  getValueByOrigin,
  getTransformedObjectCoordinates,
  getMinMaxFromPoints,
  round,
  CENTER,
} from '../geometry';

const directionSetterMap = {
  left: 'setX',
  center: 'setX',
  right: 'setX',
  top: 'setY',
  middle: 'setY',
  bottom: 'setY',
};

export const alignTo = {
  left: (area, object) => {
    const minMax = getMinMaxFromPoints(getTransformedObjectCoordinates(object));

    return round(object.x + (area.left - minMax.minX));
  },
  center: (area, object) => {
    const expectedPosition = area.left + area.width / 2;
    const coords = getTransformedObjectCoordinates(object);
    const center = getValueByOrigin(coords, CENTER, CENTER);

    return round(object.x + expectedPosition - center.x);
  },
  right: (area, object) => {
    const minMax = getMinMaxFromPoints(getTransformedObjectCoordinates(object));

    return round(object.x + (area.right - minMax.maxX));
  },
  top: (area, object) => {
    const minMax = getMinMaxFromPoints(getTransformedObjectCoordinates(object));

    return round(object.y + (area.top - minMax.minY));
  },
  middle: (area, object) => {
    const expectedPosition = area.top + area.height / 2;
    const coords = getTransformedObjectCoordinates(object);
    const center = getValueByOrigin(coords, CENTER, CENTER);

    return round(object.y + expectedPosition - center.y);
  },
  bottom: (area, object) => {
    const minMax = getMinMaxFromPoints(getTransformedObjectCoordinates(object));

    return round(object.y + (area.bottom - minMax.maxY));
  },
};

export const alignObjectsTo = direction => ({
  ids,
  container,
  currentTime,
}) => state => {
  const setter = directionSetterMap[direction];
  const alignFunction = (area, object) =>
    object[setter](alignTo[direction](area, object), currentTime);

  const objects = ids.map(objectID => state.get(objectID));
  const area = container
    ? AABB.fromPiece(container)
    : AABB.fromObjects(objects);
  return ids.reduce(
    (newState, objectID) =>
      newState.update(objectID, object => alignFunction(area, object)),
    state,
  );
};
