import React from 'react';
import { AddIcon } from '../icons';
import { ButtonPrimary, Hideable } from '../ui';

const CreateProjectButton = () => (
  <Hideable.Trigger id="createProject">
    {({ show }) => (
      <ButtonPrimary onClick={show} icon={AddIcon} label="Create Project" />
    )}
  </Hideable.Trigger>
);

export default CreateProjectButton;
