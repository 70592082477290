export const CUBIC_BEZIER = 'cubic-bezier';
export const STEPS = 'steps';
export const JUMP_START = 'jump-start';
export const JUMP_END = 'jump-end';
export const JUMP_NONE = 'jump-none';
export const JUMP_BOTH = 'jump-both';

export const CURVES = {
  linear: {
    name: 'Linear',
    timingFunction: {
      name: CUBIC_BEZIER,
      values: [0.25, 0.25, 0.75, 0.75],
    },
  },
  ease: {
    name: 'Ease',
    timingFunction: {
      name: CUBIC_BEZIER,
      values: [0.25, 0.1, 0.25, 1],
    },
  },
  easeIn: {
    name: 'Ease in',
    timingFunction: {
      name: CUBIC_BEZIER,
      values: [0.895, 0.03, 0.685, 0.22],
    },
  },
  easeOut: {
    name: 'Ease out',
    timingFunction: {
      name: CUBIC_BEZIER,
      values: [0.165, 0.84, 0.44, 1],
    },
  },
  easeInOut: {
    name: 'Ease In Out',
    timingFunction: {
      name: CUBIC_BEZIER,
      values: [0.77, 0, 0.175, 1],
    },
  },
  stepEnd: {
    name: 'Step End',
    timingFunction: {
      name: STEPS,
      values: [1, JUMP_END],
    },
  },
};
