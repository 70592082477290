import React from 'react';
import { createUseStyles } from 'react-jss';
import { IconButtonTertiary } from '../ui/Buttons';
import { Menu, MenuProvider, MenuConsumer } from '../ui/Menu';
import { HamburgerIcon } from '../icons';
import ButtonSavePieces from './ButtonSavePieces';
import { ButtonExportPieces } from './ModalExportPieces';
import ButtonProjectList from './ButtonProjectList';
import ButtonLogout from './ButtonLogout';

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    padding: [12, 0],
    marginLeft: 48,
  },
  menu: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

const StyledHamburgerIcon = () => <HamburgerIcon style={{ width: 24 }} />;

const MenuActions = () => {
  const classes = useStyles();
  return (
    <MenuProvider>
      <MenuConsumer menuKey="menuActions">
        {({ isVisible, closeMenu, openMenu }) => (
          <div className={classes.container}>
            <IconButtonTertiary icon={StyledHamburgerIcon} onClick={openMenu} />
            <Menu
              isVisible={isVisible}
              onClose={closeMenu}
              className={classes.menu}
            >
              <ButtonSavePieces />
              <ButtonExportPieces />
              <ButtonProjectList />
              <ButtonLogout />
            </Menu>
          </div>
        )}
      </MenuConsumer>
    </MenuProvider>
  );
};

export default MenuActions;
