import React, { useCallback, useState, createContext } from 'react';
import { debounce } from 'lodash';
import { node } from 'prop-types';

const Context = createContext({
  name: '',
  searching: false,
  onChangeName: () => null,
});

const SearchContext = ({ children }) => {
  const [state, setState] = useState({
    name: '',
    searching: false,
  });

  const onChangeName = useCallback(
    debounce(name => {
      setState({ name, searching: true });
    }, 250),
    [setState],
  );

  const { name, searching } = state;
  return (
    <Context.Provider value={{ name, searching, onChangeName }}>
      {children}
    </Context.Provider>
  );
};

SearchContext.propTypes = {
  children: node.isRequired,
};

SearchContext.Consumer = Context.Consumer;

export default SearchContext;
