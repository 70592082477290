import React, { Component, createContext } from 'react';
import { node } from 'prop-types';

export const MenuContext = createContext();

class MenuProvider extends Component {
  constructor() {
    super();

    this.state = {};

    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.registerMenu = this.registerMenu.bind(this);
  }

  closeMenu(key) {
    const newState = { ...this.state };
    newState[key] = { isVisible: false };
    this.setState(newState);
  }

  openMenu(key) {
    const newState = { ...this.state };
    newState[key] = { isVisible: true };
    this.setState(newState);
  }

  registerMenu(key) {
    const newState = { ...this.state };
    newState[key] = { isVisible: false };
    this.setState(newState);
  }

  render() {
    const { state, closeMenu, openMenu, registerMenu } = this;

    return (
      <MenuContext.Provider
        value={{ menus: state, closeMenu, openMenu, registerMenu }}
      >
        {this.props.children}
      </MenuContext.Provider>
    );
  }
}

MenuProvider.propTypes = {
  children: node.isRequired,
};

export default MenuProvider;

export function withMenuStates(Comp) {
  return function WithContextComponent(props) {
    return (
      <MenuContext.Consumer>
        {menuStates => <Comp menuStates={menuStates} {...props} />}
      </MenuContext.Consumer>
    );
  };
}
