import AABB from './aabb';
import OBB from './obb';
import { extractRotationFromMatrix } from '../utils';

const getRotationFromObject = object => object.get('rotation', 0);

export const getRotationValue = objects => {
  if (!objects.size) {
    return 0;
  }

  return objects.reduce((value, object) => {
    const rotationValue = getRotationFromObject(object);
    return rotationValue > value ? rotationValue : value;
  }, Number.MIN_SAFE_INTEGER);
};

export const getBoundingBoxFromPiece = piece => AABB.fromPiece(piece);

export const isMatrixRotated = (matrix = undefined) => {
  if (!matrix) {
    return false;
  }

  return extractRotationFromMatrix(matrix) !== 0;
};

export const getBoundingBoxFromObject = (object, parentMatrix = undefined) => {
  const options = { parentMatrix };
  const rotation = object.get('rotation');

  if (rotation !== 0 || isMatrixRotated(parentMatrix)) {
    const obbOptions = {
      ...options,
      preferredAngle: rotation,
      sameObjectsAngle: true,
    };

    return OBB.fromObject(object, obbOptions);
  }

  return AABB.fromObject(object, options);
};

export const isObjectsRotated = objects =>
  objects.some(object => getRotationFromObject(object) !== 0);

export const getBoundingBoxFromObjects = (
  objects,
  parentMatrix = undefined,
) => {
  const options = { parentMatrix };

  if (isMatrixRotated(parentMatrix) || isObjectsRotated(objects)) {
    const rotation = getRotationValue(objects);
    const sameObjectsAngle = objects.every(
      obj => getRotationFromObject(obj) === rotation,
    );
    const obbOptions = {
      ...options,
      preferredAngle: sameObjectsAngle ? rotation : null,
      sameObjectsAngle,
    };

    return OBB.fromObjects(objects.toList(), obbOptions);
  }

  return AABB.fromObjects(objects.toList(), options);
};

export { AABB, OBB };
