import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { colors } from '../../ui';
import MovableComponent from '../../MovableComponent';
import { withEditableState } from '../../EditableContext';

export const BOX_SIZE = 60;
export const BOX_RADIUS = BOX_SIZE / 2;
export const ATAN2_DIFFERENCE = 90;

const cartesianRotation = (anchor, point) => {
  const radians = Math.atan2(anchor.y - point.y, anchor.x - point.x);
  return Math.floor((radians * 180) / Math.PI);
};

const limitTo360 = degree => (degree >= 360 ? degree - 360 : degree);

const toClockDegree = angle => limitTo360(angle + 180 + ATAN2_DIFFERENCE);

const applyTurn = (turn, angle) => angle + 360 * turn;

const applyRotationDiff = (rotation, diff) => rotation - diff;

const getQuadrant = angle => Math.floor((angle / 360) * 4);

const getCurrentTurn = (turn, lastAngle, newAngle) => {
  const lastQuadrant = getQuadrant(lastAngle);
  const newQuadrant = getQuadrant(newAngle);

  if (newQuadrant === 0 && lastQuadrant === 3) {
    return turn + 1;
  }

  if (lastQuadrant === 0 && newQuadrant === 3) {
    return turn - 1;
  }

  return turn;
};
class RotationBox extends Component {
  constructor() {
    super();

    this.state = {
      currentTurn: 0,
      rotationDiff: 0,
      active: false,
      position: { x: 0, y: 0 },
    };

    this.onDown = this.onDown.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onUp = this.onUp.bind(this);
  }

  onDown({ target, clientX, clientY }) {
    const { rotation } = this.props;
    const { x, y, width, height } = target.getBoundingClientRect();
    const position = {
      x: x + width / 2,
      y: y + height / 2,
    };

    const cursorRotation = toClockDegree(
      cartesianRotation(position, { x: clientX, y: clientY }),
    );

    const rotationDiff = cursorRotation - limitTo360(rotation);

    this.setState({
      currentTurn: Math.floor((rotation + rotationDiff) / 360),
      cursorRotation,
      active: true,
      rotationDiff,
      position,
    });

    this.props.editableState.begin();
  }

  onMove(diff, ev) {
    const { editableState, object, currentTime } = this.props;
    const {
      position,
      rotationDiff,
      currentTurn,
      cursorRotation: currentRotation,
    } = this.state;

    const pointer = {
      x: ev.clientX,
      y: ev.clientY,
    };

    const objects = editableState.objects.map(obj => {
      if (obj.id !== object.id) {
        return obj;
      }

      const cursorRotation = toClockDegree(
        cartesianRotation(position, pointer),
      );
      const nextTurn = getCurrentTurn(
        currentTurn,
        currentRotation,
        cursorRotation,
      );
      const rotation = applyTurn(nextTurn, cursorRotation);

      this.setState({ cursorRotation, currentTurn: nextTurn });

      return obj.setRotation(
        applyRotationDiff(rotation, rotationDiff),
        currentTime,
      );
    });

    editableState.updateObjects(objects);
  }

  onUp() {
    const { rotation, editableState } = this.props;
    this.setState({
      active: false,
      rotationDiff: 0,
      currentTurn: Math.floor(rotation / 360),
    });

    editableState.commit();
  }

  render() {
    const { classes, scale, rotation } = this.props;
    const { active } = this.state;
    return (
      <div className={classes.container}>
        <MovableComponent
          angle={rotation}
          scale={scale}
          onDown={this.onDown}
          onMove={this.onMove}
          onUp={this.onUp}
        >
          {movableProps => (
            <div className={classes.roundedBox} {...movableProps} />
          )}
        </MovableComponent>

        {active && (
          <>
            <div
              className={classes.needle}
              style={{
                transform: `rotate(${rotation}deg) translate(${BOX_RADIUS}px, 0)`,
              }}
            />
            <div className={classes.indicator}>{rotation}°</div>
          </>
        )}
      </div>
    );
  }
}

RotationBox.propTypes = {
  classes: PropTypes.object.isRequired,
  scale: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
  editableState: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  currentTime: PropTypes.number.isRequired,
};

const styles = {
  container: {
    width: BOX_SIZE,
    height: BOX_SIZE,
  },
  roundedBox: {
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    border: `1px solid ${colors.primary}`,
    borderRadius: '100%',
    cursor: 'move',
    pointerEvents: 'all',
    touchAction: 'auto',
  },
  needle: {
    position: 'absolute',
    height: 30,
    width: 1,
    background: colors.primary,
    transformOrigin: `${BOX_RADIUS}px ${BOX_RADIUS}px`,
    pointerEvents: 'none',
    touchEvents: 'none',
    willChange: 'transform',
  },
  indicator: {
    color: colors.primary,
    position: 'absolute',
    margin: 'auto',
    top: -BOX_RADIUS,
    left: 0,
    right: 0,
    fontSize: 12,
    textAlign: 'center',
  },
};

export default withEditableState(injectSheet(styles)(RotationBox));
