import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles(theme => ({
  floatInputWrapper: {
    position: 'relative',
    display: 'inline-flex',
    width: ({ expanded }) => (expanded ? '100%' : 'auto'),
  },
  button: {
    background: 'none',
    borderStyle: 'solid',
    cursor: 'pointer',
    height: 0,
    outline: 'none',
    position: 'absolute',
    width: 0,
    '&:focus': {
      boxShadow: 'none',
    },
    '&:disabled': {
      cursor: 'default',
      opacity: 0.35,
      '&:active': {
        opacity: 0.35,
      },
    },
    '&:active': {
      opacity: 0.5,
    },
  },
  buttonUp: {
    extend: 'button',
    borderWidth: ({ big }) => (big ? '0 6px 8px 6px' : '0 4px 6px 4px'),
    borderColor: `transparent transparent ${theme.fg} transparent`,
    outline: 'none',
    top: ({ big }) => (big ? 10 : 4.5),
    right: ({ big }) => (big ? 16 : 8),
  },
  buttonDown: {
    extend: 'button',
    borderWidth: ({ big }) => (big ? '8px 6px 0 6px' : '6px 4px 0 4px'),
    borderColor: `${theme.fg} transparent transparent transparent`,
    outline: 'none',
    bottom: ({ big }) => (big ? 10 : 4.5),
    right: ({ big }) => (big ? 16 : 8),
  },
}));

const StepButtonWrapper = ({
  disabled,
  big,
  expanded,
  onStepUp,
  onStepDown,
  children,
}) => {
  const theme = useTheme();
  const classes = useStyles({ big, disabled, expanded, theme });
  return (
    <div className={classes.floatInputWrapper}>
      {children({ disabled, big, expanded })}
      <button
        type="button"
        tabIndex="-1"
        disabled={disabled}
        className={classes.buttonUp}
        onClick={onStepUp}
      />
      <button
        type="button"
        tabIndex="-1"
        disabled={disabled}
        className={classes.buttonDown}
        onClick={onStepDown}
      />
    </div>
  );
};

StepButtonWrapper.propTypes = {
  children: PropTypes.func.isRequired,
  big: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onStepUp: PropTypes.func.isRequired,
  onStepDown: PropTypes.func.isRequired,
};

export default StepButtonWrapper;
