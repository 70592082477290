import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  modalButtons: {
    marginTop: 24,
  },
});

const ModalButtons = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.modalButtons}>{children}</div>;
};

ModalButtons.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalButtons;
