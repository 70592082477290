import { SET_CURRENT_TIME, SET_CLOCK_DURATION } from '../playback';
import { getCurrentPieceID } from '../stage';

export const injectPieceID = (action, pieceID) => ({
  ...action,
  payload: {
    ...action.payload,
    pieceID,
  },
});

export const currentPieceMiddleware = ({ getState }) => next => action => {
  const pieceID = getCurrentPieceID(getState());
  switch (action.type) {
    case SET_CURRENT_TIME:
    case SET_CLOCK_DURATION:
      return next(injectPieceID(action, pieceID));
    default:
      return next(action);
  }
};
