import React from 'react';
import { SaveIcon } from '../icons';
import { IconButtonTertiary } from '../ui';
import { useSavePieces } from '../hooks';

const ButtonSave = () => {
  const savePieces = useSavePieces();

  return <IconButtonTertiary onClick={savePieces} icon={SaveIcon} />;
};

export default ButtonSave;
