import React from 'react';
import PropTypes from 'prop-types';
import { buildAcceptList } from './utils';

const style = { display: 'none' };

const BaseInputUploadAsset = ({ innerRef, assetType, ...props }) => {
  const accept = buildAcceptList(assetType);

  return (
    <input
      style={style}
      type="file"
      accept={accept}
      ref={innerRef}
      {...props}
    />
  );
};

BaseInputUploadAsset.defaultProps = {
  assetType: null,
};

BaseInputUploadAsset.propTypes = {
  assetType: PropTypes.string,
  innerRef: PropTypes.func.isRequired,
};

export default BaseInputUploadAsset;
