import { Record } from 'immutable';
import baseMixin, { baseObject } from './base';
import { TEXT } from './type';

export const fontStyleUnities = {
  fontSize: 'rem',
};

export const arrayStyleTextProperty = [
  'fontSize',
  'color',
  'fontFamily',
  'fontWeight',
  'textAlign',
];

export const getTextStyle = object =>
  arrayStyleTextProperty.reduce(
    (acc, current) =>
      object[current]
        ? Object.assign(acc, {
            [current]: `${object[current]}${
              fontStyleUnities[current] ? fontStyleUnities[current] : ''
            }`,
          })
        : acc,
    {},
  );

export const defaultObject = {
  ...baseObject,
  type: TEXT,
  textContent: '',
  classContent: '',
  fontWeight: 100,
  textAlign: 'left',
  fontFamily: 'sans-serif',
  color: '#000000',
  fontSize: 1,
  backgroundColor: 'transparent',
};

const setterMapText = {
  textContent: 'setTextContent',
  color: 'setFontColor',
  fontFamily: 'setFontFamily',
  backgroundColor: 'setBackgroundColor',
  fontSize: 'setFontSize',
  fontWeight: 'setFontWeight',
  textAlign: 'setTextAlign',
};

class Text extends baseMixin(Record(defaultObject)) {
  setTextContent(contentText) {
    return this.set('textContent', contentText);
  }

  setFontSize(fontSize) {
    return this.set('fontSize', fontSize);
  }

  setFontWeight(fontWeight) {
    return this.set('fontWeight', fontWeight);
  }

  setFontColor(color) {
    return this.set('color', color);
  }

  setTextAlign(textAlign) {
    return this.set('textAlign', textAlign);
  }

  setFontFamily(fontFamily) {
    return this.set('fontFamily', fontFamily);
  }

  apply(properties, currentTime = null) {
    return Object.entries(properties).reduce((object, [property, value]) => {
      if (setterMapText[property] !== undefined) {
        return object[setterMapText[property]](value, currentTime);
      }
      return super.apply(properties, currentTime);
    }, this);
  }
}

export default Text;
