import logo from './logo.svg';
import logoInverse from './logo-inverse.svg';

const getCustomLogo = (customLogo, defaultLogo) => {
  if (customLogo && customLogo.length !== 0) {
    return customLogo;
  }

  return defaultLogo;
};

/* eslint-disable */
export const getLogoURL = () => getCustomLogo(ARTISAN_LOGO_URL, logo);

export const getLogoInverseURL = () =>
  getCustomLogo(ARTISAN_LOGO_INVERSE_URL, logoInverse);
