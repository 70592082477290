import React from 'react';
import { bool, node } from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles(theme => ({
  overlay: {
    alignItems: 'center',
    background: 'rgba(30,30,30,0.50)',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 10000,
  },
  modal: {
    background: theme.bg,
    borderRadius: 8,
    boxShadow:
      '0 24px 24px 0 rgba(0,0,0,0.12), 0 4px 12px 4px rgba(0,0,0,0.08)',
    color: theme.fg,
    padding: ({ noPadding = false }) => (noPadding ? null : 40),
    position: 'relative',
    width: ({ small }) => (small ? 496 : 640),
    boxSizing: 'border-box',
  },
}));

const Modal = ({ children, isVisible, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ ...props, isVisible, theme });
  if (!isVisible) {
    return null;
  }
  return (
    <div className={classes.overlay}>
      <div className={classes.modal}>{children}</div>
    </div>
  );
};

Modal.propTypes = {
  children: node.isRequired,
  isVisible: bool.isRequired,
};

export default Modal;
