import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { applyToPoint } from 'transformation-matrix';
import isFinite from 'lodash/isFinite';
import { colors } from '../../ui';
import { getCurrentTime } from '../../playback';
import { getZoomScale } from '../../stage';
import { getSelectedObjects } from '../../editor';
import {
  extractScaleFromMatrix,
  extractRotationFromMatrix,
} from '../../geometry';
import { getParentMatrix } from '../../selectors';
import EditorContext, { PIVOT } from '../../EditorContext';
import { withEditableState } from '../../EditableContext';
import MovableComponent from '../../MovableComponent';
import RotationBox, { BOX_SIZE } from './RotationBox';
import { getTransformString, CLIPPATH } from '../../models';

const PIVOT_SIZE = BOX_SIZE;
const DOT_SIZE = 6;
const TRIGGER_SIZE = 20;

class Pivot extends Component {
  constructor() {
    super();

    this.onDown = this.onDown.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onUp = this.onUp.bind(this);
  }

  onDown() {
    const { editableState } = this.props;
    editableState.begin();
  }

  onMove({ x, y }) {
    const { editableState, object, currentTime, parentScale } = this.props;
    const objects = editableState.objects.map(obj => {
      if (obj.id !== object.id) {
        return obj;
      }

      const originX = obj.originX + x / obj.scaleX / parentScale.scaleX;
      const originY = obj.originY + y / obj.scaleY / parentScale.scaleY;
      if (!isFinite(originX) || !isFinite(originY)) {
        return obj;
      }

      return obj.setOrigin(originX, originY, currentTime);
    });

    editableState.updateObjects(objects);
  }

  onUp() {
    const { editableState } = this.props;
    editableState.commit();
  }

  render() {
    const {
      left,
      top,
      disabled,
      classes,
      scale,
      object,
      currentTime,
      parentRotation,
    } = this.props;
    if (disabled) {
      return null;
    }

    const transform = getTransformString({ translateX: left, translateY: top });
    return (
      <EditorContext.Consumer>
        {({ isVisibleTool }) =>
          isVisibleTool(PIVOT) && (
            <div className={classes.container} style={{ transform }}>
              <MovableComponent
                angle={parentRotation + object.rotation}
                scale={scale}
                onDown={this.onDown}
                onMove={this.onMove}
                onUp={this.onUp}
              >
                {movableProps => (
                  <div {...movableProps} className={classes.moveTrigger} />
                )}
              </MovableComponent>
              <RotationBox
                object={object}
                left={left}
                top={top}
                scale={scale}
                rotation={object.rotation}
                currentTime={currentTime}
              />
              <div className={classes.dot} />
            </div>
          )
        }
      </EditorContext.Consumer>
    );
  }
}

Pivot.defaultProps = {
  object: {},
};

Pivot.propTypes = {
  editableState: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  object: PropTypes.object,
  scale: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  parentRotation: PropTypes.number.isRequired,
  parentScale: PropTypes.object.isRequired,
};

const styles = {
  container: {
    width: PIVOT_SIZE,
    height: PIVOT_SIZE,
    position: 'absolute',
    borderRadius: '100%',
    touchAction: 'none',
    pointerEvents: 'none',
  },
  dot: {
    left: `calc(50% - ${DOT_SIZE / 2}px)`,
    top: `calc(50% - ${DOT_SIZE / 2}px)`,
    position: 'absolute',
    width: DOT_SIZE,
    height: DOT_SIZE,
    border: `1px solid ${colors.primary}`,
    backgroundColor: 'white',
    boxSizing: 'border-box',
    touchAction: 'none',
    pointerEvents: 'none',
  },
  moveTrigger: {
    left: `calc(50% - ${TRIGGER_SIZE / 2}px)`,
    top: `calc(50% - ${TRIGGER_SIZE / 2}px)`,
    width: TRIGGER_SIZE,
    height: TRIGGER_SIZE,
    zIndex: 10,
    position: 'absolute',
    touchAction: 'auto',
    pointerEvents: 'all',
  },
};

const container = connect((state, { editableState }) => {
  const scale = getZoomScale(state);
  const selectedObjects = getSelectedObjects(state);
  const objects = editableState.hasObjects()
    ? editableState.objects
    : selectedObjects;
  const currentTime = getCurrentTime(state);

  let pivotLeft = 0;
  let pivotTop = 0;

  let object = objects.first();
  let parentRotation = 0;
  let parentScale = { scaleX: 1, scaleY: 1 };
  if (objects.size === 1 && object.type !== CLIPPATH) {
    const parentMatrix = getParentMatrix(state);
    const position = {
      x: object.x + object.originX,
      y: object.y + object.originY,
    };
    const newPoint = applyToPoint(parentMatrix, position);

    parentRotation = extractRotationFromMatrix(parentMatrix);
    parentScale = extractScaleFromMatrix(parentMatrix);
    pivotLeft = newPoint.x;
    pivotTop = newPoint.y;
  } else {
    object = null;
  }

  return {
    left: (pivotLeft - PIVOT_SIZE / scale / 2) * scale,
    top: (pivotTop - PIVOT_SIZE / scale / 2) * scale,
    disabled: !object,
    object,
    scale,
    currentTime,
    parentRotation,
    parentScale,
  };
});

export default withEditableState(container(injectSheet(styles)(Pivot)));
