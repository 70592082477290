import React from 'react';
import { node } from 'prop-types';
import { createUseStyles } from 'react-jss';
import { connect } from 'react-redux';
import { getZoomScale } from '../stage';

const useStyles = createUseStyles({
  zoomBox: {
    transformOrigin: 'top left',
    transform: ({ scale }) => `scale(${scale})`,
  },
});

const ZoomBox = ({ children, ...props }) => {
  const classes = useStyles(props);
  return <div className={classes.zoomBox}>{children}</div>;
};

ZoomBox.propTypes = {
  children: node.isRequired,
};

const container = connect(state => ({ scale: getZoomScale(state) }));

export default container(ZoomBox);
