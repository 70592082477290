import React from 'react';
import { TextField } from '../ui';
import { SearchIcon } from '../icons';
import SearchContext from './SearchContext';

const SearchField = () => (
  <SearchContext.Consumer>
    {({ onChangeName }) => (
      <TextField
        iconRight={SearchIcon}
        onChange={e => onChangeName(e.target.value)}
      />
    )}
  </SearchContext.Consumer>
);

export default SearchField;
