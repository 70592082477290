import React from 'react';
import { createUseStyles } from 'react-jss';
import copy from 'copy-to-clipboard';
import { connect } from 'react-redux';
import { string, func } from 'prop-types';
import { IconButtonTertiary, colors } from '../../ui';
import { CopyIcon } from '../../icons';
import { showSuccessNotification } from '../../Notification';

export const MESSAGE = 'Copied to clipboard!';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  textBox: {
    flex: 1,
    border: `1px solid ${colors.white}`,
    borderRadius: 24,
    padding: [8, 16],
    marginRight: 10,
    backgroundColor: 'transparent',
    fontSize: 16,
  },
});

const ShareLink = ({ shareLink, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <input className={classes.textBox} value={shareLink} readOnly />
      <IconButtonTertiary icon={CopyIcon} onClick={onClick} />
    </div>
  );
};

ShareLink.propTypes = {
  shareLink: string.isRequired,
  onClick: func.isRequired,
};

const container = connect(
  null,
  { showSuccessNotification },
  (stateProps, actions, ownProps) => ({
    ...stateProps,
    ...ownProps,
    onClick: () => {
      copy(ownProps.shareLink);
      actions.showSuccessNotification(MESSAGE);
    },
  }),
);

export default container(ShareLink);
