import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import propTypes from 'prop-types';
import Checkbox from './Checkbox';
import { textRegular, textRegularHeaderLabels } from '../typography';

const useStyles = createUseStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    ...textRegularHeaderLabels,
    color: theme.fg,
    marginLeft: 16,
    opacity: props => (props.disabled ? 0.35 : 1),
    fontSize: props =>
      props.small ? textRegularHeaderLabels.fontSize : textRegular.fontSize,
  },
}));

const CheckboxWithLabel = ({ small, label, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ ...props, small, theme });
  return (
    <label className={classes.wrapper}>
      <Checkbox small={small} {...props} />
      <span className={classes.label}>{label}</span>
    </label>
  );
};
CheckboxWithLabel.propTypes = {
  label: propTypes.string.isRequired,
  small: propTypes.bool,
};

CheckboxWithLabel.defaultProps = {
  small: false,
};

export default CheckboxWithLabel;
