import React from 'react';
import { createUseStyles } from 'react-jss';
import { connect } from 'react-redux';
import { getZoomScale } from '../stage';
import { getCurrentPiece } from '../project';

const useStyles = createUseStyles({
  border: ({ height, width, top, left }) => ({
    border: '1px solid black',
    background: 'transparent',
    height,
    left: left - 1,
    pointerEvents: 'none',
    position: 'absolute',
    top: top - 1,
    width,
  }),
});

const PieceBorder = ({ ...props }) => {
  const classes = useStyles(props);
  return <div className={classes.border} />;
};

const container = connect((state, { top, left }) => {
  const piece = getCurrentPiece(state);
  if (!piece) return { height: 0, width: 0 };

  const scale = getZoomScale(state);
  return {
    height: piece.height * scale + 2,
    left: left * scale,
    top: top * scale,
    width: piece.width * scale + 2,
  };
});

export default container(PieceBorder);
