import React from 'react';
import { createUseStyles } from 'react-jss';
import range from 'lodash/range';
import { connect } from 'react-redux';
import { colors } from '../ui';
import { propertyBoxSize } from './utils';
import { getClockDuration } from '../playback';

const useStyles = createUseStyles({
  box: {
    borderBottom: `1px solid ${colors.black2}`,
    borderRight: `1px solid ${colors.black2}`,
    flexShrink: 0,
    height: '100%',
    width: propertyBoxSize,
  },
});

const TimelinePropertyBoxes = ({ boxesNumber }) => {
  const classes = useStyles();
  return range(boxesNumber).map(key => (
    <div key={key} className={classes.box} />
  ));
};
const container = connect(state => ({
  boxesNumber: Math.ceil(getClockDuration(state) / 200),
}));

export default container(TimelinePropertyBoxes);
