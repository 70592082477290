import React from 'react';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';
import { SharedPlaybackControls } from '../PlaybackControls';
import { TimeMarks, Needle } from '../Timeline';
import { CustomScroll } from '../CustomScroll';

const useStyles = createUseStyles({
  playbackControls: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 470,
  },
  shortTimeline: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: 45,
    borderTop: `1px solid ${colors.black2}`,
  },
  shortTimelineContent: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: 0,
    minWidth: 0,
  },
});

const PlaybackControls = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.playbackControls}>
        <SharedPlaybackControls />
      </div>
      <div className={classes.shortTimeline}>
        <CustomScroll>
          <div className={classes.shortTimelineContent}>
            <TimeMarks scrollLeft={0} noBorder allowOverflow />
            <Needle scrollPosition={0} headOnly />
          </div>
        </CustomScroll>
      </div>
    </>
  );
};

export default PlaybackControls;
