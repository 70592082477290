import React from 'react';
import { connect } from 'react-redux';
import { number, node } from 'prop-types';
import { createUseStyles } from 'react-jss';
import { getZoomScale } from '../stage';
import { getTransformString } from '../models';

const useStyles = createUseStyles({
  pieceOffset: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
});

const PieceOffset = ({ left, top, children }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.pieceOffset}
      style={{
        transform: getTransformString({ translateX: left, translateY: top }),
      }}
    >
      {children}
    </div>
  );
};

PieceOffset.defaultProps = {
  left: 0,
  top: 0,
};

PieceOffset.propTypes = {
  left: number,
  top: number,
  children: node.isRequired,
};

const container = connect((state, { left, top }) => {
  const scale = getZoomScale(state);
  return {
    left: left * scale,
    top: top * scale,
  };
});

export default container(PieceOffset);
