import React from 'react';
import { Map } from 'immutable';

export const getDefaultState = () => ({
  objects: Map(),
});

const Context = React.createContext();

export { Context };

export const { Consumer } = Context;

export function withEditableState(Component) {
  return function WithContextComponent(props) {
    return (
      <Context.Consumer>
        {state => <Component editableState={state} {...props} />}
      </Context.Consumer>
    );
  };
}

export default Context;
