import { Map } from 'immutable';
import * as types from './types';
import { createReducer } from '../utils';
import {
  REMOVE_KEYFRAMES,
  REMOVE_OBJECTS,
  REQUEST_REMOVE_COLLECTION,
} from '../project/types';

const setSelectedKeyframes = ({ keyframes }) => () => Map(keyframes);

const selectKeyframeOnly = ({ objectID, keyframes }) => () =>
  keyframes.reduce(
    (selectedKeyframes, keyframe) =>
      selectedKeyframes.set(keyframe.id, [objectID]),
    Map(),
  );

const addKeyframeToSelection = ({ objectID, keyframes }) => state =>
  keyframes.reduce(
    (selectedKeyframes, keyframe) =>
      selectedKeyframes.set(keyframe.id, [objectID]),
    state,
  );

const removeKeyframeSelectionFromObject = ({ ids }) => state =>
  ids.reduce(
    (newState, removedID) =>
      newState.filterNot(([objectID]) => removedID === objectID),
    state,
  );

const removeKeyframes = ({ keyframes }) => oldState =>
  keyframes.reduce((state, path, id) => state.delete(id), oldState);

const removeKeyframeFromSelection = ({ keyframes }) => state =>
  keyframes.reduce(
    (selectedKeyframes, keyframe) => selectedKeyframes.delete(keyframe.id),
    state,
  );

const clearKeyframesSelection = () => () => Map({});

export const selectionReducer = createReducer(Map(), {
  [types.SELECT_KEYFRAMES_ONLY]: selectKeyframeOnly,
  [types.SET_SELECTED_KEYFRAMES]: setSelectedKeyframes,
  [types.ADD_KEYFRAMES_TO_SELECTION]: addKeyframeToSelection,
  [types.REMOVE_KEYFRAMES_FROM_SELECTION]: removeKeyframeFromSelection,
  [REMOVE_KEYFRAMES]: removeKeyframes,
  [REQUEST_REMOVE_COLLECTION]: clearKeyframesSelection,
  [types.CLEAR_OBJECTS_KEYFRAMES_SELECTION]: removeKeyframeSelectionFromObject,
  [types.CLEAR_KEYFRAMES_SELECTION]: clearKeyframesSelection,
  [REMOVE_OBJECTS]: removeKeyframeSelectionFromObject,
});

export default selectionReducer;
