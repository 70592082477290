import jwtDecode from 'jwt-decode';

export const KEY_ACCESS_TOKEN = 'accessToken';

export const KEY_REFRESH_TOKEN = 'refreshToken';

export const setAccessToken = token =>
  localStorage.setItem(KEY_ACCESS_TOKEN, token);

const setRefreshToken = token => localStorage.setItem(KEY_REFRESH_TOKEN, token);

export const setAuthStorage = (accessToken, refreshToken) => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
};

const getValue = key => localStorage.getItem(key) || '';

export const getAccessToken = () => getValue(KEY_ACCESS_TOKEN);

export const getRefreshToken = () => getValue(KEY_REFRESH_TOKEN);

export const isTokenExpired = token => {
  try {
    const now = Date.now() / 1000;
    const { exp } = jwtDecode(token);
    return exp - 2 < now;
  } catch (err) {
    return true;
  }
};

export const isTokenValid = token => token && !isTokenExpired(token);

export const isAuthenticated = () => isTokenValid(getAccessToken());

export const isRefreshTokenNearbyToExpire = (token = getRefreshToken()) => {
  try {
    const now = Date.now() / 1000 + 28800;
    const { exp } = jwtDecode(token);
    return exp - 2 < now;
  } catch (err) {
    return true;
  }
};

export const clearAuthStorage = () => {
  localStorage.removeItem(KEY_ACCESS_TOKEN);
  localStorage.removeItem(KEY_REFRESH_TOKEN);
};
