import React from 'react';
import { node } from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  actions: {
    display: 'flex',
    maxWidth: 272,
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
});

const TopbarActions = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.actions}>{children}</div>;
};

TopbarActions.propTypes = {
  children: node.isRequired,
};

export default TopbarActions;
