import React from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { explicitNull } from 'airbnb-prop-types';
import CardColor from '../CardColor/CardColor';
import colors from '../colors';

const useStyles = createUseStyles({
  defaultPallete: {
    width: 168,
    height: 34,
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const defaultListColors = [
  { color: null, id: 'no-color' },
  { color: colors.black1, id: 'black-1' },
  { color: `#7D7D7D`, id: 'grey' },
  { color: colors.white, id: 'white' },
];

const DefaultPallete = ({ onChangeColor, color }) => {
  const classes = useStyles();

  return (
    <div className={classes.defaultPallete}>
      {defaultListColors.map(el => (
        <CardColor
          color={el.color}
          isSelected={color === el.color}
          onChangeColor={onChangeColor}
          key={el.id}
        />
      ))}
    </div>
  );
};

DefaultPallete.defaultProps = {
  color: null,
};

DefaultPallete.propTypes = {
  onChangeColor: PropTypes.func.isRequired,
  color: PropTypes.oneOfType([PropTypes.string, explicitNull]),
};

export default DefaultPallete;
