import React from 'react';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';
import { ArrowDownIcon } from '../icons';

const useStyles = createUseStyles({
  overlay: {
    position: 'absolute',
    minHeight: '200px',
    height: '100%',
    width: '100%',
    backgroundColor: colors.black1,
    zIndex: 100,
    opacity: 0.9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& svg': {
      width: 20,
      fill: colors.success,
    },
  },
});

const DropZoneOverlay = () => {
  const classes = useStyles();
  return (
    <div className={classes.overlay}>
      <div className={classes.title}>
        <span>Drop files here</span>
        <ArrowDownIcon />
      </div>
    </div>
  );
};

export default DropZoneOverlay;
