import { Record } from 'immutable';
import { createReducer } from '../utils';
import * as types from './types';

export const PlaybackModel = Record({
  isPlaying: false,
  repeatMode: false,
  currentTime: 0,
  clockDuration: 0,
});

const play = () => state => state.set('isPlaying', true);

const pause = () => state => state.set('isPlaying', false);

const setCurrentTime = ({ currentTime }) => state =>
  state.set('currentTime', currentTime);

const setClockDuration = ({ clockDuration }) => state =>
  state.set('clockDuration', clockDuration);

const toggleRepeatMode = () => state =>
  state.update('repeatMode', repeatMode => !repeatMode);

export const playbackReducer = createReducer(PlaybackModel(), {
  [types.SET_CURRENT_TIME]: setCurrentTime,
  [types.SET_CLOCK_DURATION]: setClockDuration,
  [types.TOGGLE_REPEAT_MODE]: toggleRepeatMode,
  [types.PLAY]: play,
  [types.PAUSE]: pause,
});

export default playbackReducer;
