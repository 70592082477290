import React from 'react';
import { createUseStyles } from 'react-jss';
import { connect } from 'react-redux';
import { node } from 'prop-types';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import DropzoneOverlay from './DropzoneOverlay';
import { setAssetsToUpload } from './actions';
import { showErrorNotification } from '../Notification';
import { getCurrentProjectID } from '../project';

const useStyles = createUseStyles({
  dropzone: {
    flexGrow: 1,
    position: 'relative',
  },
});

export const SUPPORTED_FILE_TYPES = ['image/jpeg', 'image/png', 'video/mp4'];
export const FILE_UPLOAD_ERROR = 'Some files are not supported!';

const Dropzone = ({ children, ...actions }) => {
  const classes = useStyles();
  const [isOver, drop] = useDrop({
    accept: NativeTypes.FILE,
    drop({ files }) {
      const filesToUpload = files.filter(
        file => SUPPORTED_FILE_TYPES.indexOf(file.type) > -1,
      );

      if (files.length !== filesToUpload.length) {
        actions.showError(FILE_UPLOAD_ERROR);
      }

      actions.uploadFiles(filesToUpload);
    },
    collect(monitor) {
      return monitor.isOver();
    },
  });

  return (
    <div ref={drop} className={classes.dropzone}>
      {isOver && <DropzoneOverlay />}
      {children}
    </div>
  );
};

Dropzone.propTypes = {
  children: node.isRequired,
};

const container = connect(
  state => ({
    projectID: getCurrentProjectID(state),
  }),
  { showErrorNotification, setAssetsToUpload },
  ({ projectID }, actions, props) => ({
    ...props,
    showError(message) {
      actions.showErrorNotification(message);
    },
    uploadFiles(filelist) {
      actions.setAssetsToUpload({
        collectionPath: `/${projectID}`,
        filelist,
      });
    },
  }),
);

export default container(Dropzone);
