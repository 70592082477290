import React from 'react';
import { WhiteTheme } from '../ui';
import HeaderProjectList from './HeaderProjectList';
import Container from './Container';
import ListProjects from './ListProjects';
import ModalCreateProject from './ModalCreateProject';
import View from './View';
import Notification from '../Notification';
import SearchContext from './SearchContext';

const ViewProjects = () => (
  <WhiteTheme>
    <View>
      <SearchContext>
        <HeaderProjectList />
        <Container>
          <SearchContext.Consumer>
            {({ name, searching }) => (
              <ListProjects query={{ name, searching }} />
            )}
          </SearchContext.Consumer>
          <ModalCreateProject />
        </Container>
      </SearchContext>
      <Notification />
    </View>
  </WhiteTheme>
);

export default ViewProjects;
