import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import { Formik, Form, Field } from 'formik';
import {
  colors,
  ButtonPrimary,
  ButtonTertiary,
  ModalButtons,
  ModalSpacer,
  TextField,
  CheckboxWithLabel,
} from '../../ui';
import { SaveIcon } from '../../icons';
import {
  showSuccessNotification,
  showErrorNotification,
} from '../../Notification';
import { getCurrentProjectID, getObjectByID } from '../../project';
import { getSelectedKeyframes } from '../../selectors';
import { CustomScroll } from '../../CustomScroll';
import { ALL_PROPERTIES, PROPERTIES_LABEL, isDefined } from '../../models';
import { animations } from '../../api';

export const getDefinedProperties = keyframes => {
  if (!keyframes.size) {
    return {};
  }

  const keyframe = keyframes.first();
  return ALL_PROPERTIES.filter(prop => isDefined(keyframe.values[prop])).reduce(
    (currentProperties, prop) => ({
      ...currentProperties,
      [prop]: true,
    }),
    {},
  );
};

export const buildKeyframesList = (keyframes, selectedProperties) => {
  const initialTime = keyframes.reduce(
    (curr, keyframe) => Math.min(curr, parseInt(keyframe.time, 10)),
    Infinity,
  );

  return keyframes
    .reduce(
      (currList, keyframe) =>
        currList.push(
          Map({
            time: parseInt(keyframe.time, 10) - initialTime,
            timingFunction: keyframe.timingFunction,
            values: keyframe.values
              .toMap()
              .mapEntries(([property, originalValue]) => [
                property,
                selectedProperties[property] === true ? originalValue : null,
              ]),
          }),
        ),
      List(),
    )
    .toJS();
};

const useStyles = createUseStyles({
  title: {
    display: 'inline-block',
    fontSize: 16,
    letterSpacing: 0.47,
    marginBottom: 8,
  },
  properties: {
    width: '100%',
  },
  spacer: {
    width: '100%',
    height: 2,
    backgroundColor: colors.black2,
    margin: [16, 0],
  },
  propertyError: {
    color: colors.errorDark,
  },
  propertyCheckbox: {
    marginBottom: 16,
  },
  information: {
    fontSize: 16,
    margin: [16, 0],
  },
});

const SaveAnimationForm = ({ hide, initialValues, onSuccess, onError }) => {
  const classes = useStyles();
  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        const errors = {};

        const propertieValues = Object.values(values.properties);
        if (!propertieValues.some(value => value === true)) {
          errors.properties = 'Please select at least one property to save';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        const { animationName, projectUID, objectType, properties } = values;
        const keyframes = buildKeyframesList(values.keyframes, properties);

        animations
          .saveAnimation({
            name: animationName,
            projectUID,
            objectType,
            keyframes,
          })
          .then(() => {
            onSuccess();
            hide();
          })
          .catch(() => {
            onError();
          });
      }}
    >
      {({ errors, isSubmitting, isValid, dirty, values, setValues }) => (
        <Form>
          <Field
            name="animationName"
            validate={value => {
              if (!value) {
                return 'Required';
              }

              return undefined;
            }}
          >
            {({ field }) => (
              <TextField
                disabled={isSubmitting}
                error={errors.animationName}
                autoFocus
                required
                expanded
                {...field}
              />
            )}
          </Field>
          <div className={classes.spacer} />
          <p className={classes.information}>
            The following properties will be saved.
            <br />
            {errors.properties && (
              <span className={classes.propertyError}>{errors.properties}</span>
            )}
          </p>
          <CustomScroll autoHeight autoHeightMax={450}>
            <div className={classes.properties}>
              {Object.entries(values.properties).map(([property, checked]) => (
                <div className={classes.propertyCheckbox} key={property}>
                  <CheckboxWithLabel
                    label={PROPERTIES_LABEL[property]}
                    checked={checked}
                    onChange={value =>
                      setValues({
                        ...values,
                        properties: {
                          ...values.properties,
                          [property]: value,
                        },
                      })
                    }
                  />
                </div>
              ))}
            </div>
          </CustomScroll>
          <ModalButtons>
            <ButtonPrimary
              type="submit"
              extended
              label="Save animation"
              disabled={!isValid || isSubmitting || !dirty}
              icon={SaveIcon}
            />
            <ModalSpacer />
            <ButtonTertiary
              onClick={event => {
                event.preventDefault();
                hide();
              }}
              label="Cancel"
              extended
            />
          </ModalButtons>
        </Form>
      )}
    </Formik>
  );
};

SaveAnimationForm.propTypes = {
  hide: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    animationName: PropTypes.string,
    projectUID: PropTypes.string,
    properties: PropTypes.object,
    keyframes: PropTypes.object,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export const SUCCESS_MESSAGE = 'Animation saved successfully!';

export const ERROR_MESSAGE =
  'Could not save current keyframes. Try again later.';

export const buildInitialValues = (projectUID, objectType, keyframes) => ({
  animationName: '',
  projectUID,
  objectType,
  properties: getDefinedProperties(keyframes),
  keyframes,
});

const withRedux = connect(
  state => {
    const projectID = getCurrentProjectID(state);
    const selectedKeyframesList = List(getSelectedKeyframes(state));

    if (selectedKeyframesList.size !== 1) {
      return { initialValues: {} };
    }

    const [objectID, keyframes] = selectedKeyframesList.first();
    const object = getObjectByID(state, objectID) || {};

    return {
      initialValues: buildInitialValues(projectID, object.type, keyframes),
    };
  },
  dispatch => ({
    onSuccess: () => dispatch(showSuccessNotification(SUCCESS_MESSAGE)),
    onError: () => dispatch(showErrorNotification(ERROR_MESSAGE)),
  }),
);

export default compose(withRedux)(SaveAnimationForm);
