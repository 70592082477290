import React from 'react';
import { object } from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors } from '../../ui';

const useStyles = createUseStyles({
  errorList: {
    listStyle: 'none',
    marginTop: 16,
    marginBottom: 16,
  },
  error: {
    fontSize: 12,
    letterSpacing: 0.35,
    color: colors.errorDark,
  },
});

const ErrorList = ({ error }) => {
  const classes = useStyles();
  return (
    <ul className={classes.errorList}>
      {Object.keys(error).map(key => (
        <li key={key} className={classes.error}>
          {error[key]}
        </li>
      ))}
    </ul>
  );
};

ErrorList.propTypes = {
  error: object.isRequired,
};

export default ErrorList;
