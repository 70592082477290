import React, { Component } from 'react';
import { bool, object, func, number } from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { MarkIcon } from '../icons';
import { colors } from '../ui';
import { getClockDuration, getCurrentTime, setCurrentTime } from '../playback';
import { time2px } from './utils';
import MovableComponent from './MovableComponent';

const buildStylePosition = position => {
  const transform = `translateX(${position || 0}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
};

class Needle extends Component {
  constructor() {
    super();

    this.onMove = this.onMove.bind(this);
    this.evaluateTime = this.evaluateTime.bind(this);
  }

  onMove(deltaTime) {
    if (!deltaTime) return;
    this.props.setCurrentTime(this.evaluateTime(deltaTime));
  }

  evaluateTime(delta) {
    const { currentTime, duration } = this.props;
    const newTime = currentTime + delta;
    if (newTime < 0) return 0;
    if (newTime > duration) return duration;
    return newTime;
  }

  render() {
    const { classes, position, headOnly } = this.props;

    if (headOnly) {
      return (
        <MovableComponent onMove={this.onMove}>
          {movableProps => (
            <div
              className={classes.head}
              style={buildStylePosition(position)}
              {...movableProps}
            >
              <MarkIcon />
            </div>
          )}
        </MovableComponent>
      );
    }

    return (
      <div className={classes.needle} style={buildStylePosition(position)}>
        <MovableComponent onMove={this.onMove}>
          {movableProps => (
            <div className={classes.head} {...movableProps}>
              <MarkIcon />
            </div>
          )}
        </MovableComponent>
      </div>
    );
  }
}

Needle.defaultProps = {
  headOnly: false,
};

Needle.propTypes = {
  classes: object.isRequired,
  currentTime: number.isRequired,
  duration: number.isRequired,
  setCurrentTime: func.isRequired,
  position: number.isRequired,
  headOnly: bool,
};

const container = connect(
  (state, { scrollPosition }) => {
    const currentTime = getCurrentTime(state);
    const position = time2px(currentTime) - scrollPosition;

    return {
      position,
      scrollPosition,
      currentTime,
      duration: getClockDuration(state),
    };
  },
  { setCurrentTime },
);

const styles = injectSheet({
  needle: {
    background: colors.error,
    cursor: 'pointer',
    height: '100%',
    position: 'absolute',
    marginTop: 10,
    width: 2,
    zIndex: 1,
    pointerEvents: 'none',
    touchAction: 'none',
  },
  head: {
    fill: colors.white,
    marginTop: ({ headOnly }) => (headOnly ? 10 : 0),
    left: -9.8,
    position: 'absolute',
    width: 21.4,
    height: 16,
    pointerEvents: 'all',
  },
});

export default container(styles(Needle));
