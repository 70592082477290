import { isProduction } from '../utils';
import request from '../http';
import { keyDecoder } from './utils';

export const url = path => {
  const scheme = isProduction() ? 'https' : 'http';
  /* eslint-disable-next-line  */
  return `${scheme}://${SERVER_NAME_ACCOUNTS}${path}`;
};

export const requestTokenDecoder = ({ access, refresh }) => ({
  accessToken: access,
  refreshToken: refresh,
});

const formatError = errors => errors.join(' ');

export const requestTokenErrorDecoder = data => {
  const nonFieldErrorsKey = 'non_field_errors';
  const nonFieldErrors = data[nonFieldErrorsKey];

  const errors = {
    error: nonFieldErrors ? formatError(data[nonFieldErrorsKey]) : undefined,
  };

  const keys = Object.keys(data).filter(key => key !== nonFieldErrorsKey);
  return keys.reduce(
    (curr, key) => ({
      ...curr,
      [key]: formatError(data[key]),
    }),
    errors,
  );
};

export const refreshTokenDecoder = keyDecoder('access');

export default {
  requestToken: (email, password) =>
    request(
      {
        url: url('/token'),
        method: 'POST',
        data: { email, password },
      },
      requestTokenDecoder,
      requestTokenErrorDecoder,
    ),

  refreshToken: refreshToken =>
    request(
      {
        url: url('/refresh'),
        method: 'POST',
        data: { refresh: refreshToken },
      },
      refreshTokenDecoder,
    ),
};
