import React from 'react';
import { createUseStyles } from 'react-jss';
import { string } from 'prop-types';
import { colors } from '../ui';
import { FolderIcon } from '../icons';

const useStyles = createUseStyles({
  collectionIcon: {
    width: 16,
    marginRight: 8,
    fill: colors.white,
  },
  collectionSpan: {
    display: 'flex',
    alignItems: 'center',
  },
});

const CollectionName = ({ name }) => {
  const classes = useStyles();
  return (
    <>
      <span className={classes.collectionSpan}>
        <FolderIcon className={classes.collectionIcon} />
        {name}
      </span>
    </>
  );
};

CollectionName.propTypes = {
  name: string.isRequired,
};

export default CollectionName;
