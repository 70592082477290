import React from 'react';
import { bool } from 'prop-types';
import { connect } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { KeyIcon } from '../icons';
import { getPieceParentID } from '../project';

const useStyles = createUseStyles({
  icon: {
    fill: '#FFD615',
    height: 16,
    width: 16,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: 16,
  },
});

const MasterIcon = ({ isMaster }) => {
  const classes = useStyles();
  if (isMaster) {
    return <KeyIcon className={classes.icon} />;
  }
  return null;
};

MasterIcon.propTypes = {
  isMaster: bool.isRequired,
};

const container = connect((state, { pieceID }) => ({
  isMaster: !getPieceParentID(state, pieceID),
}));

export default container(MasterIcon);
