import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import container from './container';
import { tracker } from '../../api';
import { useRequest } from '../../http';
import { IconButton } from '../../ui';
import { PauseIcon } from '../../icons';

const ButtonPauseTracker = ({ projectID, onPause, onFail }) => {
  const { requesting, request } = useRequest(tracker.pauseTracker(projectID), {
    doRequest: false,
    onSuccess: onPause,
    onFail,
  });
  const handleOnClick = useCallback(() => request(), [request]);

  return (
    <IconButton
      onClick={handleOnClick}
      disabled={requesting}
      icon={PauseIcon}
    />
  );
};

ButtonPauseTracker.propTypes = {
  projectID: PropTypes.string.isRequired,
  onPause: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
};

export default container(ButtonPauseTracker);
