import React from 'react';
import PropTypes from 'prop-types';
import { explicitNull } from 'airbnb-prop-types';
import BaseToggleAspectRatio from './BaseToggleAspectRatio';
import BaseInput from './BaseInput';
import ToggleAspectRatio from './ToggleAspectRatio';
import { PROPERTIES_PRECISION } from '../../models';

const scaleXProperty = 'scaleX';
const scaleXPrecision = PROPERTIES_PRECISION[scaleXProperty];

const scaleYProperty = 'scaleY';
const scaleYPrecision = PROPERTIES_PRECISION[scaleYProperty];

const Scale = ({ scaleX, scaleY, disabled, onChange }) => (
  <BaseToggleAspectRatio>
    {(keepAspectRatio, toggleKeepAspect) => (
      <>
        <BaseInput
          id={scaleXProperty}
          min={0}
          property={scaleXProperty}
          label="W"
          value={scaleX}
          onChange={onChange(scaleXProperty, keepAspectRatio)}
          disabled={disabled}
          precision={scaleXPrecision}
        />
        <ToggleAspectRatio
          keepAspectRatio={keepAspectRatio}
          onClick={toggleKeepAspect}
        />
        <BaseInput
          id={scaleYProperty}
          min={0}
          property={scaleYProperty}
          label="H"
          value={scaleY}
          onChange={onChange(scaleYProperty, keepAspectRatio)}
          disabled={disabled}
          precision={scaleYPrecision}
        />
      </>
    )}
  </BaseToggleAspectRatio>
);

Scale.defaultProps = {
  scaleX: null,
  scaleY: null,
  disabled: true,
  onChange: () => null,
};

Scale.propTypes = {
  scaleX: PropTypes.oneOfType([PropTypes.number, explicitNull]),
  scaleY: PropTypes.oneOfType([PropTypes.number, explicitNull]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Scale;
