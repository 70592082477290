import React from 'react';
import { CheckboxWithLabel } from '../ui';
import EditorContext, { PIVOT } from '../EditorContext';

const TogglePivot = () => (
  <EditorContext.Consumer>
    {({ isVisibleTool, toggleToolVisibility }) => (
      <CheckboxWithLabel
        small
        label="Pivot"
        checked={isVisibleTool(PIVOT)}
        onChange={() => toggleToolVisibility(PIVOT)}
      />
    )}
  </EditorContext.Consumer>
);

export default TogglePivot;
