import React from 'react';
import { connect } from 'react-redux';
import useDropAssets, { dropTargetActions } from './dropTarget';
import AssetEntry from './AssetEntry';
import Content from './Content';
import { RootAssets } from '../Assets';

const UncategorizedAssets = props => {
  const [{ isOver, isDragging }, drop] = useDropAssets(props);

  return (
    <div ref={drop}>
      <Content isOver={isOver} isDragging={isDragging} requiredMinHeight>
        <RootAssets>
          {({ assets }) =>
            !!assets.size && (
              <>
                {assets.map(asset => (
                  <AssetEntry key={asset.path} asset={asset} />
                ))}
              </>
            )
          }
        </RootAssets>
      </Content>
    </div>
  );
};

const container = connect(null, dropTargetActions);

export default container(UncategorizedAssets);
