import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { bool, func, string } from 'prop-types';
import { CloseIcon, ReplayIcon } from '../../icons';
import { ButtonTertiary, IconButtonTertiary } from '../../ui';
import ConnectedPiece from '../ConnectedPiece';

const closeButtonStyle = { position: 'absolute', right: 32, top: 24 };

const useStyles = createUseStyles({
  backdrop: {
    alignItems: 'center',
    background: 'rgba(30,30,30,0.95)',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 10000,
    flexWrap: 'wrap',
    overflow: 'auto',
  },
  spacer: {
    flexBasis: '100%',
    height: 8,
  },
  pieceWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'center',
    justifyContent: 'center',
  },
});

const ModalPreview = ({ isVisible, onClick, pieceID }) => {
  const classes = useStyles();
  const [playing, setPlaying] = useState(true);
  const replay = event => {
    event.preventDefault();
    event.stopPropagation();
    setPlaying(false);

    setTimeout(() => {
      setPlaying(true);
    });
  };

  if (!isVisible) {
    return false;
  }

  return (
    <div
      role="button"
      tabIndex="-1"
      className={classes.backdrop}
      onClick={onClick}
    >
      <ButtonTertiary icon={CloseIcon} label="Close" style={closeButtonStyle} />
      <div className={classes.pieceWrapper}>
        <ConnectedPiece
          pieceID={pieceID}
          isObjectsVisible={playing}
          loaded={false}
        />
        <div className={classes.spacer} />
        <IconButtonTertiary icon={ReplayIcon} onClick={replay} />
      </div>
    </div>
  );
};

ModalPreview.propTypes = {
  isVisible: bool.isRequired,
  onClick: func.isRequired,
  pieceID: string.isRequired,
};

export default ModalPreview;
