import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import AssetIcon from './AssetIcon';
import { colors } from '../../ui';
import { Filename } from '../../Assets';
import ModalAssetChanger, { OpenModalButton } from '../ModalAssetChanger';

const useStyles = createUseStyles({
  assetBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 40,
    backgroundColor: colors.black2,
    marginTop: 8,
    marginBottom: 8,
  },
});

const Asset = ({ asset, object, disabled }) => {
  const classes = useStyles();

  if (disabled) {
    return null;
  }

  return (
    <div className={classes.assetBox}>
      <ModalAssetChanger currentAsset={asset} objectID={object.id} />
      <AssetIcon asset={asset} />
      <Filename filename={asset.filename} />
      <OpenModalButton disabled={disabled} />
    </div>
  );
};

Asset.propTypes = {
  asset: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  object: PropTypes.object.isRequired,
};

export default Asset;
