import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import * as icons from '../../icons';
import colors from '../colors';
import * as typography from '../typography';
import IconButtonTertiary from '../Buttons/IconButtonTertiary';

const useStyles = createUseStyles({
  SnackBar: {
    ...typography.textRegular,
    padding: [14, 16],
    boxShadow: '0 8px 8px 0 rgba(0,0,0,0.16), 0 0 4px 0 rgba(0,0,0,0.08)',
    borderRadius: 8,
    display: 'inline-flex',
    position: 'fixed',
    left: '50%',
    bottom: 50,
    transform: 'translateX(-50%)',
    zIndex: 10001,
    background: ({ error, success }) => {
      if (error) {
        return colors.error;
      }
      if (success) {
        return colors.success;
      }
      return colors.white;
    },
    color: ({ error, success }) =>
      error || success ? colors.white : colors.black4,
    '& button': {
      height: 'auto',
      width: 'auto',
      marginLeft: 16,
      '& svg': {
        margin: 0,
        fill: ({ error, success }) =>
          error || success ? colors.white : colors.black4,
      },
    },
  },
});

const SnackBar = ({ onClose, text, isVisible, ...props }) => {
  const classes = useStyles(props);
  if (isVisible) {
    return (
      <div className={classes.SnackBar}>
        {text}
        <IconButtonTertiary onClick={onClose} icon={icons.CloseIcon} />
      </div>
    );
  }
  return null;
};

SnackBar.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default SnackBar;
