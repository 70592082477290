import React from 'react';
import PropTypes from 'prop-types';
import Context from './Context';

class Trigger extends React.PureComponent {
  render() {
    const { id } = this.props;
    const { isVisible, showArgs, hideArgs } = this.context.components.get(
      id,
    ) || {
      isVisible: false,
      showArgs: [],
      hideArgs: [],
    };

    return this.props.children({
      isVisible,
      showArgs,
      hideArgs,
      show: (...args) => this.context.show(id, ...args),
      hide: (...args) => this.context.hide(id, ...args),
    });
  }
}

Trigger.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};

Trigger.contextType = Context;

export default Trigger;
