import React from 'react';
import { bool } from 'prop-types';
import { connect } from 'react-redux';
import { MenuItem } from '../../ui';
import { ExportIcon } from '../../icons';
import { getAllPiecesIDs } from '../../project';
import { ModalContext } from './ModalExportPieces';

const ButtonExportPieces = ({ disabled }) => (
  <ModalContext.Trigger>
    {({ show }) => (
      <MenuItem
        icon={ExportIcon}
        disabled={disabled}
        onClick={show}
        label="Export Creatives"
      />
    )}
  </ModalContext.Trigger>
);

ButtonExportPieces.propTypes = {
  disabled: bool.isRequired,
};

const container = connect(state => ({
  disabled: !getAllPiecesIDs(state).length,
}));

export default container(ButtonExportPieces);
