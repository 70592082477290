import { List, Set, Record } from 'immutable';
import { createSelector } from 'reselect';
import { combineReducers } from 'redux-immutable';
import { AssetModel } from '../models';
import { getAssetsSlice } from '../project';
import * as types from './types';

export const UploadEntryModel = Record({
  file: null,
  filename: '',
  localURL: '',
  path: '',
  assetType: '',
});

export const makeUploadEntry = ({
  file = null,
  filename = '',
  localURL = '',
  path = '',
  assetType = '',
} = {}) =>
  UploadEntryModel({
    file,
    filename,
    localURL,
    path,
    assetType,
  });

export const Model = Record({
  assetsToUpload: List(),
  assetToDelete: '',
  selectedAssets: Set(),
});

const setAssetsToUpload = ({ assets }) => state =>
  state.update('assetsToUpload', toUpload => toUpload.push(...assets));

const removeAssetToUpload = ({ localURL }) => state =>
  state.update('assetsToUpload', assets =>
    assets.filter(asset => asset.localURL !== localURL),
  );

const selectAssetOnly = ({ assetPaths }) => state =>
  state.update('selectedAssets', () => Set([...assetPaths]));

const addAssetToSelection = ({ assetPaths }) => state =>
  state.update('selectedAssets', oldState => oldState.concat(...assetPaths));

const removeAssetFromSelection = ({ assetPaths }) => state =>
  state.update('selectedAssets', oldState =>
    oldState.filter(key => assetPaths.indexOf(key) === -1),
  );

const removeAssetDueDeletion = ({ assetPath }) => state =>
  removeAssetFromSelection({ assetPaths: [assetPath] })(state);

const clearAssetsSelection = () => state =>
  state.update('selectedAssets', () => Set());

export const panelReducer = (state = Model(), action) => {
  switch (action.type) {
    case types.SET_ASSETS_TO_UPLOAD:
      return setAssetsToUpload(action.payload)(state);
    case types.REMOVE_ASSET_TO_UPLOAD:
      return removeAssetToUpload(action.payload)(state);
    case types.SELECT_ASSETS_ONLY:
      return selectAssetOnly(action.payload)(state);
    case types.ADD_ASSETS_TO_SELECTION:
      return addAssetToSelection(action.payload)(state);
    case types.REMOVE_ASSETS_FROM_SELECTION:
      return removeAssetFromSelection(action.payload)(state);
    case types.DELETE_ASSET:
      return removeAssetDueDeletion(action.payload)(state);
    case types.CLEAR_ASSETS_SELECTION:
      return clearAssetsSelection()(state);
    default:
      return state;
  }
};

export default combineReducers({
  panel: panelReducer,
});

const getPanelAssets = state => state.get('panelAssets');

const getPanel = state => getPanelAssets(state).get('panel');

export const getAssetsToUpload = state => getPanel(state).get('assetsToUpload');

export const getModalDelete = state => getPanelAssets(state).get('modalDelete');

// Selected Assets
export const getSelectedAssetsPath = state =>
  getPanel(state).get('selectedAssets');

export const getSelectedAssets = createSelector(
  [getSelectedAssetsPath, getAssetsSlice],
  (selectedAssetsPath, assets) =>
    selectedAssetsPath.map(assetPath => assets.get(assetPath, AssetModel())),
);

export const getAssetToPreview = state => {
  const assets = getSelectedAssets(state);
  return assets.size === 1 ? assets.first() : null;
};

export const getAssetsAreSelected = (state, assets) => {
  const selectedAssetsPath = getSelectedAssetsPath(state);
  return assets.every(asset => selectedAssetsPath.has(asset.path));
};

export const getAssetIsSelected = (state, assetPath) =>
  getSelectedAssetsPath(state).has(assetPath);
