import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { DurationInput } from '../ui';
import { setCurrentTime, getCurrentTime, getIsPlaying } from '../playback';
import { getPieceDuration } from '../project';

const useStyles = createUseStyles({
  currentTime: {
    width: 85,
  },
});

const InputCurrentTime = ({ ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.currentTime}>
      <DurationInput expanded {...props} />
    </div>
  );
};

const reduxConnector = connect(
  (state, { match }) => ({
    disabled: getIsPlaying(state),
    max: getPieceDuration(state, match.params.pieceID),
    value: getCurrentTime(state, match.params.pieceID),
  }),
  {
    onChange: setCurrentTime,
  },
  (state, actions) => ({ ...state, ...actions }),
);

export default withRouter(reduxConnector(InputCurrentTime));
