import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { getReversedSelectedChildrens } from '../selectors';
import EntryObject from './EntryObject';

const SelectedListObjects = ({ objectsID }) =>
  objectsID.map(objectID => <EntryObject key={objectID} objectID={objectID} />);

SelectedListObjects.propTypes = {
  objectsID: PropTypes.instanceOf(List).isRequired,
};

const container = connect(state => ({
  objectsID: getReversedSelectedChildrens(state),
}));

export default container(SelectedListObjects);
