import React from 'react';
import { Popover } from '../ui';
import ButtonDuplicateObjects from './ButtonDuplicateObjects';
import ButtonRenameLayer from './ButtonRenameLayer';

const PopoverTimeline = () => (
  <Popover id="popover-timeline">
    <ButtonDuplicateObjects />
    <ButtonRenameLayer />
  </Popover>
);

export default PopoverTimeline;
