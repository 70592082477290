import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  input: {
    margin: [0, 16, 0, 8],
  },
});

const InputContainer = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.input}>{children}</div>;
};

InputContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InputContainer;
