import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, Set } from 'immutable';
import injectSheet from 'react-jss';
import { CustomScroll } from '../CustomScroll';
import { setSelectedKeyframes, setSelectedObjects } from '../editor';
import DragSelect from '../DragSelect';
import { preventDefault } from './utils';

class TimelineRight extends React.Component {
  constructor() {
    super();
    this.scrollContainer = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
  }

  // eslint-disable-next-line
  UNSAFE_componentWillUpdate({ scrollTop }) {
    const instance = this.scrollContainer.current;
    if (!instance) {
      return;
    }

    if (this.props.scrollTop !== scrollTop) {
      instance.scrollTop(scrollTop);
    }
  }

  handleScroll(e) {
    const { onScroll } = this.props;

    // FIXME it will be fixed in v17
    // prevent scroll from children
    // https://github.com/facebook/react/issues/19156
    if (e.target !== e.currentTarget) {
      return;
    }

    onScroll(e);
  }

  render() {
    const {
      classes,
      children,
      scrollLeft,
      scrollTop,
      onSelectedKeyframes,
    } = this.props;

    return (
      <DragSelect
        onSelectedItems={onSelectedKeyframes}
        scrollOffsetTop={scrollTop}
        scrollOffsetLeft={scrollLeft}
        onScroll={(left, top) => {
          const instance = this.scrollContainer.current;
          if (!instance) {
            return;
          }

          instance.scrollLeft(instance.getScrollLeft() + left);
          instance.scrollTop(instance.getScrollTop() + top);
        }}
        rightDeadzone={12}
        bottomDeadzone={12}
        fillColor="rgba(217,217,217,0.10)"
        borderColor="rgba(217,217,217,0.5)"
      >
        <CustomScroll
          scrollRef={this.scrollContainer}
          onScroll={this.handleScroll}
        >
          <div
            role="button"
            tabIndex="-1"
            className={classes.wrapper}
            onKeyDown={preventDefault}
            onKeyUp={preventDefault}
          >
            {children}
          </div>
        </CustomScroll>
      </DragSelect>
    );
  }
}

TimelineRight.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  onSelectedKeyframes: PropTypes.func.isRequired,
  scrollLeft: PropTypes.number.isRequired,
  scrollTop: PropTypes.number.isRequired,
  onScroll: PropTypes.func.isRequired,
};

const container = connect(null, dispatch => ({
  onSelectedKeyframes: (_, items) => {
    let selectedKeyframes = Map();
    selectedKeyframes = items.reduce(
      (stateKeyframes, [keyframeID, objectID]) => {
        return stateKeyframes.set(keyframeID, [objectID]);
      },
      selectedKeyframes,
    );

    dispatch(setSelectedKeyframes(selectedKeyframes));
    const selectedObjects = selectedKeyframes
      .toSetSeq()
      .map(([objectID]) => objectID);
    dispatch(setSelectedObjects(Set(selectedObjects)));
  },
}));

const styles = injectSheet({
  wrapper: {
    flexGrow: 1,
    minHeight: 0,
    minWidth: 0,
  },
});

export default container(styles(TimelineRight));
