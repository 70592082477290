import React, { useEffect, useState } from 'react';
import { object, func } from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import {
  initFromServer,
  requestAssets,
  setCurrentProject,
  unsetProject,
} from '../project';
import {
  updateLastModification,
  startBackgroundTasks,
  stopBackgroundTasks,
} from '../editor';
import { path } from '../App';
import ViewEditor from '../ViewEditor';
import ViewPieces from '../ViewPieces';
import ViewIssues from '../ViewIssues';
import { View, Content } from '../ViewState';
import HeaderViewProject from '../HeaderViewProject';
import PreventDataLoss from './PreventDataLoss';

export const PROJECT_PIECES = gql`
  query projectDetail($projectID: String!) {
    project(id: $projectID) {
      pieces
      guides
      name
      amp
      updatedAt
    }
  }
`;

const ViewProject = ({ match, ...props }) => {
  const [decodeError, setDecodeError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { error, data } = useQuery(PROJECT_PIECES, {
    variables: { projectID: match.params.projectID },
    fetchPolicy: 'no-cache',
  });

  const { projectID } = match.params;

  useEffect(() => {
    props.requestAssets(match.params.projectID);
    props.updateLastModification(null);
    return function cleanup() {
      props.stopBackgroundTasks();
      props.unsetProject();
    };
  }, []);

  useEffect(() => {
    if (!data || !data.project) {
      return;
    }

    const { project } = data;

    try {
      const { name, amp, updatedAt } = project;
      props.setCurrentProject({ id: projectID, name, amp });

      const pieces = JSON.parse(project.pieces);
      const guides = JSON.parse(project.guides);
      props.initFromServer({ pieces, guides, updatedAt, isAMP: amp });
      props.startBackgroundTasks();
      setLoading(false);
    } catch (e) {
      setDecodeError(true);
    }
  }, [data]);

  if (error || decodeError) {
    return (
      <View>
        <HeaderViewProject />
        <Content>
          <p>Something went wrong. Try again later.</p>
        </Content>
      </View>
    );
  }

  if (loading) {
    return (
      <View>
        <HeaderViewProject />
        <Content>
          <p>Loading...</p>
        </Content>
      </View>
    );
  }

  return (
    <View>
      <HeaderViewProject />
      <PreventDataLoss />
      <Content>
        <>
          <Route exact path={path.pieceDetail} component={ViewEditor} />
          <Route exact path={path.pieceList} component={ViewPieces} />
          <Route exact path={path.issueList} component={ViewIssues} />
        </>
      </Content>
    </View>
  );
};

ViewProject.propTypes = {
  match: object.isRequired,
  initFromServer: func.isRequired,
  setCurrentProject: func.isRequired,
  unsetProject: func.isRequired,
  requestAssets: func.isRequired,
  updateLastModification: func.isRequired,
  startBackgroundTasks: func.isRequired,
  stopBackgroundTasks: func.isRequired,
};

const container = connect(null, {
  initFromServer,
  setCurrentProject,
  unsetProject,
  requestAssets,
  updateLastModification,
  startBackgroundTasks,
  stopBackgroundTasks,
});

export default withRouter(container(ViewProject));
