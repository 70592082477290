import React, { Component, createRef } from 'react';
import injectSheet from 'react-jss';
import { explicitNull, or } from 'airbnb-prop-types';
import { object, node, func, bool } from 'prop-types';
import { IconButtonTertiary } from '../Buttons';
import { CloseIcon } from '../../icons';
import colors from '../colors';

class Menu extends Component {
  constructor(props) {
    super(props);
    this.menuRef = createRef();
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutsideHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutsideHandler);
  }

  onClickOutsideHandler(ev) {
    if (this.props.isVisible && !this.menuRef.current.contains(ev.target)) {
      ev.stopPropagation();
      ev.preventDefault();

      this.props.onClose(ev);
    }
  }

  render() {
    const { classes, isVisible, onClose, children } = this.props;

    return (
      isVisible && (
        <div className={classes.container} ref={this.menuRef}>
          <div className={classes.closeContainer}>
            <IconButtonTertiary icon={CloseIcon} onClick={onClose} />
          </div>
          <div className={classes.content}>{children}</div>
        </div>
      )
    );
  }
}

Menu.defaultProps = {
  onClose() {},
  isVisible: false,
  children: null,
};

Menu.propTypes = {
  onClose: func,
  isVisible: bool,
  children: or([node, explicitNull()]),
  classes: object.isRequired,
};

const style = injectSheet({
  container: {
    top: 0,
    right: -17,
    paddingTop: 60,
    borderRadius: 4,
    background: colors.black4,
    width: 234,
    position: 'absolute',
    zIndex: 3,
    overflow: 'hidden',
  },
  closeContainer: {
    background: 'transparent',
    position: 'absolute',
    top: 12,
    right: 16,
  },
  icon: {
    fill: colors.white,
    width: 24,
    height: 24,
  },
});

export default style(Menu);
