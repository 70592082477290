import React from 'react';
import PropTypes from 'prop-types';
import { explicitNull, or } from 'airbnb-prop-types';
import { FloatInput } from '../../ui';

const FontSize = ({ fontSize, disabled, fontSizeChange }) => (
  <FloatInput
    id="fontSize"
    disabled={disabled}
    onChange={fontSizeChange}
    value={fontSize === null ? null : fontSize}
    max={100}
    min={1}
    expanded
    precision={1}
  />
);

FontSize.propTypes = {
  fontSize: or([PropTypes.number, explicitNull()]),
  disabled: PropTypes.bool,
  fontSizeChange: PropTypes.func,
};

FontSize.defaultProps = {
  fontSize: null,
  disabled: true,
  fontSizeChange: () => {},
};

export default FontSize;
