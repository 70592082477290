import React from 'react';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';

const useStyles = createUseStyles({
  headerObjects: {
    borderBottom: `1px solid ${colors.black2}`,
    height: 32,
    flexShrink: 0,
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '32px',
    paddingLeft: 44,
  },
});

const HeaderObjects = () => {
  const classes = useStyles();
  return <div className={classes.headerObjects}>Layers</div>;
};

export default HeaderObjects;
