import React from 'react';
import { string } from 'prop-types';
import { Types } from '../dnd';
import DragAsset from './DragAsset';
import DragTimelineObjects from './DragTimelineObjects';

const DragItem = props => {
  switch (props.itemType) {
    case Types.ASSETS:
    case Types.COLLECTION:
      return <DragAsset {...props} />;
    case Types.TIMELINE_OBJECT:
      return <DragTimelineObjects {...props} />;
    default:
      return null;
  }
};

DragItem.propTypes = {
  itemType: string.isRequired,
};

export default DragItem;
