import React from 'react';
import { bool, object, instanceOf, string } from 'prop-types';
import { explicitNull, or } from 'airbnb-prop-types';
import { connect } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { List } from 'immutable';
import { Popover, PopoverItem, PopoverTrigger, colors } from '../ui';
import { ArrowDownIcon } from '../icons';
import {
  removeObjectsByPieceID,
  getPieceParentID,
  getPieceByID,
  getAllPieces,
  pieceToString,
  setPieceParentID,
  copyPieceContent,
} from '../project';

const useStyles = createUseStyles({
  button: {
    background: 'transparent',
    fontSize: 12,
    fontWeight: 800,
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.35,
    },
  },
  inheritInfo: {
    fontSize: 12,
    fontWeight: 400,
    display: 'inline-block',
  },
  icon: {
    fill: colors.white,
    width: 8,
    marginLeft: 8,
  },
});

const SelectParentPiece = ({ availableParents, disabled, id, parentPiece }) => {
  const classes = useStyles();
  return (
    <>
      <Popover id={id}>
        {availableParents.map(parent => (
          <PopoverItem
            key={parent.id}
            label={parent.label}
            onClick={parent.onClick}
          />
        ))}
      </Popover>
      <div>
        {parentPiece && (
          <p className={classes.inheritInfo}>Inheriting style from&nbsp;</p>
        )}
        <PopoverTrigger id={id}>
          {openPopover => (
            <button
              type="button"
              className={classes.button}
              disabled={disabled}
              onClick={openPopover}
            >
              {parentPiece ? parentPiece.label : 'Inherit style'}
              <ArrowDownIcon className={classes.icon} />
            </button>
          )}
        </PopoverTrigger>
      </div>
    </>
  );
};

SelectParentPiece.propTypes = {
  availableParents: instanceOf(List).isRequired,
  disabled: bool.isRequired,
  id: string.isRequired,
  parentPiece: or([object.isRequired, explicitNull()]).isRequired,
};

const getAvailableParentsForPiece = (state, pieceID) =>
  getAllPieces(state).filter(piece => piece.id !== pieceID);

const getParentPiece = (state, pieceID) => {
  const parentID = getPieceParentID(state, pieceID);
  const parentPiece = getPieceByID(state, parentID);
  return parentPiece || null;
};

const makeItem = (piece, onClick) => ({
  id: piece.id,
  label: pieceToString(piece),
  onClick,
});

const container = connect(
  (state, { pieceID }) => ({
    availableParents: getAvailableParentsForPiece(state, pieceID),
    parentPiece: getParentPiece(state, pieceID),
    piece: getPieceByID(state, pieceID),
  }),
  { setPieceParentID, removeObjectsByPieceID, copyPieceContent },
  ({ parentPiece, availableParents, piece }, actions, { pieceID }) => ({
    availableParents: availableParents.map(parent =>
      makeItem(parent, () => {
        actions.removeObjectsByPieceID(pieceID);
        actions.setPieceParentID(pieceID, parent.id);
        actions.copyPieceContent(parent, piece);
      }),
    ),
    disabled: availableParents.isEmpty(),
    id: `inherit-${pieceID}`,
    parentPiece: parentPiece && makeItem(parentPiece),
  }),
);

export default container(SelectParentPiece);
