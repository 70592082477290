import { JUMP_START, JUMP_BOTH, JUMP_END, JUMP_NONE } from '../../models';

export const calculateJumps = (steps, stepPosition) => {
  if (JUMP_NONE === stepPosition) {
    return steps - 1;
  }

  if (JUMP_BOTH === stepPosition) {
    return steps + 1;
  }

  return steps;
};

export const stepFunction = (
  steps = 1,
  stepPosition = JUMP_END,
) => progress => {
  let currentStep = Math.floor(progress * steps);
  if ([JUMP_START, JUMP_BOTH].includes(stepPosition)) {
    currentStep += 1;
  }

  if (stepPosition === JUMP_NONE && steps <= 1) {
    throw new Error(
      `AnimationEngine unsupported step position '${JUMP_NONE}' for ${steps} steps`,
    );
  }

  if ((progress * steps) % 1 === 0) {
    currentStep -= 1;
  }

  if (progress >= 0 && currentStep < 0) {
    currentStep = 0;
  }

  const jumps = calculateJumps(steps, stepPosition);

  if (progress <= 1 && currentStep > jumps) {
    currentStep = jumps;
  }

  return currentStep / jumps;
};

export default stepFunction;
