import React from 'react';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';
import Actions from './TopbarActions';
import Tools from './Tools';
import SaveInfo from './SaveInfo';
import ButtonSave from './ButtonSave';

const useStyles = createUseStyles({
  topbar: {
    background: colors.black4,
    display: 'flex',
    flexShrink: 0,
    height: 32,
  },
});

const Topbar = () => {
  const classes = useStyles();
  return (
    <div className={classes.topbar}>
      <Tools />
      <Actions>
        <SaveInfo />
        <ButtonSave />
      </Actions>
    </div>
  );
};

export default Topbar;
