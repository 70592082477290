import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  button: {
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: 16,
    width: 16,
    padding: 0,
    outline: 'none',
    color: 'inherit',
    backgroundColor: 'inherit',
  },
  icon: {
    width: 16,
    height: 16,
  },
});

const IconButton = ({ icon, ...props }) => {
  const classes = useStyles();
  const Icon = icon;
  return (
    <button type="button" className={classes.button} {...props}>
      {icon && <Icon className={classes.icon} />}
    </button>
  );
};

IconButton.propTypes = {
  icon: PropTypes.func,
};

IconButton.defaultProps = {
  icon: null,
};

export default IconButton;
