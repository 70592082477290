import React from 'react';
import { node } from 'prop-types';
import { createUseStyles } from 'react-jss';
import { BlackTheme } from '../ui';
import Notification from '../Notification';

const useStyles = createUseStyles({
  view: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
});

const View = ({ children }) => {
  const classes = useStyles();
  return (
    <BlackTheme>
      <div className={classes.view}>
        {children}
        <Notification />
      </div>
    </BlackTheme>
  );
};

View.propTypes = {
  children: node.isRequired,
};

export default View;
