import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProjectID, getCurrentProjectName } from '../project';

const ValidateProject = ({ projectID, projectName, children }) => {
  if (!projectID || !projectName) {
    return null;
  }

  return children;
};

ValidateProject.defaultProps = {
  project: undefined,
};

ValidateProject.propTypes = {
  project: PropTypes.object,
  children: PropTypes.node.isRequired,
};

const container = connect(state => ({
  projectID: getCurrentProjectID(state),
  projectName: getCurrentProjectName(state),
}));

export default container(ValidateProject);
