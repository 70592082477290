import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  modalSpacer: {
    height: 16,
    width: '100%',
  },
});

const ModalSpacer = () => {
  const classes = useStyles();
  return <div className={classes.modalSpacer} />;
};

export default ModalSpacer;
