import { InsetClipPath } from './inset';
import { INSET } from './types';

export { INSET };
export { buildClipPathStyle, buildInsetStyle } from './style';

export const makeInsetClipPath = ({
  left = 0,
  top = 0,
  width = 0,
  height = 0,
} = {}) =>
  new InsetClipPath({
    values: [left, top, width, height],
  });

export const makeClipPathFromObject = object => {
  if (!object) {
    return null;
  }

  switch (object.type) {
    case INSET:
      return new InsetClipPath({
        values: object.values,
      });
    default:
      return null;
  }
};
