import React from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { or, explicitNull } from 'airbnb-prop-types';
import { createUseStyles } from 'react-jss';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import { Formik, Form } from 'formik';
import { saveAs } from 'file-saver';
import flatten from 'lodash/flatten';
import {
  colors,
  ButtonPrimary,
  ButtonFourth,
  ButtonTertiary,
  Modal,
  ModalHeader,
  ModalContent,
  ModalButtons,
  ModalSpacer,
  Select,
  ItemModel,
  IconButtonPrimary,
  IconButtonSecondary,
} from '../../ui';
import PieceSelector from './PieceSelector';
import BorderPicker from './BorderPicker';
import { createHideableComponent } from '../../ui/hideable';
import { showErrorNotification } from '../../Notification';
import {
  setIsWaitingForEstimative,
  setLoading,
  setPiecesSelected,
  resetExportCreativeTree,
} from '../actions';
import { getProjectFilename, prepareObjectToLoop } from './utils';
import {
  getPiece,
  getAllPiecesIDs,
  getCurrentProjectName,
} from '../../project';
import { zipper } from '../../api';
import { AddIcon, CloseIcon } from '../../icons';
import { CustomScroll } from '../../CustomScroll';
import LoopSelector, { LOOP_OPTIONS } from './LoopSelector';

export const ModalContext = createHideableComponent();

export const ERROR_MESSAGE =
  'Could not export selected pieces. Try again later.';

export const ERROR_MESSAGE_ESTIMATIVE =
  'Could not estimate the size of selected pieces. Try again later.';

export const TEMPLATES = List([
  ItemModel({ label: 'Generic Server', value: 'default' }),
  ItemModel({ label: 'Sizmek', value: 'sizmek' }),
  ItemModel({ label: 'GoogleAD', value: 'googlead' }),
  ItemModel({ label: 'Globo.com', value: 'globo' }),
  ItemModel({ label: 'AMP', value: 'amp' }),
  ItemModel({ label: 'Xandr', value: 'xandr' }),
]);

const defaultTemplate = {
  pieces: [],
  border: 'none',
  looping: LOOP_OPTIONS.get(0),
};

const INITIAL_VALUES = {
  [TEMPLATES.first().value]: { ...defaultTemplate },
};

const getTemplateItemFromValue = value =>
  TEMPLATES.find(item => item.value === value);

const getFilteredTemplates = values =>
  TEMPLATES.filter(item => Object.keys(values).indexOf(item.value) === -1);

export const isValidValues = values =>
  Object.keys(values).length &&
  Object.keys(values).every(key => values[key].pieces.length > 0);

export const validateForm = values => {
  const errors = {};
  if (!isValidValues(values)) {
    errors.empty = true;
    return errors;
  }

  return undefined;
};

export const serializeTemplate = ({ pieces, border, looping }) => ({
  pieces,
  border,
  looping: looping.value,
});

export const serializeTemplates = templates =>
  Object.entries(templates).reduce(
    (currentTemplate, [key, value]) => ({
      ...currentTemplate,
      [key]: serializeTemplate(value),
    }),
    {},
  );

const addValue = ({ values, setValues }) => () => {
  setValues({
    ...values,
    [getFilteredTemplates(values).first().value]: {
      ...defaultTemplate,
    },
  });
};

const changeValue = ({ templateName, setValues, values }) => value => {
  const newValues = values;
  const { pieces, border, looping } = newValues[templateName];
  delete newValues[templateName];

  setValues({ ...newValues, [value.value]: { pieces, border, looping } });
};

const removeValue = ({ templateName, setValues, values }) => () => {
  const newValues = values;
  delete newValues[templateName];
  setValues(newValues);
};

const useStyles = createUseStyles({
  title: {
    display: 'inline-block',
    fontSize: 16,
    letterSpacing: 0.47,
    marginBottom: 8,
  },
  spacer: {
    width: '100%',
    height: 2,
    backgroundColor: colors.black2,
    margin: [25, 0],
  },
  adServer: {
    display: 'flex',
    paddingRight: 15,
  },
  selector: {
    width: '100%',
    marginRight: 16,
  },
  templates: {
    width: '100%',
  },
});

const ModalExportPieces = ({
  getPieces,
  onSubmitError,
  onEstimateError,
  forceVisibility,
  availablePieces,
  currentProjectName,
  setIsWaitingForEstimativeInServer,
  setLoadingPieces,
  setPiecesReceived,
  resetExportFileStructure,
}) => {
  const classes = useStyles();
  const preparePieces = templates => {
    const hasLoop = Object.values(templates).some(
      template => template.looping.value !== null,
    );
    const pieces = getPieces(
      flatten(Object.values(templates).map(template => template.pieces)),
      hasLoop,
    );
    return pieces;
  };
  return (
    <ModalContext.Target hideableOptions={{ isVisible: forceVisibility }}>
      {({ isVisible, hide }) => (
        <Modal isVisible={isVisible}>
          <ModalHeader>Export pieces</ModalHeader>
          <ModalContent>
            <Formik
              initialValues={{ ...INITIAL_VALUES }}
              validate={validateForm}
              onSubmit={(templates, { setSubmitting }) => {
                setSubmitting(true);
                const pieces = preparePieces(templates);
                return zipper
                  .zipPieces({
                    pieces,
                    templates: serializeTemplates(templates),
                  })
                  .then(({ ok, data }) => {
                    setSubmitting(false);

                    if (ok) {
                      hide();
                      resetExportFileStructure();
                      saveAs(data, getProjectFilename(currentProjectName));
                    } else {
                      onSubmitError();
                    }
                  })
                  .catch(onSubmitError);
              }}
            >
              {({ isSubmitting, isValid, dirty, values, setValues }) => (
                <Form>
                  <CustomScroll autoHeight autoHeightMax={450}>
                    <div className={classes.templates}>
                      {Object.keys(values).map((templateName, index) => (
                        <div key={templateName}>
                          <span className={classes.title}>
                            Adserver {index + 1}
                          </span>
                          <div className={classes.adServer}>
                            <div className={classes.selector}>
                              <Select
                                items={getFilteredTemplates(values)}
                                value={getTemplateItemFromValue(templateName)}
                                onChange={changeValue({
                                  templateName,
                                  values,
                                  setValues,
                                })}
                                disabled={
                                  Object.keys(values).length !== index + 1
                                }
                                big
                              />
                            </div>
                            <IconButtonSecondary
                              type="button"
                              icon={CloseIcon}
                              onClick={removeValue({
                                templateName,
                                values,
                                setValues,
                              })}
                            />
                          </div>
                          <PieceSelector
                            templateName={templateName}
                            pieces={availablePieces}
                          />
                          <BorderPicker templateName={templateName} />
                          <LoopSelector templateName={templateName} />
                          {Object.keys(values).length - 1 > index && (
                            <div className={classes.spacer} />
                          )}
                        </div>
                      ))}
                    </div>
                  </CustomScroll>
                  <ModalButtons>
                    <ButtonFourth
                      type="button"
                      extended
                      label="Calculate"
                      disabled={!isValid || isSubmitting || !dirty}
                      onClick={() => {
                        const pieces = preparePieces(values);
                        setLoadingPieces(true);
                        setIsWaitingForEstimativeInServer(true);
                        return zipper
                          .getEstimative({
                            pieces,
                            templates: serializeTemplates(values),
                          })
                          .then(full => {
                            setLoadingPieces(false);
                            const { ok, data } = full;
                            if (ok) {
                              setPiecesReceived(data.folderStructure);
                            } else {
                              onEstimateError();
                            }
                          })
                          .catch(onSubmitError);
                      }}
                    />
                    <div className={classes.spacer} />
                  </ModalButtons>
                  <IconButtonPrimary
                    type="button"
                    icon={AddIcon}
                    onClick={addValue({ setValues, values })}
                    disabled={getFilteredTemplates(values).size === 0}
                  />
                  <ModalButtons>
                    <ButtonPrimary
                      type="submit"
                      extended
                      label="Export"
                      disabled={!isValid || isSubmitting || !dirty}
                    />
                    <ModalSpacer />
                    <ButtonTertiary
                      onClick={event => {
                        event.preventDefault();
                        resetExportFileStructure();
                        hide();
                      }}
                      label="Cancel"
                      extended
                    />
                  </ModalButtons>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </ModalContext.Target>
  );
};

ModalExportPieces.propTypes = {
  availablePieces: arrayOf(String).isRequired,
  forceVisibility: or([bool, explicitNull()]),
  getPieces: func.isRequired,
  onSubmitError: func.isRequired,
  onEstimateError: func.isRequired,
  currentProjectName: string.isRequired,
  setIsWaitingForEstimativeInServer: func.isRequired,
  setLoadingPieces: func.isRequired,
  setPiecesReceived: func.isRequired,
  resetExportFileStructure: func.isRequired,
};

ModalExportPieces.defaultProps = {
  forceVisibility: undefined,
};

const lazyGetPieces = state => (piecesIDs, hasLoop) =>
  piecesIDs.reduce(
    (curr, pieceID) => ({
      ...curr,
      [pieceID]: getPiece(state, pieceID, hasLoop ? prepareObjectToLoop : null),
    }),
    {},
  );

const withRedux = connect(
  state => ({
    getPieces: lazyGetPieces(state),
    availablePieces: getAllPiecesIDs(state),
    currentProjectName: getCurrentProjectName(state),
  }),
  dispatch => ({
    onSubmitError: () => dispatch(showErrorNotification(ERROR_MESSAGE)),
    onEstimateError: () =>
      dispatch(showErrorNotification(ERROR_MESSAGE_ESTIMATIVE)),
    setPiecesReceived: pieces => dispatch(setPiecesSelected(pieces)),
    setLoadingPieces: loading => dispatch(setLoading(loading)),
    setIsWaitingForEstimativeInServer: iswaiting =>
      dispatch(setIsWaitingForEstimative(iswaiting)),
    resetExportFileStructure: () => dispatch(resetExportCreativeTree()),
  }),
);

export default compose(withRedux)(ModalExportPieces);
