import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { tracker, TRACKER_STATUS } from '../../api';
import { useRequest } from '../../http';
import container from './container';
import TrackerInfo from './TrackerInfo';
import ControlButtons from './ControlButtons';

export const TRACKER_INTERVAL = 30000;

export const formatTime = seconds => {
  const hours = Math.floor(seconds / 3600);
  const minutes = `0${Math.floor(seconds / 60) % 60}`.slice(-2);
  return [hours, minutes].join(':');
};

const checkStatus = (status, ...statuses) =>
  statuses.some(expectedStatus => status === expectedStatus);

const TimeTracker = ({ projectID, onTrackerFail, trackerInterval }) => {
  if (!projectID) {
    return null;
  }

  const { loading, data, ok, request } = useRequest(
    tracker.getTracker(projectID),
    { onFail: onTrackerFail },
    [projectID],
  );

  const refetch = useCallback(() => {
    request({});
  }, [request]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.status === TRACKER_STATUS.STARTED) {
      setTimeout(request, trackerInterval);
    }
  }, [data]);

  if (loading || !ok) {
    return null;
  }

  const fancyLabel = formatTime(data.counter);
  const isActive = checkStatus(data.status, TRACKER_STATUS.STARTED);

  return (
    <TrackerInfo isActive={isActive}>
      <ControlButtons
        isActive={isActive}
        refetch={refetch}
        isPlayable={checkStatus(
          data.status,
          TRACKER_STATUS.PAUSED,
          TRACKER_STATUS.STOPPED,
          null,
        )}
        isPausable={checkStatus(data.status, TRACKER_STATUS.STARTED)}
        isStoppable={checkStatus(
          data.status,
          TRACKER_STATUS.STARTED,
          TRACKER_STATUS.PAUSED,
        )}
      />
      <span>{fancyLabel}</span>
    </TrackerInfo>
  );
};

TimeTracker.defaultProps = {
  projectID: null,
  trackerInterval: TRACKER_INTERVAL,
};

TimeTracker.propTypes = {
  projectID: PropTypes.string,
  onTrackerFail: PropTypes.func.isRequired,
  trackerInterval: PropTypes.number,
};

export default container(TimeTracker);
