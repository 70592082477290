import React from 'react';
import { InsetClipPath } from '../icons';
import SidebarTool from './SidebarTool';
import { StageMode } from '../editor';

const ButtonInsetClipPath = () => (
  <SidebarTool tool={StageMode.insetClipPath} icon={InsetClipPath} />
);

export default ButtonInsetClipPath;
