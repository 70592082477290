import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { url } from '../App';

const RedirectToSharedProject = () => {
  const { shareID } = useParams();

  return (
    <Redirect
      to={url.sharedProject({
        shareID,
      })}
    />
  );
};
export default RedirectToSharedProject;
