import React from 'react';
import { createUseStyles } from 'react-jss';
import colors from '../colors';

const useStyles = createUseStyles({
  spacer: {
    background: colors.gray2,
    height: 1,
    marginBottom: 8,
    marginTop: 8,
    width: '100%',
  },
});

const PopoverSpacer = () => {
  const classes = useStyles();
  return <div className={classes.spacer} />;
};

export default PopoverSpacer;
