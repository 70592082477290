import { combineReducers } from 'redux-immutable';
import objects from './reducer-objects';
import assets from './reducer-assets';
import pieces from './reducer-pieces';
import guides from './reducer-guides';
import metadata from './reducer-metadata';

const projectReducer = combineReducers({
  metadata,
  guides,
  assets,
  pieces,
  objects,
});

export default projectReducer;
