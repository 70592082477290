export const getPlaybackSlice = state => state.get('playback');

// Timeline
export const getIsPlaying = state => getPlaybackSlice(state).get('isPlaying');

export const getCurrentTime = state =>
  getPlaybackSlice(state).get('currentTime');

export const getRepeatMode = state => getPlaybackSlice(state).get('repeatMode');

export const getClockDuration = state =>
  getPlaybackSlice(state).get('clockDuration');
