import React from 'react';
import { node } from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    '& > :not(:last-child)': { marginRight: 16 },
  },
});

const WrapperButtons = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.wrapper}>{children}</div>;
};

WrapperButtons.propTypes = {
  children: node.isRequired,
};

export default WrapperButtons;
