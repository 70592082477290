import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { or, explicitNull } from 'airbnb-prop-types';
import { InputColor } from '../../ui';
import WrapperColorPicker from '../../HeaderViewProject/ModalExportPieces/WrapperColorPicker';

const useStyles = createUseStyles({
  color: disabled => ({
    opacity: disabled ? 0.35 : 1,
  }),
});

const Color = ({ color, disabled, fontColorChange }) => {
  const classes = useStyles(disabled);
  const [clickPosition, setClickPosition] = useState({ left: 0, top: 0 });
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const toggleVisibilityColorPicker = useCallback(() => {
    if (!disabled) {
      setIsColorPickerVisible(!isColorPickerVisible);
    }
  }, [isColorPickerVisible, setIsColorPickerVisible]);
  const handleOnClick = useCallback(
    ({ clientX, clientY }) => {
      const getHalfOfScreenSize = window.screen.height / 2;
      const isGreaterThanCenterOfHeightScreen = clientY > getHalfOfScreenSize;
      if (!isGreaterThanCenterOfHeightScreen) {
        setClickPosition({ left: clientX, top: clientY });
        return;
      }
      setClickPosition({ left: clientX, top: getHalfOfScreenSize });
    },
    [setClickPosition],
  );
  return (
    <div className={classes.color}>
      {isColorPickerVisible && (
        <WrapperColorPicker
          position={clickPosition}
          color={!color ? '' : color}
          setColor={fontColorChange}
          closeColorPicker={toggleVisibilityColorPicker}
        />
      )}
      <InputColor
        id="fontColor"
        hasPreview
        onClick={e => {
          toggleVisibilityColorPicker(e);
          handleOnClick(e);
        }}
        value={!color ? '' : color}
        onChange={fontColorChange}
        rounded={false}
        disabled={disabled}
        isSelected
      />
    </div>
  );
};

Color.propTypes = {
  color: or([PropTypes.string, explicitNull()]),
  disabled: PropTypes.bool,
  fontColorChange: PropTypes.func,
};

Color.defaultProps = {
  color: '#000000',
  disabled: true,
  fontColorChange: () => {},
};

export default Color;
