import React from 'react';
import { string } from 'prop-types';
import { PopoverItem } from '../ui';
import LinkToEditPiece from './LinkToEditPiece';

const EditPiece = ({ pieceID }) => (
  <LinkToEditPiece pieceID={pieceID}>
    <PopoverItem label="Editar peça" />
  </LinkToEditPiece>
);

EditPiece.propTypes = {
  pieceID: string.isRequired,
};

export default EditPiece;
