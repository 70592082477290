import React from 'react';
import { connect } from 'react-redux';
import { PlayIcon, PauseIcon } from '../icons';
import { ToolbarButton } from '../ui';
import { play, pause, getIsPlaying } from '../playback';

export default connect(
  state => ({ isPlaying: getIsPlaying(state) }),
  { play, pause },
  ({ isPlaying }, actions) => ({
    icon: isPlaying ? <PauseIcon /> : <PlayIcon />,
    onClick: isPlaying ? actions.pause : actions.play,
  }),
)(ToolbarButton);
