export const buildInsetStyle = clipPath => {
  const { values } = clipPath;

  const left = values[0];
  const top = values[1];
  const width = values[2];
  const height = values[3];
  const right = width + left;
  const bottom = height + top;

  return {
    clipPath: `inset(${top}px calc(100% - ${right}px) calc(100% - ${bottom}px) ${left}px)`,
    // IE rule
    clip: `rect(${top}px ${right}px ${bottom}px ${left}px)`,
  };
};

export const buildClipPathStyle = clipPath => {
  if (!clipPath) {
    return null;
  }

  switch (clipPath.type) {
    case 'inset':
      return buildInsetStyle(clipPath);
    default:
      return null;
  }
};
