import React from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes, { string, bool } from 'prop-types';
import { explicitNull } from 'airbnb-prop-types';
import { LoadingIndicator, colors } from '../../../ui';
import { MAXSIZEOFPIECEINADSERVER } from './constants';

export const isUnityMBPresent = size =>
  size.substring(size.length - 2, size.length) === 'MB';

export const IsBiggerThanMaxSizeOfPieceInServer = size => {
  if (!size) {
    return 0;
  }
  if (isUnityMBPresent(size)) {
    return true;
  }
  return (
    parseFloat(size.substring(0, size.length - 2), 10) >
    MAXSIZEOFPIECEINADSERVER
  );
};

const useStyles = createUseStyles({
  size: sizeOfFile => ({
    color: IsBiggerThanMaxSizeOfPieceInServer(sizeOfFile)
      ? colors.alert
      : colors.white,
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 300,
  }),
});

const DisplayOrLoader = ({ loading, sizeOfFile }) => {
  const classes = useStyles(sizeOfFile);
  if (loading) {
    return <LoadingIndicator size={16} />;
  }
  if (!sizeOfFile) {
    return null;
  }
  return <span className={classes.size}>Size {sizeOfFile}</span>;
};

DisplayOrLoader.propTypes = {
  loading: bool.isRequired,
  sizeOfFile: PropTypes.oneOfType([string, explicitNull()]),
};

DisplayOrLoader.defaultProps = {
  sizeOfFile: null,
};

export default DisplayOrLoader;
