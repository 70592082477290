import { List } from 'immutable';
import quickhull from 'quickhull';
import { Matrix } from 'ml-matrix';
import { compose, rotate, applyToPoints } from 'transformation-matrix';
import BaseBB from './base';
import {
  degreeToRadians,
  radiansToDegree,
  buildCoordinates,
  extractRotationFromMatrix,
} from '../utils';
import { getCoordinatesFromObjects } from './utils';

const HALF_PI = Math.PI / 2;

const buildEdgeAngles = points => {
  const edges = [];
  for (let i = 0; i < points.length - 1; i += 1) {
    const point = points[i];
    const nextPoint = points[i + 1];

    edges.push({
      x: nextPoint.x - point.x,
      y: nextPoint.y - point.y,
    });
  }

  return edges.reduce((angles, edge) => {
    const angle = Math.abs(
      ((Math.atan2(edge.y, edge.x) % HALF_PI) + HALF_PI) % HALF_PI,
    );

    if (!angles.includes(angle)) {
      angles.push(angle);
    }

    return angles;
  }, []);
};

export const getOrientedBoundingRect = (hullPoints, options = {}) => {
  const { preferredAngle = null, parentMatrix } = options;
  const edgeAngles =
    preferredAngle !== null
      ? [
          degreeToRadians(
            preferredAngle + extractRotationFromMatrix(parentMatrix),
          ),
        ]
      : buildEdgeAngles(hullPoints);

  const points2D = hullPoints.reduce(
    (curr, point) => {
      curr[0].push(point.x);
      curr[1].push(point.y);

      return curr;
    },
    [[], []],
  );

  const minBBox = edgeAngles.reduce(
    (curr, angle) => {
      const rotationMatrix = new Matrix([
        [Math.cos(angle), Math.sin(angle)],
        [-Math.sin(angle), Math.cos(angle)],
      ]);
      const pointsMatrix = new Matrix(points2D);
      const rotatedPoints = rotationMatrix.mmul(pointsMatrix).to2DArray();

      const minX = Math.min(...rotatedPoints[0]);
      const maxX = Math.max(...rotatedPoints[0]);
      const minY = Math.min(...rotatedPoints[1]);
      const maxY = Math.max(...rotatedPoints[1]);

      const width = maxX - minX;
      const height = maxY - minY;
      const area = width * height;

      if (area < curr.area) {
        return {
          angle,
          rotationMatrix,
          area,
          width,
          height,
          minX,
          maxX,
          minY,
          maxY,
        };
      }

      return curr;
    },
    {
      angle: 0,
      rotationMatrix: new Matrix(2, 2),
      area: Number.MAX_SAFE_INTEGER,
      width: 0,
      height: 0,
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0,
    },
  );

  const {
    angle,
    rotationMatrix,
    width,
    height,
    minX,
    maxX,
    minY,
    maxY,
  } = minBBox;
  const centerX = (minX + maxX) / 2;
  const centerY = (minY + maxY) / 2;
  const centerMatrix = new Matrix([[centerX, centerY]]);
  const centerPoints = centerMatrix.mmul(rotationMatrix).to2DArray();
  const center = {
    x: centerPoints[0][0],
    y: centerPoints[0][1],
  };
  const left = center.x - width / 2;
  const top = center.y - height / 2;

  return {
    angle,
    left,
    top,
    center,
    width,
    height,
  };
};

class OBB extends BaseBB {
  constructor(params) {
    super(params);
    const angle = params.angle || 0;
    this.angle = angle;
    this.angleDeg = radiansToDegree(angle);
    this.sameObjectsAngle = params.sameObjectsAngle || false;
  }

  static fromPiece() {
    throw new Error('Use Axis-Aligned Bounding Box(AABB) for piece bounds');
  }

  static fromObject(object, options = {}) {
    return OBB.fromObjects(List([object]), options);
  }

  static fromObjects(objects, options = {}) {
    if (!objects.size) {
      return new OBB({});
    }

    const points = getCoordinatesFromObjects(objects, options.parentMatrix);
    const hullPoints = quickhull(points);
    const { angle, left, top, center, width, height } = getOrientedBoundingRect(
      hullPoints,
      options,
    );

    const plainPoints = buildCoordinates({ left, top, width, height });
    const matrix = compose(rotate(angle, center.x, center.y));
    const coordinates = applyToPoints(matrix, plainPoints);

    return new OBB({
      angle,
      left,
      top,
      width,
      height,
      coordinates,
      sameObjectsAngle: options.sameObjectsAngle,
    });
  }
}

export default OBB;
