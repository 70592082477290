import React from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { colors, textRegular } from '../ui';
import { getPieceFormatString } from '../project';

const useStyles = createUseStyles({
  format: {
    ...textRegular,
    color: colors.white,
    display: 'inline-block',
    verticalAlign: 'middle',
    flexGrow: 1,
  },
});

const PieceFormat = ({ format }) => {
  const classes = useStyles();
  return <p className={classes.format}>{format}</p>;
};

PieceFormat.propTypes = {
  format: string.isRequired,
};

const container = connect((state, { pieceID }) => ({
  format: getPieceFormatString(state, pieceID),
}));

export default container(PieceFormat);
