import * as project from './selectors';
import { animateObject } from './animation';
import { DIV, IMG, CLIPPATH, VIDEO, TEXT } from '../models';

const serializePiece = ({
  id,
  parentID,
  duration,
  height,
  width,
  createdAt,
  updatedAt,
}) => ({
  id,
  parentID,
  duration,
  height,
  width,
  createdAt,
  updatedAt,
});

const serializeScalarValue = (currentObj, prop, value) => ({
  [prop]: value,
});

const serializeNonScalarValue = (currentObj, prop, value) => {
  if (!value) {
    return {};
  }

  return {
    [prop]: value.toJS(),
  };
};

const propertiesSerializers = {
  id: serializeScalarValue,
  name: serializeScalarValue,
  type: serializeScalarValue,
  pieceID: serializeScalarValue,
  fullSize: serializeScalarValue,
  inheritance: serializeScalarValue,
  height: serializeScalarValue,
  width: serializeScalarValue,
  left: serializeScalarValue,
  top: serializeScalarValue,
  opacity: serializeScalarValue,
  rotation: serializeScalarValue,
  originX: serializeScalarValue,
  originY: serializeScalarValue,
  translateX: serializeScalarValue,
  translateY: serializeScalarValue,
  scaleX: serializeScalarValue,
  scaleY: serializeScalarValue,
  clipPath: serializeNonScalarValue,
};

const serializeBaseObject = object =>
  Object.entries(propertiesSerializers).reduce(
    (obj, [propKey, serializer]) => ({
      ...obj,
      ...serializer(obj, propKey, object.get(propKey)),
    }),
    {
      keyframes: object.get('keyframes').toList().toJS(),
    },
  );

export const serializeObject = object => {
  const initialObjectState = animateObject(0)(object);
  let serializedObject = serializeBaseObject(initialObjectState);

  const clipPathObject = object.get('clipPathObject');
  if (clipPathObject) {
    const serializedClipPath = serializeObject(clipPathObject);

    serializedObject = {
      ...serializedObject,
      clipPathObject: serializedClipPath,
    };
  }

  switch (object.get('type')) {
    case CLIPPATH:
      return {
        ...serializedObject,
        targetObjectID: object.get('targetObjectID'),
        classContent: object.get('classContent'),
      };
    case DIV:
      return {
        ...serializedObject,
        backgroundColor: object.get('backgroundColor'),
        classContent: object.get('classContent'),
        childrens: object
          .get('childrens')
          .map(child => serializeObject(child))
          .toJS(),
      };
    case TEXT:
      return {
        ...serializedObject,
        color: object.get('color'),
        fontFamily: object.get('fontFamily'),
        textAlign: object.get('textAlign'),
        textContent: object.get('textContent'),
        fontWeight: object.get('fontWeight'),
        fontSize: object.get('fontSize'),
        backgroundColor: object.get('backgroundColor'),
        classContent: object.get('classContent'),
      };
    case VIDEO:
      return {
        ...serializedObject,
        assetPath: object.get('assetPath'),
        muted: object.get('muted'),
        autoPlay: object.get('autoPlay'),
        classContent: object.get('classContent'),
      };
    case IMG:
      return {
        ...serializedObject,
        assetPath: object.get('assetPath'),
        classContent: object.get('classContent'),
      };
    default:
      return serializedObject;
  }
};

const serializer = state => {
  const pieces = project
    .getAllPieces(state)
    .map(piece => {
      return {
        ...serializePiece(piece),
        objects: serializeObject(
          project.getNestedObjectsMap(
            state,
            project.getObjectByID(
              state,
              project.getRootNodeFromPieceID(state, piece.id).objectID,
            ),
          ),
        ),
      };
    })
    .toJS();

  const guides = project.getAllGuides(state).toJS();

  return { pieces, guides };
};

export const getCurrentProject = state => ({
  id: project.getCurrentProjectID(state),
  name: project.getCurrentProjectName(state),
  amp: project.getCurrentProjectIsAMP(state),
  ...serializer(state),
  updatedAt: project.getCurrentProjectUpdatedAt(state),
});

export default serializer;
