import dayjs from 'dayjs';
import { List } from 'immutable';
import { ItemModel } from '../ui';

const MONTH_FORMAT = 'MMMM';

export const buildValue = date => {
  const year = date.get('year');
  const label = `${date.format(MONTH_FORMAT)}/${year}`;
  return ItemModel({
    label,
    value: {
      year,
      month: date.get('month') + 1,
      key: label,
    },
  });
};

export const buildOptions = (currentDate = dayjs()) => {
  const options = [];
  const currentOption = buildValue(currentDate);

  options.push(currentOption);
  for (let i = 0; i < 4; i += 1) {
    options.push(buildValue(currentDate.subtract(1 + i, 'month')));
  }

  return List(options);
};
