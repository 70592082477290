import * as types from './types';

export const setIsWaitingForEstimative = isWaitingForEstimative => ({
  type: types.SET_IS_WAITING_FOR_ESTIMATIVE,
  payload: { isWaitingForEstimative },
});

export const setLoading = loading => ({
  type: types.SET_LOADING,
  payload: { loading },
});

export const setPiecesSelected = piecesSelected => ({
  type: types.SET_PIECES_SELECTED,
  payload: { piecesSelected },
});

export const resetExportCreativeTree = () => ({
  type: types.RESET_TREE_EXPORT_CREATIVES,
});
