import React from 'react';
import { connect } from 'react-redux';
import { getAllCurrentChildrens } from '../selectors';
import ObjectEditable from './ObjectEditable';

const ActiveObjects = ({ childrensID }) =>
  childrensID.map(childrenID => (
    <ObjectEditable key={childrenID} objectID={childrenID} />
  ));

const container = connect(state => ({
  childrensID: getAllCurrentChildrens(state),
}));

export default container(ActiveObjects);
