import { List } from 'immutable';
import BaseBB from './base';
import { buildCoordinates, getMinMaxFromPoints } from '../utils';
import { getCoordinatesFromObjects } from './utils';

export const buildDimensions = (objects, parentMatrix) => {
  const points = getCoordinatesFromObjects(objects, parentMatrix);
  const { minX, maxX, minY, maxY } = getMinMaxFromPoints(points);

  return {
    left: minX,
    top: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
};

class AABB extends BaseBB {
  static fromPiece(piece) {
    const left = 0;
    const top = 0;
    const { width, height } = piece;

    return new AABB({
      width,
      height,
      coordinates: buildCoordinates({ left, top, width, height }),
    });
  }

  static fromObject(object, options = {}) {
    return AABB.fromObjects(List([object]), options);
  }

  static fromObjects(objects, options = {}) {
    if (!objects.size) {
      return new AABB({});
    }

    const dimensions = buildDimensions(objects, options.parentMatrix);
    return new AABB({
      ...dimensions,
      coordinates: buildCoordinates(dimensions),
    });
  }
}

export default AABB;
