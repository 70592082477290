import React from 'react';
import { CursorIcon } from '../icons';
import SidebarTool from './SidebarTool';
import { StageMode } from '../editor';

const ButtonSelectTool = () => (
  <SidebarTool tool={StageMode.select} icon={CursorIcon} />
);

export default ButtonSelectTool;
