import React from 'react';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import { object, string, func, bool } from 'prop-types';
import { Formik, Form } from 'formik';
import { gql, useMutation } from '@apollo/client';
import {
  ButtonPrimary,
  ButtonTertiary,
  TextField,
  Modal,
  ModalHeader,
  ModalContent,
  ModalButtons,
  ModalSpacer,
  createHideableComponent,
} from '../../ui';
import { showNotification } from '../../Notification';

export const RENAME_PROJECT = gql`
  mutation RenameProject($id: String!, $name: String!) {
    updateProjectName(id: $id, name: $name) {
      project {
        id
      }
    }
  }
`;

const ModalContext = createHideableComponent('renameProject');

export const ERROR_MESSAGE =
  'It was not possible to update project name. Try again later.';

const ModalRenameProject = ({
  project,
  sendErrorNotification,
  hide,
  isVisible,
  refetch,
}) => {
  const [renameProject] = useMutation(RENAME_PROJECT);

  return (
    <Modal small isVisible={isVisible}>
      <ModalHeader>Rename Project</ModalHeader>
      <ModalContent>
        <Formik
          initialValues={{ name: project.name }}
          validate={values => {
            const errors = {};
            if (!values.name) {
              errors.name = 'Required';
            }

            return errors;
          }}
          onSubmit={({ name }, { setSubmitting }) => {
            setSubmitting(true);
            renameProject({
              variables: { id: project.id, name },
            })
              .then(({ data }) => {
                const projectID = data.updateProjectName.project.id;
                if (projectID) {
                  refetch();
                  setSubmitting(false);
                  hide();
                  return;
                }

                throw new Error('Response error');
              })
              .catch(() => {
                sendErrorNotification();
                setSubmitting(false);
              });
          }}
        >
          {({
            isSubmitting,
            isValid,
            values,
            errors,
            handleChange,
            handleBlur,
            dirty,
          }) => (
            <Form>
              <TextField
                type="text"
                title="Project Title"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={errors.name}
                expanded
                required
              />
              <ModalButtons>
                <ButtonPrimary
                  extended
                  type="submit"
                  label="Rename"
                  disabled={isSubmitting || !isValid || !dirty}
                />
                <ModalSpacer />
                <ButtonTertiary
                  type="button"
                  extended
                  label="Cancel"
                  disabled={isSubmitting}
                  onClick={hide}
                />
              </ModalButtons>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

ModalRenameProject.propTypes = {
  id: string.isRequired,
  sendErrorNotification: func.isRequired,
  hide: func.isRequired,
  isVisible: bool.isRequired,
  refetch: func.isRequired,
  project: object.isRequired,
};

const withRedux = connect(null, dispatch => ({
  sendErrorNotification() {
    dispatch(showNotification(ERROR_MESSAGE));
  },
}));

export default compose(withRedux, ModalContext.hoc)(ModalRenameProject);
