import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  controlBar: {
    alignItems: 'center',
    display: 'flex',
    height: 48,
    flexShrink: 0,
    paddingRight: 14,
    paddingLeft: 14,
    width: '100%',
  },
});

const ControlBar = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.controlBar}>{children}</div>;
};

ControlBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ControlBar;
