import React from 'react';
import { createUseStyles } from 'react-jss';
import { Header, Spacer } from '../HeaderProjectList';
import CreateProjectButton from './CreateProjectButton';
import SearchField from './SearchField';

const useStyle = createUseStyles({
  searchBox: {
    display: 'inline',
    paddingLeft: 48,
    paddingRight: 48,
  },
});

const HeaderProjectList = () => {
  const classes = useStyle();

  return (
    <Header>
      <div className={classes.searchBox}>
        <SearchField />
      </div>
      <Spacer />
      <CreateProjectButton />
    </Header>
  );
};

export default HeaderProjectList;
