import React from 'react';
import PropTypes from 'prop-types';
import { PopoverItem } from '../../ui';
import { ModalContext } from './ModalApplyAnimation';

const ButtonApplyAnimation = ({ objectID, time }) => (
  <ModalContext.Trigger>
    {({ show }) => (
      <PopoverItem
        label="Apply animation"
        onClick={() => show(objectID, time)}
      />
    )}
  </ModalContext.Trigger>
);

ButtonApplyAnimation.propTypes = {
  objectID: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
};

export default ButtonApplyAnimation;
