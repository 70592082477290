import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSelectedObjects } from '../../editor';
import { IMG, VIDEO, CLIPPATH } from '../../models';
import Section from '../Section';
import { Asset } from '../toolsContainers';
import SelectionInfo from './SelectionInfo';
import ClipPathAlert from './ClipPathAlert';
import { extractTypes } from '../utils';
import { computeSelectedType } from './utils';

const isHeaderVisible = selectionSize => selectionSize > 0;

const hasClipPathConflict = (selectionSize, selectedTypes) =>
  selectionSize > 1 && selectedTypes.has(CLIPPATH);

const isOnlyImageOrVideoSelected = (selectionSize, computedType) =>
  selectionSize === 1 && (computedType === IMG || computedType === VIDEO);

const Header = ({ selectionSize, selectedTypes }) => {
  if (!isHeaderVisible(selectionSize)) {
    return null;
  }

  if (hasClipPathConflict(selectionSize, selectedTypes)) {
    return (
      <Section noBorder>
        <ClipPathAlert selectedTypes={selectedTypes} />
      </Section>
    );
  }

  const computedType = computeSelectedType(selectedTypes);

  if (isOnlyImageOrVideoSelected(selectionSize, computedType)) {
    return (
      <Section noBorder>
        <Asset />
      </Section>
    );
  }

  return (
    <Section noBorder>
      <SelectionInfo
        selectionSize={selectionSize}
        computedType={computedType}
      />
    </Section>
  );
};

Header.propTypes = {
  selectionSize: PropTypes.number.isRequired,
  selectedTypes: PropTypes.object.isRequired,
};

const container = connect(state => {
  const objects = getSelectedObjects(state);
  const selectedTypes = extractTypes(objects);

  return {
    selectionSize: objects.size,
    selectedTypes,
  };
});

export default container(Header);
