import { createSelector } from 'reselect';
import { getObjectByID, getPieceObjectsIDs } from '../project/selectors';

const getEditor = state => state.get('editor');

// Stage
const getStageSlice = state => getEditor(state).get('stage');

export const getStageMode = state => getStageSlice(state).get('mode');

export const getAlignToContainer = state =>
  getStageSlice(state).get('alignToContainer');

// Selected obejcts
const getSelectedObjectsSlice = state =>
  getEditor(state).get('selectedObjects');

export const getSelectedObjectsIDs = createSelector(
  getSelectedObjectsSlice,
  ids => ids.toList(),
);

export const getSortedSelectedObjectsIDs = (state, pieceID) => {
  const selection = getSelectedObjectsSlice(state);
  const pieceObjects = getPieceObjectsIDs(state, pieceID);
  return pieceObjects.filter(objectID => selection.has(objectID));
};

export const getObjectIsSelected = (objectID, state) =>
  getSelectedObjectsSlice(state).has(objectID);

export const getSelectedObjects = state => {
  const ids = getSelectedObjectsIDs(state);
  return ids.map(id => getObjectByID(state, id));
};

// Selected keyframes
export const getSelectedKeyframes = state =>
  getEditor(state).get('selectedKeyframes');

export const getKeyframeIsSelected = (keyframe, state) =>
  getSelectedKeyframes(state).has(keyframe.id);

export const getKeyframesIsSelected = (keyframeList, state) =>
  keyframeList.every(keyframe => getKeyframeIsSelected(keyframe, state));

// Objects Visibility
const getHiddenObjects = state => getEditor(state).get('hiddenObjects');

export const getIsObjectVisible = (state, objectID) =>
  !getHiddenObjects(state).has(objectID);

// Objects Changes
export const getLockedObjects = state => getEditor(state).get('lockedObjects');

export const getIsObjectLocked = (state, objectID) =>
  getLockedObjects(state).has(objectID);

export const getCurrentObjectID = state =>
  getStageSlice(state).get('currentObjectID');

export const getLastPieceID = state => getStageSlice(state).get('lastPieceID');

// Popover
export const getObjectToRename = state =>
  state.get('editor').get('objectToRename');

export const getIsRenamingObject = (state, objectID) =>
  state.get('editor').get('objectToRename') === objectID;

// Last modification
export const getLastModificationDate = state =>
  state.getIn(['editor', 'lastModification'], null);

// Focused Area
export const getFocusedArea = state => state.get('editor').get('focusedArea');
