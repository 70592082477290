import React from 'react';
import { bool } from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { PopoverItem } from '../../ui';
import { getSelectedKeyframes } from '../../selectors';
import { ModalContext } from './ModalSaveAnimation';

const ButtonSaveAnimation = ({ disabled }) => (
  <ModalContext.Trigger>
    {({ show }) => (
      <PopoverItem label="Save animation" disabled={disabled} onClick={show} />
    )}
  </ModalContext.Trigger>
);

ButtonSaveAnimation.propTypes = {
  disabled: bool.isRequired,
};

const container = connect(state => {
  const selectedKeyframes = getSelectedKeyframes(state);
  return {
    disabled:
      selectedKeyframes.isEmpty() ||
      selectedKeyframes.size > 1 ||
      selectedKeyframes.first(List()).size <= 1,
  };
});

export default container(ButtonSaveAnimation);
