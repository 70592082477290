import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { currentParentObjectIDMiddleware } from './editor';
import { currentTimeMiddleware } from './playback';
import { currentPieceMiddleware } from './project';
import reducer from './reducers';
import saga from './sagas';
import { ensureConsistencyOnUndo } from './middlewares';

const configureStore = initialState => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = composeWithDevTools(
    applyMiddleware(
      thunk,
      currentPieceMiddleware,
      currentTimeMiddleware,
      currentParentObjectIDMiddleware,
      ensureConsistencyOnUndo,
      sagaMiddleware,
    ),
  );
  const store = createStore(reducer, initialState, middlewares);
  sagaMiddleware.run(saga);
  return store;
};

export default configureStore;
