import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';
import * as typography from '../typography';

const useStyles = createUseStyles(theme => ({
  button: {
    ...typography.textRegular,
    ...theme.buttons.default,
    alignItems: 'center',
    borderRadius: 20,
    cursor: 'pointer',
    display: 'flex',
    minHeight: 40,
    justifyContent: 'center',
    minWidth: 88,
    outline: 'none',
    width: props => (props.extended ? '100%' : 'auto'),
    padding: [0, 16],
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 16,
  },
}));

const ButtonDefault = ({ label, icon, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ ...props, theme });
  delete props.theme; // eslint-disable-line react/prop-types, no-param-reassign
  delete props.extended; // eslint-disable-line no-param-reassign
  const Icon = icon;
  return (
    <button type="button" className={classes.button} {...props}>
      {icon && <Icon className={classes.icon} />}
      {label}
    </button>
  );
};

ButtonDefault.propTypes = {
  extended: PropTypes.bool,
  label: PropTypes.string.isRequired,
  icon: PropTypes.func,
};

ButtonDefault.defaultProps = {
  extended: false,
  icon: null,
};

export default ButtonDefault;
