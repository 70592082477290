import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';
import StepButtonWrapper from '../StepButtonsWrapper';
import getFormatter from './formatters';

export const DEFAULT_SPACER = ':';

class DurationInput extends React.Component {
  constructor(props) {
    super();
    this.formatter = getFormatter(props.format);
    const valueFromProps = this.formatter.getValueFromProps(props.value);
    const value = this.formatter.isValidTime(valueFromProps)
      ? valueFromProps
      : this.formatter.DEFAULT_VALUE;
    this.state = {
      value,
    };

    this.onChange = this.onChange.bind(this);
    this.applyValue = this.applyValue.bind(this);
    this.insertValue = this.insertValue.bind(this);
    this.removeValue = this.removeValue.bind(this);
    this.changeRawValue = this.changeRawValue.bind(this);
    this.validateMax = this.validateMax.bind(this);
  }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps, prevState) {
    const value = this.formatter.getValueFromProps(this.props.value);
    if (!this.formatter.isValidTime(value)) {
      return;
    }

    if (prevState.value !== value && this.state.value !== value) {
      this.setState({ value });
    }
  }

  onChange(event) {
    event.preventDefault();
    const { target } = event;

    if (target.value.length < this.state.value.length) {
      this.removeValue(target);
      return;
    }

    this.insertValue(target);
  }

  validateMax(value) {
    const { max } = this.props;
    return max && value > max;
  }

  insertValue(target) {
    const { selectionStart } = target;
    const targetValue = target.value;
    const oldValue = this.state.value;
    const insertPosition = selectionStart - 1;
    let newSelectionPosition = selectionStart;
    let newDigit = target.value[insertPosition];
    const oldDigit = oldValue[insertPosition];
    const oldDigitIsMask = this.formatter.oldDigitIsMask(oldDigit);

    // To permit paste values in input this is required, because the normal input
    const targetSlicePos = insertPosition;
    let oldValueSlicePos = insertPosition + 1;
    if (oldDigitIsMask) {
      const newValues = this.formatter.getNewDigitWithMask(newDigit, oldDigit);
      ({ newDigit } = newValues);
      oldValueSlicePos += newValues.deltaPosition;
      newSelectionPosition += newValues.deltaPosition;
    }

    const newValue = [
      targetValue.slice(0, targetSlicePos),
      newDigit,
      oldValue.slice(oldValueSlicePos),
    ].join('');

    this.applyValue(target, newValue, newSelectionPosition);
  }

  removeValue(target) {
    const { selectionStart } = target;
    const targetValue = target.value;
    const oldValue = this.state.value;
    const removedPosition = selectionStart;
    const previousDigit = oldValue[selectionStart - 1];
    const oldDigit = oldValue[selectionStart];
    const newDigit = this.formatter.getDigitFromOldDigit(oldDigit);
    let newSelectionPosition = removedPosition;

    if (this.formatter.oldDigitIsMask(previousDigit)) {
      newSelectionPosition -= 1;
    }

    let newValue = [
      targetValue.slice(0, removedPosition),
      newDigit,
      oldValue.slice(removedPosition + 1),
    ].join('');

    if (this.formatter.isOnlyMask(newValue)) {
      newValue = this.formatter.DEFAULT_VALUE;
    }

    this.applyValue(target, newValue, newSelectionPosition);
  }

  applyValue(element, newValue, cursorPosition) {
    const { onChange } = this.props;
    let newCursorPosition = cursorPosition;
    const oldValue = this.state.value;
    let value = newValue;
    const valueMS = this.formatter.convertToMS(this.formatter.getTime(value));

    if (
      !this.formatter.isValidValue(value) ||
      !this.formatter.isValidTime(newValue) ||
      this.validateMax(valueMS)
    ) {
      newCursorPosition -= 1;
      value = oldValue;
    }

    this.setState({ value }, () => {
      /* eslint-disable no-param-reassign */
      element.selectionStart = newCursorPosition;
      element.selectionEnd = newCursorPosition;
      if (value === oldValue) {
        return;
      }

      onChange(valueMS);
    });
  }

  changeRawValue(amount) {
    const { onChange } = this.props;
    const { value } = this.state;
    let valueMS = this.formatter.convertToMS(this.formatter.getTime(value));
    valueMS += amount;
    if (valueMS < 0) {
      valueMS = 0;
    }

    if (this.validateMax(valueMS)) {
      return;
    }

    this.setState({ value: this.formatter.getValueFromProps(valueMS) }, () => {
      onChange(valueMS);
    });
  }

  render() {
    const { expanded, big, disabled, step } = this.props;
    const { value } = this.state;

    return (
      <StepButtonWrapper
        expanded={expanded}
        big={big}
        disabled={disabled}
        onStepUp={() => this.changeRawValue(step)}
        onStepDown={() => this.changeRawValue(-step)}
      >
        {inputProps => (
          <Input onChange={this.onChange} value={value} {...inputProps} />
        )}
      </StepButtonWrapper>
    );
  }
}

DurationInput.defaultProps = {
  format: DurationInput.Duration,
  expanded: false,
  big: false,
  disabled: false,
  value: 0,
  max: null,
  step: 500,
  onChange: () => null,
};

DurationInput.propTypes = {
  format: PropTypes.string,
  expanded: PropTypes.bool,
  big: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
};

export default DurationInput;
