import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Range } from 'react-range';
import colors from '../colors';
import ButtonIncrease from './ButtonIncrease';
import ButtonDecrease from './ButtonDecrease';
import PopupPercentage from './PopupPercentage';

const useStyles = createUseStyles({
  slider: {
    width: 204,
    height: 50,
    display: 'flex',
    flexDirection: 'row',
  },
  wrapperRange: {
    width: 160,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  track: {
    width: 160,
    height: 4,
    background: colors.gray1,
    borderRadius: '4px',
  },
  thumb: {
    borderRadius: '100%',
    width: 12,
    height: 12,
    background: colors.gray2,
    border: 'none',
    outline: 'none',
    '&:active, &:hover': {
      border: 'none',
      outline: 'none',
      boxShadow: `0px 0px 4px 1px ${colors.yellow}`,
      background: colors.yellow,
    },
  },
});

const noOp = () => null;

const Slider = ({ value, onChange, min, max, step, stepButton }) => {
  const [isActivePopover, setIsActivePopover] = useState(false);
  const [timeoutID, setTimeoutID] = useState(null);

  const shortActivationPopover = useCallback(() => {
    setIsActivePopover(true);

    if (timeoutID !== null) {
      clearTimeout(timeoutID);
    }

    setTimeoutID(setTimeout(() => setIsActivePopover(false), 4000));
  }, [setIsActivePopover, timeoutID, setTimeoutID]);

  const handleOnClickIncreaseButton = useCallback(() => {
    shortActivationPopover();

    const newValue = value + stepButton;
    if (newValue > max) {
      return onChange(max);
    }

    return onChange(newValue);
  }, [shortActivationPopover, value, max, onChange]);

  const handleOnClickDecreaseButton = useCallback(() => {
    shortActivationPopover();

    const newValue = value - stepButton;
    if (newValue < min) {
      return onChange(min);
    }

    return onChange(newValue);
  }, [shortActivationPopover, value, min, onChange]);

  const onPointerOver = useCallback(() => {
    if (timeoutID !== null) {
      clearTimeout(timeoutID);
    }

    setIsActivePopover(true);
  }, [timeoutID, setIsActivePopover]);

  const onPointerLeave = useCallback(() => {
    setIsActivePopover(false);
  }, [setIsActivePopover]);

  const classes = useStyles();
  return (
    <div
      className={classes.slider}
      onPointerDown={e => {
        e.stopPropagation();
      }}
    >
      <ButtonDecrease
        disabled={value === min}
        onClick={handleOnClickDecreaseButton}
      />
      <div className={classes.wrapperRange}>
        <Range
          step={step}
          min={min}
          max={max}
          values={[value]}
          onChange={onChange}
          renderTrack={({ props, children }) => (
            <div {...props} role="none" className={classes.track}>
              {children}
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              className={classes.thumb}
              role="none"
              onPointerOver={onPointerOver}
              onPointerLeave={onPointerLeave}
              onKeyDown={noOp}
              onKeyUp={noOp}
            >
              <div {...props}>
                <PopupPercentage
                  value={value}
                  isActive={isDragged || isActivePopover}
                />
              </div>
            </div>
          )}
        />
      </div>
      <ButtonIncrease
        disabled={value === max}
        onClick={handleOnClickIncreaseButton}
      />
    </div>
  );
};

Slider.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  stepButton: PropTypes.number.isRequired,
};

export default Slider;
