import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { InputTimeline } from '../ui';
import { renameObject } from '../project';

const isEnterPressed = ({ key }) => key === 'Enter';

const RenameObject = ({ isOpen, onClose, initialValue, objectID }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [value, setValue] = useState(initialValue);

  const handleKeyDown = useCallback(
    e => {
      const { target } = e;
      if (isEnterPressed(e)) {
        dispatch(renameObject(objectID, e.target.value));
        onClose();
      }
      setValue(target.value);
    },
    [onClose, dispatch],
  );

  const handleChange = useCallback(e => {
    setValue(e.target.value);
  });

  const handleBlur = useCallback(
    e => {
      dispatch(renameObject(objectID, e.target.value));
      onClose();
    },
    [onClose, dispatch],
  );

  useEffect(() => {
    if (isOpen) {
      if (ref.current) {
        ref.current.focus();
      }
    }
  }, [isOpen]);

  return (
    <InputTimeline
      value={value === null ? '' : value}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      onBlur={handleBlur}
      ref={ref}
    />
  );
};

RenameObject.defaultProps = {
  initialValue: null,
};

RenameObject.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  objectID: PropTypes.string.isRequired,
};

export default RenameObject;
