import React from 'react';
import { IconButtonTertiary } from '../ui';
import { ExitIcon } from '../icons';
import { useLogout } from '../auth';

const LogoutButton = () => {
  const logout = useLogout();

  return <IconButtonTertiary icon={ExitIcon} onClick={logout} />;
};

export default LogoutButton;
