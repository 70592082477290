import React from 'react';
import { string } from 'prop-types';
import { createUseStyles } from 'react-jss';
import { connect } from 'react-redux';
import { fromNow } from '../utils';
import { getCurrentProjectUpdatedAt } from '../project';

const useStyles = createUseStyles({
  saveInfoBox: {
    fontSize: 12,
    display: 'flex',
  },
  savedText: {
    color: '#23E072',
    paddingRight: 3,
  },
});

const SaveInfo = ({ updatedAt }) => {
  const classes = useStyles();
  if (!updatedAt) {
    return <div className={classes.saveInfoBox} />;
  }

  return (
    <span className={classes.saveInfoBox}>
      <span className={classes.savedText}> saved </span>
      {fromNow(updatedAt)}
    </span>
  );
};

SaveInfo.defaultProps = {
  updatedAt: null,
};

SaveInfo.propTypes = {
  updatedAt: string,
};

const container = connect(state => ({
  updatedAt: getCurrentProjectUpdatedAt(state),
}));

export default container(SaveInfo);
