import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import {
  updateObjects as updateObjectsAction,
  reduceObjects as reduceObjectsFn,
} from '../project';
import { getSelectedObjects } from '../editor';
import Context, { getDefaultState } from './Context';

class Provider extends React.Component {
  constructor() {
    super();
    this.state = getDefaultState();

    this.begin = this.begin.bind(this);
    this.hasObjects = this.hasObjects.bind(this);
    this.updateObjects = this.updateObjects.bind(this);
    this.reduceObjects = this.reduceObjects.bind(this);
    this.commit = this.commit.bind(this);
    this.rollback = this.rollback.bind(this);
  }

  begin() {
    this.setState((state, props) => {
      const objects = state.objects.merge(
        Map(props.selectedObjects.map(obj => [obj.id, obj])),
      );

      return {
        ...state,
        objects,
      };
    });
  }

  hasObjects() {
    return this.state.objects.size > 0;
  }

  updateObjects(objects) {
    this.setState(state => ({
      ...state,
      objects,
    }));
  }

  reduceObjects(ids, updater) {
    this.setState(state => {
      const objects = reduceObjectsFn(ids, updater)(state.objects);
      return {
        ...state,
        objects,
      };
    });
  }

  commit() {
    const { objects } = this.state;
    this.props.updateObjects(objects.toList());

    this.setState(getDefaultState());
  }

  rollback() {
    this.setState({
      objects: Map(),
    });
  }

  render() {
    const {
      begin,
      hasObjects,
      updateObjects,
      reduceObjects,
      commit,
      rollback,
    } = this;
    const { objects } = this.state;

    return (
      <Context.Provider
        value={{
          begin,
          hasObjects,
          updateObjects,
          reduceObjects,
          commit,
          rollback,
          objects,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
  updateObjects: PropTypes.func.isRequired,
  selectedObjects: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    selectedObjects: getSelectedObjects(state),
  }),
  {
    updateObjects: updateObjectsAction,
  },
)(Provider);
