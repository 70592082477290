import { Record } from 'immutable';
import { isDefined } from '../utils';
import baseMixin, { baseObject } from './base';
import { IMG } from './type';

export const defaultObject = {
  ...baseObject,
  assetPath: '',
  assetURL: null,
  type: IMG,
};

class ImageObject extends baseMixin(Record(defaultObject)) {
  updateAsset(asset) {
    let newObject = this.set('assetPath', asset.path).set(
      'assetURL',
      asset.url,
    );

    if (
      !this.hasPropertyKeyframes('width') &&
      !this.hasPropertyKeyframes('height')
    ) {
      const widthRatio = asset.width / this.width;
      const heightRatio = asset.height / this.height;
      newObject = newObject
        .setWidth(asset.width)
        .setHeight(asset.height)
        .set('originX', this.originX * widthRatio)
        .set('originY', this.originY * heightRatio)
        .update('keyframes', keyframes =>
          keyframes.map(keyframe =>
            keyframe
              .updateIn(['values', 'originX'], originX =>
                isDefined(originX) ? originX * widthRatio : originX,
              )
              .updateIn(['values', 'originY'], originY =>
                isDefined(originY) ? originY * heightRatio : originY,
              ),
          ),
        );
    }

    return newObject;
  }
}

export default ImageObject;
