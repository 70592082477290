import React from 'react';
import { bool, func } from 'prop-types';
import Toggle from 'react-toggled';
import { createUseStyles } from 'react-jss';
import { ClosedLockIcon, OpenedLockIcon } from '../../icons';

const useStyles = createUseStyles({
  toggle: {
    width: 16,
    height: 16,
    position: 'absolute',
    zIndex: 121,
    backgroundColor: 'transparent',
    color: 'white',
    fill: 'white',
    '& svg': {
      padding: 3,
    },
  },
});

const ToggleDisable = ({ checked, onToggle }) => {
  const classes = useStyles();
  return (
    <Toggle on={checked} onToggle={onToggle}>
      {({ on, getTogglerProps }) => (
        <button className={classes.toggle} type="button" {...getTogglerProps()}>
          {on ? <ClosedLockIcon /> : <OpenedLockIcon />}
        </button>
      )}
    </Toggle>
  );
};

ToggleDisable.propTypes = {
  checked: bool.isRequired,
  onToggle: func.isRequired,
};

export default ToggleDisable;
