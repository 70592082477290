import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  pieces: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    padding: 25,
  },
});

const PiecesContainer = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.pieces}>{children}</div>;
};

PiecesContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PiecesContainer;
