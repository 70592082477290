import { share as shareAPI } from '../api';

export const queryProjectPieces = (shareID, onSuccess, onError) => {
  shareAPI
    .getSharedPieces(shareID)
    .then(response => {
      if (!response.ok) {
        return onError();
      }

      return onSuccess(response.data);
    })
    .catch(() => {
      onError();
    });
};
