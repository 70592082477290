import React from 'react';
import { instanceOf, string } from 'prop-types';
import { connect } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { List } from 'immutable';
import { Panel } from '../ui';
import { CustomScroll } from '../CustomScroll';
import { getCollectionsPaths, getCurrentProjectID } from '../project';
import { getAssetsToUpload } from './reducers';
import ModalDeleteAsset from './ModalDelete';
import ModalCreateCollection from './ModalCreateCollection';
import UploadEntry from './UploadEntry';
import UncategorizedAssets from './UncategorizedAssets';
import Collection from './Collection';
import MenuBottom from './MenuBottom';
import Dropzone from './Dropzone';
import AssetPreview from './AssetPreview';

const useStyles = createUseStyles({
  panelAssets: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
});

const PanelAssets = ({ collections, toUpload, projectID, ...panelProps }) => {
  const classes = useStyles();
  return (
    <>
      <ModalDeleteAsset />
      <ModalCreateCollection />
      <Panel title="Assets" startOpened {...panelProps}>
        <div className={classes.panelAssets}>
          <AssetPreview />
          <CustomScroll>
            <Dropzone>
              {collections.map(collectionPath => (
                <Collection key={collectionPath} path={collectionPath} />
              ))}
              <UncategorizedAssets path={`/${projectID}`} />
              {toUpload.map(asset => (
                <UploadEntry key={asset.localURL} asset={asset} />
              ))}
              <MenuBottom />
            </Dropzone>
          </CustomScroll>
        </div>
      </Panel>
    </>
  );
};

PanelAssets.propTypes = {
  collections: instanceOf(List).isRequired,
  projectID: string.isRequired,
  toUpload: instanceOf(List).isRequired,
};

const container = connect(state => ({
  projectID: getCurrentProjectID(state),
  collections: getCollectionsPaths(state),
  toUpload: getAssetsToUpload(state).toList(),
}));

export default container(PanelAssets);
