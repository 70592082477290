import React from 'react';
import { node, string, object } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

import { url } from '../App';

const useStyles = createUseStyles({
  link: {
    textDecoration: 'none',
  },
});

const LinkToEditPiece = ({ children, pieceID, match }) => {
  const classes = useStyles();
  return (
    <Link
      to={url.pieceDetail({
        projectID: match.params.projectID,
        pieceID,
      })}
      className={classes.link}
    >
      {children}
    </Link>
  );
};

LinkToEditPiece.propTypes = {
  children: node.isRequired,
  pieceID: string.isRequired,
  match: object.isRequired,
};

export default withRouter(LinkToEditPiece);
