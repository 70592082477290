import { useContext, useState, useEffect, useCallback } from 'react';
import Context from './Context';

const defaultState = {
  requesting: false,
  loading: true,
  data: null,
  errorReason: null,
  ok: false,
  decoder: undefined,
  errorDecoder: undefined,
};

export const callIfDefined = (callback = null, ...args) => {
  if (callback === null) {
    return;
  }

  callback(...args);
};

export const UNDEFINED_PROVIDER =
  'Unable to do request because Provider was not provided';

const useRequest = (
  { config, decoder, errorDecoder },
  { doRequest = true, onRequest = null, onSuccess = null, onFail = null } = {},
  requestDeps = [],
) => {
  const client = useContext(Context);
  if (!client) {
    throw new Error(UNDEFINED_PROVIDER);
  }

  const [state, setState] = useState({
    ...defaultState,
    config,
    doRequest,
    decoder,
    errorDecoder,
  });

  useEffect(() => {
    if (state.config === config) {
      return;
    }

    setState(oldState => ({ ...oldState, config }));
  }, requestDeps);

  const request = useCallback(
    (newConfig = { config: {}, decoder, errorDecoder }) => {
      setState(oldState => ({
        ...oldState,
        doRequest: true,
        config: { ...state.config, ...newConfig.config },
        decoder: newConfig.decoder ? newConfig.decoder : oldState.decoder,
        errorDecoder: newConfig.errorDecoder
          ? newConfig.errorDecoder
          : oldState.errorDecoder,
      }));
    },
    [setState],
  );

  useEffect(() => {
    if (state.doRequest === false) {
      return;
    }

    setState(oldState => ({ ...oldState, requesting: true }));
    callIfDefined(onRequest);
    client(state.config, state.decoder, state.errorDecoder)
      .then(({ ok, data, reason = null }) => {
        setState(oldState => ({
          ...oldState,
          requesting: false,
          loading: false,
          ok,
          data,
          errorReason: reason,
        }));
        if (ok === true) {
          callIfDefined(onSuccess, { ok, data, reason });
        } else {
          callIfDefined(onFail, { ok, data, reason });
        }
      })
      .catch(({ ok, data, reason }) => {
        setState(oldState => ({
          ...oldState,
          requesting: false,
          loading: false,
          ok,
          data,
          errorReason: reason,
        }));
        callIfDefined(onFail, { ok, data, reason });
      });
  }, [state.config]);

  const { requesting, loading, ok, data, errorReason } = state;
  return { requesting, loading, ok, data, errorReason, request };
};

export default useRequest;
