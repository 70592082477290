import { Record } from 'immutable';

export const AssetModel = Record({
  path: '',
  url: '',
  filename: '',
  height: 0,
  width: 0,
  assetType: '',
});

export const CollectionModel = Record({
  path: '',
  name: '',
});

export const VIDEO_ASSET_TYPE = 'video';
export const IMAGE_ASSET_TYPE = 'image';
