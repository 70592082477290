import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { getCurrentProjectID } from '../project';
import { Popover, PopoverTrigger } from '../ui/Popover';
import { IconButtonTertiary, colors } from '../ui';
import { Ellipsis, CheckedIcon } from '../icons';
import { OPEN, APPROVED } from './constants';
import { useRequest } from '../http';

const borderLeft = status => (status === OPEN ? colors.yellow : colors.success);
const opacityStatus = status => (status === APPROVED ? '0.35' : '1.0');
const displayDisabled = status => status === APPROVED;

const useStyles = createUseStyles({
  issueCard: {
    borderTop: `1px solid ${colors.black2}`,
    borderLeft: ({ issue: { status } }) => `2px solid ${borderLeft(status)}`,
    minHeight: 60,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.black4,

    '&:last-child': {
      borderBottom: `1px solid ${colors.black2}`,
    },
  },
  icon: {
    fill: 'white',
    height: 16,
    width: 24,
    marginLeft: 14,
  },
  headerCard: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  title: {
    paddingLeft: 14,
    fontSize: 16,
    color: colors.white,
    whiteSpace: 'nowrap',
    opacity: ({ issue: { status } }) => opacityStatus(status),
  },
  date: {
    marginLeft: 16,
    fontSize: 12,
    color: colors.gray1,
    whiteSpace: 'nowrap',
    opacity: ({ issue: { status } }) => opacityStatus(status),
  },
  containerDescription: {
    display: 'flex',
    margin: [8, 16, 16, 16],
  },
  description: {
    fontSize: 16,
    color: colors.white,
    wordBreak: 'break-all',
    whiteSpace: 'pre',
    opacity: ({ issue: { status } }) => opacityStatus(status),
  },
});

const IssueCard = ({ issue, renderActions, refresh, projectID }) => {
  const classes = useStyles({ issue });

  const { request: doAction } = useRequest(
    {},
    {
      doRequest: false,
      onSuccess: refresh,
    },
  );

  return (
    <div className={classes.issueCard}>
      <div className={classes.headerCard}>
        <div className={classes.containerTitle}>
          {displayDisabled(issue.status) && (
            <CheckedIcon className={classes.icon} />
          )}
          <p className={classes.title}>{issue.issue}</p>
          <p className={classes.date}>
            {dayjs(issue.created_at).format('DD MMM - HH:mm')}
          </p>
        </div>
        <Popover id={`${issue.id}`} isDown>
          {renderActions({ projectID, issue, doAction })}
        </Popover>
        <PopoverTrigger id={`${issue.id}`}>
          {open => (
            <IconButtonTertiary
              onClick={open}
              onContextMenu={open}
              icon={Ellipsis}
            />
          )}
        </PopoverTrigger>
      </div>
      <div className={classes.containerDescription}>
        <p className={classes.description}>{issue.description}</p>
      </div>
    </div>
  );
};

IssueCard.defaultProps = {
  renderActions: () => null,
};

IssueCard.propTypes = {
  issue: PropTypes.object.isRequired,
  renderActions: PropTypes.func,
  refresh: PropTypes.func.isRequired,
  projectID: PropTypes.string.isRequired,
};

const container = connect(state => ({
  projectID: getCurrentProjectID(state),
}));

export default container(IssueCard);
