import React from 'react';
import { string } from 'prop-types';
import { createUseStyles } from 'react-jss';
import Format from './Format';
import HoverMask from './HoverMask';
import ConnectedPiece from './ConnectedPiece';
import PieceActionsPopover from './PieceActionsPopover';
import MasterIcon from './MasterIcon';
import ModalPreview, { PreviewButton } from './ModalPreview';
import { createHideableComponent } from '../ui/hideable';
import ParentPiece from './ParentPiece';

const useStyles = createUseStyles({
  linkPiece: {
    marginBottom: 48,
    marginRight: 48,
    flex: 0,
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 8,
    minWidth: 150,
  },
  piece: {
    display: 'inline-block',
    flex: 0,
    position: 'relative',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const LinkPiece = ({ pieceID }) => {
  const classes = useStyles();
  const PreviewContext = createHideableComponent();
  return (
    <div className={classes.linkPiece}>
      <div className={classes.header}>
        <MasterIcon pieceID={pieceID} />
        <Format pieceID={pieceID} />
        <PieceActionsPopover pieceID={pieceID} />
      </div>
      <div className={classes.piece}>
        <ConnectedPiece pieceID={pieceID} loaded={false} />
        <HoverMask pieceID={pieceID} />
      </div>
      <div className={classes.footer}>
        <ParentPiece pieceID={pieceID} />
        <PreviewContext.Target>
          {({ isVisible, hide }) => (
            <ModalPreview
              pieceID={pieceID}
              isVisible={isVisible}
              onClick={hide}
            />
          )}
        </PreviewContext.Target>
        <PreviewContext.Trigger>
          {({ show }) => <PreviewButton onClick={show} />}
        </PreviewContext.Trigger>
      </div>
    </div>
  );
};

LinkPiece.propTypes = {
  pieceID: string.isRequired,
};

export default LinkPiece;
