import React from 'react';
import { number } from 'prop-types';
import { createUseStyles } from 'react-jss';
import Piece from './Piece';
import PieceBorder from './PieceBorder';
import PopoverObject from './PopoverObject';
import ZoomBox from './ZoomBox';
import TransformBox from './TransformBox';
import EditableContext from '../EditableContext';
import EditorContext, { GUIDES } from '../EditorContext';
import Pivot from './Pivot';
import Ruler from './Ruler';
import PieceOffset from './PieceOffset';
import ActiveObjects from './ActiveObjects';
import ActiveContainerBorder from './ActiveContainerBorder';

const useStyles = createUseStyles({
  pieceEditable: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
});

const PieceEditable = ({ top, left }) => {
  const classes = useStyles();
  return (
    <div className={classes.pieceEditable}>
      <EditableContext.Provider>
        <EditorContext.Consumer>
          {({ isVisibleTool }) => (
            <Ruler top={top} left={left} isVisible={isVisibleTool(GUIDES)}>
              <PopoverObject />
              <ZoomBox>
                <Piece top={top} left={left} />
              </ZoomBox>
              <PieceBorder top={top} left={left} />
              <PieceOffset left={left} top={top}>
                <ActiveContainerBorder />
                <ActiveObjects />
                <TransformBox />
                <Pivot />
              </PieceOffset>
            </Ruler>
          )}
        </EditorContext.Consumer>
      </EditableContext.Provider>
    </div>
  );
};

PieceEditable.propTypes = {
  left: number.isRequired,
  top: number.isRequired,
};

export default PieceEditable;
