import { LEFT, RIGHT, CENTER, TOP, BOTTOM } from '../../geometry';

export const DIRECTIONS = {
  LEFT,
  TOP,
  RIGHT,
  BOTTOM,
};

export const BORDER_SIZE = 2;
export const DOT_SIZE = 4;
export const TRIGGER_SIZE = 20;

export const FULL_DOT_SIZE = DOT_SIZE + BORDER_SIZE * 2;

const HALF_BORDER = BORDER_SIZE / 2;

const DOT_POSITIONS = {
  [LEFT]: -FULL_DOT_SIZE / 2 - HALF_BORDER,
  [TOP]: -FULL_DOT_SIZE / 2 - HALF_BORDER,
  [RIGHT]: -FULL_DOT_SIZE / 2 - HALF_BORDER,
  [BOTTOM]: -FULL_DOT_SIZE / 2 - HALF_BORDER,
  [CENTER]: `calc(50% - ${FULL_DOT_SIZE / 2 - HALF_BORDER}px)`,
};

const TRIGGER_POSITIONS = {
  [LEFT]: -TRIGGER_SIZE / 2,
  [TOP]: -TRIGGER_SIZE / 2,
  [RIGHT]: -TRIGGER_SIZE / 2,
  [CENTER]: `calc(50% - ${TRIGGER_SIZE / 2}px)`,
  [BOTTOM]: -TRIGGER_SIZE / 2,
};

export const DOTS_POSITIONS = [
  // top
  [
    {
      left: DOT_POSITIONS[LEFT],
      top: DOT_POSITIONS[TOP],
    },
    `${LEFT}-${TOP}`,
  ],
  [
    {
      left: DOT_POSITIONS[CENTER],
      top: DOT_POSITIONS[TOP],
    },
    `${TOP}-${CENTER}`,
  ],
  [
    {
      right: DOT_POSITIONS[RIGHT],
      top: DOT_POSITIONS[TOP],
    },
    `${RIGHT}-${TOP}`,
  ],
  // middle
  [
    {
      left: DOT_POSITIONS[LEFT],
      top: DOT_POSITIONS[CENTER],
    },
    `${LEFT}-${CENTER}`,
  ],
  [
    {
      right: DOT_POSITIONS[RIGHT],
      top: DOT_POSITIONS[CENTER],
    },
    `${RIGHT}-${CENTER}`,
  ],
  // bottom
  [
    {
      left: DOT_POSITIONS[LEFT],
      bottom: DOT_POSITIONS[BOTTOM],
    },
    `${LEFT}-${BOTTOM}`,
  ],
  [
    {
      left: DOT_POSITIONS[CENTER],
      bottom: DOT_POSITIONS[BOTTOM],
    },
    `${CENTER}-${BOTTOM}`,
  ],
  [
    {
      right: DOT_POSITIONS[RIGHT],
      bottom: DOT_POSITIONS[BOTTOM],
    },
    `${RIGHT}-${BOTTOM}`,
  ],
];

const cursor = 'move';
export const TRIGGERS_POSITIONS = [
  // top
  [
    {
      left: TRIGGER_POSITIONS[LEFT],
      top: TRIGGER_POSITIONS[TOP],
      cursor,
    },
    [LEFT, TOP],
  ],
  [
    {
      left: TRIGGER_POSITIONS[CENTER],
      top: TRIGGER_POSITIONS[TOP],
      cursor,
    },
    [TOP],
  ],
  [
    {
      right: TRIGGER_POSITIONS[RIGHT],
      top: TRIGGER_POSITIONS[TOP],
      cursor,
    },
    [RIGHT, TOP],
  ],

  // middle
  [
    {
      left: TRIGGER_POSITIONS[LEFT],
      top: TRIGGER_POSITIONS[CENTER],
      cursor,
    },
    [LEFT],
  ],
  [
    {
      right: TRIGGER_POSITIONS[RIGHT],
      top: TRIGGER_POSITIONS[CENTER],
      cursor,
    },
    [RIGHT],
  ],

  // bottom
  [
    {
      left: TRIGGER_POSITIONS[LEFT],
      bottom: TRIGGER_POSITIONS[BOTTOM],
      cursor,
    },
    [LEFT, BOTTOM],
  ],
  [
    {
      left: TRIGGER_POSITIONS[CENTER],
      bottom: TRIGGER_POSITIONS[BOTTOM],
      cursor,
    },
    [BOTTOM],
  ],
  [
    {
      right: TRIGGER_POSITIONS[RIGHT],
      bottom: TRIGGER_POSITIONS[BOTTOM],
      cursor,
    },
    [RIGHT, BOTTOM],
  ],
];
