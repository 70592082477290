import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  concat,
  InMemoryCache,
} from '@apollo/client';
import fetch from 'unfetch';
import { getAccessToken } from '../auth';
import { getUrlScheme } from '../api';

/* eslint-disable-next-line  */
const getServerUrl = () => `${getUrlScheme()}://${SERVER_NAME_API}/projects`;

const httpLink = new HttpLink({ uri: getServerUrl(), fetch });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: getAccessToken(),
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

export default client;
