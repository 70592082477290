/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { object, bool, number, func } from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { getZoomScale } from '../stage';
import { StageMode, getStageMode } from '../editor';
import MovableComponent from '../MovableComponent';

class PanArea extends Component {
  constructor() {
    super();

    this.state = { isDragging: false };

    this.onDown = this.onDown.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onUp = this.onUp.bind(this);

    this.isDragging = false;
  }

  onDown() {
    this.setState({ isDragging: true });
  }

  onMove(position) {
    this.props.update({
      left: position.x,
      top: position.y,
    });
  }

  onUp() {
    this.setState({ isDragging: false });
  }

  render() {
    if (!this.props.enabled) return null;

    const { scale } = this.props;
    const style = {
      cursor: this.state.isDragging ? 'grabbing' : 'grab',
      touchAction: 'none',
    };

    return (
      <MovableComponent
        scale={scale}
        onDown={this.onDown}
        onMove={this.onMove}
        onUp={this.onUp}
      >
        {movableProps => (
          <div
            {...movableProps}
            className={this.props.classes.pan}
            style={style}
          />
        )}
      </MovableComponent>
    );
  }
}

PanArea.propTypes = {
  classes: object.isRequired,
  enabled: bool.isRequired,
  scale: number.isRequired,
  update: func.isRequired,
};

const style = injectSheet({
  pan: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
});

const container = connect((state, { alwaysEnabled }) => ({
  scale: getZoomScale(state),
  enabled: alwaysEnabled || getStageMode(state) === StageMode.pan,
}));

export default container(style(PanArea));
