import Provider from './Provider';
import { Consumer } from './Context';

export { default as Provider } from './Provider';
export { Consumer, withEditableState } from './Context';
export { default as ObjectConsumer } from './ObjectConsumer';

export * from './test-utils';

export default {
  Provider,
  Consumer,
};
