import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { button as colors } from '../colors';

const useStyles = createUseStyles({
  simpleIconButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fill: colors.fg.normal,
    '&:hover': {
      fill: colors.fg.hover,
    },
    '&:active': {
      fill: colors.fg.active,
    },
    '&:disabled': {
      fill: colors.fg.disabled,
      cursor: 'default',
    },
  },
});

const SimpleIconButton = ({ icon, size, ...props }) => {
  const classes = useStyles();
  return (
    <button
      type="button"
      className={classes.simpleIconButton}
      style={{ width: size }}
      {...props}
    >
      {icon}
    </button>
  );
};

SimpleIconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  size: PropTypes.number.isRequired,
};

export default SimpleIconButton;
