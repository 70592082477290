import { Map } from 'immutable';
import { compose, translate, rotateDEG, scale } from 'transformation-matrix';
import { CLIPPATH } from './type';

export const hasObjectClipPath = (object = Map({})) =>
  object.get('clipPathObjectID', null) !== null;

export const getObjectMatrix = object => {
  switch (object.type) {
    case CLIPPATH:
      return translate(object.x, object.y);
    default: {
      const { originX, originY } = object;

      const matrix = compose(
        translate(object.x, object.y),
        rotateDEG(object.rotation, originX, originY),
        scale(object.scaleX, object.scaleY, originX, originY),
      );

      return matrix;
    }
  }
};

export const clipPathFilter = obj => obj.type === CLIPPATH;
