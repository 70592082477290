import { List } from 'immutable';
import cuid from 'cuid';
import { createReducer } from '../utils';
import * as types from './types';
import { GuideModel } from '../models/guide';

const StateModel = List();

const initFromServer = ({ guides }) => () =>
  List(
    guides.reduce(
      (curr, guide) => [
        ...curr,
        GuideModel({ ...guide, createdAt: new Date(guide.createdAt) }),
      ],
      [],
    ),
  );

const createGuide = ({ guide }) => state =>
  state.push(
    new GuideModel({
      ...guide,
      createdAt: new Date(),
      id: cuid(),
    }),
  );

const deleteGuide = ({ guideID }) => state =>
  state.filter(guide => guide.id !== guideID);

const deleteGuidesWithPieceID = ({ pieceID }) => state =>
  state.filter(guide => guide.pieceID !== pieceID);

const moveGuide = ({ guideID, position }) => state =>
  state.map(guide => (guide.id === guideID ? guide.merge(position) : guide));

export default createReducer(StateModel, {
  [types.INIT_FROM_SERVER]: initFromServer,
  [types.CREATE_GUIDE]: createGuide,
  [types.DELETE_GUIDE]: deleteGuide,
  [types.REMOVE_PIECE]: deleteGuidesWithPieceID,
  [types.MOVE_GUIDE]: moveGuide,
});
