import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CLIPPATH, TEXT } from '../models';
import { getSelectedObjects } from '../editor';
import { extractTypes } from './utils';
import TextTools from './TextTools';
import ClipPathTools from './ClipPathTools';
import CommonTools from './CommonTools';

const ActiveTools = ({
  selectionSize,
  hasClipPathSelected,
  hasTextSelected,
}) => {
  if (selectionSize === 1 && hasClipPathSelected) {
    return <ClipPathTools />;
  }
  if (selectionSize === 1 && hasTextSelected) {
    return <TextTools />;
  }
  return <CommonTools />;
};

ActiveTools.propTypes = {
  selectionSize: PropTypes.number.isRequired,
  hasClipPathSelected: PropTypes.bool.isRequired,
  hasTextSelected: PropTypes.bool.isRequired,
};

const container = connect(state => {
  const objects = getSelectedObjects(state);
  const selectedTypes = extractTypes(objects);
  const hasClipPathSelected = selectedTypes.has(CLIPPATH);
  const hasTextSelected = selectedTypes.has(TEXT);

  return {
    selectionSize: objects.size,
    hasClipPathSelected,
    hasTextSelected,
  };
});

export default container(ActiveTools);
