import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { incrementObjectsProperties } from '../../project';
import { colors } from '../../ui';
import { clipPathFilter, CLIPPATH } from '../../models';
import { getCurrentTime } from '../../playback';
import { getZoomScale } from '../../stage';
import {
  getObjectIsSelected,
  getIsObjectLocked,
  getIsObjectVisible,
  selectObjectOnClick,
  selectObjectOnly,
  removeObjectFromSelection,
  setCurrentObjectID,
  clearObjectsSelection,
} from '../../editor';
import GuidedMove from '../guidedMove';
import MovableComponent from '../../MovableComponent';
import { withEditableState } from '../../EditableContext';
import {
  getBoundingBoxFromObject,
  extractRotationFromMatrix,
  extractScaleFromMatrix,
} from '../../geometry';
import { getParentMatrixByObjectID } from '../../selectors';
import EditorContext, { BORDER } from '../../EditorContext';

const BORDER_WIDTH = 1;

export const getObjectBorderStyle = ({
  area,
  zoomScale,
  borderColor,
  isBorderVisible,
  isLocked,
}) => {
  const borderWidth = BORDER_WIDTH * zoomScale;

  const x = area.left * zoomScale - BORDER_WIDTH;
  const y = area.top * zoomScale - BORDER_WIDTH;

  return {
    width: (area.width + BORDER_WIDTH * 2) * zoomScale,
    height: (area.height + BORDER_WIDTH * 2) * zoomScale,
    transform: `translate(${x}px, ${y}px) rotate(${area.angleDeg}deg)`,
    borderWidth,
    borderColor: isBorderVisible ? borderColor : 'transparent',
    borderStyle: 'dashed',

    // FIXME it is a workaround to work for two selected types of objects
    pointerEvents: isLocked ? 'none' : 'all',
  };
};

class DraggableObject extends Component {
  constructor() {
    super();

    this.alreadySelected = false;
    this.moved = false;
    this.guidedMove = new GuidedMove();

    this.onDown = this.onDown.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onUp = this.onUp.bind(this);
  }

  onDown(event) {
    const { editableState } = this.props;

    if (this.props.selectableRef && this.props.selectableRef.current) {
      this.props.selectableRef.current.focus({ preventScroll: true });
    }
    this.alreadySelected = this.props.isSelected;
    this.props.select(event);

    editableState.begin();
  }

  onMove(position, event) {
    this.moved = true;

    let currentPosition = {
      left: position.x,
      top: position.y,
    };

    if (event.shiftKey) {
      currentPosition = this.guidedMove.calculatePosition(currentPosition);
    } else if (this.guidedMove.hasMove()) {
      this.guidedMove.resetValues();
    }

    const { left, top } = currentPosition;
    const { editableState, parentScale } = this.props;
    let { objects } = editableState;

    if (objects.size > 1) {
      objects = editableState.objects.filterNot(clipPathFilter);
    }

    editableState.updateObjects(
      objects.map(obj =>
        obj.setPosition(
          obj.x + left / parentScale.scaleX,
          obj.y + top / parentScale.scaleY,
          this.props.currentTime,
        ),
      ),
    );
  }

  onUp(event) {
    const { editableState } = this.props;

    if (!this.moved && event.shiftKey && this.alreadySelected) {
      this.props.removeObjectFromSelection();
    }

    this.alreadySelected = false;
    this.moved = false;
    this.guidedMove.resetValues();
    editableState.commit();
  }

  render() {
    const {
      area,
      isLocked,
      isVisible,
      classes,
      zoomScale,
      selectableRef,
      parentMatrix,
      borderColor,
      isBorderVisible,
      onContextMenu,
      onDoubleClick,
    } = this.props;

    if (!isVisible) {
      return null;
    }

    return (
      <EditorContext.Consumer>
        {({ isVisibleTool }) => (
          <MovableComponent
            angle={extractRotationFromMatrix(parentMatrix)}
            scale={zoomScale}
            onDown={this.onDown}
            onMove={this.onMove}
            onUp={this.onUp}
            disabled={isLocked}
          >
            {movableProps => (
              <div
                {...movableProps}
                ref={selectableRef}
                className={classes.objectBorder}
                style={getObjectBorderStyle({
                  area,
                  zoomScale,
                  borderColor,
                  isBorderVisible: isVisibleTool(BORDER) && isBorderVisible,
                  isLocked,
                })}
                onContextMenu={onContextMenu}
                onDoubleClick={onDoubleClick}
              />
            )}
          </MovableComponent>
        )}
      </EditorContext.Consumer>
    );
  }
}

DraggableObject.defaultProps = {
  isBorderVisible: true,
  selectableRef: null,
  onContextMenu: null,
  onDoubleClick: null,
};

DraggableObject.propTypes = {
  area: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  selectIfNotSelected: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
  isLocked: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  removeObjectFromSelection: PropTypes.func.isRequired,
  editableState: PropTypes.object.isRequired,
  zoomScale: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  object: PropTypes.object.isRequired,
  parentMatrix: PropTypes.object.isRequired,
  parentScale: PropTypes.object.isRequired,
  borderColor: PropTypes.string.isRequired,
  selectableRef: PropTypes.object,
  isBorderVisible: PropTypes.bool,
  onContextMenu: PropTypes.func,
  onDoubleClick: PropTypes.func,
};

const container = connect(
  (state, { object, editableState }) => {
    let isLocked = getIsObjectLocked(state, object.id);
    const isSelected = getObjectIsSelected(object.id, state);
    const parentMatrix = getParentMatrixByObjectID(state, object.id, {
      cache: editableState.objects,
    });
    const area = getBoundingBoxFromObject(object, parentMatrix);
    const parentScale = extractScaleFromMatrix(parentMatrix);

    let borderColor = colors.primary;
    let isBorderVisible = true;
    if (object.type === CLIPPATH) {
      borderColor = colors.info;
      const targetObjectIsSelected = getObjectIsSelected(
        object.targetObjectID,
        state,
      );
      isBorderVisible = targetObjectIsSelected;
      isLocked =
        (!targetObjectIsSelected && !isSelected) ||
        (targetObjectIsSelected && isSelected);
    } else if (object.clipPathObjectID) {
      const clipPathObjectIsSelected = getObjectIsSelected(
        object.clipPathObjectID,
        state,
      );

      borderColor = clipPathObjectIsSelected ? colors.info : borderColor;
    }

    return {
      area,
      parentMatrix,
      isLocked,
      isSelectable: !isLocked,
      isVisible: getIsObjectVisible(state, object.id),
      assetURL: object.assetURL || '',
      isSelected,
      zoomScale: getZoomScale(state),
      currentTime: getCurrentTime(state),
      parentScale,
      borderColor,
      isBorderVisible,
    };
  },
  {
    incrementObjectsProperties,
    selectObjectOnClick,
    selectObjectOnly,
    removeObjectFromSelection,
    setCurrentObjectID,
    clearObjectsSelection,
  },
  ({ isSelected, ...stateProps }, actions, { object, ...ownProps }) => ({
    object,
    isSelected,
    ...stateProps,
    ...ownProps,

    removeObjectFromSelection() {
      actions.removeObjectFromSelection(object.id);
    },

    select(event) {
      actions.selectObjectOnClick(event, object.id);
    },

    selectIfNotSelected() {
      if (!isSelected) {
        actions.selectObjectOnly(object.id);
      }
    },
  }),
);

const style = injectSheet({
  objectBorder: {
    position: 'absolute',
    outline: 'none',
  },
});

export default withEditableState(container(style(DraggableObject)));
