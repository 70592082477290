import React from 'react';
import { object, node, func } from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  property: {
    display: 'flex',
    height: ({ height }) => height,
    position: 'relative',
  },
});
const TimelineProperty = ({
  onContextMenu,
  children,
  forwardRef,
  ...props
}) => {
  const classes = useStyles(props);
  return (
    <div
      ref={forwardRef}
      className={classes.property}
      onContextMenu={onContextMenu}
    >
      {children}
    </div>
  );
};

TimelineProperty.propTypes = {
  onContextMenu: func.isRequired,
  children: node.isRequired,
  forwardRef: object.isRequired,
};

export default TimelineProperty;
