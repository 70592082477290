import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { title4, colors } from '../ui';
import { getCurrentProjectName } from '../project';

const useStyles = createUseStyles({
  projectTitleContainer: {
    display: 'flex',
    minWidth: 0,
    flexGrow: 1,
    marginRight: 48,
  },
  projectTitle: {
    ...title4,
    color: colors.gray1,
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const Title = ({ projectName }) => {
  const classes = useStyles();
  return (
    <div className={classes.projectTitleContainer}>
      <h1 className={classes.projectTitle}>{projectName}</h1>
    </div>
  );
};

Title.propTypes = {
  projectName: PropTypes.string.isRequired,
};

const container = connect(state => ({
  projectName: getCurrentProjectName(state),
}));

export default container(Title);
