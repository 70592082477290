import React from 'react';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';

const useStyles = createUseStyles({
  splitter: {
    backgroundColor: colors.black2,
    height: 32,
    margin: [0, 16],
    width: 1,
  },
});

const Splitter = () => {
  const classes = useStyles();
  return <div className={classes.splitter} />;
};

export default Splitter;
