export const SET_ASSETS_TO_UPLOAD = 'PANEL_ASSETS/SET_ASSETS_TO_UPLOAD';
export const REMOVE_ASSET_TO_UPLOAD = 'PANEL_ASSETS/REMOVE_ASSET_TO_UPLOAD';

export const DELETE_ASSET = 'PANEL_ASSETS/DELETE_ASSET';

export const SELECT_ASSETS_ONLY = 'SELECTION/ASSETS/SELECT_ONLY';
export const ADD_ASSETS_TO_SELECTION = 'SELECTION/ASSETS/ADD_TO_SELECTION';
export const REMOVE_ASSETS_FROM_SELECTION =
  'SELECTION/ASSETS/REMOVE_FROM_SELECTION';
export const CLEAR_ASSETS_SELECTION = 'SELECTION/ASSETS/CLEAR_SELECTION';

export const REQUEST_MOVE_ASSETS_TO_COLLECTION =
  'PANEL_ASSETS/REQUEST_MOVE_TO_COLLECTION';
