import parseInt from 'lodash/parseInt';

const MASK = ' s';
const REGEX = /^\d+ s$/;
export const DEFAULT_VALUE = `0${MASK}`;

export const getValueFromProps = milliseconds => {
  const seconds = Math.floor(milliseconds / 1000);

  return `${seconds} s`;
};

export const getTime = timeStr => ({
  seconds: parseInt(timeStr),
});

export const isValidTime = timeStr => {
  const { seconds } = getTime(timeStr);

  return seconds >= 0;
};

export const convertToMS = ({ seconds }) => seconds * 1000;

export const isValidValue = timeStr => timeStr.match(REGEX);

export const oldDigitIsMask = oldDigit => oldDigit === ' ' || oldDigit === 's';

export const getNewDigitWithMask = (newDigit, oldDigit) => ({
  newDigit: `${newDigit}${oldDigit}`,
  deltaPosition: 0,
});

export const getDigitFromOldDigit = oldDigit =>
  oldDigitIsMask(oldDigit) ? oldDigit : '';

export const isOnlyMask = newValue => newValue === MASK;
