import { Map } from 'immutable';
import { NodeModel } from '../models';
import { createReducer } from '../utils';
import * as types from './types';

const extractNodesFromObject = (object, parentID = null) => {
  if (!(object.childrens instanceof Array)) {
    return [];
  }

  let nodeList = [
    NodeModel({
      objectID: object.id,
      parentID,
    }),
  ];

  object.childrens.forEach(children => {
    nodeList = nodeList.concat(extractNodesFromObject(children, object.id));
    nodeList[0] = nodeList[0].update('childrensID', childrens =>
      childrens.push(children.id),
    );
  });

  return nodeList;
};

const initFromServer = ({ pieces }) => () =>
  Map(
    pieces.reduce(
      (curr, piece) => ({
        ...curr,
        [piece.id]: Map(
          extractNodesFromObject(piece.objects).reduce((currentArray, node) => {
            currentArray.push([node.objectID, node]);
            return currentArray;
          }, []),
        ),
      }),
      {},
    ),
  );

const getIndexIn = (pieces, id) => pieces.indexOf(id);

const sortByLayerOrderAsc = pieces => (firstValue, secondValue) => {
  const firstValueIndex = pieces.indexOf(firstValue);
  const secondValueIndex = pieces.indexOf(secondValue);
  if (firstValueIndex > secondValueIndex) {
    return 1;
  }

  if (firstValueIndex < secondValueIndex) {
    return -1;
  }

  return 0;
};

const sortByLayerOrderDesc = pieces => (firstValue, secondValue) => {
  const firstValueIndex = pieces.indexOf(firstValue);
  const secondValueIndex = pieces.indexOf(secondValue);
  if (firstValueIndex > secondValueIndex) {
    return -1;
  }

  if (firstValueIndex < secondValueIndex) {
    return 1;
  }

  return 0;
};

const filterObjectsInSelection = selection => id =>
  selection.indexOf(id) === -1;

const moveObjectsToTop = ({ ids, pieceID, parentObjectID }) => state => {
  return state.updateIn([pieceID, parentObjectID, 'childrensID'], pieces =>
    pieces
      .filter(filterObjectsInSelection(ids))
      .push(...pieces.filterNot(filterObjectsInSelection(ids))),
  );
};

const moveObjectsToBottom = ({ ids, pieceID, parentObjectID }) => state =>
  state.updateIn([pieceID, parentObjectID, 'childrensID'], pieces =>
    pieces
      .filter(filterObjectsInSelection(ids))
      .unshift(...pieces.filterNot(filterObjectsInSelection(ids))),
  );

const moveObjectsOneStepToBottom = ({
  ids,
  pieceID,
  parentObjectID,
}) => state =>
  state.updateIn([pieceID, parentObjectID, 'childrensID'], pieces => {
    let newPieces = pieces;
    let lastIndex = null;

    ids.sort(sortByLayerOrderAsc(pieces)).forEach(id => {
      let insertIndex = getIndexIn(pieces, id) - 1;
      if (lastIndex === insertIndex) {
        lastIndex = insertIndex + 1;
        return;
      }

      if (insertIndex < 0) {
        insertIndex = 0;
      }

      lastIndex = insertIndex;
      newPieces = newPieces
        .filter(filterObjectsInSelection([id]))
        .splice(insertIndex, 0, id);
    });

    return newPieces;
  });

const moveObjectsOneStepToTop = ({ ids, pieceID, parentObjectID }) => state =>
  state.updateIn([pieceID, parentObjectID, 'childrensID'], pieces => {
    let newPieces = pieces;
    let lastIndex = null;
    ids.sort(sortByLayerOrderDesc(pieces)).forEach(id => {
      let insertIndex = getIndexIn(pieces, id) + 1;

      if (lastIndex === insertIndex) {
        lastIndex = insertIndex - 1;
        return;
      }

      if (insertIndex >= pieces.size - 1) {
        insertIndex = pieces.size - 1;
      }

      lastIndex = insertIndex;
      newPieces = newPieces
        .filter(filterObjectsInSelection([id]))
        .splice(insertIndex, 0, id);
    });

    return newPieces;
  });

const moveObjectsToIndex = ({ ids, pieceID, parentObjectID, index }) => state =>
  state.updateIn([pieceID, parentObjectID, 'childrensID'], objects => {
    // Filter some clippaths ids that could be present
    // Keep the same order from layer list on editor
    const orderedIDs = ids
      .filter(id => objects.includes(id))
      .sort(sortByLayerOrderAsc(objects));

    // Inject the array of ids collected by ui in expected index
    // and filter the ids present in this array
    // then flatten the array to be flat
    return objects
      .insert(index, orderedIDs)
      .filter(filterObjectsInSelection(orderedIDs))
      .flatten();
  });

const removePiece = ({ pieceID }) => state => state.delete(pieceID);

const resetByPieceID = () => () => Map();

const handlers = {
  [types.INIT_FROM_SERVER]: initFromServer,
  [types.MOVE_OBJECTS_TO_TOP]: moveObjectsToTop,
  [types.MOVE_OBJECTS_TO_BOTTOM]: moveObjectsToBottom,
  [types.MOVE_OBJECTS_ONE_STEP_TO_BOTTOM]: moveObjectsOneStepToBottom,
  [types.MOVE_OBJECTS_ONE_STEP_TO_TOP]: moveObjectsOneStepToTop,
  [types.MOVE_OBJECTS_TO_INDEX]: moveObjectsToIndex,
  [types.REMOVE_PIECE]: removePiece,
  [types.UNSET_PROJECT]: resetByPieceID,
};

const byPieceID = createReducer(Map(), handlers);

export default byPieceID;
