import { Component } from 'react';
import { object, string, func } from 'prop-types';
import { withMenuStates } from './MenuProvider';

class MenuConsumer extends Component {
  constructor(props) {
    super(props);
    props.menuStates.registerMenu(props.menuKey);

    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.getMenuState = this.getMenuState.bind(this);
  }

  getMenuState() {
    const { menuStates, menuKey } = this.props;
    return menuStates.menus[menuKey];
  }

  openMenu() {
    this.props.menuStates.openMenu(this.props.menuKey);
  }

  closeMenu() {
    this.props.menuStates.closeMenu(this.props.menuKey);
  }

  toggleMenu() {
    if (this.getMenuState().isVisible) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  render() {
    const { openMenu, closeMenu, toggleMenu } = this;
    const { menuStates, children, menuKey } = this.props;

    const menuState = menuStates.menus[menuKey] || { isVisible: false };

    return children({
      isVisible: menuState.isVisible,
      openMenu,
      closeMenu,
      toggleMenu,
    });
  }
}

MenuConsumer.propTypes = {
  children: func.isRequired,
  menuKey: string.isRequired,
  menuStates: object.isRequired,
};

export default withMenuStates(MenuConsumer);
