import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { TEXT } from '../models';

dayjs.extend(relativeTime);

export const fromNow = date => dayjs(date).fromNow();

const buildActionPayload = (requiredArgs, passedArgs) =>
  requiredArgs &&
  requiredArgs.reduce((curr, kwarg) => {
    const value = passedArgs[kwarg];
    if (value === undefined) {
      throw new Error(`Missing '${kwarg}' argument`);
    }
    return { ...curr, [kwarg]: value };
  }, {});

export const createAction = ({ type, payload, flags }) => kwargs => ({
  type,
  flags,
  payload: buildActionPayload(payload, kwargs),
});

export const createReducer = (initialState, handlers) => (
  state = initialState,
  { type, payload },
) => {
  const hasHandler = Object.prototype.hasOwnProperty.call(handlers, type);
  if (!hasHandler) {
    return state;
  }
  return handlers[type](payload)(state);
};

export const clipText = (text, maxLength = 30) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength - 3).concat('...');
};

export const isProduction = () => process.env.NODE_ENV === 'production';

export const decodeFilename = filename => {
  try {
    return decodeURI(filename);
  } catch (err) {
    return filename;
  }
};

export const splitPath = path => {
  const list = path.split('/');
  const index = list.length - 1;
  return {
    filename: decodeFilename(list[index]),
    path: list.slice(0, index).join('/'),
  };
};

export const FILENAME_INVALID_CHARS = /[?%#\\/]/;

export const injectMaskIDs = maskMap => (list, childrenID) => {
  if (list.includes(childrenID)) {
    return list;
  }

  let currentList = list.push(childrenID);
  const clipPathObjectID = maskMap.get(childrenID);
  if (clipPathObjectID && !currentList.includes(clipPathObjectID)) {
    currentList = currentList.push(clipPathObjectID);
  }
  return currentList;
};

export const getFormattedDate = (date = dayjs()) =>
  date.format('YY-MM-DDTHH-mm');

/* eslint-disable */
const prohibitedCharactersNTFS = /[*/:<>?|"\\]|[\u0000-\u001f]|\u007f/g;
const controlledCharacters = /[\u0080-\u009f]/g;
/* eslint-enable */

const setOfRegExProhibited = new RegExp(
  `${controlledCharacters.source}|${prohibitedCharactersNTFS.source}`,
  'gu',
);

const defaultCharacter = '_';

export const formatFilename = projectName =>
  projectName.replaceAll(setOfRegExProhibited, defaultCharacter);

export const everyTextObjectHaveTextContent = arrObject =>
  arrObject.reduce((acc, object) => {
    if (object.type === TEXT) {
      return acc && object.textContent !== '';
    }
    return acc;
  }, true);
