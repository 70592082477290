import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  formField: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: props => (props.noMargin ? null : 4),
    marginBottom: props => (props.noMargin ? null : 4),
    width: props => (props.width ? props.width : null),
  },
});

const FormGroup = ({ children, ...props }) => {
  const classes = useStyles(props);
  return <div className={classes.formField}>{children}</div>;
};

FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormGroup;
