import React from 'react';
import { createUseStyles } from 'react-jss';
import colors from '../colors';
import Preview from './Preview';

const useStyles = createUseStyles({
  containerBox: {
    backgroundColor: colors.black3,
    flexShrink: 0,
    height: 164,
    padding: 16,
    width: '100%',
  },
  previewBox: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.black2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
});

const AssetPreview = props => {
  const classes = useStyles();
  return (
    <div className={classes.containerBox}>
      <div className={classes.previewBox}>
        <Preview {...props} />
      </div>
    </div>
  );
};

export default AssetPreview;
