import React from 'react';
import { string } from 'prop-types';
import { createUseStyles } from 'react-jss';
import { connect } from 'react-redux';
import { getCurrentProjectID } from '../project';
import { colors } from '../ui';
import { IconButtonOpenCreateCollectionModal } from './ModalCreateCollection';
import InputUploadAsset from './InputUploadAsset';
import IconButtonUpload from './IconButtonUpload';

const useStyles = createUseStyles({
  bottomPanel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: colors.black2,
    height: 48,
    padding: [0, 16],
  },
});

const MenuBottom = ({ projectID }) => {
  const classes = useStyles();
  let inputElm = null;
  return (
    <div className={classes.bottomPanel}>
      <InputUploadAsset
        collectionPath={`/${projectID}`}
        innerRef={c => {
          inputElm = c;
        }}
      />
      <IconButtonUpload onClick={() => inputElm.click()} />
      <IconButtonOpenCreateCollectionModal />
    </div>
  );
};

MenuBottom.propTypes = {
  projectID: string.isRequired,
};

const container = connect(state => ({
  projectID: getCurrentProjectID(state),
}));

export default container(MenuBottom);
