import React from 'react';
import { node } from 'prop-types';
import { refreshToken, initAuthService } from './api';

const INTERVAL = 30000;

class Auth extends React.Component {
  constructor() {
    super();
    this.state = { loading: true };
    this.interval = null;
  }

  componentDidMount() {
    initAuthService().then(() => {
      this.setState({ loading: false });
      this.interval = setInterval(refreshToken, INTERVAL);
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    if (this.state.loading) return null;
    return this.props.children;
  }
}

Auth.propTypes = {
  children: node.isRequired,
};

export default Auth;
