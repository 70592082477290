import React from 'react';
import { arrayOf, string } from 'prop-types';
import { connect } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';
import LinkPiece from './LinkPiece';
import ModalAddSizes from './ModalAddSizes';
import AddSizesButton from './AddSizesButton';
import SelectPiecesOrder from './SelectPiecesOrder';
import { getSortedPieces } from './reducers';
import { CustomScroll } from '../CustomScroll';
import ModalShareLink, { ShareButton } from './ModalShareLink';
import Container from './Container';
import PiecesContainer from './PiecesContainer';
import BuildVersion from './BuildVersion';

const useStyles = createUseStyles({
  subHeader: {
    alignItems: 'center',
    background: colors.black4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    height: 88,
    padding: [0, 48],
    zIndex: 2,
    boxShadow:
      '0 16px 16px 0 rgba(0,0,0,0.16), 0 2px 8px 0 rgba(0,0,0,0.08) !important',
  },
  buttonGroup: {
    display: 'flex',
  },
});

const ViewPieces = ({ pieces }) => {
  const classes = useStyles();
  return (
    <Container>
      <ModalAddSizes />
      <ModalShareLink />
      <div className={classes.subHeader}>
        <SelectPiecesOrder />
        <div className={classes.buttonGroup}>
          <AddSizesButton />
          <ShareButton label="Share" />
          <ShareButton label="QA Link" isQA />
        </div>
      </div>
      <CustomScroll>
        <PiecesContainer>
          {pieces.map(pieceID => (
            <LinkPiece key={pieceID} pieceID={pieceID} />
          ))}
        </PiecesContainer>
      </CustomScroll>
      <BuildVersion />
    </Container>
  );
};

ViewPieces.propTypes = {
  pieces: arrayOf(string).isRequired,
};

const container = connect(state => ({
  pieces: getSortedPieces(state),
}));

export default container(ViewPieces);
