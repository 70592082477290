import React from 'react';
import { createUseStyles } from 'react-jss';
import { node } from 'prop-types';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: [0, 'auto'],
    width: 1040,
    height: '100%',
  },
});

const Container = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};

Container.propTypes = {
  children: node.isRequired,
};

export default Container;
