import React from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';

const useStyles = createUseStyles({
  svgIcon: {
    width: '100%',
    display: 'block',
  },
});

const SvgIcon = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <svg className={classes.svgIcon} viewBox="0 0 50 50" {...props}>
      {children}
    </svg>
  );
};

SvgIcon.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SvgIcon;
