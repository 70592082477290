import { isProduction, FILENAME_INVALID_CHARS } from '../utils';

export const encodeURIIfRequired = uri => {
  const decodedURI = decodeURI(uri);
  if (decodedURI !== uri) {
    return uri;
  }

  return encodeURI(uri);
};

export const getUrlScheme = () => (isProduction() ? 'https' : 'http');

export const urlFactory = apiPath => path => {
  const scheme = getUrlScheme();
  return encodeURIIfRequired(
    /* eslint-disable-next-line  */
    `${scheme}://${SERVER_NAME_API}/${apiPath}${path}`,
  );
};

export const identityDecoder = data => data;

export const keyDecoder = key => data => data[key];

export const nullDecoder = () => null;

export const encodeFilename = filename => {
  if (!filename.match(FILENAME_INVALID_CHARS)) {
    return filename;
  }

  return encodeURIComponent(filename);
};
