import React from 'react';
import PropTypes from 'prop-types';
import { explicitNull, or } from 'airbnb-prop-types';
import { FloatInput } from '../../ui';

const Opacity = ({ opacity, disabled, opacityChange }) => (
  <FloatInput
    id="opacity"
    disabled={disabled}
    onChange={opacityChange}
    value={opacity === null ? null : opacity * 100}
    max={100}
    min={0}
    expanded
    precision={0}
  />
);

Opacity.propTypes = {
  opacity: or([PropTypes.number, explicitNull()]),
  disabled: PropTypes.bool,
  opacityChange: PropTypes.func,
};

Opacity.defaultProps = {
  opacity: null,
  disabled: true,
  opacityChange: () => {},
};

export default Opacity;
