import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';
import * as typography from '../typography';

const useStyles = createUseStyles(theme => ({
  button: {
    ...typography.textRegular,
    ...theme.buttons.default,
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '100%',
    cursor: 'pointer',
    display: 'flex',
    height: 32,
    justifyContent: 'center',
    width: 32,
    padding: 0,
    outline: 'none',
  },
  icon: {
    width: 16,
    height: 16,
  },
}));

const IconButtonDefault = ({ icon, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ ...props, theme });
  delete props.theme; // eslint-disable-line react/prop-types, no-param-reassign
  const Icon = icon;
  return (
    <button type="button" className={classes.button} {...props}>
      {icon && <Icon className={classes.icon} />}
    </button>
  );
};

IconButtonDefault.propTypes = {
  icon: PropTypes.func,
};

IconButtonDefault.defaultProps = {
  icon: null,
};

export default IconButtonDefault;
