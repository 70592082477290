import React from 'react';
import { number, string } from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { colors, label, textBold } from '../ui';
import { url } from '../App';
import { fromNow } from '../utils';
import TogglePopover from './TogglePopover';
import PopoverProject from './PopoverProject';
import ProjectStatus, { READY } from './ProjectStatus';

const useStyles = createUseStyles({
  card: {
    display: 'flex',
    height: 104,
  },
  statusWrapper: {
    flex: 1,
    display: 'flex',
    minWidth: 0,
    opacity: ({ status = READY }) => (status === READY ? 1 : 0.5),
    pointerEvents: ({ status = READY }) => (status === READY ? 'all' : 'none'),
  },
  statusInfo: {
    display: 'flex',
    width: '100%',
  },
  createdAt: {
    minWidth: 140,
    alignSelf: 'center',
    flexGrow: 1,
    color: colors.gray1,
    ...label,
    textAlign: 'right',
  },
  options: {
    marginRight: 16,
    display: 'flex',
    alignItems: 'center',
  },
  info: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    minWidth: 0,
    marginLeft: 24,
    marginRight: 16,
    marginTop: 8,
  },
  name: {
    width: '100%',
    ...textBold,
    lineHeight: '18px',

    '& a': {
      display: 'inline-block',
      width: '100%',
      color: colors.gray1,
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  picture: {
    alignItems: 'center',
    background: '#FF6C4B',
    borderRadius: 8,
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    fontSize: 60,
    fontWeight: 600,
    height: 104,
    justifyContent: 'center',
    lineHeight: '60px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    width: 104,
  },
});

const CardProject = ({ id, name, status, error, createdAt }) => {
  const classes = useStyles({ status });
  const pieceListURL = url.pieceList({ projectID: id });
  return (
    <div className={classes.card}>
      <div className={classes.statusWrapper}>
        <Link to={pieceListURL} className={classes.picture}>
          {name[0]}
        </Link>
        <div className={classes.info}>
          <h3 className={classes.name}>
            <Link to={pieceListURL}>{name}</Link>
          </h3>
          <div className={classes.statusInfo}>
            <ProjectStatus status={status} error={error} />
            <p className={classes.createdAt}>Added {fromNow(createdAt)}</p>
          </div>
        </div>
      </div>
      <div className={classes.options}>
        <TogglePopover id={id} />
        <PopoverProject id={id} />
      </div>
    </div>
  );
};

CardProject.defaultProps = {
  error: null,
};

CardProject.propTypes = {
  createdAt: string.isRequired,
  id: string.isRequired,
  name: string.isRequired,
  status: number.isRequired,
  error: string,
};

export default CardProject;
