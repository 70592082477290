import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  svgShape: {
    width: '100%',
    display: 'block',
    fill: 'white',
  },
});

export const ShapeArrow = () => {
  const classes = useStyles();
  return (
    <svg viewBox="0 0 12 16" className={classes.svgShape}>
      <path d="M11.5 1.33419C11.5 0.597335 10.7964 0 9.92855 0H2.07143C1.20355 0 0.5 0.597335 0.5 1.33419V9.81293C0.499773 10.1212 0.612341 10.4219 0.822143 10.6735C0.847793 10.7237 0.876645 10.7727 0.908571 10.8202L4.67213 15.4165C4.97698 15.7825 5.47207 16 5.99999 16C6.52791 16 7.02299 15.7825 7.32784 15.4165L11.0914 10.8202C11.1233 10.7727 11.1522 10.7237 11.1778 10.6735C11.3892 10.42 11.5018 10.1168 11.5 9.80626V1.33419Z" />
    </svg>
  );
};
