import React from 'react';
import IncreaseIcon from './IncreaseIcon';
import Button from './Button';

const ButtonIncrease = props => (
  <Button {...props}>
    <IncreaseIcon />
  </Button>
);

export default ButtonIncrease;
