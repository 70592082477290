import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { applyToPoint, compose, inverse } from 'transformation-matrix';
import { getParentMatrix } from '../selectors';
import { createObject, getCurrentProjectIsAMP } from '../project';
import { AMP_PROHIBITED_PROPERTIES, makeTextObject } from '../models';
import { getZoomScale, getCurrentPieceID } from '../stage';
import {
  StageMode,
  getStageMode,
  addObjectToSelection,
  setStageMode,
} from '../editor';

const useStyles = createUseStyles({
  textTool: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    cursor: 'cell',
  },
});

const TextTool = ({ left, top, stageRef }) => {
  const classes = useStyles();
  const scale = useSelector(state => getZoomScale(state));
  const pieceID = useSelector(state => getCurrentPieceID(state));
  const enabled = useSelector(state => getStageMode(state) === StageMode.text);
  const parentMatrix = useSelector(state => getParentMatrix(state));
  const projectIsAMP = useSelector(state => getCurrentProjectIsAMP(state));
  const dispatch = useDispatch();
  const onClick = e => {
    const width = 100;
    const height = 100;
    if (!stageRef.current) return;

    const { offsetLeft, offsetTop } = stageRef.current;

    const { x, y } = applyToPoint(compose(inverse(parentMatrix)), {
      x: (e.clientX - offsetLeft) / scale - left,
      y: (e.clientY - offsetTop) / scale - top,
    });
    const object = makeTextObject({
      width,
      height,
      left: x - width / 2,
      top: y - height / 2,
      pieceID,
      editorOptions: {
        prohibitedProperties: projectIsAMP ? AMP_PROHIBITED_PROPERTIES : [],
      },
    });
    dispatch(createObject(object));
    dispatch(setStageMode(StageMode.select));
    dispatch(addObjectToSelection(object.id));
  };
  return (
    enabled && (
      <div
        role="button"
        tabIndex={-1}
        onClick={onClick}
        className={classes.textTool}
      />
    )
  );
};

TextTool.propTypes = {
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  stageRef: PropTypes.object.isRequired,
};

export default TextTool;
