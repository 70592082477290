import { List, Map } from 'immutable';
import { PieceModel } from './piece';
import { KeyframeModel, KeyframeValuesModel } from './keyframe';
import { makeClipPathFromObject } from './properties';
import {
  DIV,
  IMG,
  CLIPPATH,
  VIDEO,
  TEXT,
  makeObject,
  makeVideoObject,
  makeDivObject,
  makeClipPathObject,
  makeTextObject,
} from './object';

const unserializeScalarValue = val => val;

const unserializeValueMap = {
  left: unserializeScalarValue,
  top: unserializeScalarValue,
  width: unserializeScalarValue,
  height: unserializeScalarValue,
  opacity: unserializeScalarValue,
  rotation: unserializeScalarValue,
  originX: unserializeScalarValue,
  originY: unserializeScalarValue,
  translateX: unserializeScalarValue,
  translateY: unserializeScalarValue,
  scaleX: unserializeScalarValue,
  scaleY: unserializeScalarValue,
  clipPath: makeClipPathFromObject,
};

const unserializeKeyframeValues = values =>
  Object.entries(unserializeValueMap).reduce(
    (currentObj, [property, unserializer]) => {
      const value = values[property];

      return {
        ...currentObj,
        [property]: unserializer(value),
      };
    },
    {},
  );

const unserializeKeyframes = keyframes =>
  Map(
    Object.values(keyframes).map(kf => [
      kf.time,
      KeyframeModel({
        ...kf,
        values: KeyframeValuesModel(unserializeKeyframeValues(kf.values)),
      }),
    ]),
  );

export const normalizeObject = object => ({
  ...object,
  forceID: object.id,
  clipPath: makeClipPathFromObject(object.clipPath),
  keyframes: unserializeKeyframes(object.keyframes),
});

export const unserializeObject = object => {
  switch (object.type) {
    case CLIPPATH:
      return makeClipPathObject(normalizeObject(object));
    case DIV:
      return makeDivObject(normalizeObject(object));
    case IMG:
      return makeObject(normalizeObject(object));
    case VIDEO:
      return makeVideoObject(normalizeObject(object));
    case TEXT:
      return makeTextObject(normalizeObject(object));
    default:
      return makeObject();
  }
};

export const unserializer = (serializedPiece, serializedObjects) => {
  const piece = PieceModel(serializedPiece);
  const objects = List(serializedObjects.map(unserializeObject));
  return { piece, objects };
};
