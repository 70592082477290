import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  header: {
    display: 'flex',
    flex: [1, 1, '100%'],
    paddingBottom: 8,
  },
});

const SectionLabelBox = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.header}>{children}</div>;
};

SectionLabelBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionLabelBox;
