import React from 'react';
import ModalApplyAnimation from './ModalApplyAnimation';
import ModalSaveAnimation from './ModalSaveAnimation';

const TimelineModals = () => {
  return (
    <>
      <ModalApplyAnimation />
      <ModalSaveAnimation />
    </>
  );
};

export default TimelineModals;
