import React from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Scroll } from '../Scroll';
import colors from '../colors';
import { itemToString } from './models';
import * as typography from '../typography';

const useStyles = createUseStyles({
  items: {
    background: colors.white,
    borderRadius: 8,
    boxShadow: '0 4px 4px 0 rgba(0,0,0,0.16), 0 0 2px 1px rgba(0,0,0,0.08)',
    color: colors.gray1,
    display: 'inline-block',
    position: 'fixed',
    overflow: 'hidden',
    padding: [16, 0],
    maxHeight: 280,
    width: 'auto',
    zIndex: 1,
  },
  baseItem: {
    ...typography.textRegular,
    color: colors.gray1,
    cursor: 'pointer',
    height: 32,
    lineHeight: '32px',
    padding: [0, 16],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  item: {
    extend: 'baseItem',
  },
  itemHover: {
    extend: 'baseItem',
    background: colors.gray3,
  },
  itemsWrapper: {
    maxHeight: 248,
  },
});

const getItemClass = (currentIndex, itemIndex, classes) => {
  if (currentIndex === itemIndex) {
    return classes.itemHover;
  }
  return classes.item;
};

const getItemKey = item => {
  const value = item.get('value');
  if (value instanceof Object && value.key) {
    return value.key;
  }

  return value;
};

export const getPositionFromRef = ref => {
  if (!ref.current) {
    return {};
  }

  const { left, top } = ref.current.getBoundingClientRect();

  return {
    left,
    top,
  };
};

const Items = ({
  positionRef,
  items,
  getItemProps,
  isOpen,
  highlightedIndex,
}) => {
  const classes = useStyles();
  if (!isOpen || !items.size) {
    return null;
  }

  return (
    <div className={classes.items} style={getPositionFromRef(positionRef)}>
      <Scroll>
        <div className={classes.itemsWrapper}>
          {items.map((item, index) => (
            <div
              key={getItemKey(item)}
              className={getItemClass(highlightedIndex, index, classes)}
              {...getItemProps({ item })}
            >
              {itemToString(item)}
            </div>
          ))}
        </div>
      </Scroll>
    </div>
  );
};

Items.defaultProps = {
  highlightedIndex: null,
};

Items.propTypes = {
  positionRef: PropTypes.object.isRequired,
  getItemProps: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(null),
  ]),
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.instanceOf(List).isRequired,
};

export default Items;
