import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import colors from '../colors';

const getDepthPadding = ({ isSelected = false, depth = 0 }) => {
  if (isSelected && depth === 0) {
    return 8 * (depth + 1);
  }

  if (isSelected && depth > 0) {
    return 12 * (depth + 1);
  }

  return 16 * (depth + 1);
};
const getBackgroundColor = ({ isSelected = false }) =>
  isSelected ? colors.black2 : 'transparent';

export const useStyles = createUseStyles({
  entry: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    height: 32,
    paddingRight: 16,
    paddingLeft: getDepthPadding,
    backgroundColor: getBackgroundColor,
    marginTop: 2,
    marginBottom: 2,
    marginRight: ({ noScroll = false }) => (noScroll ? null : 8),
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
    border: ({ isSelected = false }) =>
      isSelected ? `1px solid${colors.yellow}` : 0,
    borderLeft: ({ isSelected = false }) =>
      isSelected ? `8px solid${colors.yellow}` : 0,

    '&:hover': {
      backgroundColor: colors.black2,
    },
  },
});

const SelectableEntry = ({ children, onClick, ...props }) => {
  const classes = useStyles(props);
  return (
    <div
      role="button"
      tabIndex={-1}
      className={classes.entry}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

SelectableEntry.defaultProps = {
  onClick: () => null,
};

SelectableEntry.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default SelectableEntry;
