import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import colors, { button } from '../colors';

const useStyles = createUseStyles({
  sidebarButton: {
    background: 'transparent',
    height: 32,
    width: 32,
    borderRadius: 100,
    textAlign: 'center',
    lineHeight: 1,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      background: colors.black3,
    },
    '&:active': {
      background: colors.black2,
    },
    '&:disabled': {
      opacity: 0.35,
      '&:hover': {
        background: 'transparent',
      },
      '&:active': {
        background: 'transparent',
      },
    },
  },
  sidebarButtonActive: {
    extend: 'sidebarButton',
    background: colors.black2,
    cursor: 'normal',
    '&:hover': {
      background: 'colors.black2',
    },
  },
  icon: {
    height: 16,
    width: 16,
    fill: colors.white,
  },
});

const SidebarButton = ({ isActive, icon, ...props }) => {
  const classes = useStyles();
  const Icon = icon;
  const cls = isActive ? classes.sidebarButtonActive : classes.sidebarButton;
  return (
    <button type="button" className={cls} {...props}>
      <Icon className={classes.icon} />
    </button>
  );
};

SidebarButton.propTypes = {
  icon: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};

SidebarButton.defaultProps = {
  isActive: false,
};

export default SidebarButton;
