import { useState, useCallback } from 'react';
import { identity, inverse, applyToPoint } from 'transformation-matrix';

const defaultPosition = { x: 0, y: 0 };

export const useDrag = (matrix = identity(), { onUp }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [initialPosition, setInitialPosition] = useState(defaultPosition);
  const [currentPosition, setCurrentPosition] = useState(defaultPosition);

  const onPointerDown = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      setIsDragging(true);
      const position = applyToPoint(inverse(matrix), {
        x: e.clientX,
        y: e.clientY,
      });
      setInitialPosition(position);
      setCurrentPosition(position);
    },
    [matrix, setIsDragging, setInitialPosition, setCurrentPosition],
  );

  const onPointerMove = useCallback(
    e => {
      if (!isDragging) {
        return;
      }

      setCurrentPosition(
        applyToPoint(inverse(matrix), { x: e.clientX, y: e.clientY }),
      );
    },
    [isDragging, matrix, setCurrentPosition],
  );

  const onPointerUp = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      setIsDragging(false);
      onUp(e, initialPosition, currentPosition);
    },
    [setIsDragging, initialPosition, currentPosition, onUp],
  );

  const onGotPointerCapture = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      setIsDragging(true);
    },
    [setIsDragging],
  );

  const onLostPointerCapture = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      setIsDragging(false);
    },
    [setIsDragging],
  );

  return {
    isDragging,
    pointerHandlers: {
      onPointerDown,
      onPointerMove,
      onPointerUp,
      onPointerCancel: onLostPointerCapture,
      onGotPointerCapture,
      onLostPointerCapture,
    },
    initialPosition,
    currentPosition,
  };
};
