import { Record } from 'immutable';

export const NOTIFICATION_TYPES = {
  INFO: 'INFO',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

export const NotificationModel = Record({
  isVisible: false,
  type: NOTIFICATION_TYPES.INFO,
  message: '',
});
