import * as types from './types';

// Stage
export const setZoomScale = scale => ({
  type: types.SET_ZOOM_SCALE,
  payload: { scale },
});

export const setCurrentPieceID = pieceID => ({
  type: types.SET_CURRENT_PIECE,
  payload: { pieceID },
});

export const unsetCurrentPieceID = () => ({
  type: types.UNSET_CURRENT_PIECE,
});
