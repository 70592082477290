import React from 'react';
import { bool, string, func } from 'prop-types';
import { createUseStyles } from 'react-jss';
import colors from '../colors';
import * as typography from '../typography';

const useStyles = createUseStyles({
  popoverItem: {
    ...typography.textRegular,
    background: colors.white,
    color: colors.gray1,
    display: 'flex',
    cursor: 'pointer',
    padding: [4, 16],
    textAlign: 'left',
    width: '100%',
    '&:hover': {
      background: colors.gray3,
    },
    '&:active': {
      background: colors.gray2,
    },
    '&:last-child': {
      marginBottom: 0,
    },
    '&:disabled': {
      opacity: 0.35,
      '&:hover, &:active': {
        background: colors.white,
      },
    },
  },
});

const PopoverItem = ({ label, onClick, disabled }) => {
  const classes = useStyles();
  return (
    <button
      type="button"
      onClick={onClick}
      className={classes.popoverItem}
      disabled={disabled}
    >
      {label}
    </button>
  );
};
PopoverItem.propTypes = {
  label: string.isRequired,
  onClick: func,
  disabled: bool,
};

PopoverItem.defaultProps = {
  onClick: null,
  disabled: undefined,
};

export default PopoverItem;
