import React from 'react';
import { bool } from 'prop-types';
import { or, explicitNull } from 'airbnb-prop-types';
import { Modal, ModalHeader, ModalContent } from '../../ui';
import { createHideableComponent } from '../../ui/hideable';
import SaveAnimationForm from './SaveAnimationForm';

export const ModalContext = createHideableComponent();

const ModalSaveAnimation = ({ forceVisibility }) => (
  <ModalContext.Target hideableOptions={{ isVisible: forceVisibility }}>
    {({ isVisible, hide }) => (
      <Modal isVisible={isVisible} small>
        <ModalHeader>Save Animation</ModalHeader>
        <ModalContent>
          <SaveAnimationForm hide={hide} />
        </ModalContent>
      </Modal>
    )}
  </ModalContext.Target>
);

ModalSaveAnimation.propTypes = {
  forceVisibility: or([bool, explicitNull()]),
};

ModalSaveAnimation.defaultProps = {
  forceVisibility: undefined,
};

export default ModalSaveAnimation;
