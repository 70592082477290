import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors } from '../../ui';

const useStyles = createUseStyles({
  timeTracker: {
    width: 120,
    height: 32,
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: [4, 0],
    fontSize: 12,
    backgroundColor: ({ isActive }) =>
      isActive ? colors.primary : colors.white,
    color: ({ isActive }) => (isActive ? colors.white : colors.black1),
    fill: ({ isActive }) => (isActive ? colors.white : colors.black1),

    '& > span': {
      color: 'inherit',
      marginLeft: 10,
    },
  },
});

const TrackerInfo = ({ children, ...props }) => {
  const classes = useStyles(props);
  return <div className={classes.timeTracker}>{children}</div>;
};

TrackerInfo.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TrackerInfo;
