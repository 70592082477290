import React from 'react';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';

const useStyles = createUseStyles({
  spacer: {
    width: 1,
    flexGrow: 0,
    flexShrink: 0,
    height: '70%',
    background: colors.black2,
    margin: [0, 16, 0, 8],
  },
});

const Spacer = () => {
  const classes = useStyles();
  return <div className={classes.spacer} />;
};

export default Spacer;
