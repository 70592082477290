import React from 'react';
import RowContainer from './RowContainer';
import Container from './Container';
import Issues from './Issues';
import IssueCard from './IssueCard';
import { CustomScroll } from '../CustomScroll';
import { SharedPieces } from '../ViewPieces';
import ResolveIssue from './ResolveIssue';

const ViewIssues = () => (
  <RowContainer>
    <SharedPieces />
    <Container>
      <Issues>
        {(issues, refresh) => (
          <>
            <CustomScroll>
              {issues &&
                issues.map(issue => (
                  <IssueCard
                    key={issue.id}
                    issue={issue}
                    refresh={refresh}
                    renderActions={ResolveIssue}
                  />
                ))}
            </CustomScroll>
          </>
        )}
      </Issues>
    </Container>
  </RowContainer>
);

export default ViewIssues;
