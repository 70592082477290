import React from 'react';
import { func, number } from 'prop-types';

export class Resizable extends React.Component {
  /* eslint-disable react/no-unused-state */
  constructor(props) {
    super(props);
    this.state = { top: props.initialTop, left: props.initialLeft };
    this.isDragging = false;
    this.initialTop = null;
    this.initialLeft = null;
    this.updateValue = this.updateValue.bind(this);
    this.onStart = this.onStart.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onEnd = this.onEnd.bind(this);
  }

  onStart(event) {
    this.isDragging = true;
    event.target.setPointerCapture(event.pointerId);
    this.initialTop = event.pageY;
    this.initialLeft = event.pageX;
  }

  onMove(event) {
    if (!this.isDragging) return;
    this.updateValue(event.pageY, event.pageX);
    this.initialTop = event.pageY;
    this.initialLeft = event.pageX;
  }

  onEnd(event) {
    this.isDragging = false;
    event.target.releasePointerCapture(event.pointerId);
  }

  updateValue(currentTop, currentLeft) {
    const { initialTop, initialLeft } = this;
    this.setState(state => ({
      top: state.top + (initialTop - currentTop),
      left: state.left + (initialLeft - currentLeft),
    }));
  }

  render() {
    return this.props.children({
      top: this.state.top,
      left: this.state.left,
      onStart: this.onStart,
      onMove: this.onMove,
      onEnd: this.onEnd,
    });
  }
}

Resizable.propTypes = {
  children: func.isRequired,
  initialLeft: number,
  initialTop: number,
};

Resizable.defaultProps = {
  initialLeft: 0,
  initialTop: 0,
};

export default Resizable;
