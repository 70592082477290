import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  inputs: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    fontSize: 16,
    justifyContent: 'center',
  },
});

const InputsContainer = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.inputs}>{children}</div>;
};

InputsContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InputsContainer;
