import React from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { colors } from '../ui';
import { ArrowLeft } from '../icons';
import LinkToSharedProject from './LinkToSharedProject';

const useStyles = createUseStyles({
  titleContainer: {
    backgroundColor: colors.black4,
    width: '100%',
    height: 40,
    display: 'flex',
    padding: [8, 48],
    fontSize: 16,
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 24,
    fill: 'white',
  },
});

const Header = ({ pieceID }) => {
  const classes = useStyles();

  return (
    <div className={classes.titleContainer}>
      <LinkToSharedProject>
        <div className={classes.icon}>
          <ArrowLeft />
        </div>
      </LinkToSharedProject>
      <span className={classes.title}>{pieceID}</span>
    </div>
  );
};

Header.propTypes = {
  pieceID: PropTypes.string.isRequired,
};

export default Header;
