import { Set } from 'immutable';
import be from 'bezier-easing';
import memoize from 'lodash/memoize';
import { getKeyframesSorted } from '../utils';
import {
  CUBIC_BEZIER,
  isDefined,
  SCALAR_PROPERTIES,
  NON_SCALAR_PROPERTIES,
  STEPS,
} from '../../models';
import stepFunction from './stepFunction';

export const setPropertyValue = (object, propName, value) =>
  object.setIn([propName], value);

export const getPrevAndNextKeyframes = (object, currentTime) => {
  const keyframes = getKeyframesSorted(object);

  let prev = null;
  let next = null;
  for (let index = 0; index < keyframes.size; index += 1) {
    const keyframe = keyframes.get(index);

    if (currentTime < keyframe.time) {
      prev = null;
      next = keyframe;
      break;
    }

    if (currentTime >= keyframe.time) {
      if (index === keyframes.count() - 1) {
        prev = keyframe;
        break;
      } else if (currentTime < keyframes.get(index + 1).time) {
        prev = keyframe;
        next = keyframes.get(index + 1);
        break;
      }
    }
  }

  return [prev, next];
};

const getProgress = (currentTime, prev, next) => {
  const duration = next.time - prev.time;
  const delay = prev.time;
  return (currentTime - delay) / duration;
};

const TIMING_FUNCTIONS = {
  [CUBIC_BEZIER]: be,
  [STEPS]: stepFunction,
};

export const fallbackLinearEasing = () => be([0.25, 0.25, 0.75, 0.75]);

export const getTimingFunction = memoize(timingFunctionName => {
  const fn = TIMING_FUNCTIONS[timingFunctionName];

  if (!fn) {
    // eslint-disable-next-line
    console.error(
      `Animation # timing function '${timingFunctionName}' not supported. Returned fallback cubic-bezier linear`,
    );

    return fallbackLinearEasing;
  }

  return fn;
});

export const applyEvaluatedValue = (object, prev, next, currentTime) => {
  const easing = getTimingFunction(prev.timingFunction.name)(
    ...prev.timingFunction.values,
  );
  const progress = easing(getProgress(currentTime, prev, next));

  let newObject = SCALAR_PROPERTIES.reduce((modifiedObject, prop) => {
    const prevValue = prev.values[prop];
    const nextValue = next.values[prop];
    if (!isDefined(prevValue) || !isDefined(nextValue)) {
      return modifiedObject;
    }

    const deltaValue = nextValue - prevValue;
    return setPropertyValue(
      modifiedObject,
      prop,
      prevValue + progress * deltaValue,
    );
  }, object);

  newObject = NON_SCALAR_PROPERTIES.reduce((modifiedObject, prop) => {
    const prevProp = prev.values[prop];
    const nextProp = next.values[prop];

    if (!isDefined(prevProp) || !isDefined(nextProp)) {
      return modifiedObject;
    }

    return setPropertyValue(
      modifiedObject,
      prop,
      prevProp.animate(nextProp, progress),
    );
  }, newObject);

  return newObject;
};

export const applyKeyframeProperties = (object, keyframe) => {
  const newObject = SCALAR_PROPERTIES.reduce((modifiedObject, prop) => {
    const value = keyframe.values[prop];
    if (!isDefined(value)) {
      return modifiedObject;
    }

    return setPropertyValue(modifiedObject, prop, value);
  }, object);

  return NON_SCALAR_PROPERTIES.reduce((modifiedObject, prop) => {
    const value = keyframe.values[prop];
    if (!isDefined(value)) {
      return modifiedObject;
    }

    return setPropertyValue(modifiedObject, prop, value);
  }, newObject);
};

export const animateObject = currentTime => object => {
  const [prev, next] = getPrevAndNextKeyframes(object, currentTime);
  if (!next && !prev) {
    return object;
  }

  if (!prev || !next) {
    return applyKeyframeProperties(object, prev || next);
  }

  return applyEvaluatedValue(object, prev, next, currentTime);
};

export const updateObjects = currentTime => (objects, objectID) =>
  objects.updateIn(['byID', objectID], animateObject(currentTime));

const getAllChildrens = nodeMap => {
  let objectIDs = Set([]);

  if (!nodeMap) {
    return objectIDs;
  }

  nodeMap.forEach(node => {
    objectIDs = objectIDs.add(node.objectID);
    objectIDs = objectIDs.concat(node.childrensID);
  });

  return objectIDs;
};

export const collectClipPathIDs = (objects, objectIDs) =>
  objectIDs.reduce((current, objectID) => {
    const clipPathObjectID = objects.getIn([
      'byID',
      objectID,
      'clipPathObjectID',
    ]);

    if (!clipPathObjectID) {
      return current;
    }

    return current.add(clipPathObjectID);
  }, objectIDs);

const animateObjects = ({ pieceID, currentTime }) => objects => {
  const nodeMap = objects.getIn(['byPieceID', pieceID]);
  const pieceObjects = collectClipPathIDs(objects, getAllChildrens(nodeMap));

  return pieceObjects.reduce(updateObjects(currentTime), objects);
};

export default animateObjects;
