import React from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { createUseStyles } from 'react-jss';
import { projects } from '../api';
import { ButtonPrimary, Select } from '../ui';
import Title from './Title';
import connect from './containers';
import { buildOptions } from './utils';

const useStyles = createUseStyles({
  options: {
    display: 'flex',
    alignItems: 'center',
  },
  selectWrapper: {
    width: 400,
    paddingRight: 15,
  },
});

const MonthlyReport = ({ showError }) => {
  const classes = useStyles();
  const items = buildOptions();
  const [{ selectedItem }, setState] = React.useState({
    selectedItem: items.first(),
  });
  const handleCallback = React.useCallback(
    newItem => {
      setState({ selectedItem: newItem });
    },
    [setState],
  );
  const [downloading, setDownloading] = React.useState(false);
  const handleDownload = React.useCallback(() => {
    setDownloading(true);
    const { year } = selectedItem.value;
    const { month } = selectedItem.value;
    projects.monthlyReport(year, month).then(({ ok, data }) => {
      setDownloading(false);
      if (ok) {
        saveAs(data, `month-report-${year}-${month}.csv`);
      } else {
        showError();
      }
    });
  }, [selectedItem, setDownloading]);

  return (
    <div>
      <Title>Monthly Report</Title>
      <div className={classes.options}>
        <div className={classes.selectWrapper}>
          <Select
            placeholder="Month"
            items={items}
            onChange={handleCallback}
            value={selectedItem}
          />
        </div>
        <ButtonPrimary
          label="Download"
          onClick={handleDownload}
          disabled={downloading}
        />
      </div>
    </div>
  );
};

MonthlyReport.propTypes = {
  showError: PropTypes.func.isRequired,
};

export default connect(MonthlyReport);
