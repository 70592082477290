import { ActionCreators as undoRedo } from 'redux-undo';
import {
  removeObjects,
  updateObjects as updateObjectsAction,
  removeKeyframes,
  getChildrensFromObject,
} from '../project';
import { getCurrentTime } from '../playback';
import { getCurrentPieceID } from '../stage';
import {
  getSelectedObjects,
  getSelectedObjectsIDs,
  getSelectedKeyframes,
  setStageMode,
  StageMode,
  getStageMode,
  setSelectedObjects,
  getCurrentObjectID,
  getLockedObjects,
} from '../editor';

const updateObjects = updater => event => (dispatch, getState) => {
  const objects = getSelectedObjects(getState());
  const currentTime = getCurrentTime(getState());
  const scale = event.shiftKey ? 10 : 1;

  return dispatch(
    updateObjectsAction(objects.map(updater(currentTime, scale))),
  );
};

export const incrementObjectX = value =>
  updateObjects((currentTime, scale) => obj =>
    obj.setX(obj.x + value * scale, currentTime),
  );

export const incrementObjectY = value =>
  updateObjects((currentTime, scale) => obj =>
    obj.setY(obj.y + value * scale, currentTime),
  );

const removeSelectedObjects = () => (dispatch, getState) =>
  dispatch(removeObjects(getSelectedObjectsIDs(getState())));

const selectAllObjects = event => (dispatch, getState) => {
  event.preventDefault();
  event.stopPropagation();

  const state = getState();
  const parentID = getCurrentObjectID(state);
  const pieceID = getCurrentPieceID(state);
  const childrens = getChildrensFromObject(state, pieceID, parentID);
  const lockedObjects = getLockedObjects(state);
  const selectableChildrens = childrens.filterNot(objectID =>
    lockedObjects.includes(objectID),
  );

  dispatch(setSelectedObjects(selectableChildrens));
};

export const selectAll = {
  name: 'selectAll',
  keys: ['ctrl+a'],
  handler: selectAllObjects,
};

export const deleteObjects = {
  name: 'deleteObject',
  keys: ['del'],
  handler: removeSelectedObjects,
};

export const moveObjectsUp = {
  name: 'moveObjectUp',
  keys: ['up'],
  handler: incrementObjectY(-1),
};

export const moveObjectsDown = {
  name: 'moveObjectDown',
  keys: ['down'],
  handler: incrementObjectY(1),
};

export const moveObjectsRight = {
  name: 'moveObjectRight',
  keys: ['right'],
  handler: incrementObjectX(1),
};

export const moveObjectsLeft = {
  name: 'moveObjectLeft',
  keys: ['left'],
  handler: incrementObjectX(-1),
};

export const undo = {
  name: 'undo',
  keys: 'ctrl+z',
  handler: undoRedo.undo,
};

export const redo = {
  name: 'redo',
  keys: 'ctrl+shift+z',
  handler: undoRedo.redo,
};

const changeStageMode = newStageMode => () => (dispatch, getState) => {
  if (getStageMode(getState()) === newStageMode) {
    return;
  }

  dispatch(setStageMode(newStageMode));
};

export const stageModePan = {
  name: 'stageModePan',
  keys: { sequence: 'space', action: 'keydown' },
  handler: changeStageMode(StageMode.pan),
};

export const stageModeSelect = {
  name: 'stageModeSelect',
  keys: { sequence: 'space', action: 'keyup' },
  handler: changeStageMode(StageMode.select),
};

const removeSelectedKeyframes = () => (dispatch, getState) =>
  dispatch(removeKeyframes({ keyframes: getSelectedKeyframes(getState()) }));

export const deleteKeyframes = {
  name: 'deleteKeyframes',
  keys: ['del'],
  handler: removeSelectedKeyframes,
};

export const stageShortcuts = [
  deleteObjects,
  moveObjectsUp,
  moveObjectsDown,
  moveObjectsRight,
  moveObjectsLeft,
  redo,
  undo,
  stageModePan,
  stageModeSelect,
  selectAll,
];

export const timelineShortcuts = [
  deleteKeyframes,
  moveObjectsUp,
  moveObjectsDown,
  moveObjectsRight,
  moveObjectsLeft,
  redo,
  undo,
  selectAll,
];
