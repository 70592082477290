import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { VideoIcon } from '../../icons';
import colors from '../colors';

const useStyles = createUseStyles({
  video: {
    display: 'flex',
    backgroundColor: colors.gray3,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  icon: ({ small, customSize }) => {
    let size = small ? 40 : 80;

    if (customSize) {
      size = customSize;
    }

    return {
      width: size,
      height: size,
      fill: colors.gray1,
    };
  },
});

const VideoThumb = ({ style, ...props }) => {
  const classes = useStyles(props);

  return (
    <div className={classes.video} style={style}>
      <div className={classes.icon}>
        <VideoIcon />
      </div>
    </div>
  );
};

VideoThumb.defaultProps = {
  small: false,
  style: undefined,
  customSize: null,
};

VideoThumb.propTypes = {
  style: PropTypes.object,
  small: PropTypes.bool,
  customSize: PropTypes.number,
};

export default VideoThumb;
