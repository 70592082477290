import React from 'react';
import { Popover } from '../ui';
import ButtonDeleteObject from './ButtonDeleteObject';

const PopoverObject = () => (
  <Popover id="popover-object">
    <ButtonDeleteObject />
  </Popover>
);

export default PopoverObject;
