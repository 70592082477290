import React from 'react';
import { func } from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  handler: {
    width: '100%',
    height: 8,
    position: 'absolute',
    cursor: 'row-resize',
  },
});

const ResizableHandler = ({ onStart, onMove, onEnd }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.handler}
      onPointerDown={onStart}
      onPointerMove={onMove}
      onPointerUp={onEnd}
    />
  );
};

ResizableHandler.propTypes = {
  onStart: func.isRequired,
  onMove: func.isRequired,
  onEnd: func.isRequired,
};

export default ResizableHandler;
