import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AlignTopIcon } from '../icons';
import { ToolbarButton } from '../ui';
import { getAlignToContainer, getSelectedObjectsIDs } from '../editor';
import { getCurrentPiece, alignObjectsToTop } from '../project';

const ButtonAlignTop = ({ disabled, onClick }) => {
  if (disabled) {
    return null;
  }

  return <ToolbarButton icon={<AlignTopIcon />} onClick={onClick} />;
};

ButtonAlignTop.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const reduxConnector = connect(
  state => {
    let container = getCurrentPiece(state);
    if (!getAlignToContainer(state)) {
      container = null;
    }

    const ids = getSelectedObjectsIDs(state);
    let disabled = true;
    if (ids.size >= 1) {
      disabled = false;
    }
    return { ids, disabled, container };
  },
  { alignObjectsToTop },
  ({ ids, container, ...state }, actions) => ({
    ...state,
    onClick: () => actions.alignObjectsToTop({ ids, container }),
  }),
);

export default reduxConnector(ButtonAlignTop);
