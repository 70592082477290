import React from 'react';
import { useDragLayer } from 'react-dnd';
import DragItem from './DragItem';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

export const CustomDragLayer = () => {
  const props = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!props.isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <DragItem {...props} />
    </div>
  );
};

export default CustomDragLayer;
