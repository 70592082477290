import React from 'react';
import PropTypes from 'prop-types';

class BaseToggleAspectRatio extends React.Component {
  constructor() {
    super();
    this.state = {
      keepAspectRatio: false,
    };
    this.toggleAspectRatio = this.toggleAspectRatio.bind(this);
  }

  toggleAspectRatio() {
    this.setState(({ keepAspectRatio }) => ({
      keepAspectRatio: !keepAspectRatio,
    }));
  }

  render() {
    const { keepAspectRatio } = this.state;
    const { children } = this.props;
    return children(keepAspectRatio, this.toggleAspectRatio);
  }
}

BaseToggleAspectRatio.propTypes = {
  children: PropTypes.func.isRequired,
};

export default BaseToggleAspectRatio;
