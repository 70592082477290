import React from 'react';
import cuid from 'cuid';
import hoistStatics from 'hoist-non-react-statics';
import HideableComponent from './Component';
import Trigger from './Trigger';
import Provider from './Provider';

export const withHideableComponent = (Component, { id = null } = {}) => {
  const displayName = `withHideable(${
    Component.displayName || Component.name
  })`;
  const WithHideable = props => {
    const remainingProps = { ...props };
    if (id !== null) {
      remainingProps.id = id;
    }

    return (
      <HideableComponent {...remainingProps}>
        {hideableProps => <Component {...hideableProps} {...remainingProps} />}
      </HideableComponent>
    );
  };

  WithHideable.displayName = displayName;
  WithHideable.WrappedComponent = Component;

  return hoistStatics(WithHideable, Component);
};

export const createHideableComponent = (id = cuid()) => {
  return {
    Target: props => <HideableComponent id={id} {...props} />,
    Trigger: props => <Trigger id={id} {...props} />,
    hoc: Component => withHideableComponent(Component, { id }),
  };
};

export const getHideableEvents = wrapper =>
  wrapper.find(Provider).instance().events;

export const wrapHideable = (component, components = {}) => (
  <Provider components={components} recordEvents>
    {component}
  </Provider>
);
