import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ButtonLeftEntry from './ButtonLeftEntry';
import { OpenedLockIcon, ClosedLockIcon } from '../icons';
import {
  getIsObjectLocked,
  toggleObjectLock,
  removeObjectFromSelection,
  clearKeyframesSelectionFromObject,
} from '../editor';

const ButtonToggleVisibility = ({ isLocked, isSelected, onClick }) => (
  <ButtonLeftEntry onClick={onClick} isSelected={isSelected}>
    {isLocked ? <ClosedLockIcon /> : <OpenedLockIcon />}
  </ButtonLeftEntry>
);

ButtonToggleVisibility.propTypes = {
  isLocked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

const container = connect(
  (state, { objectID }) => ({
    isLocked: getIsObjectLocked(state, objectID),
  }),
  {
    toggleObjectLock,
    removeObjectFromSelection,
    clearKeyframesSelectionFromObject,
  },
  (state, actions, { isSelected, objectID }) => ({
    ...state,
    isSelected,
    onClick(event) {
      event.preventDefault();
      event.stopPropagation();

      actions.clearKeyframesSelectionFromObject(objectID);
      actions.removeObjectFromSelection(objectID);
      actions.toggleObjectLock(objectID);
    },
  }),
);

export default container(ButtonToggleVisibility);
