import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import CardColor from '../CardColor/CardColor';
import colors from '../colors';

const borderRadius = hasPreview => (hasPreview ? 20 : 24);

const useStyles = createUseStyles({
  inputColor: ({ hasPreview, rounded }) => ({
    width: hasPreview ? 145 : 143,
    height: hasPreview ? 32 : 40,
    borderRadius: !rounded ? 0 : borderRadius(hasPreview),
    display: 'flex',
    border: hasPreview
      ? `1px solid ${colors.white}`
      : `1px solid ${colors.primary}`,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  input: {
    width: 87,
    fontSize: 16,
    background: 'none',
    color: colors.white,
    border: 'none',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
});

const regexColor = /#(\d|[A-F]){6}$/;
const defaultColor = '#FFFFFF';

const InputColor = ({
  hasPreview,
  onClick,
  value,
  onChange,
  rounded,
  disabled,
  isSelected,
}) => {
  const [color, setColor] = useState(defaultColor);
  const classes = useStyles({ hasPreview, rounded });

  const validationColor = inputColor =>
    inputColor.length === 7 && regexColor.test(inputColor);

  const handleOnChange = e => {
    const valueUpperCase = e.target.value.toUpperCase();
    setColor(valueUpperCase);
    if (!validationColor(valueUpperCase)) {
      return;
    }
    onChange(valueUpperCase);
  };

  useEffect(() => {
    setColor(value);
  }, [value]);

  return (
    <div className={classes.inputColor}>
      <input
        className={classes.input}
        type="text"
        value={color}
        onChange={handleOnChange}
        maxLength="7"
        spellCheck="false"
        disabled={disabled}
      />
      {hasPreview && (
        <CardColor
          color={color || colors.white}
          isSelected={isSelected}
          small
          onClick={onClick}
        />
      )}
    </div>
  );
};

InputColor.propTypes = {
  hasPreview: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  rounded: PropTypes.bool,
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
};

InputColor.defaultProps = {
  rounded: true,
  hasPreview: false,
  onClick: () => {},
  onChange: () => {},
  disabled: false,
  isSelected: false,
};
export default InputColor;
