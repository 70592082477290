import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';

const useStyles = createUseStyles({
  header: {
    alignItems: 'center',
    background: colors.black1,
    display: 'flex',
    flexShrink: 0,
    height: 64,
    padding: [0, 48],
  },
});

const Header = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.header}>{children}</div>;
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Header;
