import { Map, List } from 'immutable';
import { alignTo } from './align';
import { animateObject } from './animation';
import { roundValue } from './utils';
import { makeObject, isDefined, CLIPPATH } from '../models';
import { AABB } from '../geometry';

export const WIDTH = 'width';
export const HEIGHT = 'height';

export const LEFT_BASED_ALIGNMENT = ['left', 'center', 'right'];
export const TOP_BASED_ALIGNMENT = ['top', 'middle', 'bottom'];

export const getPieceSmallerDimension = ({ width, height }) =>
  width > height ? HEIGHT : WIDTH;

export const getPieceGrowRatio = (parentPiece, piece) => ({
  widthRatio: piece.width / parentPiece.width,
  heightRatio: piece.height / parentPiece.height,
});

export const getObjectSizeGrowRatio = (
  { width, height },
  pieceGrowRatio,
  prefDimension,
) => {
  let newWidth;
  let newHeight;

  if (prefDimension === WIDTH) {
    newWidth = width * pieceGrowRatio.widthRatio;
    newHeight = newWidth / (width / height);
  } else {
    newHeight = height * pieceGrowRatio.heightRatio;
    newWidth = newHeight / (height / width);
  }

  const widthRatio = newWidth / width;
  const heightRatio = newHeight / height;

  return {
    widthRatio: Number.isNaN(widthRatio) ? 1 : widthRatio,
    heightRatio: Number.isNaN(heightRatio) ? 1 : heightRatio,
  };
};

const getPreferredOrientation = (
  object,
  currentPosition,
  pieceArea,
  orientations,
) =>
  orientations
    .reduce((array, orientation) => {
      const preferredPosition = alignTo[orientation](pieceArea, object);
      const diff = currentPosition - preferredPosition;

      array.push({
        orientation,
        diff,
      });

      return array;
    }, [])
    .sort(
      (firstElm, secondElm) =>
        Math.abs(firstElm.diff) - Math.abs(secondElm.diff),
    )[0];

export const getXPreferredOrientation = (object, currentPosition, pieceArea) =>
  getPreferredOrientation(
    object,
    currentPosition,
    pieceArea,
    LEFT_BASED_ALIGNMENT,
  );

export const getYPreferredOrientation = (object, currentPosition, pieceArea) =>
  getPreferredOrientation(
    object,
    currentPosition,
    pieceArea,
    TOP_BASED_ALIGNMENT,
  );

export const getKeyframeTimes = (...propertyKeyframes) => {
  let keyframeTimes = List();

  propertyKeyframes.forEach(keyframes =>
    keyframes.keySeq().forEach(val => {
      if (keyframeTimes.includes(val)) {
        return;
      }

      keyframeTimes = keyframeTimes.push(val);
    }),
  );

  return keyframeTimes;
};

export const getScale = (partialValue, totalValue) => {
  const result = partialValue / totalValue;
  if (Number.isNaN(result)) {
    return 1;
  }

  return result;
};

export class ObjectResizer {
  constructor(piece, parentPiece, parentObject) {
    this.saveInitialValues = this.saveInitialValues.bind(this);
    this.piece = piece;
    this.parentPiece = parentPiece;

    // computed values
    this.pieceArea = AABB.fromPiece(this.piece);
    this.parentPieceArea = AABB.fromPiece(this.parentPiece);
    this.pieceGrowRatio = getPieceGrowRatio(parentPiece, piece);
    this.prefDimension = getPieceSmallerDimension(piece);
    this.fluidDimension = this.prefDimension === WIDTH ? HEIGHT : WIDTH;
    this.newLeft = 0;
    this.newTop = 0;

    this.saveInitialValues(parentObject);
  }

  saveInitialValues(parentObject) {
    const { translateX, translateY } = this.getNewObjectPosition(parentObject);
    this.newLeft = translateX;
    this.newTop = translateY;
  }

  getNewObjectSize(parentObject) {
    const objectGrowRatio = getObjectSizeGrowRatio(
      parentObject,
      this.pieceGrowRatio,
      parentObject.fullSize ? this.fluidDimension : this.prefDimension,
    );

    return {
      width: roundValue(parentObject.width * objectGrowRatio.widthRatio),
      height: roundValue(parentObject.height * objectGrowRatio.heightRatio),
    };
  }

  getNewOrigin(parentObject) {
    const objectGrowRatio = getObjectSizeGrowRatio(
      parentObject,
      this.pieceGrowRatio,
      parentObject.fullSize ? this.fluidDimension : this.prefDimension,
    );

    return {
      originX: roundValue(parentObject.originX * objectGrowRatio.widthRatio),
      originY: roundValue(parentObject.originY * objectGrowRatio.heightRatio),
    };
  }

  getNewObjectPosition(parentObject, newObject = parentObject) {
    const xPreferred = getXPreferredOrientation(
      parentObject,
      parentObject.x,
      this.parentPieceArea,
    );

    const yPreferred = getYPreferredOrientation(
      parentObject,
      parentObject.y,
      this.parentPieceArea,
    );

    const objectGrowRatio = getObjectSizeGrowRatio(
      {
        width: parentObject.width,
        height: parentObject.height,
      },
      this.pieceGrowRatio,
      parentObject.fullSize ? this.fluidDimension : this.prefDimension,
    );

    const x = roundValue(
      alignTo[xPreferred.orientation](this.pieceArea, newObject) +
        xPreferred.diff * objectGrowRatio.widthRatio,
    );

    const y = roundValue(
      alignTo[yPreferred.orientation](this.pieceArea, newObject) +
        yPreferred.diff * objectGrowRatio.heightRatio,
    );

    return {
      left: this.newLeft,
      top: this.newTop,
      translateX: x - this.newLeft,
      translateY: y - this.newTop,
    };
  }

  getNewObjectKeyframes(parentObject) {
    const keyframes = parentObject.get('keyframes');

    return keyframes.reduce((currentMap, keyframe, keyframeTime) => {
      let newObjectKeyframes = currentMap;
      const actualParentObject = animateObject(keyframeTime)(parentObject);

      const widthKeyframe = parentObject.getIn([
        'keyframes',
        keyframeTime,
        'values',
        'width',
      ]);
      const { width, height } = this.getNewObjectSize(actualParentObject);
      if (isDefined(widthKeyframe)) {
        newObjectKeyframes = newObjectKeyframes.setIn(
          [keyframeTime, 'values', 'width'],
          width,
        );
      }

      const heightKeyframe = parentObject.getIn([
        'keyframes',
        keyframeTime,
        'values',
        'height',
      ]);
      if (isDefined(heightKeyframe)) {
        newObjectKeyframes = newObjectKeyframes.setIn(
          [keyframeTime, 'values', 'height'],
          height,
        );
      }

      const { originX, originY } = this.getNewOrigin(actualParentObject);
      const originXKeyframe = parentObject.getIn([
        'keyframes',
        keyframeTime,
        'values',
        'originX',
      ]);
      if (isDefined(originXKeyframe)) {
        newObjectKeyframes = newObjectKeyframes.setIn(
          [keyframeTime, 'values', 'originX'],
          originX,
        );
      }

      const originYKeyframe = parentObject.getIn([
        'keyframes',
        keyframeTime,
        'values',
        'originY',
      ]);
      if (isDefined(originYKeyframe)) {
        newObjectKeyframes = newObjectKeyframes.setIn(
          [keyframeTime, 'values', 'originY'],
          originY,
        );
      }

      const { left, top, translateX, translateY } = this.getNewObjectPosition(
        actualParentObject,
        makeObject({
          width,
          height,
          scaleX: actualParentObject.scaleX,
          scaleY: actualParentObject.scaleY,
        }),
      );

      const leftKeyframe = parentObject.getIn([
        'keyframes',
        keyframeTime,
        'values',
        'left',
      ]);
      if (isDefined(leftKeyframe)) {
        newObjectKeyframes = newObjectKeyframes.setIn(
          [keyframeTime, 'values', 'left'],
          left,
        );
      }

      const topKeyframe = parentObject.getIn([
        'keyframes',
        keyframeTime,
        'values',
        'top',
      ]);
      if (isDefined(topKeyframe)) {
        newObjectKeyframes = newObjectKeyframes.setIn(
          [keyframeTime, 'values', 'top'],
          top,
        );
      }

      const translateXKeyframe = parentObject.getIn([
        'keyframes',
        keyframeTime,
        'values',
        'translateX',
      ]);
      if (isDefined(translateXKeyframe) || isDefined(leftKeyframe)) {
        newObjectKeyframes = newObjectKeyframes.setIn(
          [keyframeTime, 'values', 'translateX'],
          translateX,
        );
      }

      const translateYKeyframe = parentObject.getIn([
        'keyframes',
        keyframeTime,
        'values',
        'translateY',
      ]);
      if (isDefined(translateYKeyframe) || isDefined(topKeyframe)) {
        newObjectKeyframes = newObjectKeyframes.setIn(
          [keyframeTime, 'values', 'translateY'],
          translateY,
        );
      }

      const clipPathKeyframe = parentObject.getIn([
        'keyframes',
        keyframeTime,
        'values',
        'clipPath',
      ]);
      if (isDefined(clipPathKeyframe)) {
        newObjectKeyframes = newObjectKeyframes.setIn(
          [keyframeTime, 'values', 'clipPath'],
          clipPathKeyframe
            .setLeft(left + translateX)
            .setTop(top + translateY)
            .setWidth(width)
            .setHeight(height),
        );
      }

      return newObjectKeyframes;
    }, Map(keyframes));
  }
}

export const makeObjectResponsive = ({
  object,
  parentObject,
  piece,
  parentPiece,
}) => {
  let newObject = object;
  const objectResizer = new ObjectResizer(piece, parentPiece, parentObject);

  if (newObject.type === CLIPPATH) {
    const { width, height } = objectResizer.getNewObjectSize(parentObject);

    newObject = newObject.setWidth(width).setHeight(height);

    const {
      left,
      top,
      translateX,
      translateY,
    } = objectResizer.getNewObjectPosition(parentObject, newObject);
    newObject = newObject.setLeft(left + translateX).setTop(top + translateY);

    newObject = newObject.setIn(
      ['keyframes'],
      objectResizer.getNewObjectKeyframes(parentObject),
    );

    return newObject;
  }

  const { width, height } = objectResizer.getNewObjectSize(parentObject);
  newObject = newObject.setIn(['width'], width).setIn(['height'], height);

  const { originX, originY } = objectResizer.getNewOrigin(parentObject);
  newObject = newObject.set('originX', originX).set('originY', originY);

  const {
    left,
    top,
    translateX,
    translateY,
  } = objectResizer.getNewObjectPosition(parentObject, newObject);
  newObject = newObject
    .setIn(['left'], left)
    .setIn(['top'], top)
    .setIn(['translateX'], translateX)
    .setIn(['translateY'], translateY);

  newObject = newObject.setIn(['opacity'], parentObject.getIn(['opacity']));

  newObject = newObject.setIn(
    ['keyframes'],
    objectResizer.getNewObjectKeyframes(parentObject),
  );

  return newObject;
};
