import React from 'react';
import { Panel } from '../ui';
import { CustomScroll } from '../CustomScroll';
import Header from './Header';
import ActiveTools from './ActiveTools';

const PanelProperties = () => (
  <Panel startOpened title="Properties">
    <CustomScroll>
      <Header />
      <ActiveTools />
    </CustomScroll>
  </Panel>
);

export default PanelProperties;
