import { connect } from 'react-redux';
import { DurationInput } from '../ui';
import { getIsPlaying, getClockDuration, setClockDuration } from '../playback';

const reduxConnector = connect(
  state => ({
    disabled: getIsPlaying(state),
    max: 60000,
    step: 1000,
    value: getClockDuration(state),
    format: DurationInput.SecondsFormat,
  }),
  {
    onChange: setClockDuration,
  },
);

export default reduxConnector(DurationInput);
