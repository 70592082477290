import React from 'react';
import { createUseStyles } from 'react-jss';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { Select, ItemModel } from '../ui';
import { setPiecesOrderBy } from './actions';
import { SUPPORTED_ORDER_LIST, getOrderBy } from './reducers';

const useStyles = createUseStyles({
  select: {
    width: 140,
  },
});

const SelectPiecesOrder = ({ value, onChange }) => {
  const classes = useStyles();
  return (
    <div className={classes.select}>
      <Select
        placeholder="Ordered by"
        items={SUPPORTED_ORDER_LIST.map(orderBy => ItemModel(orderBy))}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};
SelectPiecesOrder.propTypes = {
  value: object.isRequired,
  onChange: func.isRequired,
};

const container = connect(
  state => ({
    value: ItemModel(getOrderBy(state)),
  }),
  dispatch => ({
    onChange(value) {
      dispatch(setPiecesOrderBy(value.toJS()));
    },
  }),
);

export default container(SelectPiecesOrder);
