import React from 'react';
import PropTypes from 'prop-types';
import { explicitNull } from 'airbnb-prop-types';
import BaseToggleAspectRatio from './BaseToggleAspectRatio';
import BaseInput from './BaseInput';
import ToggleAspectRatio from './ToggleAspectRatio';
import { PROPERTIES_PRECISION } from '../../models';

const widthProperty = 'width';
const widthPrecision = PROPERTIES_PRECISION[widthProperty];

const heightProperty = 'height';
const heightPrecision = PROPERTIES_PRECISION[heightProperty];

const Dimensions = ({ width, height, disabled, onChange }) => (
  <BaseToggleAspectRatio>
    {(keepAspectRatio, toggleKeepAspect) => (
      <>
        <BaseInput
          id={widthProperty}
          min={0}
          property={widthProperty}
          label="W"
          value={width}
          onChange={onChange(widthProperty, keepAspectRatio)}
          disabled={disabled}
          precision={widthPrecision}
        />
        <ToggleAspectRatio
          keepAspectRatio={keepAspectRatio}
          onClick={toggleKeepAspect}
        />
        <BaseInput
          id={heightProperty}
          min={0}
          property={heightProperty}
          label="H"
          value={height}
          onChange={onChange(heightProperty, keepAspectRatio)}
          disabled={disabled}
          precision={heightPrecision}
        />
      </>
    )}
  </BaseToggleAspectRatio>
);

Dimensions.defaultProps = {
  width: null,
  height: null,
  disabled: true,
  onChange: () => null,
};

Dimensions.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, explicitNull]),
  height: PropTypes.oneOfType([PropTypes.number, explicitNull]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Dimensions;
