import React from 'react';
import PropTypes from 'prop-types';
import { PopoverItem } from '../ui';

const ButtonUpload = ({ onClick }) => (
  <PopoverItem label="Upload assets" onClick={onClick} />
);

ButtonUpload.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ButtonUpload;
