import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import container from './container';
import { tracker } from '../../api';
import { useRequest } from '../../http';
import { IconButton } from '../../ui';
import { StopIcon } from '../../icons';

const ButtonStopTracker = ({ projectID, onStop, onFail }) => {
  const { requesting, request } = useRequest(tracker.stopTracker(projectID), {
    doRequest: false,
    onSuccess: onStop,
    onFail,
  });
  const handleOnClick = useCallback(() => request(), [request]);

  return (
    <IconButton onClick={handleOnClick} disabled={requesting} icon={StopIcon} />
  );
};

ButtonStopTracker.propTypes = {
  projectID: PropTypes.string.isRequired,
  onStop: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
};

export default container(ButtonStopTracker);
