import React from 'react';
import { func, string } from 'prop-types';
import Hideable from '../hideable';

const PopoverTrigger = ({ children, id, onOpen }) => (
  <Hideable.Consumer>
    {({ show }) =>
      children((event, ...args) => {
        event.stopPropagation();
        event.preventDefault();

        show(id, { x: event.clientX, y: event.clientY }, ...args);
        onOpen(event);
      })
    }
  </Hideable.Consumer>
);

PopoverTrigger.defaultProps = {
  onOpen: () => null,
};

PopoverTrigger.propTypes = {
  id: string.isRequired,
  children: func.isRequired,
  onOpen: func,
};

export default PopoverTrigger;
