import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  wrapperBtns: {
    width: 22,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    width: 14,
    height: 50,
    background: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const Button = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapperBtns}>
      <button type="button" className={classes.btn} {...props}>
        {children}
      </button>
    </div>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;
