import { useState, useCallback, useEffect } from 'react';

export const usePanArea = () => {
  const [{ pieceTop, pieceLeft }, setPanPosition] = useState({
    pieceTop: 0,
    pieceLeft: 0,
  });

  const updatePanPosition = useCallback(
    ({ left, top }) => {
      setPanPosition(state => ({
        ...state,
        pieceLeft: Math.round(state.pieceLeft + left),
        pieceTop: Math.round(state.pieceTop + top),
      }));
    },
    [setPanPosition],
  );

  return [{ pieceTop, pieceLeft }, updatePanPosition, setPanPosition];
};

export const usePanInitialization = (
  stageRef,
  pieceWidth,
  pieceHeight,
  setPanPosition,
) => {
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    const stageComponent = stageRef.current;
    if (initialized || !stageComponent) {
      return;
    }

    if (stageComponent.clientWidth !== 0 && stageComponent.clientHeight !== 0) {
      setPanPosition({
        pieceLeft: Math.round((stageComponent.clientWidth - pieceWidth) / 2),
        pieceTop: Math.round((stageComponent.clientHeight - pieceHeight) / 2),
      });
      setInitialized(true);
    }
  }, [initialized, setPanPosition]);
};
