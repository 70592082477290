import React from 'react';
import { node } from 'prop-types';
import { createUseStyles } from 'react-jss';
import Spacer from './Spacer';
import ButtonLogout from './ButtonLogout';
import { getLogoURL } from '../logo';

const useStyles = createUseStyles({
  header: {
    alignItems: 'center',
    boxShadow: '0 4px 4px 0 rgba(0,0,0,0.16), 0 0 2px 1px rgba(0,0,0,0.08)',
    display: 'flex',
    flexShrink: 0,
    height: 96,
    padding: [0, 48],
    width: '100%',
  },
  logo: {
    height: 32,
  },
  headerItems: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  logout: {
    display: 'inline',
    marginLeft: 8,
  },
});

const Header = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <img className={classes.logo} src={getLogoURL()} alt="Artisan logo" />
      <div className={classes.headerItems}>
        {children || <Spacer />}
        <div className={classes.logout}>
          <ButtonLogout />
        </div>
      </div>
    </div>
  );
};

Header.defaultProps = {
  children: null,
};

Header.propTypes = {
  children: node,
};

export default Header;
