import React from 'react';
import { object } from 'prop-types';
import { colors } from '../ui';
import { SelectedListObjects } from '../Timeline';

export const getWrapperStyle = currentOffset => {
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    position: 'absolute',
    background: colors.black4,
    opacity: 0.5,
    width: 263,
    minHeight: 32,
    transform,
    WebkitTransform: transform,
  };
};

const DragTimelineObjects = ({ currentOffset }) => (
  <div style={getWrapperStyle(currentOffset)}>
    <SelectedListObjects />
  </div>
);

DragTimelineObjects.defaultProps = {
  currentOffset: null,
};

DragTimelineObjects.propTypes = {
  currentOffset: object,
};

export default DragTimelineObjects;
