import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import colors from '../colors';
import * as typography from '../typography';

const useStyles = createUseStyles({
  button: {
    ...typography.textRegular,
    alignItems: 'center',
    textAlign: 'center',
    background: colors.primary,
    borderRadius: '100%',
    cursor: 'pointer',
    color: colors.white,
    display: 'flex',
    height: 32,
    justifyContent: 'center',
    width: 32,
    padding: 0,
    outline: 'none',
    '&:hover': {
      background: colors.primaryLight,
    },
    '&:active': {
      background: colors.primaryDark,
    },
    '&:disabled': {
      opacity: 0.35,
      '&:hover': {
        background: colors.primary,
      },
      '&:active': {
        background: colors.primary,
      },
    },
  },
  icon: {
    width: 16,
    height: 16,
    fill: colors.white,
  },
});

const IconButtonPrimary = ({ icon, ...props }) => {
  const classes = useStyles();
  const Icon = icon;
  return (
    <button type="button" className={classes.button} {...props}>
      {icon && <Icon className={classes.icon} />}
    </button>
  );
};

IconButtonPrimary.propTypes = {
  icon: PropTypes.func,
};

IconButtonPrimary.defaultProps = {
  icon: null,
};

export default IconButtonPrimary;
