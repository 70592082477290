import React from 'react';
import { bool } from 'prop-types';
import BasePanel from './BasePanel';

class Panel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpened: !!this.props.startOpened };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(state => ({ isOpened: !state.isOpened }));
  }

  render() {
    return (
      <BasePanel
        isOpened={this.state.isOpened}
        onClick={this.toggle}
        {...this.props}
      />
    );
  }
}

Panel.propTypes = {
  startOpened: bool,
};

Panel.defaultProps = {
  startOpened: false,
};

export default Panel;
