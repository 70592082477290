import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { PanelsWrapper } from '../ui';
import LeftSidebar from '../LeftSidebar';
import PanelAssets from '../PanelAssets';
import PanelProperties from '../PanelProperties';
import Topbar from '../Topbar';
import { Clock } from '../StageComponents';
import Layout from './Layout';
import ViewStage from './ViewStage';
import EditorContext from '../EditorContext';

const Editor = () => (
  <DndProvider backend={HTML5Backend}>
    <EditorContext>
      <Clock />
      <Layout
        sidebar={<LeftSidebar />}
        topbar={<Topbar />}
        stage={<ViewStage />}
        panels={
          <PanelsWrapper>
            <PanelProperties />
            <PanelAssets />
          </PanelsWrapper>
        }
      />
    </EditorContext>
  </DndProvider>
);

export default Editor;
