import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { animations } from '../../api';
import { SimpleIconButton } from '../../ui';
import { TrashIcon } from '../../icons';
import { useRequest } from '../../http';
import { showErrorNotification } from '../../Notification';

const useStyles = createUseStyles({
  deleteAnimation: {
    width: 100,
    display: 'flex',
    justifyContent: 'space-between',
  },
  textButton: {
    color: 'white',
    backgroundColor: 'transparent',
    fontSize: 12,
    cursor: 'pointer',
    opacity: 0.65,

    '&:hover,&:active': {
      opacity: 1,
    },
    '&:disabled': {
      opacity: 0.4,
      cursor: 'auto',
    },
  },
  deleteTextButton: {
    extend: 'textButton',
    color: 'red',
  },
});

const DeleteAnimation = ({ animationID, onRemove, onFail }) => {
  const classes = useStyles();
  const { requesting, request } = useRequest(
    animations.deleteAnimation(animationID),
    { doRequest: false, onSuccess: onRemove, onFail },
    [onRemove],
  );
  const [opened, setState] = useState(false);
  const handleOpenClick = useCallback(() => setState(true), [setState]);
  const handleCloseClick = useCallback(() => setState(false), [setState]);
  const handleRequest = useCallback(() => request(), [request]);

  if (opened === false) {
    return (
      <SimpleIconButton
        onClick={handleOpenClick}
        size={12}
        icon={<TrashIcon />}
      />
    );
  }

  return (
    <div className={classes.deleteAnimation}>
      <button
        className={classes.deleteTextButton}
        type="button"
        disabled={requesting}
        onClick={handleRequest}
      >
        Delete
      </button>
      <button
        className={classes.textButton}
        type="button"
        disabled={requesting}
        onClick={handleCloseClick}
      >
        Cancel
      </button>
    </div>
  );
};

DeleteAnimation.propTypes = {
  animationID: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
};

export const ERROR_MESSAGE = 'Animation cannot be removed!';

const withRedux = connect(null, dispatch => ({
  onFail: () => dispatch(showErrorNotification(ERROR_MESSAGE)),
}));

export default withRedux(DeleteAnimation);
