import React from 'react';
import { node } from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  viewPieces: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
});

/* eslint-disable no-unused-vars */
const RowContainer = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.viewPieces}>{children}</div>;
};

RowContainer.propTypes = {
  children: node.isRequired,
};

export default RowContainer;
