import React from 'react';
import PropTypes from 'prop-types';
import Spacer from './Spacer';
import ButtonStartTracker from './ButtonStartTracker';
import ButtonPauseTracker from './ButtonPauseTracker';
import ButtonStopTracker from './ButtonStopTracker';

const ControlButtons = ({
  refetch,
  isActive,
  isPlayable,
  isPausable,
  isStoppable,
}) => {
  const buttons = [];
  if (isPlayable) {
    buttons.push(<ButtonStartTracker onStart={refetch} key="start-button" />);
  }

  if (isPausable) {
    buttons.push(<ButtonPauseTracker onPause={refetch} key="pause-button" />);
  }

  if (isStoppable) {
    buttons.push(<ButtonStopTracker onStop={refetch} key="stop-button" />);
  }

  return buttons.map((Button, index) =>
    index !== buttons.length - 1
      ? [Button, <Spacer isActive={isActive} key={`spacer-${Button.key}`} />]
      : Button,
  );
};

ControlButtons.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isPlayable: PropTypes.bool.isRequired,
  isPausable: PropTypes.bool.isRequired,
  isStoppable: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default ControlButtons;
