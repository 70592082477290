import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import MovableComponent from '../../MovableComponent';
import { TRIGGER_SIZE } from './constants';
import EditorContext, { TRANSFORMATION_BOX } from '../../EditorContext';

const useStyles = createUseStyles({
  resizer: {
    width: TRIGGER_SIZE,
    height: TRIGGER_SIZE,
    position: 'absolute',
    touchAction: 'none',
    pointerEvents: 'all',
    zIndex: 110,
  },
});

const Resizer = ({ angle, disabled, scale, style, onDown, onChange, onUp }) => {
  const classes = useStyles();
  if (disabled) {
    return null;
  }

  return (
    <EditorContext.Consumer>
      {({ isVisibleTool }) =>
        isVisibleTool(TRANSFORMATION_BOX) && (
          <MovableComponent
            angle={angle}
            scale={scale}
            onDown={onDown}
            onMove={onChange}
            onUp={onUp}
          >
            {movableProps => (
              <div
                {...movableProps}
                className={classes.resizer}
                style={style}
              />
            )}
          </MovableComponent>
        )
      }
    </EditorContext.Consumer>
  );
};

Resizer.defaultProps = {
  style: undefined,
};

Resizer.propTypes = {
  angle: PropTypes.number.isRequired,
  style: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  scale: PropTypes.number.isRequired,
  onDown: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onUp: PropTypes.func.isRequired,
};

export default Resizer;
