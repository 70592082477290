import React from 'react';
import { bool, node, object, func, array } from 'prop-types';
import { or } from 'airbnb-prop-types';
import injectSheet from 'react-jss';
import { Scroll } from '../Scroll';
import colors from '../colors';
import { withHideableComponent } from '../hideable';

const noOp = evt => {
  evt.stopPropagation();
  evt.preventDefault();
};

const hideCall = (hide, onClose) => evt => {
  noOp(evt);
  hide();

  if (onClose) {
    onClose(evt);
  }
};

class Popover extends React.Component {
  componentDidUpdate() {
    if (this.elm) {
      const { x, y } = this.props.showArgs[0];
      this.elm.style.left = `${x - this.elm.offsetWidth / 1.5}px`;
      this.elm.style.top = this.props.isDown
        ? `${y}px`
        : `${y - this.elm.offsetHeight}px`;
    }
  }

  render() {
    const {
      children,
      classes,
      hide,
      isVisible,
      onClose,
      showArgs,
    } = this.props;
    return isVisible ? (
      <div
        className={classes.overlay}
        onPointerDown={noOp}
        onPointerMove={noOp}
        onPointerUp={noOp}
        onClick={hideCall(hide, onClose)}
        onContextMenu={hideCall(hide, onClose)}
        role="button"
        tabIndex="-1"
      >
        <div
          ref={elm => {
            this.elm = elm;
          }}
          className={classes.popover}
        >
          <Scroll>
            <div className={classes.popoverWrapper}>
              {typeof children === 'function'
                ? children(...showArgs)
                : children}
            </div>
          </Scroll>
        </div>
      </div>
    ) : null;
  }
}

Popover.defaultProps = {
  onClose: null,
  isDown: false,
};

Popover.propTypes = {
  isVisible: bool.isRequired,
  showArgs: array.isRequired,
  hide: func.isRequired,
  children: or([node, func]).isRequired,
  classes: object.isRequired,
  onClose: func,
  isDown: bool,
};

const styles = injectSheet({
  overlay: {
    height: '100%',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 10000,
  },
  popover: {
    background: colors.white,
    borderRadius: 8,
    boxShadow: '0 4px 4px 0 rgba(0,0,0,0.16), 0 0 2px 1px rgba(0,0,0,0.08)',
    position: 'absolute',
    display: 'inline-block',
    zIndex: 10001,
    padding: [16, 0],
    maxHeight: 280,
    width: 'auto',
    minWidth: 140,
    overflow: 'hidden',
  },
  popoverWrapper: {
    maxHeight: 248,
  },
});

export default withHideableComponent(styles(Popover));
