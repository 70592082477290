import cuid from 'cuid';
import { getFormattedDate, formatFilename } from '../../utils';

export const maxSizeName = 235;

export const getProjectFilename = nameProject =>
  `${formatFilename(nameProject).slice(
    0,
    maxSizeName,
  )}-${getFormattedDate()}.zip`;

const sortKeyframes = (keyframeA, keyframeB) => keyframeA.time - keyframeB.time;

export const prepareObjectToLoop = (object, piece) =>
  object.update('keyframes', keyframes => {
    if (!keyframes.size) {
      return keyframes;
    }

    let newKeyframes = keyframes;
    const firstKey = '0';
    if (!newKeyframes.has(firstKey)) {
      const initialKeyframe = newKeyframes.min(sortKeyframes);

      newKeyframes = newKeyframes.set(
        firstKey,
        initialKeyframe
          // FIXME, this should be a function to duplicateKeyframe
          // using old keyframes as base
          // but there is no place to put correctly
          .set('id', cuid())
          .set('time', 0),
      );
    }

    const lastKey = `${piece.duration}`;
    if (!newKeyframes.has(lastKey)) {
      const lastKeyframe = newKeyframes.max(sortKeyframes);

      newKeyframes = newKeyframes.set(
        lastKey,
        lastKeyframe
          // FIXME
          .set('id', cuid())
          .set('time', piece.duration),
      );
    }

    return newKeyframes;
  });
