import { Record } from 'immutable';
import { createReducer } from '../utils';
import * as types from './types';

export const modelExportCreatives = ({
  isWaitingForEstimative = false,
  loading = false,
  piecesSelected = {},
} = {}) =>
  Record({
    isWaitingForEstimative,
    loading,
    piecesSelected,
  })();

const setLoading = ({ loading }) => state => state.set('loading', loading);

const setPiecesSelected = ({ piecesSelected }) => state =>
  state.set('piecesSelected', piecesSelected);

const setISWaitingForEstivamative = ({ isWaitingForEstimative }) => state =>
  state.set('isWaitingForEstimative', isWaitingForEstimative);

const resetExportCreativeTree = () => state =>
  setISWaitingForEstivamative({ isWaitingForEstimative: false })(
    setPiecesSelected({ piecesSelected: {} })(
      setLoading({ loading: false })(state),
    ),
  );

const exportCreativesReducer = createReducer(modelExportCreatives(), {
  [types.SET_IS_WAITING_FOR_ESTIMATIVE]: setISWaitingForEstivamative,
  [types.SET_LOADING]: setLoading,
  [types.SET_PIECES_SELECTED]: setPiecesSelected,
  [types.RESET_TREE_EXPORT_CREATIVES]: resetExportCreativeTree,
});

export default exportCreativesReducer;
