import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OneStepToTopIcon } from '../icons';
import { ToolbarButton } from '../ui';
import { moveObjectsOneStepToTop } from '../project';
import { getCurrentPieceID } from '../stage';
import { getSelectedObjectsIDs } from '../editor';

const ButtonMoveOneStepToTop = ({ disabled, onClick }) => {
  if (disabled) {
    return null;
  }
  return <ToolbarButton icon={<OneStepToTopIcon />} onClick={onClick} />;
};

ButtonMoveOneStepToTop.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const reduxConnector = connect(
  state => {
    const pieceID = getCurrentPieceID(state);
    const ids = getSelectedObjectsIDs(state);
    let disabled = true;
    if (ids.size > 0) {
      disabled = false;
    }
    return { ids, disabled, pieceID };
  },
  { moveObjectsOneStepToTop },
  ({ ids, pieceID, ...state }, actions) => ({
    ...state,
    onClick: () => actions.moveObjectsOneStepToTop({ pieceID, ids }),
  }),
);

export default reduxConnector(ButtonMoveOneStepToTop);
