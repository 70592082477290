import React, { useCallback } from 'react';
import { string, instanceOf, func, bool } from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Types } from '../dnd';
import { makeGetAssetsByCollectionPath, getCollectionByPath } from '../project';
import { getSelectedAssets, getAssetsAreSelected } from './reducers';
import { selectAssetsOnly, selectAssetsOnClick } from './actions';
import AssetEntry from './AssetEntry';
import CollectionHeader from './CollectionHeader';
import useDropAssets, { dropTargetActions } from './dropTarget';
import { CollectionContent } from '../Assets';
import ModalRemoveCollection from './ModalRemoveCollection';
import PopoverCollection from './PopoverCollection';
import { ArrowDownIcon, PlayIcon as ArrowRightIcon } from '../icons';
import { useHideableState } from '../ui';

const Collection = ({
  path,
  selectedAssets,
  collectionAssets,
  onClick,
  isSelected,
  name,
  ...props
}) => {
  const { isVisible, toggleVisibility } = useHideableState();
  const [{ isOver, isDragging }, connectDropTarget] = useDropAssets({
    path,
    ...props,
  });
  const icon = isVisible ? ArrowDownIcon : ArrowRightIcon;
  const [, drag, connectDragPreview] = useDrag({
    item: { type: Types.COLLECTION },
    begin() {
      let assets = selectedAssets;

      if (!selectedAssets.size || !isSelected) {
        props.selectAssetsOnly(collectionAssets.map(asset => asset.path));
        assets = assets.clear();
      }

      assets = assets.merge(collectionAssets);
      return { assets };
    },
  });
  const openCollection = useCallback(
    e => {
      toggleVisibility();
      onClick(e);
    },
    [toggleVisibility, onClick],
  );
  connectDragPreview(getEmptyImage());

  return connectDropTarget(
    <div ref={drag}>
      <PopoverCollection collectionPath={path} />
      <ModalRemoveCollection collectionPath={path} />
      <CollectionHeader
        isOver={isOver}
        isSelected={isSelected}
        path={path}
        icon={icon}
        name={name}
        onClick={openCollection}
      />
      {isVisible && (
        <CollectionContent isDragging={isDragging} isOver={isOver}>
          {collectionAssets.map(asset => (
            <AssetEntry key={asset.path} asset={asset} depth={1} />
          ))}
        </CollectionContent>
      )}
    </div>,
  );
};

Collection.propTypes = {
  path: string.isRequired,
  collectionAssets: instanceOf(List).isRequired,
  onClick: func.isRequired,
  isSelected: bool.isRequired,
};

const makeMapStateToProps = () => {
  const getAssetsByCollectionPath = makeGetAssetsByCollectionPath();

  return (state, { path }) => {
    const collectionAssets = getAssetsByCollectionPath(state, {
      collectionPath: path,
    });

    return {
      name: getCollectionByPath(state, path).name,
      collectionAssets,
      selectedAssets: getSelectedAssets(state),
      isSelected: getAssetsAreSelected(state, collectionAssets),
    };
  };
};

const container = connect(
  makeMapStateToProps,
  {
    ...dropTargetActions,
    selectAssetsOnClick,
    selectAssetsOnly,
  },
  (stateProps, actions, ownProps) => ({
    ...stateProps,
    ...actions,
    ...ownProps,
    onClick(e) {
      if (!e.shiftKey) {
        return;
      }

      actions.selectAssetsOnClick(
        e,
        stateProps.isSelected,
        stateProps.collectionAssets.map(asset => asset.path),
      );
    },
  }),
);

export default container(Collection);
