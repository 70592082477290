import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import container from './container';
import { tracker } from '../../api';
import { useRequest } from '../../http';
import { IconButton } from '../../ui';
import { PlayIcon } from '../../icons';

const ButtonStartTracker = ({ projectID, onStart, onFail }) => {
  const { requesting, request } = useRequest(tracker.startTracker(projectID), {
    doRequest: false,
    onSuccess: onStart,
    onFail,
  });
  const handleOnClick = useCallback(() => request(), [request]);

  return (
    <IconButton onClick={handleOnClick} disabled={requesting} icon={PlayIcon} />
  );
};

ButtonStartTracker.propTypes = {
  projectID: PropTypes.string.isRequired,
  onStart: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
};

export default container(ButtonStartTracker);
