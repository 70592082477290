import React from 'react';
import { createUseStyles } from 'react-jss';
import { object, number, func } from 'prop-types';
import { CloseIcon } from '../../icons';
import { colors, FloatInput, IconButtonSecondary } from '../../ui';
import ErrorList from './ErrorList';

const useStyles = createUseStyles({
  fieldBox: {
    display: 'flex',
    width: '97%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
    color: colors.white,
  },
  crossIcon: {
    width: 16,
  },
  input: {
    width: 160,
  },
});

const SizeField = ({ size, error, index, replace, remove, handleBlur }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.fieldBox}>
        <div className={classes.input}>
          <FloatInput
            value={size.width}
            onChange={width =>
              replace(index, {
                ...size,
                width,
              })
            }
            onBlur={handleBlur}
            invalid={error && error.width !== undefined}
            rounded
            expanded
            big
          />
        </div>
        <CloseIcon fill={colors.white} className={classes.crossIcon} />
        <div className={classes.input}>
          <FloatInput
            value={size.height}
            onChange={height =>
              replace(index, {
                ...size,
                height,
              })
            }
            onBlur={handleBlur}
            invalid={error && error.height !== undefined}
            rounded
            expanded
            big
          />
        </div>
        <IconButtonSecondary
          type="button"
          icon={CloseIcon}
          onClick={() => remove(index)}
        />
      </div>
      {error != null && <ErrorList error={error} />}
    </div>
  );
};

SizeField.defaultProps = {
  error: null,
};

SizeField.propTypes = {
  error: object,
  size: object.isRequired,
  index: number.isRequired,
  replace: func.isRequired,
  remove: func.isRequired,
  handleBlur: func.isRequired,
};

export default SizeField;
