import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import { useMutation, gql } from '@apollo/client';
import { string, func } from 'prop-types';
import {
  ButtonPrimary,
  ButtonTertiary,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  createHideableComponent,
} from '../../ui';
import { showNotification } from '../../Notification';

export const ERROR_MESSAGE =
  'It was not possible to duplicate project now. Try again later.';

export const DUPLICATE_PROJECT = gql`
  mutation DuplicateProject($id: String!) {
    duplicateProject(id: $id) {
      project {
        id
      }
    }
  }
`;

const ModalContext = createHideableComponent('duplicateProject');

const ModalDuplicateProject = ({ id, refetch, sendErrorNotification }) => {
  const [state, setState] = useState({
    submitting: false,
  });
  const [duplicateProject] = useMutation(DUPLICATE_PROJECT);

  const duplicatingProject = useCallback(
    closeModal => () => {
      setState({
        submitting: true,
      });
      duplicateProject({ variables: { id } })
        .then(({ data }) => {
          const { project } = data.duplicateProject;
          if (project.id) {
            refetch();
            closeModal();
            setState({
              submitting: false,
            });
            return;
          }

          throw new Error('Response error');
        })
        .catch(() => {
          setState({
            submitting: false,
          });
          sendErrorNotification();
        });
    },
    [id, refetch, setState, sendErrorNotification],
  );

  return (
    <ModalContext.Target>
      {({ isVisible, hide }) => (
        <Modal isVisible={isVisible}>
          <ModalHeader>Duplicate Project</ModalHeader>
          <ModalContent>
            You are about to duplicate this project. When you press confirm, you
            will allow to copy all assets and pieces to another project
          </ModalContent>
          <ModalFooter>
            <ButtonTertiary
              label="Cancel"
              disabled={state.submitting}
              onClick={hide}
            />
            <ButtonPrimary
              label="Confirm"
              disabled={state.submitting}
              onClick={duplicatingProject(hide)}
            />
          </ModalFooter>
        </Modal>
      )}
    </ModalContext.Target>
  );
};

ModalDuplicateProject.propTypes = {
  id: string.isRequired,
  refetch: func.isRequired,
  sendErrorNotification: func.isRequired,
};

const withRedux = connect(null, dispatch => ({
  sendErrorNotification() {
    dispatch(showNotification(ERROR_MESSAGE));
  },
}));

export default compose(withRedux)(ModalDuplicateProject);
