// Playback
export const SET_CURRENT_TIME = 'PLAYBACK/SET_CURRENT_TIME';

export const SET_CLOCK_DURATION = 'PLAYBACK/SET_CLOCK_DURATION';

export const PLAY = 'PLAYBACK/PLAY';

export const PAUSE = 'PLAYBACK/PAUSE';

export const TOGGLE_REPEAT_MODE = 'PLAYBACK/TOGGLE_REPEAT_MODE';
