import React from 'react';
import PropTypes from 'prop-types';
import { or, explicitNull } from 'airbnb-prop-types';
import Spacer from '../Spacer';
import BaseInput from './BaseInput';
import { PROPERTIES_PRECISION } from '../../models';

const translateXProperty = 'translateX';
const translateXPrecision = PROPERTIES_PRECISION[translateXProperty];

const translateYProperty = 'translateY';
const translateYPrecision = PROPERTIES_PRECISION[translateYProperty];

const Translate = ({
  translateX,
  translateY,
  disabled,
  translateXChange,
  translateYChange,
}) => (
  <>
    <BaseInput
      id={translateXProperty}
      label="X"
      disabled={disabled}
      onChange={translateXChange}
      value={translateX}
      precision={translateXPrecision}
    />
    <Spacer />
    <BaseInput
      id={translateYProperty}
      label="Y"
      disabled={disabled}
      onChange={translateYChange}
      value={translateY}
      precision={translateYPrecision}
    />
  </>
);

Translate.propTypes = {
  translateX: or([PropTypes.number, explicitNull()]),
  translateY: or([PropTypes.number, explicitNull()]),
  disabled: PropTypes.bool,
  translateXChange: PropTypes.func,
  translateYChange: PropTypes.func,
};
Translate.defaultProps = {
  translateX: null,
  translateY: null,
  disabled: true,
  translateXChange: () => {},
  translateYChange: () => {},
};

export default Translate;
