import React from 'react';
import { useSelector } from 'react-redux';
import GlobalShortcuts, {
  timelineShortcuts,
  stageShortcuts,
} from '../Shortcuts';
import { getFocusedArea, STAGE_FOCUSED, TIMELINE_FOCUSED } from '../editor';

const shortcutsByArea = {
  [STAGE_FOCUSED]: stageShortcuts,
  [TIMELINE_FOCUSED]: timelineShortcuts,
};

const EditorShortcuts = () => {
  const focusedArea = useSelector(state => getFocusedArea(state));
  return <GlobalShortcuts shortcutsHandlers={shortcutsByArea[focusedArea]} />;
};

export default EditorShortcuts;
