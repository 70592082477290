import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { applyToPoint, compose, inverse } from 'transformation-matrix';
import { getParentMatrix } from '../selectors';
import { createObject, getCurrentProjectIsAMP } from '../project';
import { makeDivObject, AMP_PROHIBITED_PROPERTIES } from '../models';
import { getZoomScale, getCurrentPieceID } from '../stage';
import {
  StageMode,
  getStageMode,
  addObjectToSelection,
  setStageMode,
} from '../editor';

const useStyles = createUseStyles({
  divTool: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    cursor: 'cell',
  },
});

const DivTool = ({ enabled, onClick }) => {
  const classes = useStyles();
  return (
    enabled && (
      <div
        role="button"
        tabIndex={-1}
        onClick={onClick}
        className={classes.divTool}
      />
    )
  );
};

DivTool.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const container = connect(
  state => ({
    scale: getZoomScale(state),
    pieceID: getCurrentPieceID(state),
    enabled: getStageMode(state) === StageMode.div,
    parentMatrix: getParentMatrix(state),
    projectIsAMP: getCurrentProjectIsAMP(state),
  }),
  {
    createObject,
    setStageMode,
    addObjectToSelection,
  },
  (stateProps, actions, { left, top, stageRef }) => ({
    ...stateProps,
    onClick(e) {
      const width = 100;
      const height = 100;
      if (!stageRef.current) return;

      const { offsetLeft, offsetTop } = stageRef.current;
      const { parentMatrix, scale } = stateProps;

      const { x, y } = applyToPoint(compose(inverse(parentMatrix)), {
        x: (e.clientX - offsetLeft) / scale - left,
        y: (e.clientY - offsetTop) / scale - top,
      });

      const object = makeDivObject({
        width,
        height,
        left: x - width / 2,
        top: y - height / 2,
        pieceID: stateProps.pieceID,
        editorOptions: {
          prohibitedProperties: stateProps.projectIsAMP
            ? AMP_PROHIBITED_PROPERTIES
            : [],
        },
      });
      actions.createObject(object);
      actions.setStageMode(StageMode.select);
      actions.addObjectToSelection(object.id);
    },
  }),
);

export default container(DivTool);
