import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxWithLabel } from '../../ui';

const FullSizeOption = ({ fullSize, onChange, disabled }) => (
  <CheckboxWithLabel
    label="Mark as full size object"
    onChange={onChange}
    checked={fullSize}
    disabled={disabled}
    small
  />
);

FullSizeOption.propTypes = {
  fullSize: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

FullSizeOption.defaultProps = {
  fullSize: false,
  disabled: true,
  onChange: () => {},
};

export default FullSizeOption;
