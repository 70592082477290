import React from 'react';
import { createUseStyles } from 'react-jss';
import { string, func, bool } from 'prop-types';
import ModalRemoveCollection from './ModalRemoveCollection';
import PopoverCollection, {
  ButtonOpenPopoverCollection,
} from './PopoverCollection';
import {
  CollectionHeader as BaseHeader,
  CollectionToggleIcon,
  CollectionName,
} from '../Assets';

const useStyles = createUseStyles({
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const CollectionHeader = ({
  name,
  path,
  icon,
  onClick,
  isOver,
  isSelected,
}) => {
  const classes = useStyles();
  return (
    <>
      <PopoverCollection collectionPath={path} />
      <ModalRemoveCollection collectionPath={path} />
      <BaseHeader
        onClick={onClick}
        icon={icon}
        isOver={isOver}
        isSelected={isSelected}
      >
        <CollectionName name={name} />
        <CollectionToggleIcon icon={icon} />
        <div className={classes.actions}>
          <ButtonOpenPopoverCollection collectionPath={path} />
        </div>
      </BaseHeader>
    </>
  );
};

CollectionHeader.propTypes = {
  name: string.isRequired,
  path: string.isRequired,
  icon: func.isRequired,
  onClick: func.isRequired,
  isOver: bool.isRequired,
  isSelected: bool.isRequired,
};

export default CollectionHeader;
