import { accounts } from '../api';
import {
  clearAuthStorage,
  isAuthenticated,
  getRefreshToken,
  setAccessToken,
  isRefreshTokenNearbyToExpire,
} from './storage';

export const refreshToken = () =>
  new Promise(resolve => {
    const token = getRefreshToken();

    if (!token) {
      clearAuthStorage();
      return resolve();
    }

    return accounts.refreshToken(token).then(({ ok, data }) => {
      if (ok) {
        setAccessToken(data);
      } else if (!isAuthenticated()) {
        clearAuthStorage();
      }
      return resolve();
    });
  });

export const initAuthService = () =>
  new Promise(resolve => {
    // Try to guarantee at least 8 hours of valid refresh token
    if (isRefreshTokenNearbyToExpire()) {
      clearAuthStorage();
      return resolve(false);
    }

    if (isAuthenticated()) {
      return resolve(false);
    }

    return refreshToken().then(() => resolve(true));
  });
