import React from 'react';
import { createUseStyles } from 'react-jss';
import { SliderZoom } from '../StageComponents';

const useStyles = createUseStyles({
  zoom: {
    position: 'relative',
    height: 50,
  },
  sliderZoom: {
    position: 'absolute',
    right: 0,
  },
});

const SharedPlayerZoom = () => {
  const classes = useStyles();

  return (
    <div className={classes.zoom}>
      <div className={classes.sliderZoom}>
        <SliderZoom />
      </div>
    </div>
  );
};

export default SharedPlayerZoom;
