import cuid from 'cuid';
import { Record } from 'immutable';

const basePiece = {
  id: '',
  height: 0,
  width: 0,
  parentID: null,
  duration: 5 * 1000,
  createdAt: null,
  updatedAt: null,
};

export const PieceModel = Record(basePiece);

export const makePiece = pieceProps => {
  const timestamp = new Date();
  return PieceModel({
    id: cuid(),
    height: pieceProps.height,
    width: pieceProps.width,
    createdAt: pieceProps.createdAt ? pieceProps.createdAt : timestamp,
    updatedAt: pieceProps.updatedAt ? pieceProps.updatedAt : timestamp,
  });
};

export const makeBasePiece = piece => ({
  ...basePiece,
  ...piece,
});
