import roundValue from 'lodash/round';
import { identity, compose, applyToPoints } from 'transformation-matrix';
import {
  LEFT,
  RIGHT,
  CENTER,
  TOP,
  BOTTOM,
  OPPOSITE_ORIGIN,
  X_ORIGIN_KEYWORDS,
  Y_ORIGIN_KEYWORDS,
} from './constants';

export const buildOriginString = (xOrigin, yOrigin) =>
  [xOrigin, yOrigin].join('-');

export const ORIGINS = {
  [buildOriginString(LEFT, TOP)]: 0,
  [buildOriginString(CENTER, TOP)]: 1,
  [buildOriginString(RIGHT, TOP)]: 2,
  [buildOriginString(LEFT, CENTER)]: 3,
  [buildOriginString(CENTER, CENTER)]: 4,
  [buildOriginString(RIGHT, CENTER)]: 5,
  [buildOriginString(LEFT, BOTTOM)]: 6,
  [buildOriginString(CENTER, BOTTOM)]: 7,
  [buildOriginString(RIGHT, BOTTOM)]: 8,
};

export const getValueByOrigin = (coords, originX, originY) =>
  coords[ORIGINS[buildOriginString(originX, originY)]];

export const getOppositeOrigin = keywords => {
  const originX =
    OPPOSITE_ORIGIN[
      keywords.find(keyword => X_ORIGIN_KEYWORDS.includes(keyword))
    ] || CENTER;
  const originY =
    OPPOSITE_ORIGIN[
      keywords.find(keyword => Y_ORIGIN_KEYWORDS.includes(keyword))
    ] || CENTER;

  return buildOriginString(originX, originY);
};

export const buildCoordinates = ({ left = 0, top = 0, width, height }) => [
  // left-top
  {
    x: left,
    y: top,
  },
  // center-top
  {
    x: left + width / 2,
    y: top,
  },
  // right-top
  {
    x: left + width,
    y: top,
  },
  // left-center
  {
    x: left,
    y: top + height / 2,
  },
  // center-center
  {
    x: left + width / 2,
    y: top + height / 2,
  },
  // right-center
  {
    x: left + width,
    y: top + height / 2,
  },
  // left-bottom
  {
    x: left,
    y: top + height,
  },
  // center-bottom
  {
    x: left + width / 2,
    y: top + height,
  },
  // right-bottom
  {
    x: left + width,
    y: top + height,
  },
];

export const getObjectCoordinates = object => {
  const { width, height } = object;

  return buildCoordinates({ width, height });
};

export const getTransformedObjectCoordinates = (
  object,
  parentMatrix = identity(),
) => {
  const matrix = compose(parentMatrix, object.matrix);
  return applyToPoints(matrix, getObjectCoordinates(object));
};

export const getMinMaxFromPoints = points =>
  points.reduce(
    (coords, point) => {
      const newCoords = { ...coords };
      const { x, y } = point;
      if (x < coords.minX) {
        newCoords.minX = x;
      }

      if (y < coords.minY) {
        newCoords.minY = y;
      }

      if (x > coords.maxX) {
        newCoords.maxX = x;
      }

      if (y > coords.maxY) {
        newCoords.maxY = y;
      }

      return newCoords;
    },
    {
      minX: points[0].x,
      minY: points[0].y,
      maxX: points[0].x,
      maxY: points[0].y,
    },
  );

export const degreeToRadians = degree => degree * (Math.PI / 180);
export const radiansToDegree = radians => radians * (180 / Math.PI);

export const round = value => roundValue(value, 2);

export const extractRotationFromMatrix = (matrix = identity()) => {
  const { a, b } = matrix;
  return radiansToDegree(Math.atan2(-b, a) * -1);
};

export const extractScaleFromMatrix = (matrix = identity()) => {
  const { sign, sqrt, abs } = Math;
  const { a, b, c, d } = matrix;

  return {
    scaleX: abs(sign(a) * sqrt(a ** 2 + b ** 2)),
    scaleY: abs(sign(d) * sqrt(c ** 2 + d ** 2)),
  };
};
