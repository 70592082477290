import React, { Component } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { setCurrentTime, getClockDuration } from '../playback';
import {
  getKeyframeIsSelected,
  getSelectedKeyframes,
  selectObjectOnClick,
  selectKeyframeOnClick,
  getIsObjectLocked,
} from '../editor';
import { updatePropertyKeyframeTime } from '../project';
import { colors, PopoverTrigger } from '../ui';
import { getKeyframeSize, time2px } from './utils';
import { getIsClipPathID } from '../selectors';
import MovableComponent from './MovableComponent';
import { createSelectable } from '../DragSelect';

class Keyframe extends Component {
  constructor() {
    super();
    this.onDown = this.onDown.bind(this);
    this.onMove = this.onMove.bind(this);
    this.keyframeRef = React.createRef();
  }

  onDown(event) {
    if (!this.props.isSelected || event.shiftKey) {
      this.props.select(event);
    }

    this.props.selectableRef.current.focus();
  }

  onMove(rawTime) {
    let deltaTime = rawTime;
    if (!deltaTime) {
      return;
    }

    const { pieceDuration, time } = this.props;
    const newTime = time + deltaTime;

    if (newTime < 0) {
      deltaTime = 0;
    } else if (newTime > pieceDuration) {
      deltaTime = pieceDuration - time;
    }

    this.props.updateKeyframeTime(deltaTime);
  }

  render() {
    const {
      classes,
      time,
      timelineHeight,
      onClick,
      selectableRef,
      objectID,
      isClipPath,
    } = this.props;

    const keyframeSize = getKeyframeSize(timelineHeight);
    const transform = `translateX(${time2px(time) - (keyframeSize / 2 - 1)}px)`;
    return (
      <PopoverTrigger id="popover-keyframe">
        {openPopover => (
          <MovableComponent onDown={this.onDown} onMove={this.onMove}>
            {movableProps => (
              <div
                {...movableProps}
                ref={selectableRef}
                onClick={onClick}
                className={classes.keyframe}
                onContextMenu={evt =>
                  openPopover(evt, {
                    objectID,
                    timelineTime: time,
                    isKeyframe: true,
                    isClipPath,
                  })
                }
                style={{ transform }}
                role="button"
                tabIndex="-1"
              />
            )}
          </MovableComponent>
        )}
      </PopoverTrigger>
    );
  }
}

Keyframe.propTypes = {
  classes: object.isRequired,
  pieceDuration: number.isRequired,
  onClick: func.isRequired,
  time: number.isRequired,
  timelineHeight: number.isRequired,
  updateKeyframeTime: func.isRequired,
  select: func.isRequired,
  isSelected: bool.isRequired,
  isClipPath: bool.isRequired,
  selectableRef: object.isRequired,
  objectID: string.isRequired,
};

const container = connect(
  (state, { time, keyframe, objectID }) => ({
    pieceDuration: getClockDuration(state),
    selectedKeyframes: getSelectedKeyframes(state),
    isSelected: getKeyframeIsSelected(keyframe, state),
    isSelectable: !getIsObjectLocked(state, objectID),
    isClipPath: getIsClipPathID(state, objectID),
    time,
  }),
  {
    selectObjectOnClick,
    selectKeyframeOnClick,
    setCurrentTime,
    updatePropertyKeyframeTime,
  },
  (state, actions, { objectID, keyframe, ...props }) => ({
    ...state,
    ...props,
    keyframe,
    objectID,
    updateKeyframeTime: newTime => {
      actions.updatePropertyKeyframeTime(
        state.selectedKeyframes,
        newTime,
        state.pieceDuration,
      );
    },
    onClick: event => {
      actions.setCurrentTime(state.time);
      actions.selectObjectOnClick(event, objectID);
    },
    select(event) {
      actions.selectKeyframeOnClick(
        event,
        objectID,
        keyframe,
        state.isSelected,
      );
    },
  }),
);

const style = injectSheet({
  keyframe: {
    background: ({ isSelected }) => (isSelected ? colors.yellow : colors.gray2),
    borderRadius: '100%',
    cursor: 'pointer',
    position: 'absolute',
    top: ({ timelineHeight }) => {
      const keyframeHeight = getKeyframeSize(timelineHeight);
      return (timelineHeight - keyframeHeight) / 2;
    },
    width: ({ timelineHeight }) => getKeyframeSize(timelineHeight),
    height: ({ timelineHeight }) => getKeyframeSize(timelineHeight),
    touchAction: 'none',
    '&:focus': {
      boxShadow: '0 0 2px 2px rgba(255,214,21,0.50) !important',
    },
  },
});

export default container(
  createSelectable(style(Keyframe), ({ objectID, keyframe }) => [
    keyframe.id,
    objectID,
  ]),
);
