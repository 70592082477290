import React from 'react';
import { array, node } from 'prop-types';
import TOOLS from './tools';

const defaultState = {
  visibleTools: TOOLS,
  toggleToolVisibility: () => null,
  isVisibleTool: () => null,
};
export const Context = React.createContext(defaultState);

const isVisibleTool = (state, tool) => state.visibleTools.includes(tool);

class EditorContext extends React.Component {
  constructor(props) {
    super(props);
    this.toggleToolVisibility = this.toggleToolVisibility.bind(this);
    this.isVisibleTool = this.isVisibleTool.bind(this);

    this.state = {
      ...defaultState,
      visibleTools: defaultState.visibleTools.filterNot(tool =>
        props.disabledTools.includes(tool),
      ),
      toggleToolVisibility: this.toggleToolVisibility,
      isVisibleTool: this.isVisibleTool,
    };
  }

  toggleToolVisibility(tool) {
    this.setState(state => ({
      ...state,
      visibleTools: isVisibleTool(state, tool)
        ? state.visibleTools.delete(tool)
        : state.visibleTools.add(tool),
    }));
  }

  isVisibleTool(tool) {
    return isVisibleTool(this.state, tool);
  }

  render() {
    const { children } = this.props;

    return <Context.Provider value={this.state}>{children}</Context.Provider>;
  }
}

EditorContext.Consumer = Context.Consumer;

EditorContext.defaultProps = {
  disabledTools: [],
};

EditorContext.propTypes = {
  children: node.isRequired,
  disabledTools: array,
};

export default EditorContext;
