import React from 'react';
import { string } from 'prop-types';
import { IconButtonTertiary, PopoverTrigger } from '../ui';
import { Ellipsis } from '../icons';
import { buildPopoverIdentifier } from './PopoverProject';

const TogglePopover = ({ id }) => (
  <PopoverTrigger id={buildPopoverIdentifier(id)}>
    {open => <IconButtonTertiary icon={Ellipsis} onClick={open} />}
  </PopoverTrigger>
);

TogglePopover.propTypes = {
  id: string.isRequired,
};

export default TogglePopover;
