import { Record } from 'immutable';
import baseMixin, { baseObject } from './base';
import { DIV } from './type';

export const defaultObject = {
  ...baseObject,
  type: DIV,
  backgroundColor: 'transparent',
};

class DivObject extends baseMixin(Record(defaultObject)) {}

export default DivObject;
