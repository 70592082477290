import { VIDEO_ASSET_TYPE, IMAGE_ASSET_TYPE } from '../models';

const IMAGE_EXT = ['.jpeg', '.jpg', '.png'];
const VIDEO_EXT = ['.mp4'];
const ALL_EXT = IMAGE_EXT.concat(VIDEO_EXT);

export const buildAcceptList = assetType => {
  if (!assetType) {
    return ALL_EXT.join(',');
  }

  if (assetType === VIDEO_ASSET_TYPE) {
    return VIDEO_EXT.join(',');
  }

  return IMAGE_EXT.join(',');
};

const assetTypeByExt = {
  mp4: VIDEO_ASSET_TYPE,
  jpeg: IMAGE_ASSET_TYPE,
  jpg: IMAGE_ASSET_TYPE,
  png: IMAGE_ASSET_TYPE,
};

export const getAssetTypeFromFilename = filename => {
  if (!filename) {
    return '';
  }

  const splits = filename.split('.');
  const ext = splits[splits.length - 1];

  return assetTypeByExt[ext] || '';
};
