import React from 'react';
import { string, func } from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { getCurrentProjectID, addCollection } from '../project';
import { assets } from '../api';
import { createHideableComponent } from '../ui/hideable';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../Notification';
import {
  ButtonPrimary,
  ButtonTertiary,
  IconButtonTertiary,
  PopoverItem,
  TextField,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '../ui';
import { FolderIcon } from '../icons';
import { FILENAME_INVALID_CHARS } from '../utils';

const ModalContext = createHideableComponent();

export const ButtonOpenCreateCollectionModal = () => (
  <ModalContext.Trigger>
    {({ show }) => <PopoverItem label="Create collection" onClick={show} />}
  </ModalContext.Trigger>
);

export const IconButtonOpenCreateCollectionModal = () => (
  <ModalContext.Trigger>
    {({ show }) => <IconButtonTertiary icon={FolderIcon} onClick={show} />}
  </ModalContext.Trigger>
);

const ModalCreateCollection = ({ projectID, onSuccess, onError, ...props }) => (
  <ModalContext.Target {...props}>
    {({ isVisible, hide }) => (
      <Modal isVisible={isVisible} small>
        <ModalHeader>Create Asset Collection</ModalHeader>
        <Formik
          initialValues={{ name: '' }}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            setSubmitting(true);
            assets
              .createCollection(projectID, values.name)
              .then(response => {
                if (response.ok) {
                  hide();
                  onSuccess(response.data);
                } else if (response.data) {
                  setErrors({ name: response.data });
                } else {
                  onError();
                }
              })
              .catch(onError)
              .then(() => setSubmitting(false));
          }}
        >
          {({ isSubmitting, isValid, dirty, errors }) => (
            <Form>
              <ModalContent>
                <Field
                  name="name"
                  validate={value => {
                    if (!value) {
                      return 'Required';
                    }

                    if (value.match(FILENAME_INVALID_CHARS)) {
                      return 'Has invalid characters';
                    }

                    return undefined;
                  }}
                >
                  {({ field }) => (
                    <TextField
                      title="Collection name"
                      disabled={isSubmitting}
                      error={errors.name}
                      expanded
                      autoComplete="off"
                      autoFocus
                      {...field}
                    />
                  )}
                </Field>
              </ModalContent>
              <ModalFooter>
                <ButtonTertiary
                  label="Cancel"
                  type="button"
                  onClick={hide}
                  disabled={isSubmitting}
                />
                <ButtonPrimary
                  label="Create"
                  type="submit"
                  disabled={isSubmitting || !isValid || !dirty}
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    )}
  </ModalContext.Target>
);

ModalCreateCollection.propTypes = {
  projectID: string.isRequired,
  onError: func.isRequired,
  onSuccess: func.isRequired,
};

const container = connect(
  state => ({
    projectID: getCurrentProjectID(state),
  }),
  { showErrorNotification, showSuccessNotification, addCollection },
  ({ projectID }, actions, props) => ({
    ...props,
    projectID,
    onSuccess: collection => {
      actions.showSuccessNotification('Collection created successfully!');
      actions.addCollection(collection);
    },
    onError: () =>
      actions.showErrorNotification(
        'Could not create your collection. Try again later.',
      ),
  }),
);

export default container(ModalCreateCollection);
