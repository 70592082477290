import React from 'react';
import ButtonTogglePlay from './ButtonTogglePlay';
import CheckboxToggleRepeatMode from './CheckboxToggleRepeatMode';
import InputCurrentTime from './InputCurrentTime';
import ControlBar from './ControlBar';
import Spacer from './Spacer';
import InputsContainer from './InputsContainer';
import InputContainer from './InputContainer';
import Clock from './Clock';
import Duration from './Duration';

const SharedPlaybackControls = () => (
  <ControlBar>
    <ButtonTogglePlay />
    <Spacer />
    <CheckboxToggleRepeatMode />
    <InputsContainer>
      <Clock />
      Time
      <InputContainer>
        <InputCurrentTime />
      </InputContainer>
      Duration
      <InputContainer>
        <Duration />
      </InputContainer>
    </InputsContainer>
  </ControlBar>
);

export default SharedPlaybackControls;
