import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Scrollbars } from 'react-custom-scrollbars';
import colors from '../ui/colors';

const useStyles = createUseStyles({
  trackVertical: {
    top: 0,
    height: '99%',
    right: 2,
    borderRadius: 10,
    width: '4px !important',
    '&:hover': {
      width: '8px !important',
    },
  },
  thumbVertical: {
    cursor: 'pointer',
    height: 80,
    width: '100%',
    background: colors.gray2,
    borderRadius: 10,
    '&:active': {
      background: colors.black2,
    },
  },
  trackHorizontal: {
    width: '99%',
    bottom: 4,
    borderRadius: 10,
    height: '4px !important',
    '&:hover': {
      height: '8px !important',
    },
  },
  thumbHorizontal: {
    cursor: 'pointer',
    background: colors.gray2,
    borderRadius: 10,
    '&:active': {
      background: colors.black2,
    },
  },
});

const CustomScroll = ({
  children,
  onScroll,
  autoHeight,
  autoHeightMax,
  scrollRef,
}) => {
  const classes = useStyles();
  return (
    <Scrollbars
      autoHeight={autoHeight}
      autoHeightMax={autoHeightMax}
      onScroll={onScroll}
      ref={scrollRef}
      renderThumbVertical={props => (
        <div {...props} className={classes.thumbVertical} />
      )}
      renderTrackVertical={props => (
        <div {...props} className={classes.trackVertical} />
      )}
      renderThumbHorizontal={props => (
        <div {...props} className={classes.thumbHorizontal} />
      )}
      renderTrackHorizontal={props => (
        <div {...props} className={classes.trackHorizontal} />
      )}
    >
      {children}
    </Scrollbars>
  );
};

CustomScroll.propTypes = {
  autoHeight: PropTypes.bool,
  autoHeightMax: PropTypes.number,
  children: PropTypes.node.isRequired,
  onScroll: PropTypes.func,
  scrollRef: PropTypes.object,
};

CustomScroll.defaultProps = {
  autoHeightMax: null,
  autoHeight: false,
  onScroll: null,
  scrollRef: null,
};

export default CustomScroll;
