import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';
import colors from '../colors';
import * as typography from '../typography';

const useStyles = createUseStyles(theme => ({
  InputMultipleLines: {
    ...props => (props.big ? typography.textRegular : typography.label),
    backgroundColor: 'transparent',
    border: `1px solid`,
    borderColor: ({ invalid }) => (invalid ? colors.error : theme.fg),
    borderRadius: props => (props.rounded ? 20 : 4),
    color: theme.fg,
    paddingBottom: 0,
    padding: props => (props.big ? 16 : 8),
    paddingTop: 0,
    outline: 'none',
    maxWidth: props => {
      if (props.expanded) {
        return '100%';
      }
      if (props.big) {
        return 350;
      }

      return 72;
    },
    width: props => (props.expanded ? '100%' : 'auto'),
    textAlign: 'left',
    '&:disabled': {
      background: 'none',
      opacity: 0.35,
    },
    '&::placeholder': {
      color: theme.fg,
    },
    '&:focus': {
      boxShadow: 'none',
      '&::placeholder': {
        color: 'transparent',
      },
      borderColor: colors.primary,
    },
    resize: 'none',
  },
}));

const InputMultipleLines = ({ rows, ...props }) => {
  const theme = useTheme();

  const classes = useStyles({ ...props, theme });
  delete props.expanded; // eslint-disable-line no-param-reassign
  delete props.big; // eslint-disable-line no-param-reassign
  delete props.invalid; // eslint-disable-line no-param-reassign
  delete props.rounded; // eslint-disable-line no-param-reassign
  return (
    <textarea type="text" className={classes.InputMultipleLines} {...props} />
  );
};

InputMultipleLines.propTypes = {
  rounded: PropTypes.bool,
  expanded: PropTypes.bool,
  big: PropTypes.bool,
  invalid: PropTypes.bool,
  rows: PropTypes.number,
  onChange: PropTypes.func,
};

InputMultipleLines.defaultProps = {
  invalid: false,
  expanded: false,
  rounded: false,
  big: false,
  rows: 2,
  onChange: () => {},
};

export default InputMultipleLines;
