import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';

const useStyles = createUseStyles({
  section: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: [12, 16],
    borderTop: ({ noBorder }) =>
      noBorder ? null : `1px solid ${colors.black2}`,
  },
});

const Section = ({ children, ...props }) => {
  const classes = useStyles(props);

  return <div className={classes.section}>{children}</div>;
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Section;
