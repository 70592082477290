import React from 'react';
import PropTypes from 'prop-types';
import BaseInput from './BaseInput';
import Spacer from '../Spacer';
import { PROPERTIES_PRECISION } from '../../models';

const leftProperty = 'left';
const leftPrecision = PROPERTIES_PRECISION[leftProperty];

const topProperty = 'top';
const topPrecision = PROPERTIES_PRECISION[topProperty];

const Position = ({ left, top, disabled, leftChange, topChange }) => (
  <>
    <BaseInput
      id={leftProperty}
      label="X"
      disabled={disabled}
      onChange={leftChange}
      value={left}
      precision={leftPrecision}
    />
    <Spacer />
    <BaseInput
      id={topProperty}
      label="Y"
      disabled={disabled}
      onChange={topChange}
      value={top}
      precision={topPrecision}
    />
  </>
);

Position.defaultProps = {
  left: null,
  top: null,
  leftChange: () => {},
  topChange: () => {},
  disabled: true,
};

Position.propTypes = {
  left: PropTypes.number,
  top: PropTypes.number,
  disabled: PropTypes.bool,
  leftChange: PropTypes.func,
  topChange: PropTypes.func,
};

export default Position;
