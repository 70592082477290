import React from 'react';
import { CURVES } from '../models';
import { Popover, PopoverSpacer } from '../ui';
import ButtonRemoveKeyframes from './ButtonRemoveKeyframes';
import ButtonSetKeyframesEasing from './ButtonSetKeyframesEasing';
import { ButtonSaveAnimation } from './ModalSaveAnimation';
import { ButtonApplyAnimation } from './ModalApplyAnimation';

const PopoverKeyframe = () => (
  <Popover id="popover-keyframe" hideableOptions={{ permanent: true }}>
    {(event, { objectID, timelineTime, isKeyframe = false } = {}) => {
      return (
        <>
          <ButtonApplyAnimation objectID={objectID} time={timelineTime} />
          <ButtonSaveAnimation />
          {isKeyframe && <PopoverSpacer />}
          {isKeyframe && (
            <>
              {Object.values(CURVES).map(easing => (
                <ButtonSetKeyframesEasing key={easing.name} easing={easing} />
              ))}
              <PopoverSpacer />
              <ButtonRemoveKeyframes />
            </>
          )}
        </>
      );
    }}
  </Popover>
);

export default PopoverKeyframe;
