import React from 'react';
import { arrayOf, string } from 'prop-types';
import { connect } from 'react-redux';
import PiecesContainer from './PiecesContainer';
import SharedPiece from './SharedPiece';
import FlexWrap from './FlexWrap';
import { CustomScroll } from '../CustomScroll';
import { getAllPiecesIDs } from '../project';

const SharedPieces = ({ pieces }) => (
  <FlexWrap>
    <CustomScroll>
      <PiecesContainer>
        {pieces.map(pieceID => (
          <SharedPiece key={pieceID} pieceID={pieceID} />
        ))}
      </PiecesContainer>
    </CustomScroll>
  </FlexWrap>
);

SharedPieces.propTypes = {
  pieces: arrayOf(string).isRequired,
};

const container = connect(state => ({
  pieces: getAllPiecesIDs(state),
}));

export default container(SharedPieces);
