import React from 'react';
import { bool, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { ButtonTertiary } from '../../ui';
import { ShareIcon } from '../../icons';
import { ModalContext } from './ModalShareLink';
import * as actions from '../actions';

const useStyles = createUseStyles({
  exportPieces: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
    height: '100%',
    marginLeft: 32,
  },
});

const ShareButton = ({ label, requestShareLink, isQA }) => {
  const classes = useStyles();
  return (
    <div className={classes.exportPieces}>
      <ModalContext.Trigger>
        {({ show }) => (
          <ButtonTertiary
            onClick={() => {
              show();
              requestShareLink(isQA);
            }}
            label={label}
            icon={ShareIcon}
          />
        )}
      </ModalContext.Trigger>
    </div>
  );
};

ShareButton.defaultProps = {
  isQA: false,
};

ShareButton.propTypes = {
  label: string.isRequired,
  requestShareLink: func.isRequired,
  isQA: bool,
};

const container = connect(null, { requestShareLink: actions.requestShareLink });

export default container(ShareButton);
