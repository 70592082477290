import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { createUseStyles } from 'react-jss';
import {
  ButtonPrimary,
  ButtonTertiary,
  Modal,
  ModalHeader,
  ModalContent,
  ModalButtons,
  ModalSpacer,
  TextField,
  CheckboxWithLabel,
} from '../../ui';
import { SaveIcon, SearchIcon } from '../../icons';
import { createHideableComponent } from '../../ui/hideable';
import { makeKeyframe, makeClipPathFromObject } from '../../models';
import { getCurrentTime } from '../../playback';
import {
  getCurrentPiece,
  applySavedAnimation,
  getStartAndEndTimeFromKeyframes,
  getCurrentProjectID,
} from '../../project';
import { showErrorNotification } from '../../Notification';
import ListAnimations from './ListAnimations';
import Spacer from './Spacer';

export const ModalContext = createHideableComponent();

const initialStateParameters = {
  onlyMine: true,
};

const useStyles = createUseStyles({
  wrapperCheckboxes: {
    display: 'flex',
    '& label:nth-child(2)': {
      marginLeft: 24,
    },
  },
});

const ModalApplyAnimation = ({
  currentProjectID,
  applyAnimation,
  hideableOptions,
  debounceTime,
}) => {
  const [searchParams, setSearchParameters] = useState(initialStateParameters);
  const [searchQuery, setSearchQuery] = useState('');
  const [projectOnly, setProjectOnly] = useState(false);
  const [selectedAnimation, setSelectedAnimation] = useState(null);
  const [debounceID, setDebounceID] = useState(null);
  const [onlyMine, setOnlyMine] = useState(initialStateParameters.onlyMine);
  const classes = useStyles();

  useEffect(() => {
    let didCancel = false;
    if (debounceID) {
      clearTimeout(debounceID);
    }

    setDebounceID(
      setTimeout(() => {
        if (didCancel) {
          return;
        }

        const params = {};

        if (projectOnly) {
          params.projectUID = currentProjectID;
        }

        if (searchQuery.length) {
          params.name = searchQuery;
        }

        if (onlyMine) {
          params.onlyMine = true;
        }

        setSearchParameters(params);
        setDebounceID(null);
      }, debounceTime),
    );

    return () => {
      didCancel = true;
    };
  }, [searchQuery, projectOnly, onlyMine]);

  const onRequest = useCallback(() => {
    setSelectedAnimation(null);
  }, [setSelectedAnimation]);

  const onSearchTextChange = useCallback(
    evt => {
      evt.stopPropagation();
      evt.preventDefault();

      setSearchQuery(evt.target.value);
    },
    [setSearchQuery],
  );

  const onProjectCheckboxChange = useCallback(
    checked => {
      setProjectOnly(checked);
    },
    [setProjectOnly],
  );

  const handleChangeOnlyMine = useCallback(
    checked => {
      setOnlyMine(checked);
    },
    [setOnlyMine],
  );

  return (
    <ModalContext.Target hideableOptions={hideableOptions}>
      {({ isVisible, showArgs, hide }) => {
        const [objectID, time] = showArgs;

        // FIXME, interesting case, without this guard
        // the test without visibility of modal content
        // throw a warning because objectID in ListAnimations
        if (!isVisible) {
          return null;
        }

        return (
          <Modal isVisible={isVisible} small>
            <ModalHeader>Animation List</ModalHeader>
            <ModalContent>
              <TextField
                iconRight={SearchIcon}
                value={searchQuery}
                onChange={onSearchTextChange}
              />
              <ModalSpacer />
              <div className={classes.wrapperCheckboxes}>
                <CheckboxWithLabel
                  label="Project only"
                  checked={projectOnly}
                  onChange={onProjectCheckboxChange}
                />
                <CheckboxWithLabel
                  label="My Animations"
                  checked={onlyMine}
                  onChange={handleChangeOnlyMine}
                />
              </div>
              <Spacer />
              <ListAnimations
                searchParams={searchParams}
                selectedAnimation={selectedAnimation}
                onSelectAnimation={setSelectedAnimation}
                onRequest={onRequest}
                objectID={objectID}
              />
              <ModalButtons>
                <ButtonPrimary
                  extended
                  type="submit"
                  label="Apply animation"
                  icon={SaveIcon}
                  disabled={!selectedAnimation}
                  onClick={() =>
                    applyAnimation(
                      objectID,
                      time,
                      selectedAnimation.keyframes,
                    )(hide)
                  }
                />
                <ModalSpacer />
                <ButtonTertiary
                  onClick={event => {
                    event.preventDefault();
                    setSelectedAnimation(null);
                    hide();
                  }}
                  label="Cancel"
                  extended
                />
              </ModalButtons>
            </ModalContent>
          </Modal>
        );
      }}
    </ModalContext.Target>
  );
};

ModalApplyAnimation.propTypes = {
  currentProjectID: PropTypes.string.isRequired,
  debounceTime: PropTypes.number,
  applyAnimation: PropTypes.func.isRequired,
  hideableOptions: PropTypes.object,
};

ModalApplyAnimation.defaultProps = {
  debounceTime: 250,
  hideableOptions: {},
};

export const createKeyframesMap = (keyframesArray, offsetTime = 0) =>
  keyframesArray.reduce((currentMap, keyframe) => {
    const time = keyframe.time + offsetTime;

    return currentMap.set(
      time,
      makeKeyframe({
        ...keyframe,
        values: {
          ...keyframe.values,
          clipPath: makeClipPathFromObject(keyframe.values.clipPath),
        },
        time,
      }),
    );
  }, Map());

export const buildErrorMessage = endOverflow =>
  `This animation cannot be applied because it's too long (+${endOverflow}ms), please select another time to apply!`;

const withRedux = connect(
  state => ({
    currentProjectID: getCurrentProjectID(state),
    piece: getCurrentPiece(state),
    currentTime: getCurrentTime(state),
  }),
  { applySavedAnimation, showErrorNotification },
  (state, actions, ownProps) => ({
    ...state,
    ...ownProps,
    applyAnimation(objectID, time, keyframes) {
      const keyframesMap = createKeyframesMap(keyframes, time);
      const [, endTime] = getStartAndEndTimeFromKeyframes(keyframesMap);
      const { piece, currentTime } = state;

      if (endTime > piece.duration) {
        const endOverflow = endTime - piece.duration;
        actions.showErrorNotification(buildErrorMessage(endOverflow));
        return () => null;
      }

      actions.applySavedAnimation(objectID, currentTime, keyframesMap);
      return next => next();
    },
  }),
);

export default withRedux(ModalApplyAnimation);
