import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { getZoomScale, getCurrentPieceID } from '../stage';
import { getCurrentObjectID } from '../editor';
import { getParentMatrix } from '../selectors';
import { colors } from '../ui';
import { getObjectByID, getRootNodeFromPieceID } from '../project';
import { getBoundingBoxFromObject } from '../geometry';

const BORDER_WIDTH = 1;

const getContainerBorderStyle = ({ area, zoomScale }) => {
  const borderSize = BORDER_WIDTH * zoomScale;

  const x = area.left * zoomScale - BORDER_WIDTH;
  const y = area.top * zoomScale - BORDER_WIDTH;

  return {
    width: (area.width + BORDER_WIDTH * 2) * zoomScale,
    height: (area.height + BORDER_WIDTH * 2) * zoomScale,
    transform: `translate(${x}px, ${y}px) rotate(${area.angleDeg}deg)`,
    border: `${borderSize}px solid ${colors.info}`,
  };
};

const useStyles = createUseStyles({
  containerBorder: {
    position: 'absolute',
    outline: 'none',
  },
});

const ActiveContainerBorder = ({ disabled, area, zoomScale }) => {
  const classes = useStyles();
  if (disabled) {
    return null;
  }

  return (
    <div
      className={classes.containerBorder}
      style={getContainerBorderStyle({ area, zoomScale })}
    />
  );
};

ActiveContainerBorder.defaultProps = {
  disabled: true,
  zoomScale: 1,
  area: null,
};

ActiveContainerBorder.propTypes = {
  zoomScale: PropTypes.number,
  area: PropTypes.object,
  disabled: PropTypes.bool,
};

const container = connect(state => {
  const objectID = getCurrentObjectID(state);
  const rootNode = getRootNodeFromPieceID(state, getCurrentPieceID(state));

  if (!objectID || objectID === rootNode.objectID) {
    return {
      disabled: true,
    };
  }

  const object = getObjectByID(state, objectID);
  const matrix = getParentMatrix(state, objectID);

  return {
    disabled: false,
    zoomScale: getZoomScale(state),
    area: getBoundingBoxFromObject(object, matrix),
  };
});

export default container(ActiveContainerBorder);
