import request from '../http';
import { identityDecoder, urlFactory } from './utils';

export const url = urlFactory('zipper');

export default {
  zipPieces: data =>
    request(
      {
        url: url(`/export_all`),
        method: 'POST',
        data,
        responseType: 'blob',
      },
      identityDecoder,
    ),

  getEstimative: data =>
    request(
      {
        url: url(`/estimative_files`),
        method: 'POST',
        data,
        responseType: 'json',
      },
      identityDecoder,
    ),
};
