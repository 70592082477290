import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ActionCreators as undoRedo } from 'redux-undo';
import {
  clearObjectsSelection,
  clearKeyframesSelection,
  clearHiddenObjects,
  clearObjectsLock,
  setCurrentObjectID,
} from '../editor';
import RedirectToPieces from '../RedirectToPieces';
import Editor from './Editor';
import { usePieceLoader } from '../hooks';

const ViewEditor = () => {
  const { pieceID } = useParams();
  const dispatch = useDispatch();
  const [piece, rootNode, currentPiece] = usePieceLoader(pieceID);

  useEffect(() => {
    if (!piece || !rootNode) {
      return undefined;
    }

    dispatch(setCurrentObjectID(rootNode.objectID));

    return () => {
      dispatch(clearObjectsSelection());
      dispatch(clearKeyframesSelection());
      dispatch(clearHiddenObjects());
      dispatch(clearObjectsLock());
      dispatch(undoRedo.clearHistory());
    };
  }, [pieceID]);

  if (!piece || !rootNode) {
    return <RedirectToPieces />;
  }

  if (!currentPiece) {
    return <p>Loading...</p>;
  }

  return <Editor />;
};

export default ViewEditor;
