const keyframeSize = 0.5;
export const getKeyframeSize = height => height * keyframeSize;

const barHeight = 0.375;
export const getBarHeight = height => height * barHeight;

export const propertyBoxSize = 25;
export const secondSize = propertyBoxSize * 5;

export const time2px = (time, offset = 0) =>
  (time / 1000) * secondSize - offset;

export const px2time = pixels => (pixels * 1000) / secondSize;

export const px2AbsTime = pixels => Math.max((pixels * 1000) / secondSize, 0);

export const preventDefault = event => {
  event.preventDefault();
};
