import { connect } from 'react-redux';
import { showErrorNotification } from '../../Notification';
import { getCurrentProjectID } from '../../project';

export const DEFAULT_MESSAGE = 'Something is wrong, please try again!';
export const TRACKER_UPDATE_FAILED =
  'Time tracker cannot be updated, please inform project manager!';

export default connect(
  state => ({
    projectID: getCurrentProjectID(state),
  }),
  dispatch => ({
    onFail() {
      dispatch(showErrorNotification(DEFAULT_MESSAGE));
    },
    onTrackerFail() {
      dispatch(showErrorNotification(TRACKER_UPDATE_FAILED));
    },
  }),
);
