import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Scrollbars } from 'react-custom-scrollbars';
import colors from '../colors';

const useStyles = createUseStyles({
  scrollView: {
    position: 'relative !important',
  },
  trackVertical: {
    top: 0,
    height: '100%',
    width: '2px !important',
    right: 6,
  },
  thumbVertical: {
    height: 80,
    width: '100%',
    background: colors.gray2,
    borderRadius: 10,
  },
});

const Scroll = ({ children }) => {
  const classes = useStyles();
  return (
    <Scrollbars
      renderThumbVertical={props => (
        <div {...props} className={classes.thumbVertical} />
      )}
      renderTrackVertical={props => (
        <div {...props} className={classes.trackVertical} />
      )}
      renderView={props => <div {...props} className={classes.scrollView} />}
    >
      {children}
    </Scrollbars>
  );
};

Scroll.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Scroll;
