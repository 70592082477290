import React from 'react';
import { createUseStyles } from 'react-jss';
import { func } from 'prop-types';

const useStyles = createUseStyles({
  toggleIcon: {
    fill: 'white',
    flexShrink: 0,
    width: 8,
    marginLeft: 8,
  },
});

const CollectionToggleIcon = ({ icon }) => {
  const Icon = icon;
  const classes = useStyles();
  return <Icon className={classes.toggleIcon} />;
};

CollectionToggleIcon.propTypes = {
  icon: func.isRequired,
};

export default CollectionToggleIcon;
