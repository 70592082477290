import React, { Component } from 'react';
import { object, func } from 'prop-types';
import { withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { initFromServer, requestAssets, setCurrentProject } from '../project';
import { queryProjectPieces } from './api';
import { View, Content } from '../ViewState';
import { Header, Logo, Title } from '../HeaderViewProject';
import SharedView from './SharedView';
import SharedViewContext from './SharedViewContext';
import ViewSharedPlayer from '../ViewSharedPlayer';
import { path } from '../App';

const LOADING = 'LOADING';
const LOADED = 'LOADED';
const ERROR = 'ERROR';

class ViewSharedProject extends Component {
  constructor() {
    super();
    this.state = { status: LOADING, isQA: false };
    this.onQueryError = this.onQueryError.bind(this);
    this.onQuerySuccess = this.onQuerySuccess.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const { shareID } = match.params;
    queryProjectPieces(shareID, this.onQuerySuccess, this.onQueryError);
  }

  onQuerySuccess({ project, isQA }) {
    const { uid, pieces, name, amp, updatedAt } = project;
    this.props.requestAssets(uid);
    this.props.setCurrentProject({ id: uid, name, amp });
    this.props.initFromServer({ pieces, updatedAt });
    this.setState({ status: LOADED, isQA });
  }

  onQueryError() {
    this.setState({ status: ERROR });
  }

  getChildren() {
    switch (this.state.status) {
      case LOADED:
        return (
          <>
            <Route exact path={path.sharedProject} component={SharedView} />
            <Route exact path={path.sharedPiece} component={ViewSharedPlayer} />
          </>
        );
      case LOADING:
        return <p>Loading...</p>;
      case ERROR:
      default:
        return <p>Something went wrong. Try again later.</p>;
    }
  }

  render() {
    return (
      <View>
        <Header>
          <Logo to="/" />
          <Title />
        </Header>
        <Content>
          <SharedViewContext.Provider value={{ isQA: this.state.isQA }}>
            {this.getChildren()}
          </SharedViewContext.Provider>
        </Content>
      </View>
    );
  }
}

ViewSharedProject.propTypes = {
  match: object.isRequired,
  initFromServer: func.isRequired,
  requestAssets: func.isRequired,
  setCurrentProject: func.isRequired,
};

const container = connect(null, {
  initFromServer,
  setCurrentProject,
  requestAssets,
});

export default withRouter(container(ViewSharedProject));
