import React from 'react';
import { createUseStyles } from 'react-jss';
import { func, node } from 'prop-types';
import { colors, label } from '../ui';

const getBackgroundColor = ({ isSelected = false }) => {
  return isSelected ? colors.black2 : colors.black4;
};

const useStyles = createUseStyles({
  header: {
    ...label,
    alignItems: 'center',
    background: getBackgroundColor,
    cursor: 'pointer',
    display: 'flex',
    height: 44,
    letterSpacing: '0.35px',
    userSelect: 'none',
    padding: [0, 16],
    border: ({ isOver = false }) =>
      isOver ? `1px solid ${colors.yellow}` : null,
  },
});

const CollectionHeader = ({ children, onClick, ...props }) => {
  const classes = useStyles(props);
  return (
    <div
      className={classes.header}
      onClick={onClick}
      role="button"
      tabIndex="-1"
    >
      {children}
    </div>
  );
};

CollectionHeader.propTypes = {
  children: node.isRequired,
  onClick: func.isRequired,
};

export default CollectionHeader;
