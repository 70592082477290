import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { ShapeArrow } from './ShapeArrow';
import colors from '../colors';

const useStyles = createUseStyles({
  popupPercentage: {
    width: 48,
    height: 36,
    position: 'absolute',
    top: -34,
    right: -30,
  },
  wrapperDisplay: {
    width: 44,
    height: 24,
    background: colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  display: {
    zIndex: 100,
    fontWeight: 400,
    fontSize: 12,
    color: colors.gray1,
  },
  wrapperSvg: {
    width: 12,
    height: 16,
    position: 'absolute',
    top: 14,
    left: 16,
  },
});

const PopupPercentage = ({ isActive, value }) => {
  const classes = useStyles();
  if (!isActive) {
    return null;
  }
  return (
    <div className={classes.popupPercentage}>
      <div className={classes.wrapperDisplay}>
        <div className={classes.display}>{value}%</div>
      </div>
      <div className={classes.wrapperSvg}>
        <ShapeArrow />
      </div>
    </div>
  );
};

PopupPercentage.propTypes = {
  value: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default PopupPercentage;
