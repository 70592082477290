import React from 'react';
import ReactDOM from 'react-dom';
import { JssProvider } from 'react-jss';
import configureStore from './store';
import App from './App';

const store = configureStore();

ReactDOM.render(
  <JssProvider classNamePrefix="app-">
    <App store={store} />
  </JssProvider>,
  document.getElementById('app'),
);
