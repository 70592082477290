import React from 'react';
import { node } from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { url } from '../App';

const LinkToSharedProject = ({ children }) => {
  const { shareID } = useParams();

  return <Link to={url.sharedProject({ shareID })}>{children}</Link>;
};

LinkToSharedProject.propTypes = {
  children: node.isRequired,
};

export default LinkToSharedProject;
