import React from 'react';
import { CheckboxWithLabel } from '../ui';
import EditorContext, { BORDER } from '../EditorContext';

const ToggleBorder = () => (
  <EditorContext.Consumer>
    {({ isVisibleTool, toggleToolVisibility }) => (
      <CheckboxWithLabel
        small
        label="Border"
        checked={isVisibleTool(BORDER)}
        onChange={() => toggleToolVisibility(BORDER)}
      />
    )}
  </EditorContext.Consumer>
);

export default ToggleBorder;
