import React from 'react';
import { node } from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  view: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    height: '100%',
  },
});

const View = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.view}>{children}</div>;
};

View.propTypes = {
  children: node.isRequired,
};

export default View;
