import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  issuesContainer: {
    paddingTop: '16px',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#282828',
  },
});

const Container = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.issuesContainer}>{children}</div>;
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
