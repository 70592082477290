import React from 'react';
import { Helmet } from 'react-helmet';

export const ARTISAN_DEFAULT_TITLE = 'Artisan';

/* eslint-disable no-undef */
export const getTitle = () => {
  if (ARTISAN_TITLE && ARTISAN_TITLE.length !== 0) {
    return ARTISAN_TITLE;
  }

  return ARTISAN_DEFAULT_TITLE;
};

const Head = () => (
  <Helmet>
    <title>{getTitle()}</title>
  </Helmet>
);

export default Head;
