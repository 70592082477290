import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSelectedObjectsIDs } from '../editor';
import { getHasClipPathSelected } from '../selectors';

const Group = ({ visible, children }) => {
  if (!visible) {
    return null;
  }

  return children;
};

Group.propTypes = {
  visible: PropTypes.bool.isRequired,
};

const reduxConnector = connect((state, { minSelectedObjects }) => {
  let visible = false;
  if (
    getSelectedObjectsIDs(state).size >= minSelectedObjects &&
    !getHasClipPathSelected(state)
  ) {
    visible = true;
  }

  return { visible };
});

export default reduxConnector(Group);
