import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  identity,
  compose,
  inverse,
  rotateDEG,
  applyToPoints,
  applyToPoint,
} from 'transformation-matrix';
import { createUseStyles } from 'react-jss';
import { getParentMatrix, getObjectMatrixByID } from '../selectors';
import {
  createClipPathObject as createClipPathObjectAction,
  getObjectByID,
} from '../project';
import {
  makeClipPathObject,
  makeInsetClipPath,
  DIV,
  IMG,
  hasObjectClipPath,
} from '../models';
import { getCurrentPieceID } from '../stage';
import {
  StageMode,
  getStageMode,
  addObjectToSelection,
  setStageMode as setStageModeAction,
  getSelectedObjectsIDs,
} from '../editor';
import { extractRotationFromMatrix } from '../geometry';
import { useDrag } from '../hooks';
import { colors } from '../ui';

const BORDER_WIDTH = 1;

const useStyles = createUseStyles({
  insetClipPath: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    cursor: 'crosshair',
  },
  previewStyle: {
    position: 'absolute',
    boxSizing: 'border-box',
    background: 'rgba(0, 174, 239, 0.1)',
    borderWidth: BORDER_WIDTH,
    borderStyle: 'solid',
    borderColor: colors.primary,
    borderRadius: 1,
  },
});

const createPreviewStyle = ({ x, y, width, height, rotation }) => ({
  width,
  height,
  transform: `translate(${x}px, ${y}px) rotate(${rotation}deg)`,
});

/* eslint-disable react/prop-types */
const InsetClipPath = ({
  enabled,
  setStageMode,
  stageMatrix,
  pieceMatrix,
  parentMatrix,
  objectMatrix,
  pieceID,
  targetObjectID,
  createClipPathObject,
}) => {
  const classes = useStyles();
  const onUp = useCallback(
    (e, initialPosition, currentPosition) => {
      if (!targetObjectID) {
        return;
      }

      const [initial, last] = applyToPoints(
        inverse(compose(pieceMatrix, parentMatrix, objectMatrix)),
        [initialPosition, currentPosition],
      );

      const left = Math.min(initial.x, last.x);
      const top = Math.min(initial.y, last.y);
      const width = Math.max(initial.x, last.x) - left;
      const height = Math.max(initial.y, last.y) - top;

      const object = makeClipPathObject({
        pieceID,
        targetObjectID,
        clipPath: makeInsetClipPath({
          left,
          top,
          width,
          height,
        }),
      });

      createClipPathObject(object);
      setStageMode(StageMode.select);
    },
    [
      setStageMode,
      createClipPathObject,
      targetObjectID,
      pieceID,
      pieceMatrix,
      parentMatrix,
      objectMatrix,
    ],
  );
  const {
    isDragging,
    pointerHandlers,
    initialPosition,
    currentPosition,
  } = useDrag(stageMatrix, { onUp });
  const [previewBox, setPreviewBox] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    rotation: 0,
  });

  useEffect(() => {
    const rotation = extractRotationFromMatrix(
      compose(parentMatrix, objectMatrix),
    );
    const rotationMatrix = compose(rotateDEG(rotation));

    const inversedMatrix = inverse(rotationMatrix);

    const initial = applyToPoint(compose(inversedMatrix), initialPosition);
    const last = applyToPoint(compose(inversedMatrix), currentPosition);

    const x = Math.min(initial.x, last.x) - BORDER_WIDTH;
    const y = Math.min(initial.y, last.y) - BORDER_WIDTH;

    const width = Math.max(initial.x, last.x) - x + BORDER_WIDTH;
    const height = Math.max(initial.y, last.y) - y + BORDER_WIDTH;

    const midPoint = applyToPoint(rotateDEG(rotation), {
      x: x + width / 2,
      y: y + height / 2,
    });

    setPreviewBox({
      x: midPoint.x - width / 2,
      y: midPoint.y - height / 2,
      width,
      height,
      rotation,
    });
  }, [
    initialPosition,
    currentPosition,
    parentMatrix,
    objectMatrix,
    setPreviewBox,
  ]);

  return (
    enabled && (
      <div role="button" className={classes.insetClipPath} {...pointerHandlers}>
        {isDragging && (
          <div
            className={classes.previewStyle}
            style={createPreviewStyle(previewBox)}
          />
        )}
      </div>
    )
  );
};

InsetClipPath.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

const SUPPORTED_TYPES = [DIV, IMG];
const isValidTarget = object =>
  SUPPORTED_TYPES.includes(object.type) && !hasObjectClipPath(object);

const container = connect(
  state => {
    const selectedObjectIDs = getSelectedObjectsIDs(state);
    let objectMatrix = identity();
    let targetObjectID = null;

    if (selectedObjectIDs.size === 1) {
      targetObjectID = selectedObjectIDs.get(0);
      const targetObject = getObjectByID(state, targetObjectID);

      if (!isValidTarget(targetObject)) {
        targetObjectID = null;
      }
    }

    if (targetObjectID) {
      objectMatrix = getObjectMatrixByID(state, targetObjectID);
    }

    return {
      enabled:
        getStageMode(state) === StageMode.insetClipPath &&
        targetObjectID !== null,
      pieceID: getCurrentPieceID(state),
      parentMatrix: getParentMatrix(state),
      objectMatrix,
      targetObjectID,
    };
  },
  {
    createClipPathObject: createClipPathObjectAction,
    setStageMode: setStageModeAction,
    addObjectToSelection,
  },
);

export default container(InsetClipPath);
