import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../ui';
import PlaybackControls from '../PlaybackControls';
import Resizable from '../resizable';
import HeaderObjects from './HeaderObjects';
import ListObjects from './ListObjects';
import ListTimelines from './ListTimelines';
import TimeMarks from './TimeMarks';
import TimelineLeft from './TimelineLeft';
import TimelineRight from './TimelineRight';
import Needle from './Needle';
import ResizableHandler from './ResizableHandler';
import ScrollContext from './ScrollContext';
import PopoverKeyframe from './PopoverKeyframe';
import {
  setFocusedArea,
  getFocusedArea,
  STAGE_FOCUSED,
  TIMELINE_FOCUSED,
} from '../editor';

const useStyles = createUseStyles({
  timeline: {
    background: colors.black4,
    display: 'flex',
    flexDirection: 'column',
    height: 270,
    '& div, & span, & button': {
      outline: 'none',
      '&:focus': {
        boxShadow: 'none',
      },
    },
    position: 'relative',
    minHeight: 48,
    '& *': {
      userSelect: 'none',
    },
  },
  content: {
    borderTop: `1px solid ${colors.black2}`,
    display: 'flex',
    flexGrow: 1,
    minHeight: 0,
  },
  left: {
    borderRight: `1px solid ${colors.black2}`,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    minHeight: 0,
    width: 264,
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 0,
    minWidth: 0,
    overflow: 'hidden',
    position: 'relative',
  },
});

const Timeline = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const focusedArea = useSelector(getFocusedArea);
  const handleFocusForStage = useCallback(() => {
    if (focusedArea === STAGE_FOCUSED) {
      return;
    }
    dispatch(setFocusedArea(STAGE_FOCUSED));
  }, [focusedArea]);
  const handleFocusForTimeline = useCallback(() => {
    if (focusedArea === TIMELINE_FOCUSED) {
      return;
    }
    dispatch(setFocusedArea(TIMELINE_FOCUSED));
  }, [focusedArea]);

  return (
    <Resizable initialTop={270}>
      {({ onEnd, onStart, onMove, top }) => (
        <div
          className={classes.timeline}
          style={{ height: top }}
          role="button"
          tabIndex="-1"
        >
          <ResizableHandler onStart={onStart} onMove={onMove} onEnd={onEnd} />
          <PlaybackControls />
          <div className={classes.content}>
            <ScrollContext>
              <ScrollContext.Consumer>
                {({ scrollLeft, scrollTop, setScrollTop, setScrollLeft }) => (
                  <>
                    <div className={classes.left} onFocus={handleFocusForStage}>
                      <HeaderObjects />
                      <TimelineLeft
                        onScroll={ev => setScrollTop(ev.target.scrollTop)}
                        scrollTop={scrollTop}
                      >
                        <ListObjects />
                      </TimelineLeft>
                    </div>
                    <div
                      className={classes.right}
                      onFocus={handleFocusForTimeline}
                    >
                      <Needle scrollPosition={scrollLeft} />
                      <TimeMarks scrollLeft={scrollLeft} />
                      <TimelineRight
                        onScroll={ev => {
                          setScrollTop(ev.target.scrollTop);
                          setScrollLeft(ev.target.scrollLeft);
                        }}
                        scrollLeft={scrollLeft}
                        scrollTop={scrollTop}
                      >
                        <PopoverKeyframe />
                        <ListTimelines />
                      </TimelineRight>
                    </div>
                  </>
                )}
              </ScrollContext.Consumer>
            </ScrollContext>
          </div>
        </div>
      )}
    </Resizable>
  );
};

export default Timeline;
