import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  increaseIcon: {
    width: 14,
    height: 12,
    display: 'block',
  },
});

const IncreaseIcon = () => {
  const classes = useStyles();
  return (
    <svg
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.increaseIcon}
    >
      <g filter="url(#slider_increase)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 1C6.76988 1 6.58333 1.18655 6.58333 1.41667V5.58333H2.41667C2.18655 5.58333 2 5.76988 2 6C2 6.23012 2.18655 6.41667 2.41667 6.41667H6.58333V10.5833C6.58333 10.8135 6.76988 11 7 11C7.23012 11 7.41667 10.8135 7.41667 10.5833V6.41667H11.5833C11.8135 6.41667 12 6.23012 12 6C12 5.76988 11.8135 5.58333 11.5833 5.58333H7.41667V1.41667C7.41667 1.18655 7.23012 1 7 1Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="slider_increase"
          x="0.5"
          y="0"
          width="13"
          height="12"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default IncreaseIcon;
