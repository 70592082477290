import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';

const useStyles = createUseStyles({
  title: {
    marginTop: 25,
    color: colors.black3,
  },
});

const MonthlyReport = ({ children }) => {
  const classes = useStyles();

  return <h1 className={classes.title}>{children}</h1>;
};

MonthlyReport.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MonthlyReport;
