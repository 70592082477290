import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { toolbarButton } from '../colors';

const useStyles = createUseStyles({
  base: {
    borderStyle: 'none',
    cursor: 'pointer',
    height: 16,
    width: 16,
    background: 'transparent',
    '& svg': {
      fill: 'white',
    },
  },
  toolbarButton: {
    extend: 'base',
    '&:active': {
      color: 'white',
      '& svg': {
        fill: toolbarButton.border.hover,
      },
    },
    '&:disabled': {
      opacity: 0.35,
      cursor: 'default',
    },
  },
  toolbarButtonActive: {
    extend: 'base',
    cursor: 'normal',
  },
});

const ToolbarButton = ({ isActive, icon, ...props }) => {
  const classes = useStyles();
  const cls = isActive ? classes.toolbarButtonActive : classes.toolbarButton;
  return (
    <button type="button" className={cls} {...props}>
      {icon}
    </button>
  );
};

ToolbarButton.defaultProps = {
  isActive: false,
};

ToolbarButton.propTypes = {
  isActive: PropTypes.bool,
  icon: PropTypes.node.isRequired,
};

export default ToolbarButton;
