import { urlFactory, identityDecoder } from './utils';
import request from '../http';
import createRequestDescription from './createRequestDescription';

export const url = urlFactory('qa');

export const requestIssueDecoder = ({ data = {} } = {}) => ({
  id: data.id,
  project_id: data.project_id,
  issue: data.issue,
  description: data.description,
  status: data.status,
  created_at: data.created_at,
});

export const validateStatus = status => status === 200 || status === 404;

export default {
  getAllIssueByProject: projectId =>
    createRequestDescription(
      {
        url: url(`/projects/${projectId}/issues`),
        method: 'GET',
        validateStatus,
      },
      identityDecoder,
    ),
  createIssue: (projectId, data) =>
    request(
      {
        url: url(`/projects/${projectId}/issues`),
        method: 'POST',
        data,
      },
      requestIssueDecoder,
    ),
  approveIssue: (projectId, issueId) =>
    createRequestDescription(
      {
        url: url(`/projects/${projectId}/issues/${issueId}/approve`),
        method: 'PUT',
      },
      requestIssueDecoder,
    ),
  reopenIssue: (projectId, issueId) =>
    createRequestDescription(
      {
        url: url(`/projects/${projectId}/issues/${issueId}/reopen`),
        method: 'PUT',
      },
      requestIssueDecoder,
    ),
  archiveIssue: (projectId, issueId) =>
    createRequestDescription(
      {
        url: url(`/projects/${projectId}/issues/${issueId}/archive`),
        method: 'PUT',
      },
      requestIssueDecoder,
    ),
  resolveIssue: (projectId, issueId) =>
    createRequestDescription(
      {
        url: url(`/projects/${projectId}/issues/${issueId}/resolve`),
        method: 'PUT',
      },
      requestIssueDecoder,
    ),
};
