import { ALL_PROPERTIES, roundProperty } from './properties';

export const isDefined = value => value !== undefined && value !== null;

export const roundObjectProperties = object =>
  ALL_PROPERTIES.reduce(
    (currentObject, prop) =>
      currentObject.update(prop, value => {
        if (!isDefined(value)) {
          return value;
        }

        return roundProperty(prop, value);
      }),
    object,
  );
