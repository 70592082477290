import React, { useState, useCallback } from 'react';
import { List } from 'immutable';
import { string, bool, object, shape } from 'prop-types';
import injectSheet from 'react-jss';
import { gql, useQuery } from '@apollo/client';
import { colors, Pagination, Select, ItemModel, Hideable } from '../ui';
import Content from './Content';
import CardProject from './CardProject';
import WelcomePage from './WelcomePage';
import LoadingPage from './LoadingPage';
import ErrorPage from './ErrorPage';
import NoResults from './NoResults';
import ModalRenameProject from './ModalRenameProject';
import ModalDeleteProject from './ModalDeleteProject';
import ModalDuplicateProject from './ModalDuplicateProject';

export const ALL_PROJECTS_QUERY = gql`
  query QueryAllProjects($name: String, $page: Int, $orderBy: String) {
    allProjects(name: $name, page: $page, orderBy: $orderBy) {
      projects {
        id
        name
        status
        createdAt
        error
      }
      pageInfo {
        page
        hasNext
        hasPrevious
        totalItems
        endIndex
        startIndex
      }
    }
  }
`;

export const supportedOrderBy = List([
  ItemModel({ label: 'Name', value: 'name' }),
  ItemModel({ label: 'Creation date', value: 'created_at' }),
  ItemModel({ label: 'Last updated', value: 'updated_at' }),
]);

const ListProjects = ({ classes, query }) => {
  const [state, setState] = useState({
    page: 1,
    selectedOrder: supportedOrderBy.get(2),
  });

  const { loading, error, data, refetch } = useQuery(ALL_PROJECTS_QUERY, {
    variables: {
      name: query.name,
      page: state.page,
      orderBy: state.selectedOrder.value,
    },
    fetchPolicy: 'network-only',
  });

  const onChangeOrder = useCallback(
    selectedOrder => {
      setState(oldState => ({
        ...oldState,
        page: 1,
        selectedOrder,
      }));
    },
    [setState],
  );

  const fetchMore = useCallback(
    page => {
      setState(oldState => ({
        ...oldState,
        page,
      }));
    },
    [setState],
  );

  const { selectedOrder } = state;

  if (loading) return <LoadingPage />;

  if (error) return <ErrorPage refetch={refetch} />;

  const { name, searching } = query;

  const { allProjects } = data;

  const { projects, pageInfo } = allProjects;

  if (projects.length === 0 && searching) {
    return <NoResults term={name} />;
  }
  if (projects.length === 0 && !searching) {
    return <WelcomePage />;
  }

  return (
    <Content>
      <div className={classes.content}>
        <div className={classes.selectBox}>
          <div className={classes.select}>
            <Select
              placeholder="Ordered by"
              items={supportedOrderBy}
              onChange={onChangeOrder}
              value={selectedOrder}
            />
          </div>
        </div>
        <ul className={classes.list}>
          {projects.map(project => (
            <li className={classes.item} key={project.id}>
              <Hideable.Provider>
                <ModalDuplicateProject id={project.id} refetch={refetch} />
                <ModalRenameProject project={project} refetch={refetch} />
                <ModalDeleteProject id={project.id} refetch={refetch} />
                <CardProject {...project} />
              </Hideable.Provider>
            </li>
          ))}
        </ul>
      </div>
      <div className={classes.pagination}>
        <Pagination {...pageInfo} fetchMore={fetchMore} />
      </div>
    </Content>
  );
};

ListProjects.propTypes = {
  classes: object.isRequired,
  query: shape({
    name: string,
    searching: bool.isRequired,
  }).isRequired,
};

const style = injectSheet({
  content: {
    width: '100%',
  },
  selectBox: {
    width: '100%',
    paddingTop: 48,
    paddingBottom: 16,
    borderBottom: `1px solid ${colors.gray2}`,
  },
  select: {
    width: 214,
  },
  list: {
    margin: [32, 0],
    width: '100%',
  },
  item: {
    marginBottom: 16,
  },
  pagination: {
    width: '100%',
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'center',
    paddingBottom: 32,
  },
});

export default style(ListProjects);
