import { TRANSFORM_PROPERTIES, buildClipPathStyle } from './properties';
import { isDefined } from './utils';

export const sortKeyframes = keyframes =>
  keyframes.sort((a, b) => a.time - b.time);

export const getKeyframesSorted = object =>
  sortKeyframes(Object.values(object.keyframes));

export const baseAnimationStyle = { animationFillMode: 'forwards' };

export const ensureTransformScale = value => {
  if (value === null || value === undefined) {
    return 1;
  }

  return value;
};

export const getTransformString = values => {
  const transformations = [];
  if (isDefined(values.translateX) || isDefined(values.translateY)) {
    transformations.push(
      `translate(${values.translateX || 0}px, ${values.translateY || 0}px)`,
    );
  }

  if (isDefined(values.rotation)) {
    transformations.push(`rotate(${values.rotation}deg)`);
  }

  if (isDefined(values.scaleX) || isDefined(values.scaleY)) {
    const scaleX = ensureTransformScale(values.scaleX);
    const scaleY = ensureTransformScale(values.scaleY);

    transformations.push(`scale(${scaleX}, ${scaleY})`);
  }

  if (!transformations.length) {
    return null;
  }

  return transformations.join(' ');
};

export const getBasePropertiesForTransform = object =>
  TRANSFORM_PROPERTIES.reduce((transform, [prop, defaultValue]) => {
    const value = object[prop];

    if (!isDefined(value) || value === defaultValue) {
      return transform;
    }

    return {
      ...transform,
      [prop]: value,
    };
  }, {});

export const getTransformOriginString = values => {
  if (!isDefined(values.originX) && !isDefined(values.originY)) {
    return null;
  }

  return `${values.originX}px ${values.originY}px`;
};

export const removeEmpty = values =>
  Object.keys(values).reduce((curr, prop) => {
    if (!isDefined(values[prop])) {
      const newObject = { ...curr };
      delete newObject[prop];
      return newObject;
    }

    return { ...curr, [prop]: values[prop] };
  }, values);

export const buildProperties = values =>
  removeEmpty({
    left: values.left,
    top: values.top,
    width: values.width,
    height: values.height,
    opacity: values.opacity,
    transform: getTransformString(values),
    transformOrigin: getTransformOriginString(values),
    ...buildClipPathStyle(values.clipPath),
  });

export const mergeProperties = (defaultValues, keyframeValues) =>
  Object.entries(defaultValues).reduce((keyframe, [prop, value]) => {
    if (isDefined(keyframe[prop])) {
      return keyframe;
    }
    return { ...keyframe, [prop]: value };
  }, keyframeValues);

const getPercentValueOfDuration = (time, duration) => (time / duration) * 100;

export const buildAnimationTimingFunction = timingFunction =>
  `${timingFunction.name}(${timingFunction.values})`;

export const getKeyframes = (keyframes, duration, delay, baseTransform = {}) =>
  keyframes.reduce((animation, keyframe) => {
    const percent = getPercentValueOfDuration(keyframe.time - delay, duration);
    const value = {
      ...buildProperties(mergeProperties(baseTransform, keyframe.values)),
      animationTimingFunction: buildAnimationTimingFunction(
        keyframe.timingFunction,
      ),
    };

    return {
      ...animation,
      [`${percent}%`]: value,
    };
  }, {});

const getAnimationDuration = keyframes => {
  const first = keyframes[0];
  const last = keyframes[keyframes.length - 1];

  return {
    duration: last.time - first.time,
    delay: first.time,
  };
};

const isValidAnimation = keyframes => keyframes.length >= 2;

const getObjectAnimationStyle = (...objects) => {
  const animationStyle = objects.reduce(
    (currentStyle, object) => {
      if (!object) {
        return currentStyle;
      }

      const keyframes = getKeyframesSorted(object);

      if (!isValidAnimation(keyframes)) {
        return currentStyle;
      }

      const { duration, delay } = getAnimationDuration(keyframes);

      const baseTransform = getBasePropertiesForTransform(object);
      return {
        ...currentStyle,
        animationDelay: [...currentStyle.animationDelay, `${delay}ms`],
        animationDuration: [...currentStyle.animationDuration, `${duration}ms`],
        animationName: [
          ...currentStyle.animationName,
          getKeyframes(keyframes, duration, delay, baseTransform),
        ],
      };
    },
    {
      ...baseAnimationStyle,
      animationDelay: [],
      animationDuration: [],
      animationName: [],
    },
  );

  if (!animationStyle.animationName.length) {
    return null;
  }

  return animationStyle;
};

export default getObjectAnimationStyle;
