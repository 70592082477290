import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ActionCreators as undoRedo } from 'redux-undo';
import {
  ButtonPrimary,
  ButtonTertiary,
  Modal,
  ModalContent,
  ModalFooter,
} from '../ui';
import { deleteAsset } from './actions';
import { getAssetByPath } from '../project';
import { createHideableComponent } from '../ui/hideable';

export const ModalContext = createHideableComponent();

const ModalDelete = ({ isVisible, hide, filename, confirmDeletion }) => (
  <Modal isVisible={isVisible}>
    <ModalContent>
      Are you sure you want to delete
      <em>{filename}</em>?
    </ModalContent>
    <ModalFooter>
      <ButtonTertiary onClick={hide} label="Cancel" />
      <ButtonPrimary onClick={confirmDeletion} label="OK" />
    </ModalFooter>
  </Modal>
);

ModalDelete.propTypes = {
  filename: PropTypes.string.isRequired,
  confirmDeletion: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
};

const container = connect(
  (state, { showArgs }) => ({
    asset: getAssetByPath(showArgs[0], state),
  }),
  {
    clearHistory: undoRedo.clearHistory,
    deleteAsset,
  },
  ({ asset }, actions, ownProps) => ({
    ...ownProps,
    filename: asset.filename,
    confirmDeletion() {
      actions.clearHistory();
      actions.deleteAsset(asset.path);
      ownProps.hide();
    },
  }),
);

export default ModalContext.hoc(container(ModalDelete));
