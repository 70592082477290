import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SidebarButton } from '../ui';
import { getStageMode, setStageMode } from '../editor';

const SidebarTool = ({ setActive, isActive, icon }) => (
  <SidebarButton onClick={setActive} isActive={isActive} icon={icon} />
);

SidebarTool.propTypes = {
  isActive: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
  icon: PropTypes.func.isRequired,
};

const container = connect(
  (state, { tool }) => ({
    isActive: getStageMode(state) === tool,
  }),
  { setStageMode },
  (stateProps, actions, ownProps) => ({
    ...stateProps,
    ...ownProps,
    setActive: () => actions.setStageMode(ownProps.tool),
  }),
);

export default container(SidebarTool);
