import React from 'react';
import DecreaseIcon from './DecreaseIcon';
import Button from './Button';

const ButtonDecrease = props => (
  <Button {...props}>
    <DecreaseIcon />
  </Button>
);

export default ButtonDecrease;
