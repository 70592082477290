/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import Context, { Component, defaultState } from './Context';
import { applyMiddleware, showEvent, hideEvent } from './events';

export class Provider extends React.Component {
  /* eslint-disable react/no-unused-state */
  constructor(props) {
    super(props);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.register = this.register.bind(this);
    this.unregister = this.unregister.bind(this);

    if (props.recordEvents === true) {
      this.events = [];
      this.show = applyMiddleware(this.events, showEvent, this.show);
      this.hide = applyMiddleware(this.events, hideEvent, this.hide);
    }

    this.state = {
      ...defaultState,
      components: Object.entries(props.components).reduce(
        (currentMap, [id, value]) => {
          return currentMap.set(id, Component(value));
        },
        defaultState.components,
      ),
      register: this.register,
      unregister: this.unregister,
      show: this.show,
      hide: this.hide,
    };
  }

  show(id, ...args) {
    this.setState(state => ({
      ...state,
      components: state.components
        .setIn([id, 'isVisible'], true)
        .setIn([id, 'showArgs'], args),
    }));
  }

  hide(id, ...args) {
    this.setState(state => ({
      ...state,
      components: state.components
        .setIn([id, 'isVisible'], false)
        .setIn([id, 'hideArgs'], args),
    }));
  }

  register(id, { isVisible = false, showArgs = [], hideArgs = [] } = {}) {
    if (this.state.components.has(id)) {
      return;
    }

    this.setState(state => ({
      ...state,
      components: state.components.set(
        id,
        Component({
          isVisible,
          showArgs,
          hideArgs,
        }),
      ),
    }));
  }

  unregister(id) {
    this.setState(state => ({
      ...state,
      components: state.components.delete(id),
    }));
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

Provider.defaultProps = {
  recordEvents: false,
  components: {},
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
  recordEvents: PropTypes.bool,
  components: PropTypes.object,
};

export default Provider;
