import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProjectID } from '../project';
import { qa } from '../api';
import { useRequest } from '../http';

const Issues = ({ projectID, children }) => {
  const { data, loading, request } = useRequest(
    qa.getAllIssueByProject(projectID),
    {},
  );

  if (loading || !data) {
    return null;
  }

  return children(data, request);
};

Issues.propTypes = {
  projectID: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};

const container = connect(state => ({
  projectID: getCurrentProjectID(state),
}));

export default container(Issues);
