import * as types from './types';
import { NOTIFICATION_TYPES } from './models';

const notify = type => message => ({
  type: types.SHOW_NOTIFICATION,
  payload: {
    type,
    message,
  },
});

export const showNotification = notify(NOTIFICATION_TYPES.INFO);

export const showErrorNotification = notify(NOTIFICATION_TYPES.ERROR);

export const showSuccessNotification = notify(NOTIFICATION_TYPES.SUCCESS);

export const closeNotification = () => ({
  type: types.CLOSE_NOTIFICATION,
});
