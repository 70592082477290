import React from 'react';
import { createUseStyles } from 'react-jss';
import { colors } from '../../ui';

const useStyles = createUseStyles({
  spacer: {
    width: 1,
    height: '100%',
    margin: [0, 8],
    backgroundColor: ({ isActive }) =>
      isActive ? colors.white : colors.black1,
  },
});

const Spacer = ({ ...props }) => {
  const classes = useStyles(props);
  return <div className={classes.spacer} />;
};

export default Spacer;
