export const path = {};
export const url = {};

path.projectDetail = '/projects/:projectID';
url.projectDetail = ({ projectID }) => `/projects/${projectID}`;

path.home = '/';
url.home = () => '/';

path.login = '/login';
url.login = () => '/login';

path.projectList = '/projects';
url.projectList = () => '/projects';

path.pieceList = '/projects/:projectID/pieces';
url.pieceList = ({ projectID }) => `${url.projectDetail({ projectID })}/pieces`;

path.issueList = '/projects/:projectID/issues';
url.issueList = ({ projectID }) => `${url.projectDetail({ projectID })}/issues`;

path.pieceDetail = '/projects/:projectID/pieces/:pieceID';
url.pieceDetail = ({ projectID, pieceID }) =>
  `${url.projectDetail({ projectID })}/pieces/${pieceID}`;

path.sharedProject = '/shared/:shareID';
url.sharedProject = ({ shareID }) => `/shared/${shareID}`;

path.sharedPiece = '/shared/:shareID/pieces/:pieceID';
url.sharedPiece = ({ shareID, pieceID }) =>
  `/shared/${shareID}/pieces/${pieceID}`;

path.reports = '/reports';
url.reports = () => '/reports';
