import React, { useCallback } from 'react';
import { List } from 'immutable';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { Select, ItemModel } from '../../ui';

export const FONT_FAMILY_OPTIONS = List([
  ItemModel({ label: 'sans-serif', value: 'sans-serif' }),
  ItemModel({ label: 'serif', value: 'serif' }),
]);

export const searchFontFamily = fontFamily =>
  FONT_FAMILY_OPTIONS.find(
    fontOption => fontOption.get('value') === fontFamily,
  );

const useStyles = createUseStyles({
  fontFamily: {
    boxSizing: 'border-box',
    display: 'flex',
    width: 240,
  },
});
const FontFamily = ({ fontFamily, disabled, fontFamilyChange }) => {
  const onChange = useCallback(
    newValue => {
      fontFamilyChange(newValue.value);
    },
    [fontFamily],
  );
  const classes = useStyles();
  return (
    <div className={classes.fontFamily}>
      <Select
        placeholder="Font Family"
        items={FONT_FAMILY_OPTIONS}
        value={searchFontFamily(fontFamily)}
        onChange={onChange}
        rounded={false}
        disabled={disabled}
      />
    </div>
  );
};

FontFamily.propTypes = {
  fontFamily: PropTypes.string,
  disabled: PropTypes.bool,
  fontFamilyChange: PropTypes.func,
};

FontFamily.defaultProps = {
  fontFamily: 'sans-serif',
  disabled: true,
  fontFamilyChange: () => {},
};

export default FontFamily;
