import { connect } from 'react-redux';
import { CLIPPATH } from '../models';
import {
  setObjectsProperties,
  incrementObjectsProperties,
  scaleObjectsProperties,
  updateObjectsOption,
  getAssetByPath,
  setStaticObjectsProperties,
} from '../project';
import { getIsPlaying } from '../playback';
import { getSelectedObjects } from '../editor';
import {
  isSelectionEmpty,
  getBaseObject,
  getProperty,
  extractTypes,
  getFullSizeOption,
} from './utils';
import * as Tools from './Tools';
import { createScale } from '../math';

export const returnNullIfDisabled = (disabled, next) => {
  if (disabled) {
    return null;
  }

  return next();
};

export const Asset = connect(state => {
  const objects = getSelectedObjects(state);
  const object = getBaseObject(objects);

  let { assetPath } = object;
  const disabled = getIsPlaying(state);

  if (disabled) {
    assetPath = null;
  }

  return {
    asset: getAssetByPath(assetPath, state),
    object,
    disabled,
  };
})(Tools.Asset);

const oppositeProperties = {
  scaleX: 'scaleY',
  scaleY: 'scaleX',
  width: 'height',
  height: 'width',
};

export const Dimensions = connect(
  state => {
    const objects = getSelectedObjects(state);
    const object = getBaseObject(objects);
    const selectedTypes = extractTypes(objects);
    const hasClipPathSelected = selectedTypes.has(CLIPPATH);
    const disabled =
      isSelectionEmpty(objects) ||
      getIsPlaying(state) ||
      (hasClipPathSelected && objects.size > 1);

    const width = returnNullIfDisabled(disabled, () => object.width);
    const height = returnNullIfDisabled(disabled, () => object.height);

    return {
      objects,
      width,
      height,
      disabled,
    };
  },
  {
    scaleObjectsProperties,
  },
  (state, actions, ownProps) => ({
    ...state,
    ...ownProps,

    onChange(property, keepAspectRatio) {
      return value => {
        if (!state.objects.count()) {
          return;
        }

        const scale = createScale(value, state[property]);
        const oppositeProperty = oppositeProperties[property];
        const properties = {
          [property]: scale,
        };

        if (keepAspectRatio) {
          properties[oppositeProperty] = scale;
        }

        actions.scaleObjectsProperties({
          ids: state.objects.map(obj => obj.id),
          properties,
        });
      };
    },
  }),
)(Tools.Dimensions);

export const FullSizeOption = connect(
  state => {
    const objects = getSelectedObjects(state);
    const object = getBaseObject(objects);

    const selectedTypes = extractTypes(objects);
    const hasClipPathSelected = selectedTypes.has(CLIPPATH);
    const disabled =
      isSelectionEmpty(objects) ||
      getIsPlaying(state) ||
      (hasClipPathSelected && objects.size > 1);

    const fullSize = returnNullIfDisabled(disabled, () =>
      getFullSizeOption(object, objects),
    );

    return {
      objects,
      fullSize,
      disabled,
    };
  },
  {
    updateObjectsOption,
  },
  (state, actions, ownProps) => ({
    ...state,
    ...ownProps,

    onChange(value) {
      actions.updateObjectsOption({
        ids: state.objects.map(obj => obj.id),
        option: 'fullSize',
        value,
      });
    },
  }),
)(Tools.FullSizeOption);

export const Opacity = connect(
  state => {
    const objects = getSelectedObjects(state);
    const object = getBaseObject(objects);
    const selectedTypes = extractTypes(objects);
    const hasClipPathSelected = selectedTypes.has(CLIPPATH);
    const disabled =
      isSelectionEmpty(objects) ||
      getIsPlaying(state) ||
      (hasClipPathSelected && objects.size > 1);

    const opacity = returnNullIfDisabled(disabled, () =>
      getProperty('opacity', object, objects),
    );

    return {
      objects,
      opacity,
      disabled,
    };
  },
  {
    setObjectsProperties,
  },
  (state, actions, ownProps) => ({
    ...state,
    ...ownProps,

    opacityChange(value) {
      if (state.objects.isEmpty()) {
        return;
      }

      actions.setObjectsProperties({
        ids: state.objects.map(obj => obj.id),
        properties: {
          opacity: value / 100,
        },
      });
    },
  }),
)(Tools.Opacity);

export const Origin = connect(
  state => {
    const objects = getSelectedObjects(state);
    const object = getBaseObject(objects);
    const selectedTypes = extractTypes(objects);
    const hasClipPathSelected = selectedTypes.has(CLIPPATH);
    const disabled =
      isSelectionEmpty(objects) ||
      getIsPlaying(state) ||
      (hasClipPathSelected && objects.size > 1);

    const originX = returnNullIfDisabled(disabled, () =>
      getProperty('originX', object, objects),
    );
    const originY = returnNullIfDisabled(disabled, () =>
      getProperty('originY', object, objects),
    );

    return {
      objects,
      originX,
      originY,
      disabled,
    };
  },
  {
    setObjectsProperties,
  },
  (state, actions, ownProps) => ({
    ...state,
    ...ownProps,

    originXChange(value) {
      if (state.objects.isEmpty()) {
        return;
      }

      actions.setObjectsProperties({
        ids: state.objects.map(obj => obj.id),
        properties: {
          originX: value,
        },
      });
    },

    originYChange(value) {
      if (state.objects.isEmpty()) {
        return;
      }

      actions.setObjectsProperties({
        ids: state.objects.map(obj => obj.id),
        properties: {
          originY: value,
        },
      });
    },
  }),
)(Tools.Origin);

export const Position = connect(
  state => {
    const objects = getSelectedObjects(state);
    const object = getBaseObject(objects);
    const selectedTypes = extractTypes(objects);
    const hasClipPathSelected = selectedTypes.has(CLIPPATH);
    const disabled =
      isSelectionEmpty(objects) ||
      getIsPlaying(state) ||
      (hasClipPathSelected && objects.size > 1);

    const left = returnNullIfDisabled(disabled, () => object.left);
    const top = returnNullIfDisabled(disabled, () => object.top);

    return {
      objects,
      left,
      top,
      disabled,
    };
  },
  {
    incrementObjectsProperties,
  },
  (state, actions, ownProps) => ({
    ...state,
    ...ownProps,

    leftChange(value) {
      if (!state.objects.count()) {
        return;
      }
      actions.incrementObjectsProperties({
        ids: state.objects.map(obj => obj.id),
        properties: { left: value - state.left },
      });
    },

    topChange(value) {
      if (!state.objects.count()) {
        return;
      }
      actions.incrementObjectsProperties({
        ids: state.objects.map(obj => obj.id),
        properties: { top: value - state.top },
      });
    },
  }),
)(Tools.Position);

export const Rotation = connect(
  state => {
    const objects = getSelectedObjects(state);
    const object = getBaseObject(objects);
    const selectedTypes = extractTypes(objects);
    const hasClipPathSelected = selectedTypes.has(CLIPPATH);
    const disabled =
      isSelectionEmpty(objects) ||
      getIsPlaying(state) ||
      (hasClipPathSelected && objects.size > 1);

    const rotation = returnNullIfDisabled(disabled, () =>
      getProperty('rotation', object, objects),
    );

    return {
      objects,
      rotation,
      disabled,
    };
  },
  {
    setObjectsProperties,
  },
  (state, actions, ownProps) => ({
    ...state,
    ...ownProps,

    rotationChange(value) {
      if (state.objects.isEmpty()) {
        return;
      }

      actions.setObjectsProperties({
        ids: state.objects.map(obj => obj.id),
        properties: {
          rotation: value,
        },
      });
    },
  }),
)(Tools.Rotation);

export const Scale = connect(
  state => {
    const objects = getSelectedObjects(state);
    const object = getBaseObject(objects);
    const selectedTypes = extractTypes(objects);
    const hasClipPathSelected = selectedTypes.has(CLIPPATH);
    const disabled =
      isSelectionEmpty(objects) ||
      getIsPlaying(state) ||
      (hasClipPathSelected && objects.size > 1);

    const scaleX = returnNullIfDisabled(disabled, () =>
      getProperty('scaleX', object, objects),
    );
    const scaleY = returnNullIfDisabled(disabled, () =>
      getProperty('scaleY', object, objects),
    );

    return {
      objects,
      scaleX,
      scaleY,
      disabled,
    };
  },
  {
    setObjectsProperties,
  },
  (state, actions, ownProps) => ({
    ...state,
    ...ownProps,

    onChange(property, keepAspectRatio) {
      return value => {
        if (!state.objects.count()) {
          return;
        }

        const scale = value;
        const properties = {
          [property]: scale,
        };

        if (keepAspectRatio) {
          properties[oppositeProperties[property]] = scale;
        }

        actions.setObjectsProperties({
          ids: state.objects.map(obj => obj.id),
          properties,
        });
      };
    },
  }),
)(Tools.Scale);

export const Translate = connect(
  state => {
    const objects = getSelectedObjects(state);
    const object = getBaseObject(objects);
    const selectedTypes = extractTypes(objects);
    const hasClipPathSelected = selectedTypes.has(CLIPPATH);
    const disabled =
      isSelectionEmpty(objects) ||
      getIsPlaying(state) ||
      (hasClipPathSelected && objects.size > 1);

    const translateX = returnNullIfDisabled(disabled, () =>
      getProperty('translateX', object, objects),
    );
    const translateY = returnNullIfDisabled(disabled, () =>
      getProperty('translateY', object, objects),
    );

    return {
      objects,
      translateX,
      translateY,
      disabled,
    };
  },
  {
    setObjectsProperties,
  },
  (state, actions, ownProps) => ({
    ...state,
    ...ownProps,

    translateXChange(value) {
      if (state.objects.isEmpty()) {
        return;
      }

      actions.setObjectsProperties({
        ids: state.objects.map(obj => obj.id),
        properties: {
          translateX: value,
        },
      });
    },

    translateYChange(value) {
      if (state.objects.isEmpty()) {
        return;
      }

      actions.setObjectsProperties({
        ids: state.objects.map(obj => obj.id),
        properties: {
          translateY: value,
        },
      });
    },
  }),
)(Tools.Translate);

export const TextContent = connect(
  state => {
    const objects = getSelectedObjects(state);
    const object = getBaseObject(objects);

    const selectedTypes = extractTypes(objects);
    const hasClipPathSelected = selectedTypes.has(CLIPPATH);
    const disabled =
      isSelectionEmpty(objects) ||
      getIsPlaying(state) ||
      objects.size > 1 ||
      (hasClipPathSelected && objects.size > 1);
    const textContent = returnNullIfDisabled(disabled, () =>
      getProperty('textContent', object, objects),
    );
    return {
      objects,
      textContent,
      disabled,
    };
  },
  {
    setStaticObjectsProperties,
  },
  (state, actions, ownProps) => ({
    ...state,
    ...ownProps,
    modifyTextContent(value) {
      if (state.objects.isEmpty()) {
        return;
      }
      actions.setStaticObjectsProperties({
        ids: state.objects.map(obj => obj.id),
        properties: {
          textContent: value,
        },
      });
    },
  }),
)(Tools.TextContent);

export const ClassContent = connect(
  state => {
    const objects = getSelectedObjects(state);
    const object = getBaseObject(objects);
    const selectedTypes = extractTypes(objects);
    const hasClipPathSelected = selectedTypes.has(CLIPPATH);
    const disabled =
      isSelectionEmpty(objects) ||
      getIsPlaying(state) ||
      objects.size > 1 ||
      (hasClipPathSelected && objects.size > 1);
    const classContent = returnNullIfDisabled(disabled, () =>
      getProperty('classContent', object, objects),
    );
    return {
      objects,
      classContent,
      disabled,
    };
  },
  {
    setStaticObjectsProperties,
  },
  (state, actions, ownProps) => ({
    ...state,
    ...ownProps,
    modifyClassContent(value) {
      if (state.objects.isEmpty()) {
        return;
      }
      actions.setStaticObjectsProperties({
        ids: state.objects.map(obj => obj.id),
        properties: {
          classContent: value,
        },
      });
    },
  }),
)(Tools.ClassContent);

export const FontSize = connect(
  state => {
    const objects = getSelectedObjects(state);
    const object = getBaseObject(objects);

    const selectedTypes = extractTypes(objects);
    const hasClipPathSelected = selectedTypes.has(CLIPPATH);
    const disabled =
      isSelectionEmpty(objects) ||
      getIsPlaying(state) ||
      objects.size > 1 ||
      (hasClipPathSelected && objects.size > 1);
    const fontSize = returnNullIfDisabled(disabled, () =>
      getProperty('fontSize', object, objects),
    );
    return {
      objects,
      fontSize,
      disabled,
    };
  },
  {
    setStaticObjectsProperties,
  },
  (state, actions, ownProps) => ({
    ...state,
    ...ownProps,
    fontSizeChange(value) {
      if (state.objects.isEmpty()) {
        return;
      }
      actions.setStaticObjectsProperties({
        ids: state.objects.map(obj => obj.id),
        properties: {
          fontSize: value,
        },
      });
    },
  }),
)(Tools.FontSize);

export const Color = connect(
  state => {
    const objects = getSelectedObjects(state);
    const object = getBaseObject(objects);
    const selectedTypes = extractTypes(objects);
    const hasClipPathSelected = selectedTypes.has(CLIPPATH);
    const disabled =
      isSelectionEmpty(objects) ||
      getIsPlaying(state) ||
      objects.size > 1 ||
      (hasClipPathSelected && objects.size > 1);
    const color = returnNullIfDisabled(disabled, () =>
      getProperty('color', object, objects),
    );
    return {
      objects,
      color,
      disabled,
    };
  },
  {
    setStaticObjectsProperties,
  },
  (state, actions, ownProps) => ({
    ...state,
    ...ownProps,
    fontColorChange(value) {
      if (state.objects.isEmpty()) {
        return;
      }
      actions.setStaticObjectsProperties({
        ids: state.objects.map(obj => obj.id),
        properties: {
          color: value,
        },
      });
    },
  }),
)(Tools.Color);

export const FontFamily = connect(
  state => {
    const objects = getSelectedObjects(state);
    const object = getBaseObject(objects);
    const selectedTypes = extractTypes(objects);
    const hasClipPathSelected = selectedTypes.has(CLIPPATH);
    const disabled =
      isSelectionEmpty(objects) ||
      getIsPlaying(state) ||
      objects.size > 1 ||
      (hasClipPathSelected && objects.size > 1);
    const fontFamily = returnNullIfDisabled(disabled, () =>
      getProperty('fontFamily', object, objects),
    );
    return {
      objects,
      fontFamily,
      disabled,
    };
  },
  {
    setStaticObjectsProperties,
  },
  (state, actions, ownProps) => ({
    ...state,
    ...ownProps,
    fontFamilyChange(value) {
      if (state.objects.isEmpty()) {
        return;
      }
      actions.setStaticObjectsProperties({
        ids: state.objects.map(obj => obj.id),
        properties: {
          fontFamily: value,
        },
      });
    },
  }),
)(Tools.FontFamily);
