import React from 'react';
import { object } from 'prop-types';
import injectSheet from 'react-jss';
import { withRouter, Redirect } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { TextField, ButtonPrimary } from '../ui';
import { accounts } from '../api';
import { setAuthStorage } from '../auth';
import FormError from './FormError';

const GENERIC_ERROR = {
  error: 'Something went wrong. Try again later',
};

class FormLogin extends React.Component {
  constructor() {
    super();
    this.state = { redirect: false };
    this.requestToken = this.requestToken.bind(this);
  }

  requestToken({ email, password }, actions) {
    accounts
      .requestToken(email, password)
      .then(({ ok, data }) => {
        if (!ok) {
          actions.setErrors(data || GENERIC_ERROR);
          actions.setSubmitting(false);
          return;
        }
        const { accessToken, refreshToken } = data;
        setAuthStorage(accessToken, refreshToken);
        actions.setSubmitting(false);
        this.setState({ redirect: true });
      })
      .catch(() => {
        actions.setErrors(GENERIC_ERROR);
        actions.setSubmitting(false);
      });
  }

  render() {
    if (this.state.redirect) {
      const { next } = this.props.location.state || { next: { pathname: '/' } };
      return <Redirect to={next} />;
    }

    const { classes } = this.props;
    return (
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={this.requestToken}
      >
        {({ errors, isSubmitting }) => (
          <Form>
            <Field name="email">
              {({ field }) => (
                <TextField
                  title="Email"
                  disabled={isSubmitting}
                  autoFocus
                  {...field}
                />
              )}
            </Field>
            <ErrorMessage name="email" component={FormError} />
            <Field name="password">
              {({ field }) => (
                <TextField
                  type="password"
                  title="Password"
                  disabled={isSubmitting}
                  {...field}
                />
              )}
            </Field>
            <ErrorMessage name="password" component={FormError} />
            {errors && errors.error && <FormError>{errors.error}</FormError>}
            <div className={classes.spacer} />
            <ButtonPrimary
              type="submit"
              label="Login"
              disabled={isSubmitting}
              extended
            />
          </Form>
        )}
      </Formik>
    );
  }
}

FormLogin.propTypes = {
  classes: object.isRequired,
  location: object.isRequired,
};

const style = injectSheet({
  spacer: {
    height: 40,
    width: '100%',
  },
});

export default withRouter(style(FormLogin));
