import { Record } from 'immutable';
import cuid from 'cuid';

export const VERTICAL = 'vertical';
export const HORIZONTAL = 'horizontal';

const baseGuide = {
  id: '',
  pieceID: '',
  orientation: VERTICAL,
  y: 0,
  x: 0,
  createdAt: null,
};

export const GuideModel = Record(baseGuide);

export const makeGuide = (guideProps = {}) => {
  const timestamp = new Date();

  return GuideModel({
    ...baseGuide,
    id: cuid(),
    createdAt: guideProps.createdAt || timestamp,
    ...guideProps,
  });
};

export const makeBaseGuide = (piece = {}) => ({
  ...baseGuide,
  ...piece,
});
