import React from 'react';
import { useSelector } from 'react-redux';
import { getClockDuration } from '../playback';

const Duration = () => {
  const duration = useSelector(getClockDuration);

  return <span>{Math.round(duration / 1000)}s</span>;
};

export default Duration;
