import React from 'react';
import SvgIcon from '../../icons/SvgIcon';

export const IconInner = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M12,23A11,11,0,1,1,23,12,11,11,0,0,1,12,23ZM12,3a9,9,0,1,0,9,9A9,9,0,0,0,12,3Z" />
  </SvgIcon>
);

export const IconOuter = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M12,0A12,12,0,0,0,0,12a2,2,0,1,0,4,0,8,8,0,0,1,8-8,2,2,0,0,0,0-4Z" />
  </SvgIcon>
);
