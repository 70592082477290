import React from 'react';

export const defaultProps = {
  isAMP: false,
  loaded: true,
  registerComponent: () => null,
  loadFinished: undefined,
};

const PieceContext = React.createContext(defaultProps);

export default PieceContext;
