import React from 'react';
import PropTypes from 'prop-types';
import { PopoverItem } from '../ui';
import { ModalContext } from './ModalDelete';

const ButtonOpenDeleteModal = ({ assetPath }) => (
  <ModalContext.Trigger>
    {({ show }) => (
      <PopoverItem onClick={() => show(assetPath)} label="Delete asset" />
    )}
  </ModalContext.Trigger>
);

ButtonOpenDeleteModal.propTypes = {
  assetPath: PropTypes.string.isRequired,
};

export default ButtonOpenDeleteModal;
