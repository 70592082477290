import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';
import { getLastModificationDate } from '../editor';
import { getCurrentProjectUpdatedAt, getCurrentProjectID } from '../project';

export const WARN_MESSAGE =
  "You'll lose your last modifications, please cancel and save project manually!";

const PreventDataLoss = () => {
  const projectID = useSelector(getCurrentProjectID);
  const lastModification = useSelector(getLastModificationDate);
  const projectUpdatedAt = useSelector(getCurrentProjectUpdatedAt);
  const lastModificationDate = new Date(lastModification);
  const updatedAtDate = new Date(projectUpdatedAt);
  const hasUnsavedModifications = lastModificationDate > updatedAtDate;

  useBeforeunload(event => {
    if (!lastModification) {
      return null;
    }

    if (hasUnsavedModifications) {
      event.preventDefault();
      return WARN_MESSAGE;
    }

    return null;
  });

  const message = useCallback(
    location => {
      if (location.pathname.startsWith(`/projects/${projectID}`)) {
        return true;
      }

      return WARN_MESSAGE;
    },
    [projectID],
  );

  if (!lastModification) {
    return null;
  }

  return <Prompt when={hasUnsavedModifications} message={message} />;
};

export default PreventDataLoss;
