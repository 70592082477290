import React from 'react';
import { createUseStyles } from 'react-jss';
import EditorContext, { TRANSFORMATION_BOX } from '../../EditorContext';
import { colors } from '../../ui';
import { DOT_SIZE, BORDER_SIZE, DOTS_POSITIONS } from './constants';

const useStyles = createUseStyles({
  dot: {
    width: DOT_SIZE,
    height: DOT_SIZE,
    border: `${BORDER_SIZE}px solid ${colors.primary}`,
    position: 'absolute',
    boxSizing: 'content-box',
    backgroundColor: 'white',
    touchAction: 'none',
    pointerEvents: 'none',
    zIndex: 120,
  },
});

const Dots = () => {
  const classes = useStyles();
  return (
    <EditorContext.Consumer>
      {({ isVisibleTool }) =>
        isVisibleTool(TRANSFORMATION_BOX) &&
        DOTS_POSITIONS.map(([style, key]) => (
          <div key={key} className={classes.dot} style={style} />
        ))
      }
    </EditorContext.Consumer>
  );
};

export default Dots;
