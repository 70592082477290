import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { showErrorNotification } from '../Notification';
import { getCurrentProjectID } from '../project';
import { TextArea, IconButtonPrimary } from '../ui';
import { SendIcon } from '../icons';
import { qa } from '../api';

const useStyles = createUseStyles({
  containerText: {
    display: 'flex',
    flexDirection: 'row',
  },
  formInput: {
    display: 'flex',
    flexDirection: 'column',
    height: 155,
    padding: [8, 8, 5, 16],
  },
  containerButton: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: [0, 16, 8, 0],
  },
});

const SubmitIssue = ({ projectID, refresh, onError }) => {
  const classes = useStyles();
  const validateSchema = ({ description }) => {
    const errors = {};
    if (!description) errors.description = 'Description is Required';
    if (description.trim() === '')
      errors.description = 'Description can`t be empty';
    return errors;
  };

  const handleSubmmit = ({ description }, { setSubmitting, resetForm }) => {
    qa.createIssue(projectID, { description })
      .then(response => {
        if (response.ok) {
          resetForm({ description: '' });
          refresh();
        } else {
          onError();
        }
      })
      .catch(onError);

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ description: '' }}
      validate={values => validateSchema(values)}
      onSubmit={handleSubmmit}
    >
      {({ isSubmitting, isValid, dirty, errors }) => (
        <Form>
          <div className={classes.formInput}>
            <div className={classes.containerText}>
              <Field name="description">
                {({ field }) => (
                  <TextArea
                    expanded={false}
                    {...field}
                    error={errors.description}
                    disabled={isSubmitting}
                  />
                )}
              </Field>
              <div className={classes.containerButton}>
                <IconButtonPrimary
                  type="submit"
                  icon={SendIcon}
                  disabled={isSubmitting || !isValid || !dirty}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SubmitIssue.propTypes = {
  projectID: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export const ERROR_MESSAGE = 'Something is wrong, please try again!';

const container = connect(
  state => ({
    projectID: getCurrentProjectID(state),
  }),
  dispatch => ({
    onError: () => dispatch(showErrorNotification(ERROR_MESSAGE)),
  }),
);

export default container(SubmitIssue);
