import React from 'react';
import { object, number, func, bool } from 'prop-types';
import { createUseStyles } from 'react-jss';
import MovableComponent from '../../MovableComponent';
import { colors } from '../../ui';
import { VERTICAL, HORIZONTAL } from '../../models';

const TOUCH_AREA = 3;
const TOUCH_AREA_MARGIN = -(TOUCH_AREA / 2);

export const getStagePositionY = ({ guide, top, scale }) =>
  guide.y * scale + top;

export const getStagePositionX = ({ guide, left, scale }) =>
  guide.x * scale + left;

const getGuideStyle = ({ guide, left, top, scale, disabled }) => ({
  left: Number(
    guide.orientation === VERTICAL && getStagePositionX({ guide, left, scale }),
  ),
  top: Number(
    guide.orientation === HORIZONTAL &&
      getStagePositionY({ guide, top, scale }),
  ),
  pointerEvents: disabled === true ? 'none' : 'all',
});

const getTouchAreaStyle = guide => {
  if (guide.orientation === HORIZONTAL) {
    return {
      cursor: 'row-resize',
      marginTop: TOUCH_AREA_MARGIN,
      width: '100%',
    };
  }

  return {
    cursor: 'col-resize',
    marginLeft: TOUCH_AREA_MARGIN,
    height: '100%',
  };
};

const useStyles = createUseStyles({
  touchArea: ({ guide }) => ({
    position: 'absolute',
    zIndex: 110,
    ...getTouchAreaStyle(guide),
  }),
  guide: ({ guide, isMoving }) => ({
    width: guide.orientation === HORIZONTAL ? '100%' : 1,
    height: guide.orientation === VERTICAL ? '100%' : 1,
    margin: 1,
    background: isMoving ? colors.primary : colors.info,
  }),
  indicator: props => ({
    backgroundColor: colors.black4,
    color: colors.white,
    fontSize: 12,
    position: 'absolute',
    rowWrap: 'white-space',
    top:
      props.guide.orientation === VERTICAL
        ? getStagePositionY(props) - 32
        : -32,
    left:
      props.guide.orientation === HORIZONTAL ? getStagePositionX(props) : 16,
  }),
});

const Guide = ({
  onMove,
  onUp,
  onDown,
  guide,
  isMoving,
  isVisible,
  disabled,
  left,
  top,
  scale,
}) => {
  const classes = useStyles({ guide, isMoving, left, scale, top });
  if (!isVisible) {
    return null;
  }

  return (
    <MovableComponent
      onMove={(_diff, { clientY: y, clientX: x }) => onMove(guide.id, { x, y })}
      onUp={event => onUp(guide.id, event)}
      onDown={event => onDown(guide.id, event)}
      disabled={disabled}
    >
      {movable => (
        <div
          className={classes.touchArea}
          {...movable}
          style={getGuideStyle({ guide, left, top, scale, disabled })}
        >
          <div className={classes.indicator}>
            {isMoving && (guide.orientation === VERTICAL ? guide.x : guide.y)}
          </div>
          <div className={classes.guide} />
        </div>
      )}
    </MovableComponent>
  );
};

Guide.propTypes = {
  guide: object.isRequired,
  left: number,
  top: number,
  scale: number,
  onMove: func.isRequired,
  onUp: func.isRequired,
  onDown: func.isRequired,
  isMoving: bool,
  isVisible: bool,
  disabled: bool,
};

Guide.defaultProps = {
  left: 0,
  top: 0,
  scale: 1,
  isMoving: false,
  isVisible: true,
  disabled: false,
};

export default Guide;
