// Stage
export const SET_STAGE_MODE = 'STAGE/SET_STAGE_MODE';

export const TOGGLE_ALIGN_TO_CONTAINER = 'TOPBAR/TOGGLE_ALIGN_TO_CONTAINER';

export const SET_CURRENT_OBJECT = 'STAGE/SET_CURRENT_OBJECT';

// Selected objects
export const SET_SELECTED_OBJECTS = 'SELECTION/OBJECTS/SET';

export const SELECT_OBJECT_ONLY = 'SELECTION/OBJECTS/SELECT_ONLY';

export const ADD_OBJECT_TO_SELECTION = 'SELECTION/OBJECTS/ADD_TO_SELECTION';

export const REMOVE_OBJECT_FROM_SELECTION =
  'SELECTION/OBJECTS/REMOVE_FROM_SELECTION';

export const CLEAR_OBJECTS_SELECTION = 'SELECTION/OBJECTS/CLEAR_SELECTION';

// Selected keyframes
export const SET_SELECTED_KEYFRAMES = 'SELECTION/KEYFRAMES/SET';

export const SELECT_KEYFRAMES_ONLY = 'SELECTION/KEYFRAMES/SELECT_ONLY';

export const ADD_KEYFRAMES_TO_SELECTION =
  'SELECTION/KEYFRAMES/ADD_TO_SELECTION';

export const REMOVE_KEYFRAMES_FROM_SELECTION =
  'SELECTION/KEYFRAMES/REMOVE_FROM_SELECTION';

export const CLEAR_OBJECTS_KEYFRAMES_SELECTION =
  'SELECTION/KEYFRAMES/CLEAR_OBJECTS_SELECTION';

export const CLEAR_KEYFRAMES_SELECTION = 'SELECTION/KEYFRAMES/CLEAR_SELECTION';

// Objects Visibility
export const TOGGLE_OBJECT_VISIBILITY = 'STAGE/OBJECTS/TOGGLE_VISIBILITY';

export const CLEAR_HIDDEN_OBJECTS = 'STAGE/OBJECTS/CLEAR_HIDDEN_OBJECTS';

// Objects Changes
export const TOGGLE_OBJECT_LOCK = 'STAGE/OBJECTS/TOGGLE_LOCK';

export const CLEAR_OBJECTS_LOCK = 'STAGE/OBJECTS/CLEAR_OBJECTS_LOCK';

// Object to Rename
export const SET_OBJECT_TO_RENAME = 'OBJECT_TO_RENAME/SET';

export const RESET_OBJECT_TO_RENAME = 'OBJECT_TO_RENAME/RESET';

// Last modification
export const UPDATE_LAST_MODIFICATION = 'EDITOR/UPDATE_LAST_MODIFICATION';

// Background tasks
export const START_BACKGROUND_TASKS = 'EDITOR/START_BACKGROUND_TASKS';

export const STOP_BACKGROUND_TASKS = 'EDITOR/STOP_BACKGROUND_TASKS';

// Focused Area
export const SET_FOCUSED_AREA = 'EDITOR/SET_FOCUSED_AREA';
