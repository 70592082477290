import React from 'react';
import { bool, string, instanceOf, func } from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { ArrowDownIcon, PlayIcon as ArrowRightIcon } from '../icons';
import { useHideableState } from '../ui/hideable';
import { getAssetsByCollectionPath, getCollectionByPath } from '../project';
import CollectionHeader from './CollectionHeader';
import CollectionName from './CollectionName';
import CollectionToggleIcon from './CollectionToggleIcon';

const Collection = ({ name, collectionAssets, children, forceIsVisible }) => {
  const { isVisible, toggleVisibility } = useHideableState({
    isVisible: forceIsVisible,
  });
  const icon = isVisible ? ArrowDownIcon : ArrowRightIcon;

  return (
    <>
      <CollectionHeader onClick={toggleVisibility}>
        <CollectionName name={name} />
        <CollectionToggleIcon icon={icon} />
      </CollectionHeader>
      {isVisible && children({ collectionAssets })}
    </>
  );
};

Collection.defaultProps = {
  forceIsVisible: undefined,
};

Collection.propTypes = {
  name: string.isRequired,
  collectionAssets: instanceOf(List).isRequired,
  children: func.isRequired,
  forceIsVisible: bool,
};

const container = connect((state, { path }) => ({
  name: getCollectionByPath(state, path).name,
  collectionAssets: getAssetsByCollectionPath(state, path),
}));

export default container(Collection);
