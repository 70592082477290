import React from 'react';
import { createUseStyles } from 'react-jss';
import { colors, jumbo, title3, textBold } from '../ui';
import CreateProjectButton from './CreateProjectButton';

const useStyles = createUseStyles({
  wrapper: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    maxWidth: 640,
  },
  title: {
    ...jumbo,
    color: colors.primary,
    marginBottom: 40,
  },
  subTitle: {
    ...title3,
    color: colors.black4,
    marginBottom: 32,
  },
  details: {
    ...textBold,
    color: colors.gray1,
    marginBottom: 49,
    textAlign: 'center',
  },
});

const WelcomePage = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <h1 className={classes.title}>Hello!</h1>
      <h2 className={classes.subTitle}>Your project list is empty.</h2>
      <p className={classes.details}>
        Let&apos;s populate it now! <br />
        You can click in the button below to create a project. Created projects
        will be displayed in this page.
      </p>
      <CreateProjectButton />
    </div>
  );
};

export default WelcomePage;
