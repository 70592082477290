import React from 'react';
import { object, string, bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { ButtonDefault } from '../ui';
import { getPieceByID } from '../project';
import { getCurrentPieceID } from '../stage';
import { getLastPieceID } from '../editor';
import { url as urlBuilder } from '../App';

const useStyles = createUseStyles({
  selectBox: {
    zIndex: 3,
    marginLeft: 24,
  },
});

const ButtonSwitchState = ({ label, url, disabled, history }) => {
  const classes = useStyles();
  return (
    <div className={classes.selectBox}>
      <ButtonDefault
        label={label}
        onClick={() => history.push(url)}
        disabled={disabled}
      />
    </div>
  );
};

ButtonSwitchState.propTypes = {
  label: string.isRequired,
  url: string.isRequired,
  disabled: bool.isRequired,
  history: object.isRequired,
};

const container = connect((state, { match }) => {
  const { projectID } = match.params;
  const currentPiece = getCurrentPieceID(state);
  const lastPieceID = getLastPieceID(state);
  const lastPiece = getPieceByID(state, lastPieceID);
  const label = currentPiece ? 'RM' : 'Editor';

  let url = urlBuilder.pieceList({ projectID });
  const disabled = !lastPiece;

  if (!currentPiece && lastPiece) {
    url = urlBuilder.pieceDetail({
      projectID,
      pieceID: lastPieceID,
    });
  }

  return {
    label,
    url,
    disabled,
  };
});

export default compose(withRouter, container)(ButtonSwitchState);
