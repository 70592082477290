import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getObjectKeyframes } from '../project';
import * as actions from '../editor';
import * as playbackActions from '../playback';
import TimelineProperty from './TimelineProperty';
import TimelinePropertyBoxes from './TimelinePropertyBoxes';
import TimelinePropertyDurationBar from './TimelinePropertyDurationBar';
import TimelinePropertyKeyframes from './TimelinePropertyKeyframes';
import Keyframe from './Keyframe';
import { PopoverTrigger } from '../ui';
import { px2AbsTime } from './utils';

export const calculateTimeByPosition = (evt, ref) => {
  const clientRect = ref.current.getBoundingClientRect();
  return px2AbsTime(evt.clientX - clientRect.left);
};

const KeyframesTimeline = ({
  objectID,
  keyframesMap,
  height,
  setCurrentTime,
  selectObjectOnly,
}) => {
  const timelineProperty = React.createRef();
  const keyframes = keyframesMap.toList().sortBy(kf => kf.time);

  return (
    <PopoverTrigger id="popover-keyframe">
      {openPopover => (
        <TimelineProperty
          onContextMenu={evt => {
            const timelineTime = calculateTimeByPosition(evt, timelineProperty);
            openPopover(evt, { objectID, timelineTime });
            setCurrentTime(timelineTime);
            selectObjectOnly(objectID);
          }}
          height={height}
          forwardRef={timelineProperty}
        >
          <TimelinePropertyBoxes />
          <TimelinePropertyKeyframes>
            {!!keyframes.count() && (
              <TimelinePropertyDurationBar
                keyframes={keyframes}
                objectID={objectID}
                timelineHeight={height}
              />
            )}
            {keyframes.map(keyframe => (
              <Keyframe
                key={keyframe.id}
                time={keyframe.time}
                keyframe={keyframe}
                objectID={objectID}
                timelineHeight={height}
              />
            ))}
          </TimelinePropertyKeyframes>
        </TimelineProperty>
      )}
    </PopoverTrigger>
  );
};

KeyframesTimeline.defaultProps = {
  height: 32,
};

KeyframesTimeline.propTypes = {
  objectID: PropTypes.string.isRequired,
  keyframesMap: PropTypes.instanceOf(Map).isRequired,
  height: PropTypes.number,
  setCurrentTime: PropTypes.func.isRequired,
  selectObjectOnly: PropTypes.func.isRequired,
};

const container = connect(
  (state, { objectID }) => ({
    keyframesMap: getObjectKeyframes(state, objectID),
  }),
  {
    setCurrentTime: playbackActions.setCurrentTime,
    selectObjectOnly: actions.selectObjectOnly,
  },
);

export default container(KeyframesTimeline);
