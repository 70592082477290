import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { assets as assetsAPI } from '../api';
import {
  removeObjectsByAssetPath,
  addAsset,
  removeAsset,
  moveAsset,
  updateAssetPath,
} from '../project';
import { clearObjectsSelection } from '../editor';
import { showErrorNotification } from '../Notification';
import * as actions from './actions';
import * as types from './types';

export const errors = {
  uploadAsset: 'Could not upload your asset. Try again later.',
  deleteAsset: 'Could not delete your asset. Try again later.',
  moveAsset: 'Could not move your asset. Try again later.',
};

export function* uploadAssetSaga(asset) {
  const response = yield call(
    assetsAPI.uploadAsset,
    asset.path,
    asset.file,
    asset.filename,
  );
  yield put(actions.removeUploadEntry(asset.localURL));
  if (response.ok) {
    yield put(addAsset(response.data));
  } else {
    yield put(showErrorNotification(errors.uploadAsset));
  }
}

export function* uploadAssetsSaga(action) {
  const { assets } = action.payload;
  for (let i = 0; i < assets.size; i += 1) {
    const asset = assets.get(i);
    yield fork(uploadAssetSaga, asset);
  }
}

export function* deleteAssetSaga(action) {
  const { assetPath } = action.payload;
  const response = yield call(assetsAPI.removeAsset, assetPath);
  if (response.ok) {
    yield put(clearObjectsSelection());
    yield put(removeObjectsByAssetPath(assetPath));
    yield put(removeAsset(assetPath));
  } else {
    yield put(showErrorNotification(errors.deleteAsset));
  }
}

export function* moveAssetToCollectionSaga(asset, collectionPath) {
  const oldPath = asset.path;
  const newPath = `${collectionPath}/${asset.filename}`;
  if (oldPath === newPath) {
    return;
  }

  const response = yield call(assetsAPI.moveAsset, asset.path, collectionPath);
  if (response.ok) {
    const { data } = response;
    yield put(moveAsset(oldPath, data.path));
    yield put(updateAssetPath(oldPath, data.path));
  } else {
    yield put(showErrorNotification(errors.moveAsset));
  }
}

export function* moveAssetsToCollectionSaga({ payload }) {
  const { collectionPath } = payload;
  let { assets } = payload;
  assets = assets.toArray();

  for (let i = 0; i < assets.length; i += 1) {
    yield fork(moveAssetToCollectionSaga, assets[i], collectionPath);
  }
}

function* panelAssetsSaga() {
  yield takeEvery(types.SET_ASSETS_TO_UPLOAD, uploadAssetsSaga);
  yield takeEvery(types.DELETE_ASSET, deleteAssetSaga);
  yield takeEvery(
    types.REQUEST_MOVE_ASSETS_TO_COLLECTION,
    moveAssetsToCollectionSaga,
  );
}

export default panelAssetsSaga;
