import React from 'react';
import { object } from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { url } from './App';

const RedirectToPieces = ({ match }) => (
  <Redirect
    to={url.pieceList({
      projectID: match.params.projectID,
    })}
  />
);

RedirectToPieces.propTypes = {
  match: object.isRequired,
};

export default withRouter(RedirectToPieces);
