import { List } from 'immutable';
import {
  getObjectIsSelected,
  getIsObjectLocked,
  getCurrentObjectID,
  getSelectedObjectsIDs,
} from './selectors';
import { getCurrentPieceID } from '../stage';
import {
  getChildrensFromObject,
  getMaskByObjectID,
} from '../project/selectors';
import * as types from './types';
import { injectMaskIDs } from '../utils';

// Stage
export const setStageMode = mode => ({
  type: types.SET_STAGE_MODE,
  payload: { mode },
});

export const toggleAlignToContainer = () => ({
  type: types.TOGGLE_ALIGN_TO_CONTAINER,
});

export const setCurrentObjectID = objectID => ({
  type: types.SET_CURRENT_OBJECT,
  payload: { objectID },
});

// Selected objects
export const setSelectedObjects = objects => ({
  type: types.SET_SELECTED_OBJECTS,
  payload: { objects },
});

export const objectLockActionCreator = action => (objectID, ...args) => (
  dispatch,
  getState,
) => {
  if (getIsObjectLocked(getState(), objectID)) {
    return null;
  }

  return dispatch(action(objectID, ...args));
};

export const selectObjectOnlyAction = objectID => ({
  type: types.SELECT_OBJECT_ONLY,
  payload: { objectID },
});
export const selectObjectOnly = objectLockActionCreator(selectObjectOnlyAction);

export const addObjectToSelectionAction = objectID => ({
  type: types.ADD_OBJECT_TO_SELECTION,
  payload: { objectID },
});
export const addObjectToSelection = objectLockActionCreator(
  addObjectToSelectionAction,
);

export const removeObjectFromSelection = objectID => ({
  type: types.REMOVE_OBJECT_FROM_SELECTION,
  payload: { ids: [objectID] },
});

export const removeObjectsFromSelection = ids => ({
  type: types.REMOVE_OBJECT_FROM_SELECTION,
  payload: { ids },
});

export const clearObjectsSelection = () => ({
  type: types.CLEAR_OBJECTS_SELECTION,
});

export const selectObjectOnClick = (clickEvent, objectID) => (
  dispatch,
  getState,
) => {
  clickEvent.stopPropagation();
  if (clickEvent.shiftKey) {
    return dispatch(addObjectToSelection(objectID));
  }

  if (!getObjectIsSelected(objectID, getState())) {
    return dispatch(selectObjectOnly(objectID));
  }

  return null;
};

export const selectObjectOnClickInTimeline = (clickEvent, objectID) => (
  dispatch,
  getState,
) => {
  clickEvent.stopPropagation();
  const state = getState();
  const isObjectSelected = getObjectIsSelected(objectID, state);
  const action = isObjectSelected
    ? removeObjectFromSelection
    : addObjectToSelection;

  if (clickEvent.ctrlKey) {
    return dispatch(action(objectID));
  }

  if (clickEvent.shiftKey) {
    const selectedObjects = getSelectedObjectsIDs(state);
    const parentID = getCurrentObjectID(state);
    const pieceID = getCurrentPieceID(state);
    const childrens = getChildrensFromObject(state, pieceID, parentID);

    const selectedIndex = childrens.keyOf(objectID);
    const [begin, end] = [
      childrens.keyOf(selectedObjects.last()),
      selectedIndex,
    ].sort((a, b) => a - b);

    if (begin === undefined || end === undefined) {
      return dispatch(action(objectID));
    }

    let childrensArr = childrens.slice(begin, end + 1);
    if (selectedIndex !== end) {
      childrensArr = childrensArr.reverse();
    }

    childrensArr.forEach(childrenID => dispatch(action(childrenID)));

    return null;
  }

  if (!isObjectSelected) {
    return dispatch(selectObjectOnly(objectID));
  }

  return null;
};

export const ensureClipPathsAreSelected = (state, objects) => {
  const maskByObjectID = getMaskByObjectID(state);
  return objects.reduce(injectMaskIDs(maskByObjectID), List([]));
};

export const ensureTimelineDragSelection = objectID => (dispatch, getState) => {
  const state = getState();
  const isObjectSelected = getObjectIsSelected(objectID, state);
  let objectsSelection = getSelectedObjectsIDs(state);

  if (!isObjectSelected) {
    objectsSelection = List([objectID]);
    return dispatch(
      setSelectedObjects(ensureClipPathsAreSelected(state, objectsSelection)),
    );
  }

  return dispatch(
    setSelectedObjects(ensureClipPathsAreSelected(state, objectsSelection)),
  );
};

// Selected keyframes

export const setSelectedKeyframes = keyframes => ({
  type: types.SET_SELECTED_KEYFRAMES,
  payload: { keyframes },
});

export const selectKeyframesOnlyAction = (objectID, keyframes) => ({
  type: types.SELECT_KEYFRAMES_ONLY,
  payload: { objectID, keyframes },
});

export const selectKeyframesOnly = objectLockActionCreator(
  selectKeyframesOnlyAction,
);

export const addKeyframesToSelectionAction = (objectID, keyframes) => ({
  type: types.ADD_KEYFRAMES_TO_SELECTION,
  payload: { objectID, keyframes },
});

export const addKeyframesToSelection = objectLockActionCreator(
  addKeyframesToSelectionAction,
);

export const removeKeyframesFromSelection = keyframes => ({
  type: types.REMOVE_KEYFRAMES_FROM_SELECTION,
  payload: { keyframes },
});

export const clearKeyframesSelectionFromObjects = ids => ({
  type: types.CLEAR_OBJECTS_KEYFRAMES_SELECTION,
  payload: { ids },
});

export const clearKeyframesSelectionFromObject = objectID =>
  clearKeyframesSelectionFromObjects([objectID]);

export const clearKeyframesSelection = () => ({
  type: types.CLEAR_KEYFRAMES_SELECTION,
});

export const selectKeyframesOnClick = (
  clickEvent,
  objectID,
  keyframes,
  isSelected,
) => {
  clickEvent.stopPropagation();
  if (clickEvent.shiftKey) {
    if (isSelected) {
      return removeKeyframesFromSelection(keyframes);
    }
    return addKeyframesToSelection(objectID, keyframes);
  }

  return selectKeyframesOnly(objectID, keyframes);
};

export const selectKeyframeOnClick = (
  clickEvent,
  objectID,
  keyframe,
  isSelected,
) => selectKeyframesOnClick(clickEvent, objectID, [keyframe], isSelected);

// Objects Visibility
export const toggleObjectVisibility = objectID => ({
  type: types.TOGGLE_OBJECT_VISIBILITY,
  payload: { objectID },
});

export const clearHiddenObjects = () => ({
  type: types.CLEAR_HIDDEN_OBJECTS,
});

// Objects Changes
export const toggleObjectLock = objectID => ({
  type: types.TOGGLE_OBJECT_LOCK,
  payload: { objectID },
});

export const clearObjectsLock = () => ({
  type: types.CLEAR_OBJECTS_LOCK,
});

// Selected Object Popover
export const setObjectToRename = objectID => ({
  type: types.SET_OBJECT_TO_RENAME,
  payload: objectID,
});

export const resetObjectToRename = () => ({
  type: types.RESET_OBJECT_TO_RENAME,
});

// Editor Last Modification
export const updateLastModification = lastModification => ({
  type: types.UPDATE_LAST_MODIFICATION,
  payload: lastModification,
});

// Editor Background Tasks
export const startBackgroundTasks = () => ({
  type: types.START_BACKGROUND_TASKS,
});

export const stopBackgroundTasks = () => ({
  type: types.STOP_BACKGROUND_TASKS,
});

// Focused Area
export const setFocusedArea = option => ({
  type: types.SET_FOCUSED_AREA,
  payload: option,
});
