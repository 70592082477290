import React from 'react';
import { string } from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors, title4, textRegular } from '../ui';

const useStyles = createUseStyles({
  box: {
    paddingTop: 32,
  },
  title: {
    ...title4,
    color: colors.black4,
    marginBottom: 32,
  },
  description: { ...textRegular, color: colors.black4 },
});

const NoResults = ({ term }) => {
  const classes = useStyles();
  return (
    <div className={classes.box}>
      <h1 className={classes.title}>Search results for “{term}”</h1>
      <p className={classes.description}>
        Could not find any results related to your search. You can try another
        relevant term.
      </p>
    </div>
  );
};

NoResults.propTypes = {
  term: string.isRequired,
};

export default NoResults;
