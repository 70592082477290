import { connect } from 'react-redux';
import { setAssetToReplace } from './actions';
import BaseInput from './BaseInputUploadAsset';

const container = connect(
  null,
  (dispatch, { assetPath }) => ({
    onChange(e) {
      dispatch(setAssetToReplace(assetPath, e.target.files[0]));
    },
  }),
  (_, actions, { assetType, innerRef }) => ({
    ...actions,
    assetType,
    innerRef,
  }),
);

export default container(BaseInput);
