import React from 'react';
import { createUseStyles } from 'react-jss';
import DragLayer from './DragLayer';
import Stage from '../StageComponents';
import Timeline, { TimelineModals } from '../Timeline';
import EditorShortcuts from './EditorShortcuts';

const useStyles = createUseStyles({
  stage: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 0,
    minWidth: 0,
    userSelect: 'none',
  },
});

const ViewStage = () => {
  const classes = useStyles();
  return (
    <>
      <TimelineModals />
      <DragLayer />
      <div className={classes.stage}>
        <EditorShortcuts />
        <Stage />
        <Timeline />
      </div>
    </>
  );
};

export default ViewStage;
