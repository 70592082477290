import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCollectionsPaths } from '../../project';
import {
  AssetIcon,
  Filename,
  RootAssets,
  Collection,
  CollectionContent,
} from '../../Assets';
import { SelectableEntry } from '../../ui';

const SelectAsset = ({ collections, selectedAsset, onChange, assetFilter }) => (
  <>
    {collections.map(collectionPath => (
      <Collection key={collectionPath} path={collectionPath}>
        {({ collectionAssets }) => (
          <CollectionContent>
            {collectionAssets.filter(assetFilter).map(asset => (
              <SelectableEntry
                key={asset.path}
                depth={1}
                isSelected={selectedAsset === asset}
                onClick={() => onChange(asset)}
              >
                <AssetIcon assetType={asset.assetType} />
                <Filename filename={asset.filename} />
              </SelectableEntry>
            ))}
          </CollectionContent>
        )}
      </Collection>
    ))}
    <RootAssets>
      {({ assets }) =>
        !!assets.size && (
          <>
            {assets.filter(assetFilter).map(asset => (
              <SelectableEntry
                key={asset.path}
                depth={0}
                isSelected={selectedAsset === asset}
                onClick={() => onChange(asset)}
              >
                <AssetIcon assetType={asset.assetType} />
                <Filename filename={asset.filename} />
              </SelectableEntry>
            ))}
          </>
        )
      }
    </RootAssets>
  </>
);

SelectAsset.defaultProps = {
  selectedAsset: null,
};

SelectAsset.propTypes = {
  collections: PropTypes.object.isRequired,
  selectedAsset: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  assetFilter: PropTypes.func.isRequired,
};

const container = connect(state => ({
  collections: getCollectionsPaths(state),
}));

export default container(SelectAsset);
