import { SET_FOCUSED_AREA } from './types';
import { createReducer } from '../utils';
import { STAGE_FOCUSED } from './constants';

const setFocusedArea = option => () => option;

const focusedArea = createReducer(STAGE_FOCUSED, {
  [SET_FOCUSED_AREA]: setFocusedArea,
});

export default focusedArea;
