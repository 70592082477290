import React, { Component } from 'react';
import { node, number, bool, func, string } from 'prop-types';
import throttle from 'lodash/throttle';

const defaultSelectedArea = { minX: 0, maxX: 0, minY: 0, maxY: 0 };
const LEFT_CLICK_BUTTON = 0;

class SelectionArea extends Component {
  constructor(props) {
    super(props);
    this.areaRef = React.createRef();
    this.canvas = React.createRef();

    this.origin = { x: 0, y: 0 };
    this.isDragging = false;
    this.offset = { x: 0, y: 0 };
    this.lastSelectedArea = { ...defaultSelectedArea };

    this.onPointerDown = this.onPointerDown.bind(this);
    this.onPointerMove = this.onPointerMove.bind(this);
    this.onPointerUp = this.onPointerUp.bind(this);
    this.isValidClick = this.isValidClick.bind(this);
    this.checkOverflow = this.checkOverflow.bind(this);
    this.onSelectedArea = throttle(
      props.onSelectedArea,
      props.eventThrottleInterval,
    );
  }

  onPointerDown(event) {
    event.stopPropagation();
    if (this.props.disabled || event.button !== LEFT_CLICK_BUTTON) {
      return;
    }

    if (!this.isValidClick(event)) {
      return;
    }

    this.isDragging = true;
    this.lastSelectedArea = { minX: 0, maxX: 0, minY: 0, maxY: 0 };
    event.target.setPointerCapture(event.pointerId);

    const { clientX, clientY } = event;
    this.origin = { x: clientX, y: clientY };
    this.offset = {
      x: this.props.scrollOffsetLeft,
      y: this.props.scrollOffsetTop,
    };
  }

  onPointerMove(event) {
    if (!this.isDragging) {
      return;
    }

    const offsetY = this.offset.y - this.props.scrollOffsetTop;
    const offsetX = this.offset.x - this.props.scrollOffsetLeft;

    const { clientX, clientY } = event;
    const pointerPosition = { x: clientX, y: clientY };
    this.lastPosition = pointerPosition;

    const origin = { ...this.origin };
    origin.y += offsetY;
    origin.x += offsetX;

    const minX = Math.min(origin.x, pointerPosition.x);
    const maxX = Math.max(origin.x, pointerPosition.x);

    const minY = Math.min(origin.y, pointerPosition.y);
    const maxY = Math.max(origin.y, pointerPosition.y);

    const canvasBB = this.canvas.current.getBoundingClientRect();
    const ctx = this.canvas.current.getContext('2d');
    ctx.canvas.width = canvasBB.width;
    ctx.canvas.height = canvasBB.height;
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.beginPath();
    ctx.rect(
      minX - canvasBB.left,
      minY - canvasBB.top,
      maxX - minX,
      maxY - minY,
    );

    const { borderColor, fillColor } = this.props;
    ctx.fillStyle = fillColor;
    ctx.fill();

    if (borderColor) {
      ctx.strokeWidth = 1;
      ctx.strokeStyle = this.props.borderColor;
      ctx.stroke();
    }
    ctx.closePath();

    event.persist();
    this.checkOverflow(event);
    this.lastSelectedArea = { minX, maxX, minY, maxY };
    this.onSelectedArea(event, this.lastSelectedArea);
  }

  onPointerUp(event) {
    if (!this.isDragging) {
      return;
    }

    const canvas = this.canvas.current;
    const ctx = this.canvas.current.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    this.isDragging = false;
    event.target.releasePointerCapture(event.pointerId);
    event.persist();
    this.onSelectedArea(event, this.lastSelectedArea);
    this.lastSelectedArea = { ...defaultSelectedArea };
  }

  isValidClick(event) {
    const {
      leftDeadzone,
      topDeadzone,
      rightDeadzone,
      bottomDeadzone,
    } = this.props;
    const boundingBox = this.areaRef.current.getBoundingClientRect();
    const clientX = event.clientX - boundingBox.x;
    const clientY = event.clientY - boundingBox.y;

    if (
      clientX < leftDeadzone ||
      clientY < topDeadzone ||
      clientX > boundingBox.width - rightDeadzone ||
      clientY > boundingBox.height - bottomDeadzone
    ) {
      return false;
    }

    return true;
  }

  checkOverflow(event) {
    const boundingBox = this.areaRef.current.getBoundingClientRect();
    const startX = boundingBox.x;
    const endX = boundingBox.x + boundingBox.width;
    const startY = boundingBox.y;
    const endY = boundingBox.y + boundingBox.height;

    let scrollLeft = 0;
    let scrollTop = 0;

    if (event.clientX > endX) {
      const distance = event.clientX - endX;
      scrollLeft += distance;
    }

    if (event.clientX < startX) {
      const distance = event.clientX - startX;
      scrollLeft += distance;
    }

    if (event.clientY < startY) {
      const distance = event.clientY - startY;
      scrollTop += distance;
    }

    if (event.clientY > endY) {
      const distance = event.clientY - endY;
      scrollTop += distance;
    }

    if (scrollLeft === 0 && scrollTop === 0) {
      return;
    }

    const { scrollSpeed } = this.props;
    this.props.onScroll(scrollLeft * scrollSpeed, scrollTop * scrollSpeed);
  }

  render() {
    const { children } = this.props;

    return (
      <div
        ref={this.areaRef}
        onPointerDown={this.onPointerDown}
        onPointerMove={this.onPointerMove}
        onPointerUp={this.onPointerUp}
        style={{ width: '100%', height: '100%' }}
      >
        <canvas
          ref={this.canvas}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 10000,
            pointerEvents: 'none',
          }}
        />
        {children}
      </div>
    );
  }
}

SelectionArea.defaultProps = {
  onSelectedArea: () => null,
  disabled: false,
  scrollOffsetLeft: 0,
  scrollOffsetTop: 0,
  onScroll: () => null,
  leftDeadzone: 0,
  topDeadzone: 0,
  rightDeadzone: 0,
  bottomDeadzone: 0,
  scrollSpeed: 0.4,
  fillColor: '#aaccee',
  borderColor: '#3399ff',
  eventThrottleInterval: 300,
};

SelectionArea.propTypes = {
  children: node.isRequired,
  onSelectedArea: func,
  disabled: bool,
  scrollOffsetLeft: number,
  scrollOffsetTop: number,
  onScroll: func,
  scrollSpeed: number,
  leftDeadzone: number,
  topDeadzone: number,
  rightDeadzone: number,
  bottomDeadzone: number,
  fillColor: string,
  borderColor: string,
  eventThrottleInterval: number,
};

export default SelectionArea;
