import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';
import { ImageIcon, VideoIcon } from '../icons';
import { VIDEO_ASSET_TYPE } from '../models';

const useStyles = createUseStyles({
  thumbIcon: {
    width: 16,
    marginRight: 8,
    fill: colors.white,
  },
});

const AssetIcon = ({ assetType }) => {
  const classes = useStyles();

  if (assetType === VIDEO_ASSET_TYPE) {
    return <VideoIcon className={classes.thumbIcon} />;
  }

  return <ImageIcon className={classes.thumbIcon} />;
};

AssetIcon.defaultProps = {
  assetType: null,
};

AssetIcon.propTypes = {
  assetType: PropTypes.string,
};

export default AssetIcon;
