import { getObjectBaseStyle } from '../models';

const getObjectEditableStyle = (object, clipPathObject = null) => ({
  ...getObjectBaseStyle(object),
  ...getObjectBaseStyle(clipPathObject),
  outline: 'none',
  boxSizing: 'content-box',
  boxShadow: 'none',
  touchAction: 'none',
  pointerEvents: 'none',
});

export default getObjectEditableStyle;
