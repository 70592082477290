import round from 'lodash/round';
import { NON_SCALAR_PROPERTIES, PROPERTIES_PRECISION } from './constants';

const roundNonScalarProperties = (value, precision) => {
  return value.round(precision);
};

const roundScalarProperties = (value, precision) => {
  return round(value, precision);
};

export const roundProperty = (property, value) => {
  const precision = PROPERTIES_PRECISION[property];

  if (NON_SCALAR_PROPERTIES.includes(property)) {
    return roundNonScalarProperties(value, precision);
  }

  return roundScalarProperties(value, precision);
};
