import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import range from 'lodash/range';
import { colors } from '../ui';
import { getClockDuration, setCurrentTime, pause } from '../playback';
import { secondSize, px2time } from './utils';

class TimeMarks extends React.Component {
  constructor() {
    super();

    this.setTime = this.setTime.bind(this);
  }

  // eslint-disable-next-line
  UNSAFE_componentWillUpdate({ scrollLeft }) {
    if (this.props.scrollLeft !== scrollLeft) {
      this.elm.scrollLeft = scrollLeft;
    }
  }

  setTime(ev, time) {
    ev.stopPropagation();
    const { left } = ev.target.getBoundingClientRect();

    const currentTime = time * 1000 + px2time(ev.clientX - left);
    const maxClockDuration = this.props.seconds * 1000;

    this.props.pause();
    if (currentTime > maxClockDuration) {
      this.props.setCurrentTime(maxClockDuration);
    } else {
      this.props.setCurrentTime(currentTime);
    }
  }

  render() {
    const { seconds, classes } = this.props;
    return (
      <div
        className={classes.timeMarkers}
        ref={elm => {
          this.elm = elm;
        }}
      >
        {range(seconds + 1).map(second => (
          <div
            role="button"
            key={second}
            className={classes.second}
            tabIndex="-1"
            onClick={ev => this.setTime(ev, second)}
          >
            {second}s
          </div>
        ))}
        <div className={classes.spacer} />
      </div>
    );
  }
}

TimeMarks.propTypes = {
  classes: PropTypes.object.isRequired,
  seconds: PropTypes.number.isRequired,
  scrollLeft: PropTypes.number.isRequired,
  setCurrentTime: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
};

const container = connect(
  state => ({
    seconds: getClockDuration(state) / 1000,
  }),
  { setCurrentTime, pause },
);

const styles = injectSheet({
  timeMarkers: {
    borderBottom: ({ noBorder }) =>
      noBorder ? null : `1px solid ${colors.black2}`,
    display: 'flex',
    height: 32,
    flexShrink: 0,
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '32px',
    overflow: ({ allowOverflow }) => (allowOverflow ? 'visible' : 'hidden'),
  },
  second: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    flexShrink: 0,
    width: secondSize,
  },
  spacer: ({ scrollbarSize }) => ({
    flexShrink: 0,
    width: scrollbarSize,
  }),
});

export default container(styles(TimeMarks));
