import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { explicitNull } from 'airbnb-prop-types';
import isNaN from 'lodash/isNaN';
import isFinite from 'lodash/isFinite';
import round from 'lodash/round';
import Input from './Input';
import StepButtonWrapper from './StepButtonsWrapper';

const handleKeyDown = ({ target, keyCode }) => {
  if (keyCode === 13) {
    target.blur();
  }
};

const FloatInput = ({
  invalid,
  expanded,
  big,
  disabled,
  rounded,
  value,
  min,
  max,
  onChange,
  precision,
}) => {
  const [prevValue, setPrevValue] = useState(null);
  const [internalValue, setInternalValue] = useState(null);

  const currentValue =
    value === null || isNaN(value) ? null : round(value, precision);
  if (currentValue !== prevValue) {
    setPrevValue(currentValue);
    setInternalValue(currentValue);
  }

  const findNearestMinOrMaxNumber = useCallback(
    inputValue => {
      let parsedValue = inputValue;
      if (isFinite(min)) {
        parsedValue = Math.max(parsedValue, min);
      }

      if (isFinite(max)) {
        parsedValue = Math.min(parsedValue, max);
      }

      return parsedValue;
    },
    [min, max],
  );
  const roundValue = useCallback(inputValue => round(inputValue, precision), [
    precision,
  ]);
  const triggerOnChange = useCallback(
    triggeredValue => {
      const parsedValue = Number(triggeredValue);
      if (!triggeredValue || !isFinite(parsedValue)) {
        setInternalValue(prevValue);
        return;
      }

      const newValue = roundValue(findNearestMinOrMaxNumber(parsedValue));
      if (newValue === prevValue) {
        setInternalValue(prevValue);
        return;
      }

      onChange(newValue);
    },
    [prevValue, onChange, roundValue, findNearestMinOrMaxNumber],
  );
  const onChangeHandle = useCallback(
    ({ target }) => {
      setInternalValue(target.value);
    },
    [setInternalValue],
  );
  const onBlurHandle = useCallback(() => {
    triggerOnChange(internalValue);
  }, [internalValue]);
  const onStepUp = useCallback(() => {
    if (!isFinite(internalValue)) {
      return;
    }

    triggerOnChange(internalValue + 1);
  }, [internalValue, setInternalValue, triggerOnChange]);
  const onStepDown = useCallback(() => {
    if (!isFinite(internalValue)) {
      return;
    }

    triggerOnChange(internalValue - 1);
  }, [internalValue, setInternalValue, triggerOnChange]);

  return (
    <StepButtonWrapper
      expanded={expanded}
      big={big}
      disabled={disabled}
      onStepUp={onStepUp}
      onStepDown={onStepDown}
    >
      {inputProps => (
        <Input
          onBlur={onBlurHandle}
          invalid={invalid}
          value={internalValue === null ? '' : internalValue}
          onKeyDown={handleKeyDown}
          onChange={onChangeHandle}
          rounded={rounded}
          {...inputProps}
        />
      )}
    </StepButtonWrapper>
  );
};

FloatInput.propTypes = {
  invalid: PropTypes.bool,
  expanded: PropTypes.bool,
  rounded: PropTypes.bool,
  big: PropTypes.bool,
  disabled: PropTypes.bool,
  precision: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, explicitNull]),
  onChange: PropTypes.func.isRequired,
  min: PropTypes.oneOfType([PropTypes.number, explicitNull]),
  max: PropTypes.oneOfType([PropTypes.number, explicitNull]),
};

FloatInput.defaultProps = {
  invalid: false,
  expanded: false,
  big: false,
  disabled: false,
  rounded: false,
  value: '',
  min: null,
  max: null,
  precision: 0,
};

export default FloatInput;
