import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { SelectableEntry } from '../../ui';
import DeleteAnimation from './DeleteAnimation';

const useStyles = createUseStyles({
  animationEntry: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
});

const AnimationEntry = ({ animation, isSelected, onClick, refetch }) => {
  const classes = useStyles();
  const handleClick = useCallback(() => onClick(animation), [
    animation.id,
    onClick,
  ]);

  return (
    <SelectableEntry isSelected={isSelected} onClick={handleClick}>
      <div className={classes.animationEntry}>
        {animation.name}
        <DeleteAnimation animationID={animation.id} onRemove={refetch} />
      </div>
    </SelectableEntry>
  );
};

AnimationEntry.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  animation: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default AnimationEntry;
