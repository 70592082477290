import { List, Record } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { createReducer } from '../utils';
import * as types from './types';
import { getAllPieces } from '../project';

const setOrderBy = ({ orderBy }) => () => orderBy;

export const SUPPORTED_ORDER_LIST = List([
  { label: 'Size', value: 'size' },
  { label: 'Creation Date', value: 'createdAt' },
  { label: 'Last Updated', value: 'updatedAt' },
]);

export const piecesOrderedByReducer = createReducer(
  SUPPORTED_ORDER_LIST.first(),
  {
    [types.SET_PIECES_ORDER_BY]: setOrderBy,
  },
);

export const ShareLinkModel = Record({
  isLoading: true,
  shareLink: '',
  error: null,
});

const setRequestShareLink = () => () => ShareLinkModel();

const setResponseShareLink = payload => state =>
  state.merge({ ...payload, isLoading: false });

export const shareLinkReducer = createReducer(ShareLinkModel(), {
  [types.REQUEST_SHARE_LINK]: setRequestShareLink,
  [types.RESPONSE_SHARE_LINK]: setResponseShareLink,
});

export default combineReducers({
  piecesOrderedBy: piecesOrderedByReducer,
  shareLink: shareLinkReducer,
});

const getViewPieces = state => state.get('viewPieces');

export const getOrderBy = state => getViewPieces(state).get('piecesOrderedBy');

const getPieceSize = piece => piece.width * piece.height;

const getDateFromPiece = piece => new Date(piece.createdAt);

const getUpdatedAtFromPiece = piece => new Date(piece.updatedAt);

const getSortFunction = state => {
  switch (getOrderBy(state).value) {
    case 'size':
      return (a, b) => {
        if (getPieceSize(a) > getPieceSize(b)) {
          return -1;
        }
        if (getPieceSize(a) < getPieceSize(b)) {
          return 1;
        }

        return 0;
      };
    case 'createdAt':
      return (a, b) => {
        if (getDateFromPiece(a) > getDateFromPiece(b)) {
          return -1;
        }
        if (getDateFromPiece(a) < getDateFromPiece(b)) {
          return 1;
        }

        return 0;
      };
    case 'updatedAt':
      return (a, b) => {
        if (getUpdatedAtFromPiece(a) > getUpdatedAtFromPiece(b)) {
          return -1;
        }
        if (getUpdatedAtFromPiece(a) < getUpdatedAtFromPiece(b)) {
          return 1;
        }

        return 0;
      };
    default:
      return () => 0;
  }
};

export const getSortedPieces = state =>
  getAllPieces(state)
    .sort(getSortFunction(state))
    .map(({ id }) => id)
    .toArray();

export const getShareLink = state => getViewPieces(state).get('shareLink');
