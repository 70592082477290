import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClockDuration, setCurrentTime } from '../playback';
import { setCurrentPieceID, unsetCurrentPieceID } from '../stage';
import {
  getPieceByID,
  getRootNodeFromPieceID,
  getCurrentPiece,
} from '../project';

export const usePieceLoader = pieceID => {
  const dispatch = useDispatch();
  const piece = useSelector(state => getPieceByID(state, pieceID));
  const rootNode = useSelector(state => getRootNodeFromPieceID(state, pieceID));
  const currentPiece = useSelector(state => getCurrentPiece(state));

  useEffect(() => {
    if (!piece || !rootNode) {
      return undefined;
    }

    dispatch(setCurrentPieceID(pieceID));
    dispatch(setClockDuration(piece.duration));

    return () => {
      dispatch(setCurrentTime(0));
      dispatch(unsetCurrentPieceID());
    };
  }, [pieceID]);

  return [piece, rootNode, currentPiece];
};
