import { Set } from 'immutable';
import * as types from './types';
import { REMOVE_OBJECTS } from '../project/types';
import { createReducer } from '../utils';

const toggleObjectVisibility = ({ objectID }) => state => {
  if (state.has(objectID)) {
    return state.remove(objectID);
  }

  return state.add(objectID);
};

const removeObjects = ({ ids }) => state =>
  ids.reduce(
    (newState, removedID) =>
      newState.filterNot(objectID => removedID === objectID),
    state,
  );

const clearHiddenObjects = () => () => Set();

const hiddenObjectsReducer = createReducer(Set(), {
  [types.TOGGLE_OBJECT_VISIBILITY]: toggleObjectVisibility,
  [REMOVE_OBJECTS]: removeObjects,
  [types.CLEAR_HIDDEN_OBJECTS]: clearHiddenObjects,
});

export default hiddenObjectsReducer;
