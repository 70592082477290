import React from 'react';
import { createUseStyles } from 'react-jss';
import { node } from 'prop-types';
import { colors } from '../ui';

const useStyles = createUseStyles({
  content: {
    background: colors.black2,
    color: colors.white,
    display: 'flex',
    flexGrow: 1,
    minHeight: 0,
    '& div': {
      boxShadow: 'none',
    },
  },
});

const Content = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.content}>{children}</div>;
};

Content.propTypes = {
  children: node.isRequired,
};

export default Content;
