import React from 'react';
import { createUseStyles } from 'react-jss';
import { connect } from 'react-redux';
import { bool, string } from 'prop-types';
import { or, explicitNull } from 'airbnb-prop-types';
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalButtons,
  ButtonPrimary,
} from '../../ui';
import { createHideableComponent } from '../../ui/hideable';
import { getShareLink } from '../reducers';
import ShareLink from './ShareLink';

export const ModalContext = createHideableComponent();

const useStyles = createUseStyles({
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const ModalShareLink = ({ forceVisibility, isLoading, error, shareLink }) => {
  const classes = useStyles();
  return (
    <ModalContext.Target hideableOptions={{ isVisible: forceVisibility }}>
      {({ isVisible, hide }) => {
        return (
          <Modal isVisible={isVisible} small>
            <ModalHeader>Sharing link</ModalHeader>
            <ModalContent>
              {isLoading && <span>Creating link, please wait...</span>}
              {error && <span>{error}</span>}
              {shareLink && <ShareLink shareLink={shareLink} />}
            </ModalContent>
            <ModalButtons>
              <div className={classes.buttonBox}>
                <ButtonPrimary
                  onClick={event => {
                    event.preventDefault();
                    hide();
                  }}
                  label="Close"
                />
              </div>
            </ModalButtons>
          </Modal>
        );
      }}
    </ModalContext.Target>
  );
};

ModalShareLink.defaultProps = {
  forceVisibility: undefined,
  error: null,
};

ModalShareLink.propTypes = {
  isLoading: bool.isRequired,
  shareLink: string.isRequired,
  error: string,
  forceVisibility: or([bool, explicitNull()]),
};

const container = connect(state => getShareLink(state).toJS());

export default container(ModalShareLink);
