import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';
import ProjectFailed from './ProjectFailed';

export const PROCESSING = 2;
export const FAILED = 1;
export const READY = 0;

const useStyles = createUseStyles({
  processing: {
    color: colors.info,
    fontSize: 16,
  },
});

const ProjectStatus = ({ status, error }) => {
  const classes = useStyles();
  switch (status) {
    case PROCESSING:
      return (
        <span className={classes.processing}>Project is being processed</span>
      );
    case FAILED:
      return <ProjectFailed error={error} />;
    default:
      return null;
  }
};

ProjectStatus.defaultProps = {
  error: null,
};

ProjectStatus.propTypes = {
  status: PropTypes.number.isRequired,
  error: PropTypes.string,
};

export default ProjectStatus;
