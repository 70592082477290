import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { AlertIcon } from '../../icons';
import { colors } from '../../ui';

const useStyles = createUseStyles({
  alertBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 70,
    backgroundColor: colors.black2,
    marginTop: 8,
    marginBottom: 8,
    padding: [8, 16],
  },
  icon: {
    width: 16,
    height: 16,
    fill: colors.white,
  },
  textMessage: {
    flex: 1,
    fontSize: 10,
    marginRight: 8,
  },
  highlight: {
    color: colors.alert,
  },
});

const areMultipleTypesSelected = selectedTypes => selectedTypes.size > 1;

const renderAlert = (classes, multipleTypesSelected) =>
  multipleTypesSelected ? (
    <p className={classes.textMessage}>
      Properties editing is disabled because different kinds of objects are
      selected. <span className={classes.highlight}>Conflict: clippath</span>
    </p>
  ) : (
    <span className={classes.textMessage}>
      Clippath properties can only be edited individually.
    </span>
  );

const ClipPathAlert = ({ selectedTypes }) => {
  const classes = useStyles();

  return (
    <div className={classes.alertBox}>
      {renderAlert(classes, areMultipleTypesSelected(selectedTypes))}
      <div className={classes.icon}>
        <AlertIcon />
      </div>
    </div>
  );
};

ClipPathAlert.propTypes = {
  selectedTypes: PropTypes.object.isRequired,
};

export default ClipPathAlert;
