import React from 'react';
import { func } from 'prop-types';
import { createUseStyles } from 'react-jss';
import { IconButtonTertiary } from '../../ui';
import { PlayIcon } from '../../icons';

const useStyles = createUseStyles({
  exportPieces: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
    height: '100%',
    marginLeft: 10,
  },
});

const PreviewButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.exportPieces}>
      <IconButtonTertiary onClick={onClick} icon={PlayIcon} />
    </div>
  );
};

PreviewButton.propTypes = {
  onClick: func.isRequired,
};

export default PreviewButton;
