import React from 'react';
import { string } from 'prop-types';
import { IconButtonTertiary, Popover, PopoverTrigger } from '../ui';
import { Ellipsis } from '../icons';
import ResetPiece from './ResetPiece';
import EditPiece from './EditPiece';
import RemovePiece from './RemovePiece';

const PieceActionsPopover = ({ pieceID }) => (
  <>
    <Popover id={pieceID}>
      <ResetPiece pieceID={pieceID} />
      <EditPiece pieceID={pieceID} />
      <RemovePiece pieceID={pieceID} />
    </Popover>
    <PopoverTrigger id={pieceID}>
      {open => (
        <IconButtonTertiary
          onClick={open}
          onContextMenu={open}
          icon={Ellipsis}
        />
      )}
    </PopoverTrigger>
  </>
);

PieceActionsPopover.propTypes = {
  pieceID: string.isRequired,
};

export default PieceActionsPopover;
