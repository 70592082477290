import { Record, Map } from 'immutable';
import { CLIPPATH } from './type';
import { updateObjectProperties, EditorOptions } from './base';
import { getObjectMatrix } from './utils';
import {
  getTransformedObjectCoordinates,
  buildOriginString,
  LEFT,
  TOP,
  ORIGINS,
} from '../../geometry';
import { applyScale } from '../../math';

export const defaultObject = {
  id: '',
  name: '',
  pieceID: '',
  targetObjectID: '',

  type: CLIPPATH,
  inheritance: null,

  clipPath: null,

  keyframes: Map({}),

  // All keys below are not serialized to server
  editorOptions: EditorOptions(),
};

const defaultOrigin = buildOriginString(LEFT, TOP);

const setterMap = {
  left: 'setLeft',
  top: 'setTop',
  width: 'setWidth',
  height: 'setHeight',
  clipPath: 'setClipPath',
};

class ClipPathObject extends Record(defaultObject) {
  get dimensions() {
    if (!this.clipPath) {
      return {
        width: 0,
        height: 0,
      };
    }

    return {
      width: this.clipPath.width,
      height: this.clipPath.height,
    };
  }

  get left() {
    if (!this.clipPath) {
      return 0;
    }

    return this.clipPath.left;
  }

  get top() {
    if (!this.clipPath) {
      return 0;
    }

    return this.clipPath.top;
  }

  get width() {
    return this.dimensions.width;
  }

  get height() {
    return this.dimensions.height;
  }

  get x() {
    if (!this.clipPath) {
      return 0;
    }

    return this.clipPath.left;
  }

  get y() {
    if (!this.clipPath) {
      return 0;
    }

    return this.clipPath.top;
  }

  get matrix() {
    return getObjectMatrix(this);
  }

  scale(scaleX, scaleY, currentTime = null, { origin = defaultOrigin } = {}) {
    const oldCoords = getTransformedObjectCoordinates(this);

    const clipPath = this.clipPath
      .setWidth(applyScale(this.clipPath.width, scaleX))
      .setHeight(applyScale(this.clipPath.height, scaleY));
    let updatedObject = updateObjectProperties(this, { clipPath }, currentTime);

    const newCoords = getTransformedObjectCoordinates(updatedObject);
    const xDifference =
      newCoords[ORIGINS[origin]].x - oldCoords[ORIGINS[origin]].x;
    const yDifference =
      newCoords[ORIGINS[origin]].y - oldCoords[ORIGINS[origin]].y;

    updatedObject = updatedObject.setPosition(
      updatedObject.x - xDifference,
      updatedObject.y - yDifference,
      currentTime,
    );

    return updatedObject;
  }

  setPosition(x, y, currentTime = null) {
    if (!this.clipPath) {
      return this;
    }

    const clipPath = this.clipPath.setLeft(x).setTop(y);
    return updateObjectProperties(this, { clipPath }, currentTime);
  }

  setLeft(left, currentTime = null) {
    if (!this.clipPath) {
      return this;
    }

    const clipPath = this.clipPath.setLeft(left);
    return updateObjectProperties(this, { clipPath }, currentTime);
  }

  setTop(top, currentTime = null) {
    if (!this.clipPath) {
      return this;
    }

    const clipPath = this.clipPath.setTop(top);
    return updateObjectProperties(this, { clipPath }, currentTime);
  }

  setWidth(width, currentTime = null) {
    if (!this.clipPath) {
      return this;
    }

    const clipPath = this.clipPath.setWidth(width);
    return updateObjectProperties(this, { clipPath }, currentTime);
  }

  setHeight(height, currentTime = null) {
    if (!this.clipPath) {
      return this;
    }

    const clipPath = this.clipPath.setHeight(height);
    return updateObjectProperties(this, { clipPath }, currentTime);
  }

  setClipPath(clipPath, currentTime = null) {
    return updateObjectProperties(this, { clipPath }, currentTime);
  }

  apply(properties, currentTime = null) {
    return Object.entries(properties).reduce(
      (object, [property, value]) =>
        object[setterMap[property]](value, currentTime),
      this,
    );
  }

  hasPropertyKeyframes(property) {
    return !this.get('keyframes')
      .filter(keyframe => keyframe.values[property] !== null)
      .isEmpty();
  }
}

export default ClipPathObject;
