import React from 'react';
import PropTypes from 'prop-types';
import InputMultipleLines from '../../ui/Input/InputMultipleLines';

const classContentProperty = 'classContent';

const ClassContent = ({ disabled, modifyClassContent, classContent }) => (
  <>
    <InputMultipleLines
      key={`input-${classContentProperty}`}
      id={`panel-properties-${classContentProperty}`}
      disabled={disabled}
      onChange={event => {
        modifyClassContent(event.target.value);
      }}
      value={!classContent ? '' : classContent}
      expanded
    />
  </>
);

ClassContent.propTypes = {
  classContent: PropTypes.string,
  disabled: PropTypes.bool,
  modifyClassContent: PropTypes.func,
};

ClassContent.defaultProps = {
  classContent: null,
  disabled: true,
  modifyClassContent: () => {},
};

export default ClassContent;
