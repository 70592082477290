import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
    textAlign: 'right',
    '& > *': {
      marginLeft: 8,
    },
  },
});

const ModalFooter = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.footer}>{children}</div>;
};

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalFooter;
