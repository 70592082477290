import React from 'react';
import { createUseStyles } from 'react-jss';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { selectObjectOnClick } from '../editor';
import { time2px, getBarHeight } from './utils';
import { colors } from '../ui';

const useStyles = createUseStyles({
  durationBar: ({ keyframes, timelineHeight }) => ({
    background: colors.gray1,
    cursor: 'pointer',
    height: getBarHeight(timelineHeight),
    left: keyframes.first() && time2px(keyframes.first().time),
    top: (timelineHeight - getBarHeight(timelineHeight)) / 2,
    position: 'absolute',
    width:
      keyframes.first() &&
      keyframes.last() &&
      time2px(keyframes.last().time - keyframes.first().time),
  }),
});

const TimelinePropertyDurationBar = ({ onClick, ...props }) => {
  const classes = useStyles(props);
  return (
    <div
      className={classes.durationBar}
      onClick={onClick}
      role="button"
      tabIndex="-1"
    />
  );
};

TimelinePropertyDurationBar.propTypes = {
  onClick: func.isRequired,
};

const container = connect(
  null,
  {
    selectObjectOnClick,
  },
  (state, actions, ownProps) => ({
    ...state,
    ...ownProps,
    onClick: event => actions.selectObjectOnClick(event, ownProps.objectID),
  }),
);

export default container(TimelinePropertyDurationBar);
