import BaseMovableComponent from '../MovableComponent';
import { px2time } from './utils';

class MovableComponent extends BaseMovableComponent {
  constructor() {
    super();

    this.middlewares = [position => px2time(position.x)];
  }
}

export default MovableComponent;
