import React, { useContext } from 'react';
import { RowContainer, Container, Issues, IssueCard } from '../ViewIssues';
import { SharedPieces } from '../ViewPieces';
import { CustomScroll } from '../CustomScroll';
import SharedIssueActions from './SharedIssueActions';
import SubmitIssueForm from './SubmitIssueForm';
import SharedViewContext from './SharedViewContext';

const SharedView = () => {
  const { isQA } = useContext(SharedViewContext);

  return (
    <RowContainer>
      <SharedPieces />
      {isQA && (
        <Container>
          <Issues>
            {(issues, refresh) => (
              <>
                <CustomScroll>
                  {issues &&
                    issues.map(issue => (
                      <IssueCard
                        key={issue.id}
                        issue={issue}
                        refresh={refresh}
                        renderActions={SharedIssueActions}
                      />
                    ))}
                </CustomScroll>
                <SubmitIssueForm refresh={refresh} />
              </>
            )}
          </Issues>
        </Container>
      )}
    </RowContainer>
  );
};

export default SharedView;
