import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { createHideableComponent } from '../../ui/hideable';
import {
  AssetPreview,
  Modal,
  ModalSpacer,
  IconButtonTertiary,
  ButtonPrimary,
  ButtonTertiary,
} from '../../ui';

import { PointingHandIcon } from '../../icons';
import { CustomScroll } from '../../CustomScroll';
import SelectAsset from './SelectAsset';
import { changeObjectAsset } from '../../project';

const ModalContext = createHideableComponent();

export const OpenModalButton = props => (
  <ModalContext.Trigger>
    {({ show }) => (
      <IconButtonTertiary icon={PointingHandIcon} onClick={show} {...props} />
    )}
  </ModalContext.Trigger>
);

class ModalAssetChanger extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedAsset: null,
    };

    this.onChange = this.onChange.bind(this);
    this.reset = this.reset.bind(this);
  }

  onChange(asset) {
    this.setState({
      selectedAsset: asset,
    });
  }

  reset() {
    this.setState({
      selectedAsset: null,
    });
  }

  render() {
    const { selectedAsset } = this.state;
    const { classes, onChange, forceIsVisible, currentAsset } = this.props;
    const assetFilter = asset => asset.assetType === currentAsset.assetType;

    return (
      <ModalContext.Target hideableOptions={{ isVisible: forceIsVisible }}>
        {({ isVisible, hide }) => (
          <Modal isVisible={isVisible} small noPadding>
            <div className={classes.content}>
              <AssetPreview asset={selectedAsset} />
              <CustomScroll autoHeight autoHeightMax={160}>
                <SelectAsset
                  selectedAsset={selectedAsset}
                  onChange={this.onChange}
                  assetFilter={assetFilter}
                />
              </CustomScroll>
            </div>
            <div className={classes.footer}>
              <ButtonPrimary
                label="Select"
                disabled={selectedAsset === null}
                onClick={() => {
                  hide();
                  onChange(selectedAsset);
                  this.reset();
                }}
                extended
              />
              <ModalSpacer />
              <ButtonTertiary
                label="Cancel"
                onClick={() => {
                  hide();
                  this.reset();
                }}
                extended
              />
            </div>
          </Modal>
        )}
      </ModalContext.Target>
    );
  }
}
ModalAssetChanger.defaultProps = {
  forceIsVisible: undefined,
};

ModalAssetChanger.propTypes = {
  currentAsset: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  forceIsVisible: PropTypes.bool,
};

const container = connect(
  null,
  {
    changeObjectAsset,
  },
  (stateProps, actions, props) => ({
    ...props,
    onChange(asset) {
      actions.changeObjectAsset(props.objectID, asset);
    },
  }),
);

const style = injectSheet({
  content: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  footer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 16,
  },
});

export default container(style(ModalAssetChanger));
