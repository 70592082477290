import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles({
  spacer: {
    flexGrow: 1,
  },
});

const Spacer = () => {
  const classes = useStyle();

  return <div className={classes.spacer} />;
};

export default Spacer;
