import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';
import { ArrowDownIcon, ArrowUpIcon } from '../../icons';
import colors from '../colors';
import { ItemModel, itemToString } from './models';
import * as typography from '../typography';

const useStyles = createUseStyles(theme => ({
  button: {
    ...typography.textRegular,
    background: 'none',
    border: '1px solid',
    borderColor: theme.fg,
    borderRadius: ({ rounded }) => (rounded ? 16 : 0),
    cursor: 'pointer',
    width: '100%',
    minWidth: 140,
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      borderColor: colors.primaryLight,
      boxShadow: 'none',
    },
    '&:enabled:hover $label': {
      borderColor: colors.primaryLight,
    },
    '&:disabled': {
      cursor: 'default',
      opacity: 0.35,
    },
  },
  label: {
    background: 'none',
    border: 0,
    color: theme.fg,
    flexGrow: 1,
    height: '100%',
    textAlign: 'left',
    paddingLeft: 16,
    lineHeight: '30px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    width: 28,
    flexShrink: 0,
    fill: theme.fg,
    background: 'transparent',
    paddingRight: 16,
    marginLeft: 'auto',
    display: 'inline-block',
  },
}));

const Button = ({
  placeholder,
  isOpen,
  selectedItem,
  disabled,
  buttonProps,
  rounded,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles({ ...props, rounded, theme });
  return (
    <button
      type="button"
      {...buttonProps}
      className={classes.button}
      disabled={disabled}
    >
      <div className={classes.label}>
        {itemToString(selectedItem) || placeholder}
      </div>
      <div className={classes.icon}>
        {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </div>
    </button>
  );
};

Button.defaultProps = {
  selectedItem: null,
  rounded: true,
};

Button.propTypes = {
  disabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  buttonProps: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedItem: PropTypes.oneOfType([
    PropTypes.instanceOf(ItemModel),
    PropTypes.instanceOf(null),
  ]),
  rounded: PropTypes.bool,
};

export default Button;
