import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import colors from '../colors';

const useStyles = createUseStyles({
  base: {
    borderStyle: 'none',
    cursor: 'pointer',
    height: 12,
    width: 12,
    backgroundColor: 'transparent',
    fill: colors.white,
    '&:disabled': {
      cursor: 'default',
    },
  },
  timelineButton: {
    extend: 'base',
    '&:active': {
      color: 'white',
      '& svg': {
        fill: colors.white,
      },
    },
  },
  timelineButtonActive: {
    extend: 'base',
    fill: colors.yellow,
  },
});

const TimelineToggle = ({ isActive, icon, ...props }) => {
  const classes = useStyles();
  const cls = isActive ? classes.timelineButtonActive : classes.timelineButton;
  const Icon = icon;
  return (
    <button type="button" className={cls} {...props}>
      <Icon />
    </button>
  );
};

TimelineToggle.defaultProps = {
  isActive: false,
};

TimelineToggle.propTypes = {
  isActive: PropTypes.bool,
  icon: PropTypes.func.isRequired,
};

export default TimelineToggle;
