import { func } from 'prop-types';
import { connect } from 'react-redux';
import { getAssetsWithoutCollection } from '../project';

const RootAssets = ({ assets, children }) => children({ assets });

RootAssets.propTypes = {
  children: func.isRequired,
};

const container = connect(state => ({
  assets: getAssetsWithoutCollection(state),
}));

export default container(RootAssets);
