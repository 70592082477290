import React from 'react';
import ButtonSwitchState from './ButtonSwitchState';
import MenuActions from './MenuActions';
import ModalExportPieces from './ModalExportPieces';
import Header from './Header';
import Logo from './Logo';
import Title from './Title';
import TimeTracker from './TimeTracker';
import ButtonValidator from './ButtonValidator';
import ValidateProject from './ValidateProject';

const HeaderViewProject = () => (
  <Header>
    <Logo />
    <ValidateProject>
      <Title />
      <TimeTracker />
      <ButtonSwitchState />
      <ButtonValidator />
      <MenuActions />
      <ModalExportPieces />
    </ValidateProject>
  </Header>
);

export default HeaderViewProject;
